.attack-networks-wrapper {

  .requestlist-dropdown {
    .device-dropdown button {
      background: transparent;
      border: none;

      &::after {
        display: none;
      }
      
    }

    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .show {
      .dropdown-toggle {
        border: none;
      }
    }

    .dropdown-menu.show {
      background: #0C0D11;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      border-radius: 4px;
      // inset: 0px auto auto 0px !important;
      inset: auto 0px auto auto !important;
      transform: translate(0px, 0px) !important;
      color: rgba(255, 255, 255, 0.64);
      width: 165px;
      font-weight: 500;

      .drop-item {
        padding: 5px 9px;

        &:hover {
          background: #ffffff20;
        }
      }
    }
  }



}




.attack-failSharing-wrapper {
  max-width: 70%;
  margin: auto;

}

.add-supplier-modal {
  .supplier-input-wrapper {
    border: 2px solid transparent;

    input {
      border: none;
      background: transparent;
    }

    &:focus-within {
      border: 2px solid #99D2EE;
      border-radius: 4px;
      background: #f6f8fb;
    }

    .attack-multi-select-dropdown.show .dropdown-toggle {
      border: 2px solid transparent !important;
    }

    .attack-multi-select-dropdown .dropdown-toggle {
      border: 2px solid transparent !important;
    }
  }

  .error-field {
    border: 2px solid #f36060 !important;
  }
}

.edit-supplier-modal {

  .disable-edit-feature {
    pointer-events: none;

    .supplier-input-wrapper {
      background: transparent !important;
    }

    .attack-multi-select-dropdown {
      input {
        display: none;
      }
    }
  }

  .supplier-input-wrapper {
    border: 2px solid transparent;

    input {
      border: none;
      background: transparent;
    }

    &:focus-within {
      border: 2px solid #99D2EE;
      border-radius: 4px;
      background: #f6f8fb;
    }

    .attack-multi-select-dropdown.show .dropdown-toggle {
      border: 2px solid transparent !important;
    }

    .attack-multi-select-dropdown .dropdown-toggle {
      border: 2px solid transparent !important;
    }

  }

  .managed-supplier-btn {
    background: #40D88E !important;
    color: #fff !important;
    border: none !important;

    &:active {
      border: none !important;
      background: #40D88E !important;
    }

    &:focus {
      border: none !important;
      background: #40D88E !important;

    }
  }

  .search-supplier-input {
    margin-top: 9px;
    padding-left: 10px;
    border: none;
    height: 34px;
    background: #f6f8fb;
    border-radius: 4px;

    &::placeholder {
      color: #9499aa;
    }
  }

  .user-detail-dropdown {
    .device-dropdown button {
      background: transparent;
      border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .show {
      .dropdown-toggle {
        border: none;
        // border: 1px solid #f36060;
        // border-radius: 2px;
      }
    }

    .dropdown-menu.show {
      background: #0c0d11;
      box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
      border-radius: 4px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      width: 200px;
    }

    .notification-btn {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.64);
      padding: 4px 13px;
    }

    .remove-btn {
      cursor: pointer;
      color: #f36060;
      padding: 5px 10px 5px 13px;
    }
  }


  .compliance-filter-dropdown {
    .tick {
      display: none;
    }

    .custom_drop-down {
      background: transparent;

      button {
        .selected-option {
          color: #616778;
          background-color: #ffffff25;
        }
      }

      .dropdown-menu.show {
        background: #0C0D11;
        color: rgba(255, 255, 255, 0.64);
        width: max-content;
      }

      .selected {
        color: rgba(255, 255, 255, 0.64) !important;
      }

      .dropdown_item {
        color: rgba(255, 255, 255, 0.64);

        &.selected {
          background-color: #ffffff25 !important;
        }

        &:hover {
          background-color: #ffffff25;
        }
      }
    }

  }

}