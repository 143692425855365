.attack-multi-select-dropdown {


    .hide-focus-outline {
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }


    &.show {
        .dropdown-toggle {
            border: 2px solid #99D2EE !important;
            width: -webkit-fill-available;
            background: #f6f8fb !important;

            input {
                background: transparent !important;
            }

            // img {
            //     margin-left: 5px;
            // }
        }
    }

    .dropdown-toggle:focus-within {
        border: 2px solid #99D2EE !important;
        width: -webkit-fill-available;
        background: #f6f8fb !important;
    }


    .dropdown-toggle {
        input {
            background: transparent !important;
        }

        border: 2px solid transparent !important;

        &::after {
            display: none;
        }

        height: auto;
        min-height: 32px;
        border-radius: 4px;
        // max-width: 200px;
        // min-width: 200px;
        padding: 3px;
        padding-left: 5px;
        background: transparent !important;
        border-color: transparent
    }

    .dropdown-menu.show {
        transform: translate(0px, 0px) !important;
        inset: auto 0px auto 0px !important;
    }

    .dropdown-toggle {
        &:focus-within {
            .typing-input {
                display: flex !important;
            }

            .selected-value {
                display: none !important;
            }
        }

        .typing-input {
            display: none;
        }

        .selected-value {
            display: flex;
        }
    }
}