.border-parent .border-item {
  border-bottom: 1px solid #ebedf1;
}

.border-parent .border-item:last-child {
  border-bottom: 1px solid transparent;
}

.border-parent .border-item.border-item-top:not(:last-child) {
  border-top: 1px solid #ebedf1;
}

.table-row-parent {
  .table-row-child {
    width: 12rem;
  }
}

.drop-drag-issue {
  inset: auto 0px auto auto !important;
}

.animated-search-input-close {
  width: 0px;
  border: none;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  input {
    width: 0px;
    border: none;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
}

.animated-search-input-open {
  width: 120px;
  border: none;
  -webkit-transition: width 1s ease-in-out;
  -moz-transition: width 1s ease-in-out;
  -o-transition: width 1s ease-in-out;
  transition: width 1s ease-in-out;
  input {
    width: 110px;
    border: none;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
  }
}

.common-big-modal {
  .modal-body {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .line-grey {
    border-top: 1px solid #ebedf1;
  }

  .modal-content {
    width: 500px;
  }

  .asset-name-wrapper {
    border: 2px solid #ebedf1;

    input {
      border: none;
      background: transparent;
    }

    &:focus-within {
      border: 2px solid #99D2EE;
      border-radius: 4px;
      background: #f6f8fb;
    }
  }

  .attack-bigmodal-input-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    // padding-left: 5px;
    border-radius: 4px;
    height: 32px;
    width: auto;
    padding-left: 5px;

    input {
      border: none;
      background: transparent;
    }

    border: 2px solid transparent;


    &:focus-within {
      border: 2px solid #99D2EE;
      border-radius: 4px;
      background: #f6f8fb;
    }
  }

  .attack-bigmodal-textarea-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid transparent;
    // padding-left: 5px;
    // height: 32px;
    width: auto;
    // padding-left: 5px;

    textarea {
      border: none;
      background: transparent;
      width: 100%;
      padding: 5px;
    }

    border: 2px solid transparent;


    &:focus-within {
      border: 2px solid #99D2EE;
      border-radius: 4px;
      background: #f6f8fb;
    }
  }

  .attack-bigmodal-dropdown {
    .custom_drop-down {
      width: fit-content;
    }
  }

  .attack-bigModal-datepicker {
    .custom_date_picker {
      height: 31px;
      background-color: transparent;
      border: 1px solid #fff;

      &:before {
        content: "";
        background: url("../../../public/images/attack-surface/date-icon.svg");
        height: 15px;
        width: 16px;
        background-repeat: no-repeat;
        background-position: 2px -1px;
        position: relative;
        left: -2px;
      }
    }
  }

  .hide-dropdown-border{
    .attack-multi-select-dropdown .dropdown-toggle:focus-within {
      border: 2px solid transparent !important;
      width: -webkit-fill-available;
      background: transparent !important;
  }
  }
}

.insights-hover-effect {
  .arrow {
    visibility: hidden;
  }

  border-radius: 4px;

  &:hover {
    background: #ebedf1;

    .arrow {
      visibility: visible;
    }
  }
}

.panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

// div#mainBody::-webkit-scrollbar {
//   display: none;
// }

.assets-search-filter-dropdown {
  .dropdown-menu.show {
    max-height: 260px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
      scrollbar-width: none;
    }

    scrollbar-width: none;
  }
}


.blurred-div {
  width: 100%;
  height: 29px;
  background: linear-gradient(to top, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.01) 100%);

  top: -31px;
  left: 0;
}

.main-parent-wrapper {
  .disable-attack-row {
    color: #9499AA !important;
    pointer-events: none;
  }

  .header-for-disabled {
    border: 1px solid #FFAC2C50;
    border-radius: 2px;
    padding: 3px 5px;
    margin-left: 10px;
  }


}