.reassign-supplier-modal {
    .round-checkbox label:after {
        border-right: none;
        content: "";
        height: 16px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 1px;
        width: 16px;
        background: #008fd4;
        border-radius: 50px;
        border: 1px solid white;
    }

    .modal-content {
        height: auto;
        max-height: 500px !important;
        width: fit-content;
    }

    .modal-body {
        padding: 16px
    }

    .modal-footer {
        border-top: 1px solid #EBEDF1;
    }




    .disabled-radio {
        label::after {
            background: #BBBEC9 !important;

        }

        input[type="radio"]:checked+label {

            background-color: #BBBEC9 !important;
            border-color: #BBBEC9 !important;
        }



    }















}

.suspend-remove-modal {
    .modal-content {
        height: 600px;
        border-radius: 8px;
    }

    .modal-header {
        border-bottom: 1px solid #EBEDF1 !important;
    }
}

.attribute-dropdown {
    .selected-option {
        color: #616778;
    }

    .dropdown_item.selected {
        background: transparent;

    }

    .dropdown_item .tick {

        margin-top: 0px;

    }

    .dropdown_item {
        &:hover {
            background: transparent;
        }
    }

    .dropdown-menu.show {
        min-width: 200px;
    }


}


.big-modal.rightBack {
    overflow: auto;
    animation-name: slideLgRightBackAnimation !important;
    animation-duration: 0.56s;
    animation-timing-function: ease;
    max-width: 500px;
    position: fixed;
    right: 1em !important;
}

@keyframes slideLgRightBackAnimation {
    0% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(0%);
        transform: translateX(0%);
    }

    100% {
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
        -webkit-transform: translateX(100%);
        transform: translateX(100%);
    }
}



.company-details-modal {
    .user-detail-dropdown {
        .device-dropdown button {
            background: transparent;
            border: none;

            &::after {
                display: none;
            }
        }

        .dropdown-toggle {
            background: #f6f8fb !important;
            border: none !important;
        }

        .show {
            .dropdown-toggle {
                border: none;
                // border: 1px solid #f36060;
                // border-radius: 2px;
            }
        }

        .dropdown-menu.show {
            background: #0c0d11;
            box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
            border-radius: 4px;
            transform: translate(0px, 0px) !important;
            inset: auto 0px auto auto !important;
            width: 200px;
        }

        .notification-btn {
            cursor: pointer;
            color: rgba(255, 255, 255, 0.64);
            padding: 4px 13px;
        }

        .remove-btn {
            cursor: pointer;
            color: #f36060;
            padding: 5px 10px 5px 13px;
        }
    }
}