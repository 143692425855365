.msp-subscription-accounts{




 .subscription-usage-wrapper{
    progress {
        vertical-align: middle;
        border-radius: 7px;
        width: 100%;
        height: 5px;
      
        box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
      }
      
      progress::-webkit-progress-bar {
        background-color: transparent;
        border-radius: 7px;
      }
      
      progress::-webkit-progress-value {
        background-color: #008fd4;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
      }
 }   
}
.msp-subscription-details{
  .tab-header {
    border-bottom: 1px solid #ebedf1;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    border-bottom: 1px solid #008fd4;
    color: #008fd4 !important;
    font-family: "Archivo";
    font-style: normal;
    background: transparent;

    img {
      filter: invert(51%) sepia(55%) saturate(5380%) hue-rotate(173deg) brightness(91%) contrast(101%)
    }
  }

  .nav-pills .nav-link {
    border-radius: 0rem;
    font-family: "Archivo";
    font-style: normal;
    padding: 0.5rem 1.1rem;
  }
}
.msp-account-subscription{
  .billing-table{
    display: grid;
    grid-template-columns: 15% 60% 9% 11% 5%;
  }
}