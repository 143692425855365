@use '../../../breakpoints.scss' as *;

.actioncentreSettingsModal {
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    padding-bottom: 10px;
  }

  .modal-heading {
    font-size: 14px;

    img {
      margin-right: 1rem;
    }
  }

  .close-icon {
    width: 0.7rem;
  }

  .title-text {
    font-size: 14px;
    font-weight: 500;
    color: #0c0d11;
  }

  .subtitle-text {
    font-size: 12px;
    font-weight: 400;
    color: #9499aa;
  }

  .connnect-button {
    img {
      margin-right: 8px;
    }
  }

  .task-creation-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px;
    width: 42px;
    height: 32px;
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    border: none;
    font-weight: 500;
  }

  .landing-page {
    .landing-div {
      padding: 18px 5px;
    }
  }

  .bodr-bottom {
    border-bottom: 1px solid #ebedf1;
  }

  .connect-tool-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2.4rem 0.5rem;

    button {
      height: 32px;
    }

    .title-text {
      margin-bottom: 0.4rem;
    }

    .subtitle-text {
      margin-bottom: 1.5rem;
    }
  }

  //   .custom-control-label::before {
  //     width: 2.6rem;
  //     height: 1.5rem;
  //     border-radius: 50rem;
  //   }
  //   .custom-control-label::after {
  //     border-radius: 50rem;
  //   }
  .setting-switch {
    .custom-control-label {
      transform: scale(1.5);
    }
  }

  label {
    border-radius: 20%;
  }

  .checkbox-div {
    .form-group {
      margin-bottom: 20px;
      margin-right: 1rem;
    }
  }
}

.taskcreationmodalissue {
  .modal-content {
    max-height: 699px !important;
    min-height: 683px !important;
  }
  max-width: 100% !important;
  width: calc(100% - 16px) !important; 
  .modal-content {
    min-width: auto !important;
  }
  @include respond-to(md) {
    width: 600px !important;
  }
}

.action_center_settings_modal {
  .titletext {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #0c0d11;
  }

  .replotposture {
    outline: none;
  }

  .text3 {
    color: #616778;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  .titletextgrey {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #9499aa;
  }

  .editbutton {
    width: 42px;
    height: 32px;
    background: #f6f8fb;
    border-radius: 2px;
    padding: 4px;
    font-family: "Archivo";
    font-style: normal;
    border: 0px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #616778;
  }

  .titlebig {
    font-size: 25px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    color: #1e1f21;
  }

  .googelbutton {
    width: 85%;
    border-radius: 6px;
    height: 48px;
    line-height: 48px;
    padding: 0 16px;
    border: 1px solid #cfcbcb;
    font-size: 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    color: #0c0d11;
    background: #fff;
    outline: none;
    position: relative;
  }

  .googelbutton2 {
    width: 100%;
    border-radius: 6px;
    height: 42px;
    line-height: 40px;
    padding: 0 16px;
    border: 1px solid #726ab9;
    font-size: 16px;
    font-family: "Archivo";
    color: #fff;
    font-style: normal;
    font-weight: 400;
    background: #726ab9;
    outline: none;
    position: relative;
  }

  .w85 {
    width: 85%;
  }

  .txtbl {
    color: #4573d2;
  }

  .googleimg {
    position: absolute;
    left: 20px;
    top: 12px;
  }

  .linethr {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #edeae9;
    line-height: 0.1em;
    margin: 10px 0 20px;
  }

  .linethr span {
    background: #fff;
    padding: 0 10px;
    color: #dddad9;
  }

  .ginput {
    color: #6d6e6f;
    font-family: "Archivo";
    font-size: 16px;
  }

  .ginputtxt {
    color: #6d6e6f;
    font-family: "Archivo";
    font-size: 12px;
  }

  .spinner_loader {
    border: 0.25em solid #bbbec9;
    border-right-color: transparent;
  }

  .loadertxt {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #9499aa;
  }

  .spinner_wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .search_dropdown {
    .dropdown-toggle {
      height: 38px;
      padding: 8px 10px;
      border: 1px solid #ebedf1;
      background-color: #fff;
    }

    .search_input {
      padding: 2px 15px;
    }

    .dropparent {
      padding: 8px 16px;
      color: #9499aa;
    }

    .textsellabel {
      color: #616778;
    }

    .dropdown-menu.show {
      max-height: 211px;
      overflow-y: scroll;
    }
  }

  .switchbig {
    .custom-switch {
      transform: scale(1.8);
    }
  }

  .dropdownnotification {
    width: 140px;

    .dropdown-toggle {
      background: #f6f8fb;
      border: 1px solid #f6f8fb;
      color: #616778;
    }
  }

  .modal_custom_footer {
    position: absolute;
    bottom: 0px;
    padding: 16px 24px 24px;
    border-top: 1px solid #dee2e6;
    width: 100%;
    left: 0px;
    display: flex;
    justify-content: space-between;
  }

  .modal_action_padding .row:first-child {
    padding-left: 24px;
    padding-right: 24px;
  }

  .modal_action_padding {
    height: inherit;
  }

  .action_cancel_btn {
    height: 32px;
    padding: 5px 8px !important;
    font-family: Archivo;
    border: 1px solid #f6f8fb;
    border-radius: 2px;
    background: #f6f8fb;
    color: #616778;
    font-weight: 500;

    &:hover {
      background: #f6f8fb;
      color: #616778;
    }

    &:active {
      background-color: #f6f8fb !important;
      color: #616778 !important;
      border: 1px solid #f6f8fb !important;
    }
  }

  .p24lr {
    padding-left: 24px;
    padding-right: 24px;
  }

  .header-setting {
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: 7rem;
  }

  .notification-header-dropdownx {
    .custom_drop-down {
      border: 1px solid #ebedf1;
      background: transparent;

      .dropdown-toggle {
        width: 114px;
        padding: 7px 5px 7px 5px;
      }

      .dropdown-menu {
        min-width: 114px;
      }

      .drop-placeholder {
        color: #b6b6bf;
      }
    }

    .tick {
      display: none;
    }
  }
}

.modalresetaction {
  .modal-content {
    min-height: fit-content
  }

  .action_cancel_btn {
    height: 32px;
    padding: 5px 8px !important;
    font-family: Archivo;
    border: 1px solid #f6f8fb;
    border-radius: 2px;
    background: #f6f8fb;
    color: #616778;
    font-weight: 500;

    &:hover {
      background: #f6f8fb;
      color: #616778;
    }

    &:active {
      background-color: #f6f8fb !important;
      color: #616778 !important;
      border: 1px solid #f6f8fb !important;
    }
  }
}