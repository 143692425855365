.bigModal .modal-header {
  padding: 45px;
}
.modal-heading {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #101010;
}
.bigModal .modal-body {
  padding: 0px 40px 21px 40px;
}
/* .bigModal input.form-control,
.bigModal .input-group-text,
.bigModal select.form-control {
  border: 1px solid transparent;
  background: #ebecf1;
  height: 46px;
  padding-top: 13px;
  padding-bottom: 13px;
} */
.bigModal input.form-control,
.suggestIntegrationModal select.form-control {
  border-left: 0;
}
.bigModal .input-group-text {
  border-right: 0;
  padding-left: 16px;
}
.bigModal textarea {
  border: 1px solid transparent;
  background: #ebecf1;
  padding: 16px;
}
.bigModal ::-webkit-scrollbar {
  width: 0px;
}
.transParentInput {
  background: transparent !important;
}
.transParentInput:focus {
  box-shadow: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.right.modal-medium .modal-content {
  overflow: auto;
  height: 96vh;
  /* right: 2.6em; */
  margin: 16px 0;
  animation-name: slideMdRightAnimation;
  animation-timing-function: ease;
  animation-duration: 0.56s;
}
.right.modal-large .modal-content {
  overflow: auto;
  height: 96vh;
  /* right: 10.6em; */
  margin: 16px 0;
  animation-name: slideLgRightAnimation;
  animation-duration: 0.56s;
  animation-timing-function: ease;
}
.modal-large.slideHide .modal-content {
  animation-name: slideLgHideAnimation;
  animation-duration: 0.56s;
  animation-timing-function: ease;
}
.modal-medium.slideHide .modal-content {
  animation-name: slideMdHideAnimation;
  animation-duration: 0.56s;
  animation-timing-function: ease;
}
.slideHide .modal-content {
  animation-name: slideHideAnimation;
  animation-duration: 0.5s;
}

.slideHide {
  animation-name: fadeAwayAnimation;
  animation-duration: 0.5s;
}

.modal-content {
  background: #ffffff;
  box-shadow: 0px 0px 80px rgba(16, 16, 16, 0.16);
  border-radius: 16px;
  height: 96vh;
}

.right .modal-dialog {
  position: absolute;
  right: 1em !important;
  margin: 0;
  width: 100%;
}

.center .modal-dialog {
  position: relative;
  right: auto;
  margin: auto;
}

.modalHeader {
  text-align: left;
  position: relative;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  align-items: flex-end;
}

.modalHeader p {
  font-size: 1.7em !important;
}

.modalHeader p.updateButton {
  font-size: 1.1em !important;
}

.modalHeader > * {
  display: inline-block;
}

.modalHeader .exitIcon {
  font-size: 1.3em;
  color: #101010;
  margin-bottom: 0;
}
.modalHeader h1 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 2.5em;
}

.modalHeader .exitIcon {
  position: absolute;
  padding: 0 0.45em;
  top: 37%;
  text-align: center;
  border-radius: 55px;
}

.exitIcon {
  cursor: pointer;
  animation-name: modalAnimateHide;
  animation-duration: 0.5s;
  left: -5px;
}
.exitIcon:hover {
  animation-name: modalCloseAnimation;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes modalAnimateHide {
  from {
    color: rgba(254, 95, 91, 0.9);
    box-shadow: 0 0 6px #f36060;
    border-radius: 55px;
    background-color: rgba(254, 95, 91, 0.08);
  }
  to {
    color: #101010;
    box-shadow: none;
  }
}

@keyframes modalCloseAnimation {
  from {
    color: #101010;
  }
  to {
    color: rgba(254, 95, 91, 0.8);
    box-shadow: 0 0 7px #f36060;
    background-color: rgba(254, 95, 91, 0.06);
  }
}

@keyframes slideRightAnimation {
  from {
    right: -70em;
    display: none;
  }
  to {
    right: 2em;
    display: block;
  }
}

@keyframes slideLgRightAnimation {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideMdRightAnimation {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideHideAnimation {
  0% {
    transform-origin: 0 0;
    transform: translateX(0%);
  }
  100% {
    transform-origin: 0 0;
    transform: translateX(100%);
  }
}
@keyframes slideLgHideAnimation {
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideMdHideAnimation {
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes fadeAwayAnimation {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.7;
  }
}
.technical-create-task{
  cursor: pointer;
}