.delete-user-modal {
  width: 100%;

  .modal-header {
    padding: 13px 10px;
    // border-bottom: 1px solid #dee2e6 !important;
  }
  .modal-content {
    border-radius: 8px;
    max-height: 300px !important;
  }
  .modal-footer {
    // border-top: none;
    padding: 9px;
    // border-top: 1px solid #dee2e6 !important;
  }
  .permission {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c0d11;
    // text-align: center;
  }
  .delete-btn {
    background: #f36060;
    border-radius: 2px;
    padding: 4px 7px;
    color: #fff;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:active {
      border: none !important;
      background: #f36060 !important;
      color: #fff !important;
    }
  }
  .cancel-btn {
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    border: none;
    margin-right: 9px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 7px;
    &:active {
      background: #f6f8fb !important;
      border: none !important;
      color: #616778 !important;
    }
  }
  .user-avatar {
    object-fit: contain;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-name {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;

    /* Grayscale / Gray 100 */

    color: #0c0d11;
  }
  .user-email {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
}
