.grayBackground {
  background: #f6f8fb;
}

.roundedCorners {
  border-radius: 8px;
}

.incidentResponse {
  padding: 1em;
}
.incidentResponse > div {
  background: #ffffff;
  border-radius: 4px;
}
.incidentResponse .input-group > * {
  background:#F6F8FB;
  border-radius: 4px;
  border: none;
  color: #b6b6bf;
  margin-bottom: 0.7em;
}
.incidentResponseForm .input-group-text {
  border: none;
  font-family: Archivo;
  font-weight: 500;
  font-size: 14px;
  /* Gray */
  color: #b6b6bf;
}

.incidentResponseForm .form-control {
  border: none;
  background:#F6F8FB;
  border-radius: 4px;
  padding: 0.5em;
  color: black;
}

.incidentResponseForm .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b6b6bf;
}

.incidentResponseForm button {
  width: 100%;
}

.imageUpload {
  position: relative;
  padding: 0.5em 1em 1.5em 1em;
}

.imageUpload > :nth-child(2) {
  margin-right: 1em;
  font-size: 1.3em;
}

.imageUpload p {
  color: black;
  font-size: 1em;
}

.imageUpload > * {
  display: inline;
}

#file-input {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.form-group {
  margin-bottom: 0;
}

.incidentResponseForm:not(.reusableForm) .input-group {
  margin-bottom: 0.3em;
}

.incidentResponseForm textarea {
  margin-bottom: 1.5em;
}
.incidentResponse .txt-grey {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  display: block;
  color: #b6b6bf;
  line-height: 22.4px;
}
.incidentResponse .txt-grey b {
    color: black;
}
.incidentResponse input.form-control,
.incidentResponse .input-group-text,
.incidentResponse select.form-control {
  border: 1px solid transparent;
  background: #F6F8FB;
  height: 46px;
  padding-top: 13px;
  padding-bottom: 13px;
  cursor: pointer;
}
.incidentResponse input.form-control,
.suggestIntegrationModal select.form-control {
  border-left: 0;
}
.incidentResponse .input-group-text {
  border-right: 0;
  padding-left: 16px;
}
.incidentResponse textarea {
  border: 1px solid transparent;
  background: #ebecf1;
  padding: 16px !important;
  cursor: pointer;
}
.bgNone{
  background:none!important;
}
.incidentResponseForm .input-group  {
  margin-bottom: 0!important;
}
.disableIncidentBtn{
  padding: 15px 23px!important;
}
