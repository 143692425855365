.rewards-v2-dropdown {
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .reward-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #616778;
  }

  &.dropdown-menu {
    min-width: 400px;
    background: #fff;

    border-radius: 8px;
  }
  &.dropdown-menu.show {
    inset: 3px auto auto 200px !important;
  }
  .invite-wrapper {
    cursor: pointer;
    border-radius: 4px;
    padding: 10px 10px;
    &:hover {
      background: #f6f8fb;
    }
  }
  .main-page-wrapper {
    min-height: 400px;
  }
  .rewards-checkbox {
    bottom: 9px;
    transform: scale(0.9);
  }
  .view-rewrads-btn {
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    font-weight: 500;
    border: none;
    &:active {
      border: none !important;
      color: #616778 !important;
      background: #f6f8fb !important;
    }
  }
  .main-botm-wrap {
    border-top: 1px solid #ebedf1;
    padding: 12px 10px 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .social-post-wrapper {
    cursor: default;
    .banner {
      max-width: 370px;
      object-fit: contain;
    }
    .banner-wrapper {
      padding: 9px 16px;
    }
    .post-btn {
      padding: 12px 6px;
      background: #f6f8fb;
      border-radius: 4px;
      color: #616778;
      font-weight: 500;
      border: none;
      flex-grow: 1;
      &:active {
        background: #f6f8fb !important;
        color: #616778 !important;
        border: none !important;
      }
    }
    .post-btn-wrapper {
      padding: 8px 14px;
    }
  }

  .preview-btn {
    padding: 1px 5px;
    border: none;
    background: #f6f8fb;
    border-radius: 2px;
    font-weight: 500;
    color: #616778;
    &:active {
      padding: 1px 5px;
      border: none !important;
      background: #f6f8fb !important;
      border-radius: 2px;
      font-weight: 500;
      color: #616778 !important;
    }
  }

  .email-sender-wrapper {
    input {
      border: none;
      padding-left: 5px;
      flex-grow: 1;
    }
    padding: 14px 14px 5px 10px;
  }
  .email-field-wrapper {
    border: 1px solid #ebedf1;
    border-radius: 4px;
    padding: 3px;
    flex-grow: 1;
    height: 33px;
    margin-right: 5px;
  }
  .custom_drop-down {
    background: transparent;
    .selected-option {
      color: #616778 !important;
    }

    .dropdown-toggle.rewards-toggle::after {
      right: -7px;
      bottom: -2px;
    }
  }
  .copy-link-btn {
    border-radius: 2px;
    border: none;
    padding: 5px;
    font-weight: 500;
    background: #008fd4;
  }
  .send-disabled {
    background: #ebedf1;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
    color: #9499aa;
    border: none;
    padding: 5px;
  }
  .clipboard-txt {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 8px;
    font-weight: 500;
    flex-grow: 1;
    margin-right: 5px;
  }
}

.rewards-dropdown-main-dashboard {
  &.show {
    .dropdown-toggle.rewards-toggle {
      border: 2px solid #008fd458 !important;
      padding: 4px 8px;
      &:hover {
        border: 2px solid #008fd458 !important;
      }
    }
  }
  .dropdown-toggle.rewards-toggle {
    border: 2px solid #008fd400 !important;
    background: transparent !important;
    border: none !important;
    color: transparent !important;
    &:hover {
      background: transparent !important;
      border: none !important;
      color: transparent !important;
    }
    &:active {
      background: transparent !important;
      border: none !important;
      color: transparent !important;
    }
    // padding: 0px !important;
  }
  .dropdown-toggle.rewards-toggle::after {
    display: none;
  }
}
