@use '../../../breakpoints.scss' as *;

.msp_client_price_calculator {}


.create-custom-package-modal {
    .modal-content {
        width: calc(100% - 16px) !important;
        margin: 0 8px;
        @include respond-to(sm) {
            width: 400px;
            margin: 0;
        }
        border-radius: 8px;
        max-height: 400px;
        height: auto;
    }
}


#sources-modal {
    width: calc(100% - 16px) !important;
    // min-height: 415px !important;
    max-width: calc(100% - 16px) !important;
    max-height: 415px !important;
    margin: 0 8px;
    display: none;
    position: fixed;
    z-index: 3000;
    background: white;
    right: 400px;
    border-radius: 4px !important;
    border: 1px solid #ebedf1;
    // box-shadow: 0px 0px 40px rgba(16, 16, 16, 0.08);
    // overflow-y: auto;
    left: 0 !important;
    top: calc(50% - 205px) !important;
    @include respond-to(lg) {
        width: 970px !important;
        max-width: 970px !important;
        left: auto !important;
        top: 50px !important;
        margin: auto;
    }
    &.disable-drag {
        transform: none !important;
    }
}


.sources-modal {
    box-shadow: 0px 12px 24px 0px #10101014;
    position: relative;

    .modal-content {
        width: 970px !important;
        // min-height: 415px !important;
        max-width: 970px !important;
        max-height: 415px !important;
        border-radius: 4px !important;
    }

    .sources-modal-header {
        padding: 7px 12px !important;
        border-bottom: 1px solid #EBEDF1 !important;
        display: flex;
    }

    .sources-modal-footer {
        height: 33px;
        margin: 8px;
    }

    .sources-modal-body {
        padding: 4px !important;
        // max-height: min-content !important;
        // .right-block {
        //     min-width: fit-content !important;
        //     max-width: fit-content  !important;
        // }

        .modal-block {
            height: 326px;

            .left-block {
                height: 100%;
                // overflow: hidden;
                .sidebar-item {
                    gap: 4px;
                    @include respond-to(md) {
                        gap: 16px;
                    }
                }
                min-width: 100px !important;
                word-break: break-word;
                @include respond-to(md) {
                    min-width: 240px !important;
                    word-break: inherit;
                }
            }

            .right-blocka {
                width: 100% !important;
                height: 100%;
                overflow-y: auto;
                scrollbar-width: none;
                border: 1px solid #EBEDF1;

                &::-webkit-scrollbar {
                    display: none;
                }


                table {
                    width: 100%;
                    border-collapse: collapse;

                    thead th {
                        background-color: #EBEDF1;
                    }

                    th,
                    td {
                        border: 1px solid #EBEDF1;
                        text-align: center;
                        padding: 8px;
                    }

                    tr {
                        display: table;
                        width: 100%;
                        table-layout: fixed;
                    }
                }

            }

            .top-block {
                position: sticky;
                top: 0;
                z-index: 1;
                background-color: #f8f9fa;
            }

            position: relative;

            .main-data-block {
                // top:40px
            }

            .right-fade {
                position: absolute;
                right: 1px;
                top: 0px;
                width: 44px;
                height: calc(100% - 31px);
                background: linear-gradient(270deg, #ffffffe5 0%, rgba(255, 255, 255, 0) 100%);
                transform: translateY(30px);
                z-index: 1;
                pointer-events: none;
            }

            .bottom-fade {
                position: absolute;
                bottom: 1px;
                right: 1px;
                width: calc(100% - 242px);
                height: 30px;
                background: linear-gradient(0deg, #ffffffe5 0%, rgba(255, 255, 255, 0) 100%);
                z-index: 1;
                pointer-events: none;
            }
        }

        // table, th, td {
        //     border: 1px solid #EBEDF1;
        //     margin: 0px;
        // }

    }

    .sources-modal-modal-footer {
        padding: 0px 5px !important;
    }

    .sources-loader {
        background-color: #ffffff8a;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
    }
}

.user-details-item {
    cursor: pointer;

    &:hover {
        color: #008FD4 !important;
    }
}

#map-current-spend-modal {
    width: calc(100% - 16px) !important;
    max-width: calc(100% - 16px) !important;
    max-height: 600px !important;
    display: none;
    position: fixed;
    z-index: 3000;
    background: white;
    right: 400px;
    margin: 0 8px;
    border-radius: 8px !important;
    border: 1px solid #ebedf1;
    left: 0 !important;
    top: calc(50% - 300px) !important;
    // box-shadow: 0px 0px 40px rgba(16, 16, 16, 0.08);
    // overflow-y: auto;
    .table-body {
        overflow-x: auto;
        max-width: 100%;
    }
    @include respond-to(lg) {
        width: 800px !important;
        max-width: 800px !important;
        left: auto !important;
        top: 50px !important;
        margin: auto;
    }
    &.disable-drag {
        transform: none !important;
    }
}

.map-current-spend-modal {
    width: 800px !important;
    height: 800px !important;
    border-radius: 8px !important;
    border: 1px solid #ebedf1;
    box-shadow: 0px 0px 40px rgba(16, 16, 16, 0.08);

    .dragger-icon {
        cursor: move;
    }

    .map-current-spend-modal-header {
        padding: 14px !important;
        border-bottom: 1px solid #EBEDF1 !important;
        position: sticky;
        top: 0;
        background: white;
        z-index: 1;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        border-top: 1px solid #ebedf1;
        border-left: 1px solid #ebedf1;
        border-right: 1px solid #ebedf1;
    }

    .map-current-spend-modal-body {
        padding: 0px 16px;
        height: 440px;
        background: white;
        overflow-y: auto;
        scrollbar-width: none;
    }

    .map-current-spend-modal-body::-webkit-scrollbar {
        width: 0px;
    }


    .map-current-spend-modal-footer {
        padding: 0px !important;
        border: none;
        position: sticky;
        bottom: 0;
        background: white;
        z-index: 1;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        border-bottom: 1px solid #ebedf1;
        border-left: 1px solid #ebedf1;
        border-right: 1px solid #ebedf1;
    }

    .blue-checkbox-minus .form-check-input {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        border: 1px solid #9E9E9E;
        border-radius: 2px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .blue-checkbox-minus .form-check-input:checked {
        background-color: #008FD4;
        border: none;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked+label {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked::before {
        content: '−';
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        color: white;
        background-color: #008FD4;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        border: 1px solid #008FD4 !important;
        font-weight: bold;
    }

    .current-spend-table {
        display: grid;
        grid-template-columns: 200px 120px 100px 120px 120px;
        @include respond-to(md) {
            grid-template-columns: 50% 14% 8% 14% 14%;
        }
        // grid-template-columns: 52% 12% 12% 12% 12%;
        // height: 3rem;
        // align-items: center;
    }

    .current-spend-table-footer-block {
        display: grid;
        grid-template-columns: 50% 50%;
        @include respond-to(md) {
            grid-template-columns: 40% 20% 20% 20%;
        }
        // height: 3rem;
        // align-items: center;
    }

    .category-filter-dropdown {
        .dropdown-toggle {
            &::after {
                display: none;
            }

            background: transparent !important;
            border: none !important;
        }

        .dropdown-menu.show {
            min-width: 190px;
            margin-left: 10px !important;
            max-width: 264px !important;
            max-height: 400px !important;
            overflow-y: auto;
            scrollbar-width: none;
        }

        .dropdown-menu.show::-webkit-scrollbar {
            width: 0px;
        }




    }

}

#sm-tooltip-assets .tooltip-inner {
    border-radius: 4px !important;
    padding: 4px 8px !important;
    transform: translateX(calc(50% - 10px));
}


.update-cyber-kpi-modal {
    .modal-content {
        width: 320px !important;
        height: fit-content;
        border-radius: 8px !important;
    }

    .modal-header {
        padding: 14px !important;
    }

    .modal-body {
        padding: 0px 16px;
    }

    .modal-footer {
        padding: 12px !important;
        display: block;
        border: none;
    }

}

.info-modal {
    .modal-content {
        width: 320px !important;
        min-height: 230px !important;
        height: auto !important;
        max-height: 90vh !important;
        overflow-y: auto;
        border-radius: 8px !important;
    }

    .modal-header {
        padding: 14px !important;
    }

    .modal-body {
        padding: 0px 16px;
    }

    .modal-footer {
        padding: 12px !important;
        display: block;
        border: none;
    }

}

.blue-checkbox .form-check-input {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.blue-checkbox .form-check-input:checked {
    background-color: #008FD4;
    border: 1px solid #008FD4;
}

.blue-checkbox .form-check-input:checked::before {
    content: "";
    position: absolute;
    top: 55%;
    left: 46%;
    width: 8px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(-45deg) scaleX(-1)
}

// .blue-checkbox .form-check-input:hover {
//     border-color: #008FD4;
// }



//----------------------------------

.managed-security-header {
    border-bottom: 1px solid #ebecf1;
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 8px 0px 8px;
    position: sticky;
    z-index: 2;


    .left-side-header {
        width: 90%;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        /* Prevent wrapping for proper horizontal scrolling */
        font-size: 14px;
        white-space: nowrap;
        overflow-x: auto;
        /* Add horizontal scrolling */
        scrollbar-width: thin;
        /* Optional: For thinner scrollbars */
        -ms-overflow-style: none;
        /* Hide scrollbar for IE and Edge */
        scroll-behavior: smooth;
        /* Optional: Smooth scrolling behavior */

        .nav-link {
            padding: 5px 0 10px 0;
            border-bottom: 1px solid #fff;
        }

        .nav-link.active {
            background-color: transparent !important;
            border-bottom: 1px solid rgb(0, 143, 212);
            border-radius: 0;
        }

        .active img {
            filter: brightness(100%);
        }
    }

    /* Hide scrollbar for Webkit browsers (optional) */
    .left-side-header::-webkit-scrollbar {
        display: none;
    }

    .right-side-header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
    }
}

.price_calulator_wrapper {
    .security-posture-block {
        padding: 20px 0px;
        margin: 16px;
        // width: 500px;
        width: 100%;

        .score-block {
            display: block;
            width: 100%;
            justify-content: center;
            margin: auto;
        }
    }

    .calculation-block {
        width: 100%;

        .main-block {
            display: block;
            padding: 16px;

            .msp_assets_inputs_wrapper>div {
                width: 100%;
                margin-right: 5px;
                margin-bottom: 5px;

                input {
                    overflow: hidden;
                }
            }

            .active-package-block {
                .select-active-package-dropdown {
                    .dropdown-toggle {
                        padding: 0;
                        background: transparent;
                        border: none;

                        &:active {
                            background: transparent;
                            border: none;
                        }

                        &::after {
                            display: none;
                        }
                    }

                    &.show {
                        .dropdown-toggle {
                            border: none;
                            background: transparent;
                        }
                    }

                    .btn-primary:not(:disabled):not(.disabled).active,
                    .btn-primary:not(:disabled):not(.disabled):active,
                    .show>.btn-primary.dropdown-toggle {
                        background-color: transparent;
                        border-color: transparent;
                    }

                    .dropdown-menu.show {
                        width: 100% !important;
                        height: 280px !important;
                        overflow-y: scroll;
                        scrollbar-width: none;
                    }

                    .dropdown-menu.show::-webkit-scrollbar {
                        display: none;
                    }

                    .dropdown-menu.wrapper-dropdown.show {
                        transform: translate(0px, 0px) !important;
                        inset: auto auto !important;
                        width: 100% !important;
                        height: 280px !important;
                    }

                    .left-dropdown-dir {
                        transform: translate(0px, 0px) !important;
                        inset: auto 0 auto auto !important;
                    }
                }

                .packages-list-header-block {
                    // display: flex;
                    // align-items: center;
                    // justify-content: space-between;
                }

                .dots-menu-block {
                    width: 30px;
                    display: flex;
                    justify-content: end;
                    align-content: end;
                    margin-top: 10px;
                    height: 100%;
                }
            }


        }
    }

    .package-info-block {
        width: 90%;
        margin: 16px;
    }
}

@include respond-to(md) {
    .price_calulator_wrapper {
        .security-posture-block {
            .score-block {
                display: flex;
            }
        }

        .calculation-block {
            .main-block {
                .msp_assets_inputs_wrapper>div {
                    width: 32%;
                }
            }
        }
    }
}


@include respond-to(lg) {
    .price_calulator_wrapper {
        display: flex;
        padding: 0px 16px;

        .security-posture-block {
            width: 49%;

            .score-block {
                display: flex;
            }
        }

        .calculation-block {
            width: 46%;
            margin-top: 16px;

            .main-block {
                .msp_assets_inputs_wrapper>div {
                    width: 48%;
                }
            }
        }
    }
}

@include respond-to(xl) {
    .price_calulator_wrapper {
        .security-posture-block {
            width: 45%;
        }

        .calculation-block {
            width: 50%;

            .main-block {
                .msp_assets_inputs_wrapper>div {
                    width: 32%;
                }
            }
        }
    }
}


@include respond-to(md) {
    .managed-security-header {
        border-bottom: 1px solid #ebecf1;
        width: 100%;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 16px 0px 16px;
        position: sticky;
        z-index: 2;


        .left-side-header {
            width: 83%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            font-size: 14px;
            white-space: nowrap;

            .nav-link {
                padding: 5px 0 10px 0;
                border-bottom: 1px solid #fff;
            }

            .nav-link.active {
                background-color: transparent !important;
                border-bottom: 1px solid rgb(0, 143, 212);
                border-radius: 0;
            }

            .active img {
                filter: brightness(100%);
            }
        }

        .right-side-header {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 8px;
        }
    }
}