.promise-v2-toast {
  position: fixed;
  z-index: 1000;
  width: 290px;
  border-radius: 4px;
  background: #0c0d11;
  color: #ffffff;
  bottom: -90px;
  visibility: hidden;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all;

  .success-textt {
    width: 65%;
  }
}

.showToast {
  visibility: visible !important;
  -webkit-animation: fadeInToast 0.6s;
  animation: fadeInToast 0.6s;
}

.hideToast {
  -webkit-animation: fadeOutToast 0.6s;
  animation: fadeOutToast 0.6s;
}

@-webkit-keyframes fadeInToast {
  from {
    bottom: -90px;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeInToast {
  from {
    bottom: -90px;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutToast {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: -70px;
    opacity: 0;
  }
}

@keyframes fadeOutToast {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: -70px;
    opacity: 0;
  }
}