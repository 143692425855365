@use '../../../breakpoints.scss' as *;

.user-email-section {
  .or-separator {
    display: flex;
    align-items: center;
    gap: 8px;
    .line {
      flex: 1;
      height: 1px;
      background-color: #EBEDF1;
    }
    .text {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      text-align: center;
      color: #9499AA;
    }
  }
  .sso-method {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .disabled-sso {
    filter: grayscale(1);
    .coming-soon {
      background-color: #EBEDF1;
      padding: 1px 6px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.02em;
      border-radius: 9999px;
      white-space: nowrap;
    }
  }
}

.account-creation-section {
  display: flex;
  .left-section-creation {
    padding: 40px 16px 80px 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    .creating-body {
      width: 280px;
    }
    .loader-section {
      margin-top: 32px;
    }
  }
  .loader {
    animation: rotate 5s linear infinite
  }
}

@include respond-to(sm) {
  .auth-layout {
    .auth-body {
      .auth-signup-sections {
        .account-creation-section {
          .left-section-creation {
            padding: 80px 40px;
            .creating-body {
              width: 320px;
            }
            .loader-section {
              margin-top: 14px;
            }
          }
        }
      }
    }
  }
}

@include respond-to(lg) {
  .auth-layout {
    .auth-body {
      .auth-signup-sections {
        .account-creation-section {
          .left-section-creation {
            padding: 120px 80px;
            width: 480px;
          }
        }
      }
    }
  }
}

.forgot-password-section {
  .send-to-info {
    display: flex;
    gap: 8px;
    .email-send-icon {
      margin-top: 4px;
    }
    span {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
    }
  }
}

.team-invite-section {
  .invite-to,
  .invite-email {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
  }
  .invite-email {
    font-weight: 500;
  }
  .line {
    margin-top: 8px;
    height: 1px;
    background-color: #EBEDF1;
    width: 100%;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
  }
  .logo {
    border-radius: 4px;
  }
}

.qr-code-section {
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
  }
  .qr-code {
    display: flex;
    justify-content: center;
  }
}