.billing-payment-modal {
  input::placeholder {
    color: #9499aa;
  }
  .country-list-dropdown {
    button {
      height: 42px;
    }
    .dropdown-menu.show {
      height: 140px;
      overflow-y: scroll;
    }
  }
  input {
    font-family: "Archivo" !important;
    font-style: normal !important;
    background: #f6f8fb;
    outline: none;
    border: none;
    padding-left: 9px;
  }
  font-family: "Archivo" !important;
  font-style: normal !important;
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c0d11;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }
  .modal-footer {
    padding: 9px;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    .header-name {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #0c0d11;
    }
    .close-icon {
      width: 12px;
    }
  }
  .modal-content {
    border-radius: 8px;
    min-height: 500px;
    max-height: 690px !important;
    height: auto !important;
  }
  .amount-details {
    background: #f6f8fb;
    border-radius: 8px;
    padding: 1rem;
  }
  .billing-interval-dropdown {
    #billing-intervals {
      background: #ffffff;
      border: 1px solid #ebedf1;
      border-radius: 4px;
      width: 100%;
      display: flex;
      color: #0c0d11;
    }

    #billing-intervals::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 2px 2px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 14px;
    }
    #billing-intervals.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }

    .dropdown-menu {
      width: 100%;
      padding: 1rem;
      color: #616778;
    }
    .percentage {
      color: #71db99;
    }
  }

  .show {
    #billing-intervals::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 2px 2px 0px;
      transform: rotate(225deg);
      margin-left: 9px;
      margin-bottom: -3px;
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      right: 14px;
    }
  }

  .card-details {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 10px;
  }
  .card-number {
    width: 65%;
  }
  .card-month {
    width: 20%;
  }
  .card-cvv {
    width: 15%;
  }
  .border-4 {
    border-radius: 4px;
  }
  .form-control {
    color: #9499aa;
    padding-left: 17px !important;
  }
  .payment-btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .payment-method {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 0.7rem 1rem;
  }
  .payment-method-list {
    background: #f6f8fb;
    border-radius: 8px;
    padding: 0.7rem 1rem;
  }
  .round-checkbox label:after {
    border-right: none;
    content: "";
    height: 16px;
    left: 1px;
    opacity: 0;
    position: absolute;
    top: 1px;
    width: 16px;
    background: #008fd4;
    border-radius: 50px;
    border: 1px solid white;
  }

  .round-checkbox input[type="radio"]:checked + label:after {
    opacity: 1;
  }
  .checkbox-wrapper {
    margin-right: 10px;
    margin-bottom: 22px;
  }

  .option-dropdown-method {
    .option-dropdown button {
      background: transparent;
      border: none;
      &::after {
        display: none;
      }
    }
    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }
    .show {
      .dropdown-toggle {
        border: none;
        // border: 1px solid #f36060;
        // border-radius: 2px;
      }
    }
    .dropdown-menu.show {
      // background: #0c0d11;
      // box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      background: #f6f8fb;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);

      border-radius: 4px;
      padding: 12px 18px;
      width: 179px !important;
      height: auto;
      inset: 0px auto auto 0px !important;
      color: #212529; // rgba(255, 255, 255, 0.64);
      cursor: pointer;
    }
  }

  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
  }

  .green-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #40d88e;
  }
  .orange-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #ffac2c;
  }
  .black-clr {
    color: #0c0d11 !important;
  }
}

.add-paymnt-btn {
  background: #008fd4;
  border-radius: 2px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  padding: 4px 6px !important;
}
.add-cancel-btn {
  margin-right: 5px;
  background: #f6f8fb !important;
  border: none !important;
  border-radius: 2px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #616778 !important;
  padding: 4px 6px !important;
  &:active {
    color: #616778 !important;
    background: #f6f8fb !important;
    border: none !important;
  }
  &:hover {
    color: #616778 !important;
    background: #f6f8fb !important;
    border: none !important;
  }
}

.remove-paymnt-btn {
  background: #f36060 !important;
  border: none !important;
  border-radius: 2px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff !important;
  padding: 4px 6px !important;
  &:active {
    background: #f36060 !important;
    color: #ffffff !important;
    border: none !important;
    padding: 4px 6px !important;
  }
}

.remove-payment-modal {
  .modal-content {
    min-height: auto !important;
  }
}
.pointer {
  cursor: pointer;
}
.remove-icon {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #616778 !important;
}
