.labelled-input-number {
  &:hover {
    border: 1px solid #d4d6dd;
  }
  &.disabled-labelled-input {
    .prefix-component {
      filter: invert(80%) sepia(8%) saturate(260%) hue-rotate(190deg)
        brightness(96%) contrast(0%);
    }
    input:disabled {
      background-color: transparent;
    }
  }
  &:focus-within {
    outline: 2px solid #99d2ee;
  }
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
  max-width: 300px;
  //   height: 40px;
  max-height: 100px;
  /* White / White 100 */

  background: #ffffff;
  /* Grayscale/Gray 05 */

  border: 1px solid #ebedf1;
  border-radius: 4px;

  .input-field {
    height: 100%;
    width: 100%;
    border: none;
    padding-left: 5px;

    &::placeholder {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      letter-spacing: 0.02em;

      color: #9499aa;
    }
  }

  .submit-btn-disabled {
    background: #ebedf1;
    color: #9499aa;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    border-color: transparent !important;
  }
}

.error-labelled-input {
  .prefix-component {
    filter: invert(51%) sepia(82%) saturate(4281%) hue-rotate(334deg)
      brightness(123%) contrast(89%);
  }
  border: 1px solid #f36060;
  &:hover {
    border: 1px solid #b2494a;
  }
}
