@use '../../../breakpoints.scss' as *;

.suggest-modal-wrapper {
  position: fixed;
  z-index: 9999; 
  right: 0; 
  top: 0;
  margin: 0;
  @include respond-to(md) {
    margin: 8px;
  }
  @include respond-to(xl) {
    right: 60%;
  }
}

.suggest-modal {
  background-color: #fff;
  box-shadow: 0px 0px 80px 0px #10101029;
  border-radius: 0 !important;
  min-width: 100vw;
  max-width: 100%;
  width: 100%;
  @include respond-to(md) {
    min-width: 576px;
    max-width: 576px;
    border-radius: 8px !important;
  }
  .header {
    padding: 0 12px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid #EBEDF1;
    .navs {
      padding: 12px 0 0 0;
    }
    .cross-btn-wrapper {
      padding: 10px 0;
      .cross-btn {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .body-top {
    padding: 8px 4px 0 4px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
  }
  .body {
    padding: 8px 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100vw;
    @include respond-to(md) {
      width: 100%;
    }
    .body-header {
      padding: 0 8px;
    }
  }
  .body-scrollview {
    height: calc(100vh - 53px);
    overflow-y: auto;
    @include respond-to(md) {
      height: 500px;
    }
  }
  .tab-content {
    padding: 0px;
  }

  .data-section {
    width: 100%;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .suggestion-content {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .section {
      padding: 4px 8px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .header-wrapper {
        padding: 2px 0;
        font-weight: 400;
      }
      .table-header,
      .suggested-item {
        grid-template-columns: 100px 100px 100px 100px;
        @include respond-to(sm) {
          grid-template-columns: 24% 47% 16% 10%;
        }
      }
      .table-header {
        display: grid;
        gap: 8px;
        padding: 0 8px;
      }
      .suggested-item {
        display: grid;
        gap: 8px;
        padding: 8px;
        border-radius: 8px;
        max-width: 100%;
        white-space: pre-wrap;
        &:hover {
          background-color: #F6F8FB;
        }
      }
      .apply-btn {
        padding: 4px 6px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #FFFFFF;
        background-color: #008FD4;
        border-radius: 2px;
        &:disabled {
          background-color: #D3D3D3;
        }
      }
    }
  }
  .recurring-spend-content {
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .table-header,
    .table-item {
      &.small-view {
        grid-template-columns: 150px 150px 150px 150px 150px !important;
      }
    }
    .table-header {
      display: grid;
      gap: 8px;
      padding: 0 8px;
    }
    .table-items {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }
    .table-item {
      padding: 4px 8px;
      display: grid;
      align-items: center;
      gap: 8px;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
