@use '../../../../breakpoints.scss' as *;

.cyber-security-posture {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas: none;
  gap: 16px;
  @include respond-to(md) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-template-areas: 
      "left right"
      "footer footer";
    .section {
      &.footer {
        grid-area: footer;
      }
    }
  }
  @include respond-to(xl) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: none;
    .section {
      &.footer {
        grid-area: auto;
      }
    }
  }
  .section {
    background-color: #F6F8FB;
    border-radius: 8px;
    .header {
      padding: 12px;
    }
    .chart {
      padding: 38px 16px;
    }
    .button {
      background-color: #008FD4;
      cursor: pointer;
      padding: 5px 8px;
      display: flex;
      color: #fff;
      border-radius: 2px;
      .icon {
        transform: rotate(-90deg);
        margin-left: 4px;
      }
    }
  }
}
