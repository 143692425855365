@use '../../../../breakpoints.scss' as *;

.sell-add-request-modal {
    max-width: 100% !important;
    .modal-content {
        width: calc(100% - 16px) !important;
        margin: 0 8px;
        @include respond-to(lg) {
            width: 800px;
        }
        height: 750px !important;
        border-radius: 8px !important;
    }

    .main-container {
      flex-direction: column;
      @include respond-to(lg) {
        flex-direction: row;
        .sell-request-section {
          &:not(:last-child) {
            border-right: 1px solid #dee2e6;
           }
        }
      }
      .sell-request-left,
      .sell-request-right {
        width: 100%;
        @include respond-to(lg) {
          width: 50%;
        }
      }
    }

    .modal-header,
    .modal-footer {
        padding: 14px !important;
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .modal-body {
        padding: 0px !important;
    }

    .blue-checkbox-minus .form-check-input {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        border: 1px solid #9E9E9E;
        border-radius: 2px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .blue-checkbox-minus .form-check-input:checked {
        background-color: #008FD4;
        border: none;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked+label {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked::before {
        content: '−';
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        color: white;
        background-color: #008FD4;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        border: 1px solid #008FD4 !important;
        font-weight: bold;
    }

    .current-spend-table {
        display: grid;
        grid-template-columns: 50% 14% 8% 14% 14%;
        // grid-template-columns: 52% 12% 12% 12% 12%;
        // height: 3rem;
        // align-items: center;
    }

    .current-spend-table-footer-block {
        display: grid;
        grid-template-columns: 40% 20% 20% 20%;
        // height: 3rem;
        // align-items: center;
    }

    .category-filter-dropdown {
        .dropdown-toggle {
            &::after {
                display: none;
            }

            background: transparent !important;
            border: none !important;
        }

        .dropdown-menu.show {
            min-width: 190px;
            margin-left: 10px !important;
            max-width: 264px !important;
            max-height: 400px !important;
            overflow-y: auto;
        }



    }

    .custom_drop-down {
        background: transparent;
        .selected-option {
          color: #616778 !important;
        }
    
        .dropdown-toggle.rewards-toggle::after {
          right: -7px;
          bottom: -2px;
        }

        .dropdown_item { 
            &:hover {
                background-color: transparent;
              }
        }
      }

      .dateDrop {
        width: 100% !important;

        @include respond-to(md) {
          width: 180px !important;
        }
      
        .dropdown-menu.show {
          // inset: auto 180px auto auto !important;
          transform: translate(85px, 5px) !important;
        }
      
        .dropdown-toggle {
          width: 100%;
          ;
        }
      }

      .dropdown-menu.show {
        // inset: auto 0px auto auto !important;
        // transform: translate(0px, 30px) !important;
      }
    
      .dropdown-toggle {
        width: 100%;
        ;
      }


}