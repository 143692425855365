@use '../../../breakpoints.scss' as *;

.billing-wrapper {
  margin-top: 2.5rem !important;
  width: 100%;
  padding: 0 20px;
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    padding: 0;
    margin: auto;
  }
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
    // padding: 1rem;
    width: 100%;
    .subscription-item {
      display: grid;
      grid-template-columns: 275px 275px 218px;
      @include respond-to(md) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .add-payment-btn {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .card-info {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
  .padding-1 {
    padding: 1rem;
  }
  .norm-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
  }

  .change-payment-method-dropdown {
    & button {
      background: transparent;
      border: none;
      &::after {
        display: none;
      }
    }
    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }
    .show {
      .dropdown-toggle {
        border: none;
        // border: 1px solid #f36060;
        // border-radius: 2px;
      }
    }
    .dropdown-menu.show {
      background: #0c0d11;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      border-radius: 4px;
      inset: auto 0px auto auto !important;
      // padding: 1rem;
      width: 195px;
      transform: translate(0px, 0px) !important;
    }
    .drop-btn {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.64);
      padding: 5px 13px;
    }
    .drop-remove-btn {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #f36060;
      padding: 6px 16px;
    }
  }

  .header-dropdown {
    .dropdown-menu.show {
      width: 233px;
    }
  }

  .show-more-btn {
    background: #f6f8fb;
    border-radius: 2px;
    cursor: pointer;
    color: #616778;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
    padding: 4px 24px 4px 6px;
    position: relative;
  }
  .arrow-down:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 45%;
    right: 9%;
  }
  .arrow-up:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    bottom: 34%;
    right: 9%;
  }
}

.cancel-subscription-modal {
  .modal-footer {
    padding: 9px;
    border-top: none !important;
  }
  .modal-content {
    border-radius: 8px;
    height: auto;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
    padding: 12px;
  }
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c0d11;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }

  .downgrade-btn {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    padding: 4px;
    background: #f36060;
    border-radius: 2px;
    border: none;
    &:active {
      background: #f36060 !important;

      border: none !important;
    }
  }
  .cancel-btn {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #f6f8fb;
    border-radius: 2px;
    padding: 4px;
    border: none;
    margin-right: 9px;
    &:active {
      background: #f6f8fb !important;

      border: none !important;
    }
  }
}
.edit-payment-border {
  border: 1px solid rgba(255, 255, 255, 0);
}
#check2-add-payment-method {
  display: none;
}