.note-section {
  // padding: 0px 32px;
  margin-bottom: 8px;
  .user-logo {
    transform: translateY(-2px);
  }
  textarea {
    padding: 5px 8px;
    line-height: 22px;
    border: 1px solid #EBEDF1;
    background: transparent;
    width: 100%;
    min-height: 80px;
    max-height: 300px;
    color: #0C0D11;
    &::placeholder {
      color: #9499AA;
    }
    &::-webkit-resizer {
      opacity: 0;
    }
  }
  .resizer {
    position: absolute;
    right: 4px;
    bottom: 8px;
    pointer-events: none;
    fill: currentColor;
    color: #BBBEC9;
  }
  .last-updated {
    color: #616778;
  }
}