.csd_background
{
    /* Light gray BG */
background: #F6F8FB;
border-radius: 8px;
}

.whiteBG
{
    background: #FFFFFF;
border-radius: 4px;
}

.right-pr
{
    float:right;
    padding-right:15px;
}

.scoreText
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;

/* Black */

color: #101010;
}

.scoreGrey
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    /* display: flex; */
    /* align-items: flex-end; */
    color: #B6B6BF;  
}
.detailList {
    padding-left: 8%;
    padding-right: 8%;
}
.detailList li {
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
}