@use '../../../breakpoints.scss' as *;

.subscription-wrapper {
  margin-top: 2.5rem !important;
  width: 100%;
  padding: 0 20px;
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    padding: 0;
    margin: auto;
  }
  font-family: "Archivo" !important;
  font-style: normal !important;

  .current {
    cursor: context-menu !important;
    background: #ebedf1;
    border: none;
    color: #9499aa;
    font-weight: 500;

    &:active {
      background: #ebedf1 !important;
      border: none !important;
      color: #9499aa !important;
      font-weight: 500 !important;
    }

    &:focus {
      background: #ebedf1 !important;
      border: none !important;
      color: #9499aa !important;
      font-weight: 500 !important;
    }
  }

  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }

  .active {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 3px 12px;
    background: #008fd4 !important;
    border-radius: 4px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border: none;

    &:active {
      color: #ffffff;
      border: none;
      background: #008fd4 !important;
    }
  }

  .deactive {
    padding: 3px 12px;
    background: #f6f8fb;
    border-radius: 6px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #616778;
    border: none;

    span {
      color: #71db99;
    }

    &:active {
      color: #616778 !important;
      border: none !important;
      background: #f6f8fb !important;

      span {
        color: #71db99;
      }
    }
  }

  .option-btn-wrapper {
    padding: 2px;
    background: #f6f8fb;
    border-radius: 6px;
  }

  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
    margin-top: 2rem;
  }

  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
    margin-bottom: 15px;
  }

  .column-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
  }

  .width-controller {
    padding: 0px 12px;
  }

  .price {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #0c0d11;

    span {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #9499aa;
    }
  }

  .includes-heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
    margin-top: 1.1rem;
  }

  ul {
    list-style-type: disc;
  }

  ul {
    li {
      margin-bottom: 4px;
      margin-left: 2rem;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #616778;

      &.extra {
        color: #008fd4;
      }
    }
  }

  .column-container {
    padding: 1.5rem 0.4rem 1.7rem;
  }

  .offer-header {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #0c0d11;
  }

  .font {
    font-family: "Archivo";
    font-style: normal;
  }

  .discount {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #008fd4;
  }

  .price-demand {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #9499aa;
  }

  .price-discount {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616778;
  }

  .border-rt {
    border-right: 1px solid #ebedf1;
  }

  .right-border-parent {
    flex-direction: column;
    .right-border-child {
      &:not(:last-child) {
        border-bottom: 1px solid #ebedf1;
      }
    }
    @include respond-to(md) {
      flex-direction: row;
      .right-border-child {
        &:not(:last-child) {
          border-right: 1px solid #ebedf1;
          border-bottom: none;
        }
      }
    }
  }

  .plan-details-grid {
    div {
      padding: 13px;
    }

    display: grid;
    grid-template-columns: 30% 17.5% 17.5% 17.5% 17.5%;
    border-bottom: 1px solid #ebedf1;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  .updated-plan-details-grid {
    div {
      padding: 13px;
    }

    display: grid;
    grid-template-columns: 23% 19% 19% 19% 20%;
    border-bottom: 1px solid #ebedf1;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }
  .updated-plan-details-saas-grid {
    div {
      padding: 13px;
    }

    display: grid;
    &.two {
      grid-template-columns: 368px 200px 200px;
      @include respond-to(md) {
        grid-template-columns: 44% 28% 28%;
      }
    }
    &.three {
      grid-template-columns: 318px 150px 150px 150px;
      @include respond-to(md) {
        grid-template-columns: 37% 21% 21% 21%;
      }
    }
    border-bottom: 1px solid #ebedf1;

    &:last-child {
      border-bottom: 1px solid transparent;
    }
  }

  .selected-licence {
    border: 1px solid #008fd4;
    border-radius: 2px;
    font-size: 8px !important;
    margin-left: 10px;
    line-height: 14px;
    padding: 0px 5px;
  }

  .plans-wrapper {
    max-height: 0;
    transition: max-height 0.5s ease-out;
  }
}

.trial-period-modal {
  .day-shower {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    background: #f6f8fb;
    color: #f36060;
    font-size: 20px;
    font-weight: 500;
  }

  .modal-content {
    border-radius: 8px;
    width: 340px;
    height: auto;
    margin: auto;
  }

  .modal-footer {
    border-top: 1px solid transparent !important;
  }
}

.extend-trial-modal {
  .modal-content {
    border-radius: 8px;
    width: 550px;
    height: auto;

    .f-black-imp {
      color: #0c0d11 !important;
    }

    .radius-4 {
      border-radius: 4px;
    }

    .border-grey {
      border: 1px solid #ebedf1;
    }
  }

  .modal-footer {
    border-top: 1px solid transparent !important;
  }

  .extend-trial-date-picker {
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    margin-right: 0.3rem;

    .highground-datpicker {
      border: 0px !important;
      background: transparent !important;
    }
  }

  //
  //
  //
  //
  //
  //
  //
  .react-international-phone-country-selector {
    position: relative;
  }

  .react-international-phone-country-selector-button {
    display: flex;
    height: var(--react-international-phone-height, 36px);
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid
      var(
        --react-international-phone-country-selector-border-color,
        var(--react-international-phone-border-color, #ebecf1)
      );
    margin: 0;
    appearance: button;
    -webkit-appearance: button;
    background-color: var(
      --react-international-phone-country-selector-background-color,
      var(--react-international-phone-background-color, white)
    );
    cursor: pointer;
    text-transform: none;
    user-select: none;
  }

  .react-international-phone-country-selector-button:hover {
    background-color: var(
      --react-international-phone-country-selector-background-color-hover,
      whitesmoke
    );
  }

  .react-international-phone-country-selector-button--hide-dropdown {
    cursor: auto;
  }

  .react-international-phone-country-selector-button--hide-dropdown:hover {
    background-color: transparent;
  }

  .react-international-phone-country-selector-button__button-content {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .react-international-phone-country-selector-button__flag-emoji {
    margin: 0 4px;
  }

  .react-international-phone-country-selector-button__flag-emoji--disabled {
    opacity: 0.75;
  }

  .react-international-phone-country-selector-button__dropdown-arrow {
    border-top: var(
        --react-international-phone-country-selector-arrow-size,
        4px
      )
      solid var(--react-international-phone-country-selector-arrow-color, #777);
    border-right: var(
        --react-international-phone-country-selector-arrow-size,
        4px
      )
      solid transparent;
    border-left: var(
        --react-international-phone-country-selector-arrow-size,
        4px
      )
      solid transparent;
    margin-right: 4px;
    transition: all 0.1s ease-out;
  }

  .react-international-phone-country-selector-button__dropdown-arrow--active {
    transform: rotateX(180deg);
  }

  .react-international-phone-country-selector-button__dropdown-arrow--disabled {
    border-top-color: var(
      --react-international-phone-disabled-country-selector-arrow-color,
      #999
    );
  }

  .react-international-phone-country-selector-button--disabled {
    background-color: var(
      --react-international-phone-disabled-country-selector-background-color,
      var(--react-international-phone-disabled-background-color, whitesmoke)
    );
    cursor: auto;
  }

  .react-international-phone-country-selector-button--disabled:hover {
    background-color: var(
      --react-international-phone-disabled-country-selector-background-color,
      var(--react-international-phone-disabled-background-color, whitesmoke)
    );
  }

  .react-international-phone-flag-emoji {
    width: var(--react-international-phone-flag-width, 24px);
    height: var(--react-international-phone-flag-height, 24px);
    box-sizing: border-box;
  }

  .react-international-phone-country-selector-dropdown {
    position: absolute;
    z-index: 1;
    top: var(--react-international-phone-dropdown-top, 44px);
    left: var(--react-international-phone-dropdown-left, 0);
    display: flex;
    width: 300px;
    max-height: 200px;
    flex-direction: column;
    padding: 4px 0;
    margin: 0;
    background-color: var(
      --react-international-phone-dropdown-item-background-color,
      var(--react-international-phone-background-color, white)
    );
    box-shadow: var(
      --react-international-phone-dropdown-shadow,
      2px 2px 16px rgba(0, 0, 0, 0.25)
    );
    color: var(
      --react-international-phone-dropdown-item-text-color,
      var(--react-international-phone-text-color, #222)
    );
    list-style: none;
    overflow-y: scroll;
  }

  .react-international-phone-country-selector-dropdown__preferred-list-divider {
    height: 1px;
    border: none;
    margin: var(
      --react-international-phone-dropdown-preferred-list-divider-margin,
      0
    );
    background: var(
      --react-international-phone-dropdown-preferred-list-divider-color,
      var(--react-international-phone-border-color, #ebecf1)
    );
  }

  .react-international-phone-country-selector-dropdown__list-item {
    display: flex;
    min-height: var(--react-international-phone-dropdown-item-height, 28px);
    box-sizing: border-box;
    align-items: center;
    padding: 2px 8px;
  }

  .react-international-phone-country-selector-dropdown__list-item-flag-emoji {
    margin-right: 8px;
  }

  .react-international-phone-country-selector-dropdown__list-item-country-name {
    overflow: hidden;
    margin-right: 8px;
    font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .react-international-phone-country-selector-dropdown__list-item-dial-code {
    color: var(--react-international-phone-dropdown-item-dial-code-color, gray);
    font-size: var(--react-international-phone-dropdown-item-font-size, 14px);
  }

  .react-international-phone-country-selector-dropdown__list-item:hover {
    background-color: var(
      --react-international-phone-selected-dropdown-item-background-color,
      var(
        --react-international-phone-selected-dropdown-item-background-color,
        whitesmoke
      )
    );
    cursor: pointer;
  }

  .react-international-phone-country-selector-dropdown__list-item--selected,
  .react-international-phone-country-selector-dropdown__list-item--focused {
    background-color: var(
      --react-international-phone-selected-dropdown-item-background-color,
      whitesmoke
    );
    color: var(
      --react-international-phone-selected-dropdown-item-text-color,
      var(--react-international-phone-text-color, #222)
    );
  }

  .react-international-phone-country-selector-dropdown__list-item--selected
    .react-international-phone-country-selector-dropdown__list-item-dial-code,
  .react-international-phone-country-selector-dropdown__list-item--focused
    .react-international-phone-country-selector-dropdown__list-item-dial-code {
    color: var(
      --react-international-phone-selected-dropdown-item-dial-code-color,
      var(--react-international-phone-dropdown-item-dial-code-color, gray)
    );
  }

  .react-international-phone-country-selector-dropdown__list-item--focused {
    background-color: var(
      --react-international-phone-selected-dropdown-item-background-color,
      var(
        --react-international-phone-selected-dropdown-item-background-color,
        whitesmoke
      )
    );
  }

  .react-international-phone-dial-code-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    border: 1px solid
      var(
        --react-international-phone-dial-code-preview-border-color,
        var(--react-international-phone-border-color, #ebecf1)
      );
    margin-right: -1px;
    background-color: var(
      --react-international-phone-dial-code-preview-background-color,
      var(--react-international-phone-background-color, white)
    );
    color: var(
      --react-international-phone-dial-code-preview-text-color,
      var(--react-international-phone-text-color, #222)
    );
    font-size: var(
      --react-international-phone-dial-code-preview-font-size,
      var(--react-international-phone-font-size, 13px)
    );
  }

  .react-international-phone-dial-code-preview--disabled {
    background-color: var(
      --react-international-phone-dial-code-preview-disabled-background-color,
      var(--react-international-phone-disabled-background-color, whitesmoke)
    );
    color: var(
      --react-international-phone-dial-code-preview-disabled-text-color,
      var(--react-international-phone-disabled-text-color, #666)
    );
  }

  .react-international-phone-input-container {
    display: flex;
  }

  .react-international-phone-input-container
    .react-international-phone-country-selector-button {
    border-radius: var(--react-international-phone-border-radius, 4px);
    margin-right: -1px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .react-international-phone-input-container .react-international-phone-input {
    overflow: visible;
    height: var(--react-international-phone-height, 36px);
    box-sizing: border-box;
    padding: 0 8px;
    border: 1px solid var(--react-international-phone-border-color, #ebecf1);
    border-radius: var(--react-international-phone-border-radius, 4px);
    margin: 0;
    background-color: var(--react-international-phone-background-color, white);
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: var(--react-international-phone-text-color, #222);
    font-family: inherit;
    font-size: var(--react-international-phone-font-size, 14px);
    width: 100%;
  }

  .react-international-phone-input-container
    .react-international-phone-input:focus {
    outline: none;
  }

  .react-international-phone-input-container
    .react-international-phone-input--disabled {
    background-color: var(
      --react-international-phone-disabled-background-color,
      whitesmoke
    );
    color: var(--react-international-phone-disabled-text-color, #666);
  }
  
}

.clients-prospects-container {
  flex-direction: column;
  .clients-prospects-item {
    &:not(:last-child) {
      border-bottom: 1px solid #dee2e6;
    }
  }
  @include respond-to(md) {
    flex-direction: row;
    .clients-prospects-item {
      &:not(:last-child) {
        border-bottom: none;
        border-right: 1px solid #dee2e6;
      }
    }
  }
}