@use '../../breakpoints.scss' as *;

.marketplace-page {
    // background-color: red;
    // height: 400px;
    width: 100%;
    display: flex;
    height: 100vh;
    overflow: hidden;

    .marketplace-left-block {
        width: 100% !important;
        border-right: 1px solid #ebedf1;

        @include respond-to(md) {
            width: 240px !important;
        }
    }

    .marketplace-right-block {
        flex: 1;
        overflow-y: auto;
        width: 100% !important;

        .top-block {
            padding: 10px 10px 10px 10px;

            @include respond-to(md) {
                padding: 20px 50px 20px 50px;
            }

            @include respond-to(lg) {
                padding: 40px 102px 16px 102px;
            }

            width: 100% !important;
            height: 164px !important;

            .searchInputFieldWithoutBorder {
                border: none;
                -webkit-transition: width 1s ease-in-out;
                -moz-transition: width 1s ease-in-out;
                -o-transition: width 1s ease-in-out;
                transition: width 1s ease-in-out;
                background: #F6F8FB !important;

                input {
                    border: none;
                    background: transparent;
                    outline: none;
                }

                &:focus-within {
                    border-radius: 4px;
                    background: #f6f8fb !important;
                }
            }
        }

        .bottom-block {
            padding: 20px 10px 102px 10px;

            @include respond-to(md) {
                padding: 20px 50px 120px 50px;
            }

            @include respond-to(lg) {
                padding: 40px 102px 120px 102px;
            }

            .container-block {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                @include respond-to(md) {
                    grid-template-columns: repeat(3, 1fr);
                }
            }

            .container-block-for2 {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                .marketplace-item-block {
                    border: 1px solid #ebedf1;
                    border-radius: 8px;
                }
            }
        }


    }


    .marketplace-right-block::-webkit-scrollbar {
        width: 0px;
    }

    .marketplace-right-block::-webkit-scrollbar-thumb {
        background-color: transparent;
    }

    .marketplace-right-block::-webkit-scrollbar-track {
        background-color: transparent;
    }
}