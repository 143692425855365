.allScenarios {
  .nav-tabs .nav-link.active {
    border: 1px solid #fff;
    border-bottom: 1px solid #008fd4;
    color: #008fd4 !important;
  }
  .nav-tabs .nav-link:hover {
    border: 1px solid #fff;
    border-bottom: 1px solid #008fd4;
  }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-bottom: 1px solid #fff;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .tab-content {
    width: 100%;
  }
}
.price_divider {
  border-bottom: 1px solid #ebecf1;
  width: 100%;
  position: absolute;
  // margin-top: 56px;
  top: 154px;
}
.managedSecurityPacks {
  // .managedSecurityPacksInner{
  //     padding-left:24px;
  //     padding-right:24px;
  // }

  .title {
    color: #101010;
    font-size: 16px;
    font-weight: 500;
  }
  .clrgry {
    color: #b6b6bf;
  }
  .pt32 {
    padding-top: 32px;
  }
  .pt16 {
    padding-top: 16px;
  }
  .inptLbl {
    border: 0px;
    background-color: #f6f8fb;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sposters {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    .innerposture {
      border: 1px solid #ebecf1;
      height: 115px;
      width: 32.5%;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      padding-left: 35px;
      padding-right: 35px;
      color: #101010;
      cursor: pointer;
    }
    .innerpostureactive {
      background: rgba(0, 143, 212, 0.08);
      border: 1px solid #008fd4;
      color: #008fd4;
    }
  }

  .btnBlue {
    background: #008fd4;
    border-radius: 4px;
    padding: 16px 24px;
    color: #fff;
    border: 0px;
    font-family: "Archivo";
  }
}
.bgor {
  background-color: rgba(255, 172, 44, 0.9);
  height: 15px;
  width: 15px;
  display: inline-block;
  border-radius: 50%;
  outline: 1px solid #008fd4;
}

.clrblue {
  color: #008fd4;
}

.title2 {
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
}
.arrowImg {
  position: absolute;
  right: 15px;
}
.arrowImg2 {
  top: -10px;
}
.arrowImg {
  display: none;
}
.dropDownActive {
  background-color: #f6f8fb;
  padding: 0px 12px 1px 12px;
  border-radius: 8px;
  .title {
    margin-bottom: 0px;
    font-size: 14px;
  }
  .title2 {
    margin-top: 0px;
    font-size: 14px;
    margin-bottom: 0px !important;
  }

  .hideBlock {
    display: none;
  }

  .arrowImg {
    display: block;
  }
}

.white-input {
  padding: 8px 15px;
  border: 1px solid #ebecf1;
  border-radius: 4px;
  input {
    border: 0px solid transparent;
    font-family: Archivo, sans-serif;
    padding: 0px;
  }
  input:focus {
    outline: none;
  }
  input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b6b6bf;
    opacity: 1; /* Firefox */
  }
}

.scenario {
  border: 1px solid #ebecf1;
  padding: 24px;
  min-height: 112px;
  border-radius: 8px;
  margin-bottom: 8px;

  .grid-container {
    display: grid;
    grid-template-columns: 60% 40%;
    padding: 0px;
  }
  .grid-item {
    padding: 0px;
  }
  .title {
    color: #101010;
    font-weight: 500;
    margin-bottom: 3px;
    font-size: 16px;
  }
  .title2 {
    color: #b6b6bf;
    font-size: 14px;
  }
  .title3 {
    color: #008fd4;
    font-weight: 500;
    font-size: 16px;
  }
}

.createScenario {
  .scenarioName {
    padding-left: 5px;
  }
  .circleDiv {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .circleHeader {
    font-size: 14px;
  }
  .circleHeaderbrd {
    border-style: solid;
    border-width: 1px;
    border-color: transparent;
    border-left: 0px;
    border-right: 0px;
    border-top: 0px;
    padding-bottom: 10px;
  }
  .addscn {
    color: #b6b6bf;
    padding: 5px 0px 10px 0 !important;
  }

  .scenario_content {
    .grid-container {
      display: grid;
      grid-template-columns: 50% 50%;
      padding: 0px;
    }
    .grid-item {
      padding: 0px;
    }

    .listWrapper {
      padding-top: 16px;
      padding-bottom: 16px;
      //   border-bottom: 1px solid #EBECF1;
      padding-left: 12px;
      padding-right: 12px;

      .title {
        color: #101010;
        font-size: 14px;
        font-weight: 500;
      }
      .listContent {
        margin-top: 15px;
        border-bottom: 1px solid #ebecf1;
        border-radius: 0px;
        padding: 12px;

        .grid-container {
          display: grid;
          grid-template-columns: 2fr 1fr;
          padding: 0px;
        }
        .fnt500 {
          font-weight: 500;
        }
      }
    }

    .annualSwitch {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .submitQuote {
    padding-left: 24px;
    padding-right: 24px;
    .grid-container {
      display: grid;
      grid-template-columns: 50% 50%;
      padding: 0px;
    }
  }
}

.rotatearrow {
  transform: rotate(180deg);
}

.print_container {
  display: grid;
  grid-template-columns: 60% 40%;
  padding: 0px;
  border: 1px solid #ebecf1;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 8px;
  .circleDiv {
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .chartjs-size-monitor {
    position: relative;
  }
  .chartjs-render-monitor,
  .chartjs-render-monitor {
    height: 120px !important;
    width: 230px !important;
  }
}

.bluebtnn {
  background: #008fd4 !important;
  border: 1px solid #008fd4;
  border-radius: 4px;
  font-weight: 500 !important;
  font-size: 14px;
  font-family: "Archivo", sans-serif;
}
.greybtnn {
  background: #ebecf1 !important;
  border-radius: 4px;
  border: 1px solid #ebecf1;
  color: #101010;
  font-weight: 500 !important;
  font-size: 14px;
  font-family: "Archivo", sans-serif;
  border-color: transparent !important;
  color: #101010 !important;
}
.greybtnn:hover {
  color: #101010;
  border: 1px solid #ebecf1;
}
.font-16 {
  font-size: 16px;
  color: #101010;
  font-family: "Archivo", sans-serif;
}
.font-14 {
  font-size: 14px;
  color: #101010;
  font-family: "Archivo", sans-serif;
}
.font-18 {
  font-size: 18px;
  color: #101010;
  font-weight: 500;
  font-family: "Archivo", sans-serif;
}
.printSuccessWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
}
.drop_down_scenario {
  background: transparent;
  padding: 0;
  border: 0px;
}

.drop_down_scenario:hover {
  background: transparent;
  padding: 0;
  border: 0px;
}
.drop_down_scenario:active {
  background: transparent !important;
  padding: 0;
  border: 0px;
}
.drop_down_scenario:focus {
  background: transparent !important;
  padding: 0;
  border: 0px;
}

.search_scenario {
  border-radius: 8px;
  .search {
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    font-family: "Archivo", sans-serif;
    color: #171718;
  }

  .search::-webkit-input-placeholder {
    color: #b6b6bf;
  }
}

.chartscenariowrapper {
  position: relative;
  height: 70px;
  .chartscenario {
    position: absolute;
    top: -28px;
    right: -85px;
    .chartjs-render-monitor,
    .chartjs-render-monitor {
      height: 130px !important;
      width: 100% !important;
    }
    .chartjs-size-monitor {
      position: relative;
    }
  }
}

.detectwrapper {
  .packs {
    display: flex;
    .btnBlue {
      height: 34px;
      border: 1px solid #ebecf1;
      padding: 5px 10px;
      margin-right: 5px;
      cursor: pointer;
      border-radius: 4px;
      background: transparent;
      color: #616778;
      font-family: "Archivo", sans-serif;
      font-size: 16px;
      font-weight: 500;
    }
    .activebtn {
      background: #008fd4;
      color: #fff;
    }
  }
}

.submitQueryModalChart {
  .grey-select2 {
    width: 35%;
    border: 0px !important;
    border-radius: 4px;
  }
  .submitqueryChartWrapper {
    .submitqueryChart {
      .chartjs-render-monitor,
      .chartjs-render-monitor {
        height: 160px !important;
        width: 100% !important;
      }
      // .chartjs-size-monitor{
      //  position: relative;
      // }
    }
  }

  // .modal-dialog {
  //     max-width: 600px !important;
  //     margin: 1.75rem auto;
  // }
  .grid-container {
    display: grid;
    grid-template-columns: 50% 25% 25%;
    padding: 0px;
  }
  .grid-container2 {
    display: grid;
    grid-template-columns: 70% 30%;
    padding: 0px;
  }
  .circleDiv {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .clrgry {
    color: #b6b6bf;
  }
  .clrgreen {
    color: #40d88e;
  }
  .grey-textarea {
    font-size: 14px;
    font-family: "Archivo", sans-serif;
  }

  .grey-textarea::-webkit-input-placeholder {
    color: #b6b6bf;
  }
  .grey-textarea {
    background: #fff;
    border: 1px solid #ebecf1;
  }
  .grey-select {
    .greyText {
      background: #fff;
    }
    border: 1px solid #ebecf1;
    .form-control {
      background: #fff;
      height: calc(1.5em + 0.75rem + 14px);
    }
  }

  .nav-link {
    border-radius: 3px;
    background: #f6f8fb;
  }
  .nav-tabs .nav-link.active {
    background: #008fd4;
    color: #fff !important;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  .nav-link:hover {
    border-bottom: 1px solid transparent;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
  }
  .nav-tabs {
    border-bottom: 0px solid #fff;
  }
  .grey-select option {
    font-size: 14px;
    font-family: "Archivo", sans-serif;
  }
  .fnttitle {
    font-size: 18px;
    color: #101010;
    font-family: "Archivo", sans-serif;
  }
}

.posture_drop_down {
  width: 450px;
  margin: auto;
  .grid-container {
    display: grid;
    width: 100%;
    grid-template-columns: 70% 20%;
    padding: 0px;
  }
  .drop_content {
    display: flex;
  }

  .score__container {
    background: none !important;
  }
  .cyberText {
    position: absolute;
    bottom: -10px;
    left: 46px;
    transform: translate(0%, 0%);

    -moz-transform: translate(91%, -320%) !important;
  }
  .cyberCompText {
    position: absolute;
    bottom: -10px;
    left: 5px;
    transform: translate(0%, 0%);
    -moz-transform: translate(45%, -320%);
  }

  .score__wrapper {
    top: -17px;
  }
  .percentage {
    left: 60%;
    top: 46%;
    width: 65%;
    height: 55%;
    box-shadow: 0px 0px 5px #60e4f352;
  }
  .clrgry {
    color: #b6b6bf;
  }
}
.print_radar {
  position: absolute;
  top: -25px;
  right: -55px;
}

.grey_btnn {
  background: #ebecf1 !important;
  border-radius: 4px;
  border: 1px solid #ebecf1;
  color: #101010;
  font-weight: 500 !important;
  font-size: 14px;
  padding: 16px 24px;
  font-family: "Archivo", sans-serif;
}
.grey_btnn:hover {
  color: #101010;
  border: 1px solid #ebecf1;
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .radarChartMain {
    margin: auto;
    height: 400px;
    width: 500px;
  }

  .priceCalculatorWrapper .chartjs-render-monitor,
  .priceCalculatorWrapper .chartjs-render-monitor {
    height: 380px !important;
    width: 500px !important;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1666px) {
  .radarChartMain {
    margin: auto;
    height: 500px;
    width: 600px;
  }

  .priceCalculatorWrapper .chartjs-render-monitor,
  .priceCalculatorWrapper .chartjs-render-monitor {
    height: 500px !important;
    width: 600px !important;
  }
}

.accordianBtn {
  border: 0;
  background: transparent;
  color: #101010;
  font-weight: 500 !important;
  font-size: 14px;
  font-family: "Archivo", sans-serif;
}

.accordianBtn:focus {
  outline: none;
}

// .animationContent{
//     height: 0  !important;
//     overflow-y: hidden;
//     -webkit-transition: height  1s ease;
//     -moz-transition: height  1s ease;
//     transition: height  1s ease;
// }
// .heightauto{
//     overflow-y: scroll;
//     scrollbar-width: none;
//     height: 300px  !important;
//      -ms-overflow-style: none;
//     /* for Internet Explorer, Edge */
//     scrollbar-width: thin;
// }

.navlinkTab {
  background: transparent;
  padding: 0;
}
.circleHeader {
  white-space: nowrap;
  .nav-link {
    padding: 5px 0 10px 0;
    border-bottom: 1px solid #fff;
  }
  .nav-link.active {
    background-color: transparent !important;
    border-bottom: 1px solid rgb(0, 143, 212);
    border-radius: 0;
  }
  .active img {
    filter: brightness(100%);
  }
}

.fntarchivo {
  font-family: "Archivo", sans-serif;
}

.checkboxstyle {
  .form-check-label {
    border-radius: 20%;
  }
}

.calculator_tooltip {
  .tooltip-inner {
    max-width: 225px;
  }

  .custom_tooltip_wrapper {
    max-width: 225px !important;
  }
}

.replotposture {
  background: #008fd4;
  border-radius: 4px;
  border: 0px;
  color: #fff;
  white-space: nowrap;
  padding: 8px 16px;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
}

.quesIconTooltip {
  background-image: url("../../../../public/images/calculator/ques.svg");
  
  height: 20px;
  width: 20px;
  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -1px;

  &:hover {
    background-image: url("../../../../public/images/calculator/quesb.svg");
  }
}
.chartCustomPrice {
  .chart_round {
    position: relative;
    float: left;
    transform: rotate(214deg);
    zoom: 0.6;
    margin-bottom: 15px;
  }

  .chart_round h2 {
    text-align: center;
    position: absolute;
    line-height: 160px;
    line-height: 160px;
    width: 100%;
    color: #101010;
    font-size: 22px;
    transform: rotate(146deg);

    -moz-transform: scale(0.8) rotate(146deg) translate(16%, -20%);
  }

  svg.chart_filled {
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -moz-transform: scale(0.6) translate(0px, 50px) rotate(-90deg);
  }

  .circle_animation {
    stroke-dasharray: 440;
    stroke-dashoffset: 440;
  }
  .circle_animation.p1 {
    stroke-dasharray: 440;
    stroke-dashoffset: 436.45;
  }

  // .circle_animation_unfilled {
  //   stroke-dasharray: 440;
  //   stroke-dashoffset: 85;
  // }

  .chart_unfilled {
    position: absolute;
    left: 0px;
    -webkit-transform: rotate(-90deg);

    transform: rotate(-90deg);
    z-index: -1;
    -moz-transform: scale(0.6) translate(0px, 50px) rotate(-90deg);
  }

  .circle_animation.p1 {
    stroke-dasharray: 440;
    stroke-dashoffset: 436.45;
  }
  .circle_animation.p2 {
    stroke-dasharray: 440;
    stroke-dashoffset: 432.9;
  }
  .circle_animation.p3 {
    stroke-dasharray: 440;
    stroke-dashoffset: 429.34;
  }
  .circle_animation.p4 {
    stroke-dasharray: 440;
    stroke-dashoffset: 425.79;
  }
  .circle_animation.p5 {
    stroke-dasharray: 440;
    stroke-dashoffset: 422.24;
  }
  .circle_animation.p6 {
    stroke-dasharray: 440;
    stroke-dashoffset: 418.69;
  }
  .circle_animation.p7 {
    stroke-dasharray: 440;
    stroke-dashoffset: 415.14;
  }
  .circle_animation.p8 {
    stroke-dasharray: 440;
    stroke-dashoffset: 411.59;
  }
  .circle_animation.p9 {
    stroke-dasharray: 440;
    stroke-dashoffset: 408.04;
  }
  .circle_animation.p10 {
    stroke-dasharray: 440;
    stroke-dashoffset: 404.49;
  }
  .circle_animation.p11 {
    stroke-dasharray: 440;
    stroke-dashoffset: 400.94;
  }
  .circle_animation.p12 {
    stroke-dasharray: 440;
    stroke-dashoffset: 397.39;
  }
  .circle_animation.p13 {
    stroke-dasharray: 440;
    stroke-dashoffset: 393.84;
  }
  .circle_animation.p14 {
    stroke-dasharray: 440;
    stroke-dashoffset: 390.29;
  }
  .circle_animation.p15 {
    stroke-dasharray: 440;
    stroke-dashoffset: 386.74;
  }
  .circle_animation.p16 {
    stroke-dasharray: 440;
    stroke-dashoffset: 383.19;
  }
  .circle_animation.p17 {
    stroke-dasharray: 440;
    stroke-dashoffset: 379.64;
  }
  .circle_animation.p18 {
    stroke-dasharray: 440;
    stroke-dashoffset: 376.09;
  }
  .circle_animation.p19 {
    stroke-dasharray: 440;
    stroke-dashoffset: 372.54;
  }
  .circle_animation.p20 {
    stroke-dasharray: 440;
    stroke-dashoffset: 368.99;
  }
  .circle_animation.p21 {
    stroke-dasharray: 440;
    stroke-dashoffset: 365.44;
  }
  .circle_animation.p22 {
    stroke-dasharray: 440;
    stroke-dashoffset: 361.89;
  }
  .circle_animation.p23 {
    stroke-dasharray: 440;
    stroke-dashoffset: 358.34;
  }
  .circle_animation.p24 {
    stroke-dasharray: 440;
    stroke-dashoffset: 354.79;
  }
  .circle_animation.p25 {
    stroke-dasharray: 440;
    stroke-dashoffset: 351.24;
  }
  .circle_animation.p26 {
    stroke-dasharray: 440;
    stroke-dashoffset: 347.69;
  }
  .circle_animation.p27 {
    stroke-dasharray: 440;
    stroke-dashoffset: 344.14;
  }
  .circle_animation.p28 {
    stroke-dasharray: 440;
    stroke-dashoffset: 340.59;
  }
  .circle_animation.p29 {
    stroke-dasharray: 440;
    stroke-dashoffset: 337.04;
  }
  .circle_animation.p30 {
    stroke-dasharray: 440;
    stroke-dashoffset: 333.49;
  }
  .circle_animation.p31 {
    stroke-dasharray: 440;
    stroke-dashoffset: 329.94;
  }
  .circle_animation.p32 {
    stroke-dasharray: 440;
    stroke-dashoffset: 326.39;
  }
  .circle_animation.p33 {
    stroke-dasharray: 440;
    stroke-dashoffset: 322.84;
  }
  .circle_animation.p34 {
    stroke-dasharray: 440;
    stroke-dashoffset: 319.29;
  }
  .circle_animation.p35 {
    stroke-dasharray: 440;
    stroke-dashoffset: 315.74;
  }
  .circle_animation.p36 {
    stroke-dasharray: 440;
    stroke-dashoffset: 312.19;
  }
  .circle_animation.p37 {
    stroke-dasharray: 440;
    stroke-dashoffset: 308.64;
  }
  .circle_animation.p38 {
    stroke-dasharray: 440;
    stroke-dashoffset: 305.09;
  }
  .circle_animation.p39 {
    stroke-dasharray: 440;
    stroke-dashoffset: 301.54;
  }
  .circle_animation.p40 {
    stroke-dasharray: 440;
    stroke-dashoffset: 297.99;
  }
  .circle_animation.p41 {
    stroke-dasharray: 440;
    stroke-dashoffset: 294.44;
  }
  .circle_animation.p42 {
    stroke-dasharray: 440;
    stroke-dashoffset: 290.89;
  }
  .circle_animation.p43 {
    stroke-dasharray: 440;
    stroke-dashoffset: 287.34;
  }
  .circle_animation.p44 {
    stroke-dasharray: 440;
    stroke-dashoffset: 283.79;
  }
  .circle_animation.p45 {
    stroke-dasharray: 440;
    stroke-dashoffset: 280.24;
  }
  .circle_animation.p46 {
    stroke-dasharray: 440;
    stroke-dashoffset: 276.69;
  }
  .circle_animation.p47 {
    stroke-dasharray: 440;
    stroke-dashoffset: 273.14;
  }
  .circle_animation.p48 {
    stroke-dasharray: 440;
    stroke-dashoffset: 269.59;
  }
  .circle_animation.p49 {
    stroke-dasharray: 440;
    stroke-dashoffset: 266.04;
  }
  .circle_animation.p50 {
    stroke-dasharray: 440;
    stroke-dashoffset: 262.49;
  }
  .circle_animation.p51 {
    stroke-dasharray: 440;
    stroke-dashoffset: 258.94;
  }
  .circle_animation.p52 {
    stroke-dasharray: 440;
    stroke-dashoffset: 255.39;
  }
  .circle_animation.p53 {
    stroke-dasharray: 440;
    stroke-dashoffset: 251.84;
  }
  .circle_animation.p54 {
    stroke-dasharray: 440;
    stroke-dashoffset: 248.29;
  }
  .circle_animation.p55 {
    stroke-dasharray: 440;
    stroke-dashoffset: 244.74;
  }
  .circle_animation.p56 {
    stroke-dasharray: 440;
    stroke-dashoffset: 241.19;
  }
  .circle_animation.p57 {
    stroke-dasharray: 440;
    stroke-dashoffset: 237.64;
  }
  .circle_animation.p58 {
    stroke-dasharray: 440;
    stroke-dashoffset: 234.09;
  }
  .circle_animation.p59 {
    stroke-dasharray: 440;
    stroke-dashoffset: 230.54;
  }
  .circle_animation.p60 {
    stroke-dasharray: 440;
    stroke-dashoffset: 226.99;
  }
  .circle_animation.p61 {
    stroke-dasharray: 440;
    stroke-dashoffset: 223.44;
  }
  .circle_animation.p62 {
    stroke-dasharray: 440;
    stroke-dashoffset: 219.89;
  }
  .circle_animation.p63 {
    stroke-dasharray: 440;
    stroke-dashoffset: 216.34;
  }
  .circle_animation.p64 {
    stroke-dasharray: 440;
    stroke-dashoffset: 212.79;
  }
  .circle_animation.p65 {
    stroke-dasharray: 440;
    stroke-dashoffset: 209.24;
  }
  .circle_animation.p66 {
    stroke-dasharray: 440;
    stroke-dashoffset: 205.69;
  }
  .circle_animation.p67 {
    stroke-dasharray: 440;
    stroke-dashoffset: 202.14;
  }
  .circle_animation.p68 {
    stroke-dasharray: 440;
    stroke-dashoffset: 198.59;
  }
  .circle_animation.p69 {
    stroke-dasharray: 440;
    stroke-dashoffset: 195.04;
  }
  .circle_animation.p70 {
    stroke-dasharray: 440;
    stroke-dashoffset: 191.49;
  }
  .circle_animation.p71 {
    stroke-dasharray: 440;
    stroke-dashoffset: 187.94;
  }
  .circle_animation.p72 {
    stroke-dasharray: 440;
    stroke-dashoffset: 184.39;
  }
  .circle_animation.p73 {
    stroke-dasharray: 440;
    stroke-dashoffset: 180.84;
  }
  .circle_animation.p74 {
    stroke-dasharray: 440;
    stroke-dashoffset: 177.29;
  }
  .circle_animation.p75 {
    stroke-dasharray: 440;
    stroke-dashoffset: 173.74;
  }
  .circle_animation.p76 {
    stroke-dasharray: 440;
    stroke-dashoffset: 170.19;
  }
  .circle_animation.p77 {
    stroke-dasharray: 440;
    stroke-dashoffset: 166.64;
  }
  .circle_animation.p78 {
    stroke-dasharray: 440;
    stroke-dashoffset: 163.09;
  }
  .circle_animation.p79 {
    stroke-dasharray: 440;
    stroke-dashoffset: 159.54;
  }
  .circle_animation.p80 {
    stroke-dasharray: 440;
    stroke-dashoffset: 155.99;
  }
  .circle_animation.p81 {
    stroke-dasharray: 440;
    stroke-dashoffset: 152.44;
  }
  .circle_animation.p82 {
    stroke-dasharray: 440;
    stroke-dashoffset: 148.89;
  }
  .circle_animation.p83 {
    stroke-dasharray: 440;
    stroke-dashoffset: 145.34;
  }
  .circle_animation.p84 {
    stroke-dasharray: 440;
    stroke-dashoffset: 141.79;
  }
  .circle_animation.p85 {
    stroke-dasharray: 440;
    stroke-dashoffset: 138.24;
  }
  .circle_animation.p86 {
    stroke-dasharray: 440;
    stroke-dashoffset: 134.69;
  }
  .circle_animation.p87 {
    stroke-dasharray: 440;
    stroke-dashoffset: 131.14;
  }
  .circle_animation.p88 {
    stroke-dasharray: 440;
    stroke-dashoffset: 127.59;
  }
  .circle_animation.p89 {
    stroke-dasharray: 440;
    stroke-dashoffset: 124.04;
  }
  .circle_animation.p90 {
    stroke-dasharray: 440;
    stroke-dashoffset: 120.49;
  }
  .circle_animation.p91 {
    stroke-dasharray: 440;
    stroke-dashoffset: 116.94;
  }
  .circle_animation.p92 {
    stroke-dasharray: 440;
    stroke-dashoffset: 113.39;
  }
  .circle_animation.p93 {
    stroke-dasharray: 440;
    stroke-dashoffset: 109.84;
  }
  .circle_animation.p94 {
    stroke-dasharray: 440;
    stroke-dashoffset: 106.29;
  }
  .circle_animation.p95 {
    stroke-dasharray: 440;
    stroke-dashoffset: 102.74;
  }
  .circle_animation.p96 {
    stroke-dasharray: 440;
    stroke-dashoffset: 99.19;
  }
  .circle_animation.p97 {
    stroke-dasharray: 440;
    stroke-dashoffset: 95.64;
  }
  .circle_animation.p98 {
    stroke-dasharray: 440;
    stroke-dashoffset: 92.09;
  }
  .circle_animation.p99 {
    stroke-dasharray: 440;
    stroke-dashoffset: 88.54;
  }
  .circle_animation.p100 {
    stroke-dasharray: 440;
    stroke-dashoffset: 85;
  }
}

// round chart styles price caluclator

.animHg {
  -moz-transition: height 0.2s;
  -ms-transition: height 0.2s;
  -o-transition: height 0.2s;
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
  height: 0px;
  overflow: hidden;
  overflow: hidden;
}

.showanimhg {
  -moz-transition: height 0.2s;
  -ms-transition: height 0.2s;
  -o-transition: height 0.2s;
  -webkit-transition: height 0.2s;
  transition: height 0.2s;
  height: 160px;
  overflow: hidden;
}

.createScenarioWrapper {
  background: #ebf6fc;
  border: 1px dashed #008fd4;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0px;
  margin-bottom: 8px;

  p {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #008fd4;
    display: flex;
    align-items: center;
  }
}

.scenariodate {
  position: absolute;
  right: -30%;
}
.scenariotooltip {
  background: #f6f8fb;
  border-radius: 9999px;
  height: 24px;
  display: flex;
  flex-direction: row;
  width: 118px;
  font-size: 12px;
  color: #616778;
  justify-content: center;
  align-items: center;
  padding: 2px 6px 2px 2px;
  position: absolute;
  right: -30%;
}
.showmorebtn {
  background-color: #f6f8fb;
}

.scenario_content:focus-within {
  background: hsl(199deg, 65%, 73%);
}
.listWrapperbg {
  background: #f6f8fb;
}

.msgToast {
  background: #f0fcf6;
  border-radius: 8px;
  padding: 12px;
  height: 56px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.msgToast2 {
  background: #fff2dd;
  border-radius: 8px;
  padding: 12px;
  height: 56px;
  display: flex;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.scenarioNameWrapper {
  display: flex;
  .editname {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 26px;
  }
  .editcreate {
    input {
      height: 25px;
    }
    .btn-primary {
      font-size: 12px;
      padding: 3px 8px;
      margin: 0;
      line-height: 0px !important;
      height: 26px;
      background: #008fd4;
      border: 1px solid #008fd4;
    }
  }
}

.deleteBtn {
  color: #616778;
  font-weight: 500;
  font-size: 14px;
  padding: 4px;
  width: 81px;
  height: 32px;
  line-height: 22px;
  display: flex;
  align-items: center;
  float: right;
  background: #f6f8fb;
  border-radius: 2px;
}

.deleteBtn2 {
  color: #616778;
  font-weight: 500;
  font-size: 14px;
  padding: 0px 10px;
  width: 105px;
  height: 28px;
  line-height: 22px;
  display: flex;
  align-items: center;
  float: right;
  background: #f6f8fb;
  border-radius: 2px;
}
.pricecalculatordropdown {
  .BillingInfo {
    // background: #0C0D11 !important;
    border-radius: 4px !important;
    height: 165px !important;
    padding: 10px 0px !important;
    // a{
    //     color: #fff !important;
    // }
  }
}
// #mainRow .complianceManagerDropdown.pricecalculatordropdown .dropdown-item:hover{
//     background: #0C0D11 !important;
// }
.contexthr {
  background: rgba(255, 255, 255, 0.24);
}
.circleDiv {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}
.circleHeader {
  font-size: 14px;
}
.circleHeaderbrd {
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  border-left: 0px;
  border-right: 0px;
  border-top: 0px;
  padding-bottom: 10px;
}
.scenario_dropdown {
  top: -16px;
  right: -15px;
  .pricecalculatordropdown button {
    border-radius: 2px;
    width: 30px;
    height: 28px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.scenario_dropdown:hover {
  background: #f6f8fb;
}

.scenario_content:hover {
  background-color: #f6f8fb;
}
.scenario_content {
  margin: 5px 0px;
}

.createScenario .round-checkbox label:after {
  border-right: none;
  content: "";
  height: 16px;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 1px;
  width: 16px;
  background: #008fd4;
  border-radius: 50px;
  border: 1px solid white;
}
.checkboxcompny label:after {
  border-right: none;
  content: "";
  height: 16px;
  left: 1px;
  opacity: 0;
  position: absolute;
  top: 1px;
  width: 16px;
  background: #008fd4;
  border-radius: 50px;
  border: 1px solid white;
}
.createScenario .checkboxstyle label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  background: none;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 12px;
  border-radius: 0px;
}

.checkboxstyle label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  background: none;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 12px;
  border-radius: 0px;
}

.commonpanel .clrgry {
  color: #616778;
}
.updatecompanymodal {
  .modal-content {
    height: 225px !important;
  }
}
.clrgry {
  color: #b6b6bf;
}

.price_calulator_wrapper {
  width: 100%;
}
.scenarioName {
  border-bottom: 1px solid #ebecf1;
  width: 100%;
}
.printsendmodal {
  .modal-content {
    max-height: 500px !important;
    min-height: 500px;
  }
}
.sharemodalinpt {
  width: 88%;
  .grey-select {
    border: 0px solid #eeeff1;
  }
  .inptLbl {
    border: 0px;
    background: none;
  }
}

.sharebtn {
  position: absolute;
  right: 10px;
  top: 8px;
  padding: 5px 8px !important;
}
.sharebtnwrpper {
  display: flex;
  border: 1px solid #eeeff1;
  background: #f6f8fb;
}

.tiltop {
  z-index: 3;
}
.tiltop:hover {
  &:after {
    content: "The price has changed since the last edit. Please open the scenario and update the price";
    position: absolute;
    background-color: black;
    color: #fff;
    border-radius: 4px;
    padding: 6px 13px;
    width: 240px;
    top: 2.3rem;
    z-index: 5;
    font-size: 14px;
  }
  &:before {
    position: absolute;
    content: "";
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 2rem;
    border-bottom: 5px solid black;
  }
}
.securityBtnmodal {
  height: 32px;
  padding: 5px 8px !important;
  font-family: Archivo;
  border: 1px solid #008fd4;
  border-radius: 2px;
  font-weight: 500;
  background-color: #008fd4;
}

.securityBtnmodal:hover {
  border: 1px solid #008fd4;
  font-weight: 500;
  background-color: #008fd4;
}

.securityBtnmodal:focus,
.securityBtnmodal:active {
  border: 1px solid #008fd4 !important;
  font-weight: 500;
  background-color: #008fd4 !important;
  box-shadow: none;
}

.printScenarioBtn {
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 10px;
  line-height: 22px;
  display: flex;
  align-items: center;
  float: right;
  background: #008fd4;
  border-radius: 2px;
}
.rightSideBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle-annually-switch .custom-control-label::before {
  background-color: #007bff;
  border: none;
}
.loader-custom {
  position: absolute;
  left: 0;
  top: 40%;
  right: 0;
  margin: auto;
}
.first-time-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .heading {
    font-family: "Archivo";
    font-size: 16px;
    font-weight: 500;
  }
  .quote {
    text-align: center;
    color: #9499aa;
  }
  .plot-button {
    font-family: "Archivo";
    font-size: 14px;
    font-weight: 500;
    background: #008fd4 !important;
  }
}
.first-asset-div {
  margin-bottom: 8px;
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-family: "Archivo";
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  .quote {
    color: #9499aa;
  }
  .button {
    font-family: "Archivo";
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
  }
}
.first-line {
  margin-top: 16px;
  color: #9499aa !important;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 10px;
  }
  .subtitle {
    margin-top: 3px;
  }
}
.initial-heading {
  margin: 16px 0 !important;
}
.success-btn {
  color: #033203;
  background-color: lightgrey !important;
  border-color: transparent !important;
}
// .welcome-modal-image {
//   background-image: url("/images/managedSecurityModal/ManagedSecurity1.svg");
//   background-repeat: no-repeat;
//   width: 100%;
//   height: 100%;
// }
.price-calculator-loader {
  position: fixed;
  z-index: 5000;
  background: #f4f4f400;
  /* left: 50%; */
  top: 15%;
  /* border: 1px solid black; */
  width: 80vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.security-pack-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  bottom: 0;
  background: #fff !important;
  z-index: 5;
}
.image-loader {
  min-width: 550px;
  min-height: 230px;
  background-color: #ededed;
  border-radius: 10px;
}
.complete-button {
  background: #008fd4 !important;
}
.sharemodalinpt {
  input::placeholder {
    color: #b6b6bf;
  }
}
.quote-modal-loader {
  position: absolute;
  left: 0;
  top: 40%;
  right: 0;
  margin: auto;
}

.printModalHeader{
  .modal-header{
    padding:17px 21px !important ;
    border-bottom: 1px solid #dee2e6 !important;
  
}
.modal-content{
  min-height: 600px !important;
}
}