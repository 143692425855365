/* Import Google font - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');


.highground-datpicker {
    padding-left: 5px;
    button.dropdown-toggle.btn-primary{
        color: #0c0d11;
      }

    .hide-focus-outline {
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }

    border: 2px solid transparent;
    // width: -webkit-fill-available;
    // background: #f6f8fb !important;

    &:focus-within {
        border: 2px solid #99D2EE !important;
        width: -webkit-fill-available;
        background: #f6f8fb !important;
        border-radius: 4px;
    }

    .wrapper {
        width: 264px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
    }

    .wrapper header {
        display: flex;
        align-items: center;
        padding: 12px 16px 10px !important;
        justify-content: space-between;
    }

    header .icons {
        display: flex;
    }

    header .icons span {
        height: 38px;
        width: 38px;
        margin: 0 1px;
        cursor: pointer;
        color: #878787;
        text-align: center;
        line-height: 38px;
        font-size: 1.9rem;
        user-select: none;
        border-radius: 50%;
    }

    .icons span:last-child {
        margin-right: -10px;
    }

    header .icons span:hover {
        background: #fcf9f9;
    }

    header .current-date {
        font-size: 14px;
        font-weight: 500;
    }

    .calendar {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .calendar ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
    }

    .calendar .days {
        margin-bottom: 20px;
    }

    .calendar li {
        color: #0C0D11;
        width: calc(100% / 7);
        font-size: 14px;

        &.inactive {
            color: #9499AA !important;
        }
    }

    .calendar .weeks li {
        font-weight: 500;
        cursor: default;
        color: #9499AA;
        font-size: 12px;
    }

    .calendar .days li {
        z-index: 1;
        cursor: pointer;
        position: relative;
        margin-top: 17px;
    }

    .calendar .days li.inactive {
        color: #9499AA !important;
    }

    .calendar .days li.active {
        color: #008FD4;
    }

    .days li::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        height: 31px;
        width: 31px;
        z-index: -1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }

    .days li.active::before {
        border: 1px solid #008FD4;
        border-radius: 5px;
    }

    .days li:not(.active):hover::before {
        background: #fdfdfd;
    }

    .days li.inactive:hover::before {
        background: transparent;

    }

    .days li.inactive {

        cursor: auto;
    }

    // Menu CSS
    .dropdown-menu.show {
        border: 0px solid black;
        padding: 0px;
    }

    .dropdown-menu.show {
        .inner-menu {
            left: 0px !important;
            padding: 0 4px;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent;
        border: transparent;
        padding-left: 0px;
    }

    &.show {
        .btn:active {
            background-color: #fff !important;
            border: transparent !important;
        }

        .dropdown-toggle {


            &:active {
                background-color: #fff !important;
                border: transparent !important;
            }

            background-color: #fff;
            border: transparent;
        }
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #fff;
        border-color: #fff;
        &.inner {
            color: #0c0d11;
        }
    }

    .inner-menu {
        overflow-y: auto;
        width: fit-content;
    }
    .inner-menu > div {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 4px;
        .date-item {
            padding: 4px;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 2px;
            color: #616778;
            cursor: pointer;
            &:hover,
            &.active {
                background-color: #008FD4;
                color: #FFF;
            }
        }
    }
}


.highground-datpicker-disabled {
    padding-left: 5px;
    button.dropdown-toggle.btn-primary{
        color: #0c0d11;
      }

    .hide-focus-outline {
        &:focus-visible {
            outline: -webkit-focus-ring-color auto 0px;
        }
    }

    border: 2px solid transparent;
    // width: -webkit-fill-available;
    // background: #f6f8fb !important;

    &:focus-within {
        border: 2px solid #EBEDF1 !important;
        width: -webkit-fill-available;
        background: #f6f8fb !important;
        border-radius: 4px;
    }

    .wrapper {
        width: 264px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.12);
    }

    .wrapper header {
        display: flex;
        align-items: center;
        padding: 12px 16px 10px !important;
        justify-content: space-between;
    }

    header .icons {
        display: flex;
    }

    header .icons span {
        height: 38px;
        width: 38px;
        margin: 0 1px;
        cursor: pointer;
        color: #878787;
        text-align: center;
        line-height: 38px;
        font-size: 1.9rem;
        user-select: none;
        border-radius: 50%;
    }

    .icons span:last-child {
        margin-right: -10px;
    }

    header .icons span:hover {
        background: #fcf9f9;
    }

    header .current-date {
        font-size: 14px;
        font-weight: 500;
    }

    .calendar {
        padding: 20px;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .calendar ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        text-align: center;
    }

    .calendar .days {
        margin-bottom: 20px;
    }

    .calendar li {
        color: #0C0D11;
        width: calc(100% / 7);
        font-size: 14px;

        &.inactive {
            color: #9499AA !important;
        }
    }

    .calendar .weeks li {
        font-weight: 500;
        cursor: default;
        color: #9499AA;
        font-size: 12px;
    }

    .calendar .days li {
        z-index: 1;
        cursor: pointer;
        position: relative;
        margin-top: 17px;
    }

    .calendar .days li.inactive {
        color: #9499AA !important;
    }

    .calendar .days li.active {
        color: #008FD4;
    }

    .days li::before {
        position: absolute;
        content: "";
        left: 50%;
        top: 50%;
        height: 31px;
        width: 31px;
        z-index: -1;
        border-radius: 50%;
        transform: translate(-50%, -50%);
    }

    .days li.active::before {
        border: 1px solid #008FD4;
        border-radius: 5px;
    }

    .days li:not(.active):hover::before {
        background: #fdfdfd;
    }

    .days li.inactive:hover::before {
        background: transparent;

    }

    .days li.inactive {

        cursor: auto;
    }

    // Menu CSS
    .dropdown-menu.show {
        border: 0px solid black;
        padding: 0px;
    }

    .dropdown-menu.show {
        inset: auto 0px auto auto !important;
        transform: translate(0px, 0px) !important;
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent;
        border: transparent;
        padding-left: 0px;
    }

    &.show {
        .btn:active {
            background-color: #fff !important;
            border: transparent !important;
        }

        .dropdown-toggle {


            &:active {
                background-color: #fff !important;
                border: transparent !important;
            }

            background-color: #fff;
            border: transparent;
        }
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
        color: #fff;
        background-color: #fff;
        border-color: #fff;
    }
}

.datepicker-wrapper {
    .date-history {
        display: none !important;
    }

    &:hover {
        .date-history {
            display: block !important;
            background: white;
            box-shadow: 0px 12px 24px 0px rgba(16, 16, 16, 0.08);
            border-radius: 4px;
            border: 1px solid rgba(235, 237, 241, 1);
            z-index: 1;
            width: 270px;
            top: calc(100% + 8px);
            left: 0;
        }
    }
}

.datepicker-wrapper-disabled {
    .date-history {
        display: none !important;
    }

    &:hover {
        .date-history {
            display: block !important;
            background: white;
            box-shadow: 0px 12px 24px 0px rgba(16, 16, 16, 0.08);
            border-radius: 4px;
            border: 1px solid #EBEDF1;
            z-index: 1;
            width: 270px;
            top: calc(100% + 8px);
            left: 0;
        }
    }
}