@use '../../breakpoints.scss' as *;

.attack-surface-client {
  position: relative;
  .round-checkbox label:after {
    border-right: none;
    content: "";
    height: 16px;
    left: 1px;
    opacity: 0;
    position: absolute;
    top: 1px;
    width: 16px;
    background: #008fd4;
    border-radius: 50px;
    border: 1px solid white;
  }

  .round-checkbox input[type="radio"]:checked+label:after {
    opacity: 1;
  }

  .circle-indicator {
    transform: rotate(-90deg);
  }

  .sidebar-btn {
    padding: 16px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ebeef5;
    cursor: pointer;
    height: 34px;
    border-radius: 4px;
    margin-left: 16px;
    img {
      transform: rotate(180deg);
    }
    @include respond-to(lg) {
      display: none;
    }
  }

  .header-wrapper {
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
      align-items: center;
    }
  }

  .sidebar-bg {
    position: fixed;
    top: 64px;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    z-index: 5;
    @include respond-to(lg) {
      display: none;
    }
  }

  .left-sidebar {
    position: fixed;
    height: calc(100vh - 64px);
    overflow-y: auto;
    z-index: 5;
    top: 64px;
    transform: translateX(calc(-100% - 64px));
    &.open {
      transform: translateX(0);
    }
    transition: transform 0.3s ease-in-out;
    @include respond-to(lg) {
      position: relative;
      height: auto;
      transform: translateX(0);
      top: 0;
    }
    width: 216px !important;
    min-width: 216px !important;
    & {
      border-right: 1px solid #ebedf1;
    }

    .component {
      color: #9499aa;

      &:hover {
        color: #616778;
        background: #f6f8fb;
        border-radius: 4px;
      }

      cursor: pointer;
    }

    .selected-component {
      color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }
  }

  .right-part {
    width: 100% !important;
  }

  .asset-data {
    display: flex;
    flex-direction: column;
    @include respond-to(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .asset-item {
      &:not(:last-child) {
        border-bottom: 1px solid #ebedf1;
      }
      @include respond-to(md) {
        &:last-child {
          border-bottom: 1px solid #ebedf1;
        }
      }
      @include respond-to(lg) {
        &:nth-child(odd) {
          border-right: 1px solid #ebedf1;
        }
      }
    }
  }

  .mini-header {
    overflow-x: auto;
    white-space: nowrap;
    border-bottom: 1px solid #ebedf1;
    gap: 16px;

    .insights-btn {
      color: #616778;
      background: #f6f8fb;
      border: none !important;

      &:active {
        color: #616778;
        background: #f6f8fb;
        border: none !important;
      }

      &:hover {
        color: #616778;
        background: #f6f8fb;
        border: none !important;
      }
    }
  }



  .checkboxstyle label:after {
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    content: "";
    height: 6px;
    left: 3px;
    opacity: 0;
    background: none;
    position: absolute;
    top: 4px;
    transform: rotate(-45deg);
    width: 12px;
    border-radius: 0px;
  }



  .custom_drop-down {
    background: transparent;

    .dropdown-menu.show {
      min-width: 155px;
    }
  }




  .attack-menu-tag {
    font-size: 9px;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 4px;
    color: #616778;
    // width: 51px;
    height: 16px;
    background: #EBEDF1;
    border-radius: 9999px;
  }

}

// div#mainBody::-webkit-scrollbar {
//   display: none;
// }

.attack-reset-settings-modal {







  .modal-content {
    border-radius: 8px;
    max-height: 190px !important;
    width: 350px !important;

  }



  .modal-title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #0c0d11;
  }

  .modal-subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0c0d11;
    /* or 157% */

    letter-spacing: 0.02em;
  }

  .modal-footer {
    border-top: none;
    padding: 10px;

    .reset-btn {
      padding: 5px 5px !important;
      height: 32px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background-color: #f36060;
      border: none !important;

      &:active {
        background-color: #f36060 !important;
        border: none !important;
      }
    }

    .cancel-btn {
      &:active {
        background: #f6f8fb !important;
        border: none !important;
        color: #616778 !important;
      }

      padding: 5px 5px !important;
      height: 32px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background: #f6f8fb;
      border: none !important;
      color: #616778;
      margin-right: 10px;
    }
  }
}

.yes-no-modal {
  z-index: 999;
  .item {
    &:hover {
      background: #f6f8fb;
    }
  }
}