.questionnaireTab-wrapper {

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        border-bottom: 1px solid transparent !important;
        color: #008fd4 !important;
        font-family: "Archivo";
        font-style: normal;
        background: transparent;
    }

    .nav-link {
        padding: 0.5rem 0rem 0rem 2rem;
    }

    .questionnaire-filter {
        .dropdown-menu.show {
            inset: auto 0px auto auto !important;

        }

        .dropdown-toggle {
            color: black
        }

        .dropdownfilter .dropdown-menu.show {
            transform: translate(0px, 4px) !important;
        }

        .filter-date-picker {
            .dropdown-menu.show {
                width: auto !important;

            }
        }
    }

    .disable-sent-questionnaire-tab{
        div.nav-item:not(:first-child){
        pointer-events: none;
        
        }
        #left-tabs-example-tab-Sent{
            pointer-events: none;
            color: #616778 !important;
        }
    }
}


.questionnaire-big-modal {
    .modal-content {
        width:1200px;// 90vw;
        border-radius: 8px;
    }

    .modal-body {
        padding: 0;
        overflow-y: hidden;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .template-item {
        .title {
            color: #9499AA;
        }

        &:hover {
            .title {
                color: #616778;
            }

            img {
                filter: invert(0.5);
            }
        }

    }

    .template-item-dropdown {
        // button {
        //     background: transparent;
        //     border: none;

        //     &::after {
        //         display: none;
        //     }
        // }

        .dropdown-toggle {
            padding: 2px 3px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }
        }

        .black-dropdown {
            .dropdown-menu.show {
                background: #0C0D11;
                box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
                border-radius: 4px;
                // inset: 0px auto auto 30px !important;
                inset: auto 0px auto auto !important;
                transform: translate(0px, 0px) !important;
                color: rgba(255, 255, 255, 0.64);
                width: 120px;
                font-weight: 500;

                .drop-item {
                    padding: 5px 12px;

                    &:hover {
                        background: #ebedf120;
                    }
                }
            }
        }

        .white-dropdown {

            .dropdown-menu.show {
                input {
                    border: none;
                    flex-grow: 1;
                }

                button {
                    height: 24px;
                    padding: 1px 5px !important;
                }

                background: #fff;
                box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
                border-radius: 4px;
                // inset: 0px auto auto 30px !important;
                inset: auto auto auto -249px !important;
                transform: translate(0px, 0px) !important;
                // color: rgba(255, 255, 255, 0.64);
                width: 290px;
                font-weight: 500;

                .drop-item {
                    padding: 5px 12px;

                    &:hover {
                        background: #ebedf120;
                    }
                }
            }

        }

        .white-side-dropdown {

            .dropdown-menu.show {
                input {
                    border: none;
                    flex-grow: 1;
                }

                button {
                    height: 24px;
                    padding: 1px 5px !important;
                }

                background: #fff;
                box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
                border-radius: 4px;
                // inset: 0px auto auto 30px !important;
                inset: auto 0px auto auto !important;
                transform: translate(0px, 0px) !important;
                // color: rgba(255, 255, 255, 0.64);
                width: 290px;
                font-weight: 500;

                .drop-item {
                    padding: 5px 12px;

                    &:hover {
                        background: #ebedf120;
                    }
                }
            }

        }
    }


    .answer-type-selection-dropdown {

        .custom_drop-down {
            background: white;

            .dropdown_item.selected {
                background: white;

                .tick {
                    margin-top: 3px;
                }
            }

            .dropdown_item:hover {
                background: white;
            }
        }

        .dropdown-menu.show {
            width: 200px;
        }


    }


    .question-chat-dropdown {
        .dropdown-toggle {
            &:active {
                border: 0px;
                background: transparent;

                &:after {
                    display: none;
                }
            }

            border: 0px;
            background: transparent;

            &:after {
                display: none;
            }
        }

        .dropdown-menu.show {
            width: 312px;
            // inset: auto 0px auto auto !important;
        }




     
          
          .dropdown.show .dropdown-menu {
            animation-name: pop-up;
            opacity: 1;
            animation-duration: 400ms;
          }
          
          @keyframes pop-up {
            0% {
               scale:0  ;
            }
            70% {
                scale:1.1  ;
            }
            100% {
                scale:1  ;
            }
          }
          


















    }

    .message-typer {
        background: #f6f8fb;
        border-radius: 4px;
        padding: 4px;

        input {
            border: none;
            background: #f6f8fb;
            flex-grow: 1;
            height: 25px;
        }

        button {
            padding: 1px 2px;
            background: #008fd4;
        }
    }

    .chat-body {
        max-height: 225px;
        overflow-y: scroll;
    }


    .round label:after {
        border-right: none;
        content: "";
        height: 16px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 1px;
        width: 16px;
        background: #008fd4;
        border-radius: 50px;
        border: 1px solid white;
    }

    progress {
        vertical-align: middle;
        border-radius: 7px;
        width: 100%;
        height: 5px;

        box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
    }

    progress::-webkit-progress-bar {
        background-color: #D4D6DD;
        border-radius: 7px;
    }

    progress::-webkit-progress-value {
        background-color: #008fd4;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
    }

    .grey-cancel-btn {
        background: #f6f8fb !important;
        color: #616778 !important;
        border: none;
        font-weight: 500;

        &:active {
            background: #f6f8fb !important;
            color: #616778 !important;
            border: none !important;
        }
    }

    .blue-next-btn {
        background: #008fd4;
        border: none;
        font-weight: 500;

        &:active {
            background: #008fd4 !important;
            color: #fff !important;
            border: none !important;
        }

        &:hover {
            background: #008fd4;
        }
    }


    .percentage-check {
        circle {
            transform: rotate(-90deg);
            transform-origin: center;
        }
    }

    .change-due-date-picker{

        .highground-datpicker:focus-within {
            border: 2px solid transparent !important;
            width: -webkit-fill-available;
            background: transparent !important;
            border-radius: 4px;
        }
        .dropdown-toggle{
            input{
                display: none !important;
            }

            &:focus{
                border: none !important;
            }
        }
    }
}


.send-questionnaire-modal {

    .modal-content {
        border-radius: 8px;
        max-height: 600px !important;
        max-width: 490px !important;
    }

    .modal-header {
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .supplier-date-picker {
        position: relative;

        &::after {
            content: "";
            width: 16px;
            height: 16px;
            position: absolute;
            background: url("../../../../public/images/supply-chain/date-icon.svg");
            background-repeat: no-repeat;
            background-position: center;
            left: -2px;
            top: 50%;
            transform: translateY(-50%);


        }

        .custom_date_picker:hover {
            border: 1px solid transparent;
            background: transparent;
        }

        .custom_date_picker {
            border: 1px solid transparent;
            background: transparent;
        }

        .highground-datpicker{
            button.dropdown-toggle.btn-primary{
                color: #0c0d11;
              }
            &:focus-within{

                background: white !important;
            }
            .dropdown-toggle{


                input{
                    padding-left: 0px !important;
                }
            }
        }
    }

    .send-questionnaire-multi-dropdown {
        .custom_drop-down-multi .dropdown-toggle {
            padding: 5px 9px;
        }
    }

    .select-template-dropdown {
        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            color: #0C0D11;

            &::after {
                display: none;
            }
        }

        .dropdown-menu.show {
            width: max-content;
            padding-top: 4px;
            padding-bottom: 4px;

            .drop-item {
                padding-top: 5px;
                padding-bottom: 5px;

                &:hover {
                    background: #9499AA20;
                }
            }
        }

    }

}

.modal-450px{
    .modal-content{
        max-width: 450px;
    }
}