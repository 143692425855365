.search-bar {
  background: #F6F8FB;
  border-radius: 4px;
  padding: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.search-bar-input {
  color: #101010;
  border: none;
  background: rgba(255, 255, 255, 0);
  width: 100%;
  line-height: 22px;
  &::placeholder {
    color: #9499aa;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}
