@use '../../../../../breakpoints.scss' as *;

.add-labour-cost-dropdown {
    .labour-const-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }



    .dropdown-menu {
        inset: 0px 0px auto 0px !important;
        transform: translate(0px, 0px) !important;
    }

    .labour-table-cost {
        display: grid;
        grid-template-columns: 30% 40% 30%;
    }
}


.msp-package-includes-dropdown {
    .package-includes-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }



    .dropdown-menu {
        inset: -4px 0px auto -2px !important;
        transform: translate(0px, 0px) !important;
    }
}

.msp-product-cost-dropdown {
    .product-cost-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }

    .dropdown-menu {
        inset: -1px 0px auto -1px !important;
        transform: translate(0px, 0px) !important;
    }

    .product-item {
        display: grid;
        grid-template-columns: 72% 26%;
    }

    .product-item .subclass {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* Number of lines to show before truncating */
        max-height: 3.6em;
        /* Adjust based on your font size and line height */
        text-overflow: ellipsis;
    }
}


.link-to-series-dropdown {
    .link-to-series-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }

    .series-item-dropdown {
        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            padding: 0px;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            inset: 0px auto auto 0px !important;
            // transform: translate(0px, 0px) !important;
        }

    }



    .link-to-series-parent-dropdown-menu {
        inset: 0px 0px auto 0px !important;
        // inset: 0px 0px auto -100px !important;
        transform: translate(0px, 0px) !important;

        // Tree structure CSS
        /* ————————————————————–
  Tree core styles
*/
        .tree {
            margin: 0em 1em;
        }

        .tree li {
            line-height: 1.2;
            position: relative;
            padding: 0 0 1em 1em;
        }

        .tree ul li {
            padding: 1em 0 0 1em;
        }

        .tree>li:last-child {
            padding-bottom: 0;
        }

        .tree_label {
            position: relative;
            display: inline-block;
            background: #fff;
        }

        .tree li:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -.5em;
            display: block;
            width: 0;
            border-left: 1px solid #D4D6DD;
            content: "";
        }

        .tree_label:after {
            position: absolute;
            top: 0;
            left: -1.5em;
            display: block;
            height: 0.5em;
            width: 1em;
            border-bottom: 1px solid #D4D6DD;
            border-left: 1px solid #D4D6DD;
            border-radius: 0 0 0 .3em;
            content: '';
        }

        .tree li:last-child:before {
            height: 1em;
            bottom: auto;
        }

        .tree>li:last-child:before {
            display: none;
        }










    }


}


.link-to-service-dropdown {
    .link-to-series-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }

    .series-item-dropdown {
        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            padding: 0px;

            &::after {
                display: none;
            }

        }

    }

    .dropdown-menu {
        inset: 0px auto auto -45px !important;
        // transform: translate(0px, 0px) !important;
    }



    .link-to-series-parent-dropdown-menu {

        // inset: 0px 0px auto 0px !important;
        // transform: translate(0px, 0px) !important;
        // Tree structure CSS
        /* ————————————————————–
  Tree core styles
*/
        .tree {
            margin: 0em 1em;
        }

        .tree li {
            line-height: 1.2;
            position: relative;
            padding: 0 0 1em 1em;
        }

        .tree ul li {
            padding: 1em 0 0 1em;
        }

        .tree>li:last-child {
            padding-bottom: 0;
        }

        .tree_label {
            position: relative;
            display: inline-block;
            background: #fff;
        }

        .tree li:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -.5em;
            display: block;
            width: 0;
            border-left: 1px solid #D4D6DD;
            content: "";
        }

        .tree_label:after {
            position: absolute;
            top: 0;
            left: -1.5em;
            display: block;
            height: 0.5em;
            width: 1em;
            border-bottom: 1px solid #D4D6DD;
            border-left: 1px solid #D4D6DD;
            border-radius: 0 0 0 .3em;
            content: '';
        }

        .tree li:last-child:before {
            height: 1em;
            bottom: auto;
        }

        .tree>li:last-child:before {
            display: none;
        }










    }


}

.msp-service-tool-tip-dropdown {
    .msp-service-tool-tip-dropdown-parent-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;

        &::after {
            display: none;
        }
    }






}


.msp-score-modal {
    .modal-content {
        width: 100%;
        @include respond-to(lg) {
            width: 990px;
        }
        @include respond-to(xl) {
            width: 1360px;
        }
        border-radius: 8px;
    }

    .modal-header {
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .modal-body {
        overflow-y: auto !important;
    }

    .score-question-dropdown {
        .custom_drop-down {
            background: transparent;
        }

        .custom_drop-down .dropdown_item:hover {
            background-color: rgba(0, 143, 212, 0.0);
        }
    }

    .table-title {
        // display: flex;
        display: grid;
        grid-template-columns: 5% 95%;
        padding: 4px 8px 4px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: var(--Grayscale-Gray-05, #EBEDF1);
    }

    .chart-wrapper {
        display: none;

        @include respond-to(xl) {
            display: flex;
        }
    }

    .dropdown-history {
        position: relative;

        .history {
            z-index: 1;
            position: absolute;
            display: none;

            padding: 4px 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 2px;
            align-self: stretch;

            border-radius: 4px;
            border: 1px solid var(--grayscale-gray-05, #EBEDF1);
            background: var(--white-white-100, #FFF);
            /* Shadow / Bottom / Medium / M */
            box-shadow: 0px 12px 24px 0px rgba(16, 16, 16, 0.08);
        }

        &:hover {
            .history {
                display: flex;
            }
        }
    }
}

.msp-features-modal {
    .modal-content {
        // width: 60vw;
        width: 640px;
        border-radius: 8px;
        height: 740px;
    }

    .modal-header {
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .modal-body {
        overflow-y: auto !important;
    }



}