#root .role-dropdown {
  .dropdown-toggle {
    background: white !important;
    border: 1px solid #ebedf1 !important;
    height: 37px;
  }
  .dropdown_item.selected {
    background-color: rgba(0, 0, 0, 0);
  }
  .dropdown_item .tick {
    margin-top: 0px;
  }
  .dropdown_item:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  .drop-placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }

  .custom_drop-down .dropdown-menu.show.left-align{
    width: 100% !important;
  }
}
.label-sign-up {
  text-align: left;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-top: 10px;
  color: #0c0d11
}

input::placeholder {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9499aa !important;
}

.button-disabled {
  background: #ebedf1 !important;
  color: #9499aa !important;
  border: none !important;
  &:active {
    background: #ebedf1 !important;
    color: #9499aa !important;
    border: none !important;
  }
}

.error-text {
  color: #f36060;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}


 

.signup-animation{
  animation: popupBlog 0.5s ease-in forwards;
}

@keyframes popupBlog {
  0% {
    transform: translate(-100%)
  }
  100% {
    transform: translate(0%)
  }
}


.signup-message {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.signup-message.visible {
  opacity: 1;
}


