@use '../../breakpoints.scss' as *;

.integrationsV2 {
    height: 100%;
    background: #fff;

    .tab-header {
        border-bottom: 1px solid #ebedf1;
        padding-left: 2rem;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show>.nav-link {
        border-bottom: 1px solid #008fd4;
        color: #008fd4 !important;
        font-family: "Archivo";
        font-style: normal;
        background: transparent;

        img {
            filter: invert(51%) sepia(55%) saturate(5380%) hue-rotate(173deg) brightness(91%) contrast(101%)
        }
    }

    .nav-pills .nav-link {
        border-radius: 0rem;
        font-family: "Archivo";
        font-style: normal;
        padding: 0.5rem 1.1rem;
    }

    .banner {
        background: #f6f8fb;
        border-radius: 8px;
        margin: 1.3rem 2.7rem;

        .banner-element-border {
            border-right: 1px solid #ebedf1;
        }

        .banner-element {
            flex-basis: 0;
            flex-grow: 1;
            padding: 1.15rem;

            .banner-element-name {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #616778;
            }

            .banner-element-value {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #0c0d11;
            }
        }
    }

    .menu-dropdown {
        .sort-dropdown button {
            background: transparent;
            border: none;

            &::after {
                display: none;
            }
        }

        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
        }

        .show {
            .dropdown-toggle {
                border: none;
            }
        }

        .dropdown-menu.show {
            background: #fff;
            box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
            border-radius: 4px;
            // inset: 0px auto auto 30px !important;
            inset: auto 0px auto auto !important;
            transform: translate(0px, 0px) !important;
            color: #616778;
            width: 165px;
            font-weight: 500;

            .drop-item {
                padding: 5px 12px;

                &:hover {
                    background: #f3f3f330;
                }
            }
        }
    }






    .menu-hr {
        border-top: 1px solid #EBEDF1 !important;
    }

    .header-menu-dropdown {
        .dropdown-menu.show {
            width: 200px !important
        }

    }














    // CSS for Connected Tools Tab
    
    
}




.integration-details-page {
    .page-banner {
        padding: 5rem 2rem 3rem 2rem;
    }
}


.suggest-integration-modal {
    max-width: 100%;
    .modal-content {
        width: calc(100% - 16px);
        @include respond-to(sm) {
            width: 400px !important;
        }
        border-radius: 8px;
    }

    .modal-footer {
        padding: 7px !important;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .round label:after {
        border-right: none;
        content: "";
        height: 16px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 1px;
        width: 16px;
        background: #008fd4;
        border-radius: 50px;
        border: 1px solid white;
    }

    .modal-content {
        height: auto;
        max-height: 500px !important;
        min-height: 460px;
        // width: fit-content;
    }

    .modal-header {
        border-bottom: 1px solid #ebedf1 !important;
    }

    .modal-body {
        padding: 16px
    }

    .modal-footer {
        border-top: 1px solid transparent;
    }




    .disabled-radio {
        label::after {
            background: #BBBEC9 !important;

        }

        input[type="radio"]:checked+label {

            background-color: #BBBEC9 !important;
            border-color: #BBBEC9 !important;
        }



    }



    .suggest-integration-dropdown {
        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            color: #0C0D11;

            &::after {
                display: none;
            }
        }

        .dropdown-menu.show {
            padding-top: 4px;
            padding-bottom: 4px;
            width: inherit;
            max-height: 150px;
            overflow-y: scroll;

            .drop-item {
                padding-top: 5px;
                padding-bottom: 5px;

                &:hover {
                    background: #9499AA20;
                }
            }
        }

    }


}


.upgrade-to-premium-modal{
    .modal-content{

        width: 340px !important;
        max-width: 340px !important;
        min-height: 420px;
        max-height: 425px !important;


    }
    .modal-footer{
        border-top: 1px solid #fff !important;
    }
    .modal-footer>* {
          margin: 0rem;  
    }
   
}

.upgrade-to-premium-dropdown{
    .dropdown-toggle{
        &::after{
          display: none;
        }
        background: transparent !important;
        border: none !important;
        padding: 0px !important;
      }

      .dropdown-menu.show{
        width: 274px;
        inset: auto 0px auto auto !important;
     
    transform: translate(0px, 4px) !important;
      }
}

.integrations-layout {
    display: flex;
    .sidebar-layout {
        width: 50px;
        min-width: 50px;
        border-right: 1px solid #EBEDF1;
        @include respond-to(md) {
            width: 280px;
            min-width: 280px;
            position: relative;
            border-right: none;
        }
        .sidebar-state-btn {
            position: absolute;
            width: 35px;
            height: 35px;
            background-color: #F6F8FB;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 7px;
            margin-left: 7px;
            transform: rotate(180deg);
            border: 1px solid #EBEDF1;
            cursor: pointer;
        }
        .sidebar-bg-open-state {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            @include respond-to(md) {
                display: none;
            }
        }
        .sidebar {
            background: #fff;
            width: 180px;
            transform: translateX(calc(-100% - 1px));
            z-index: 2;
            transition: transform 0.3s ease-in-out;
            &.open {
                transform: translateX(0);
                box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
            }
            @include respond-to(md) {
                width: 280px;
                box-shadow: none !important;
                transform: none !important;
            }
            position: fixed;
            border-right: 1px solid #EBEDF1;
            height: calc(100vh - 101px);
            padding: 16px;
            overflow-y: auto;
            .items-list {
                display: flex;
                flex-direction: column;
                gap: 4px;
                .item {
                    padding: 5px 8px;
                    min-width: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    color: #9499AA;
                    .text {
                        color: #9499AA;
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 22px;
                    }
                    .item-icon {
                        fill: #9499AA !important;
                        path {
                            fill: #9499AA !important;
                        }
                    }
                    &.active {
                        background-color: #F6F8FB;
                        color: #616778;
                        .text {
                            color: #616778;
                        }
                        .item-icon {
                            fill: #616778 !important;
                            path {
                                fill: #616778 !important;
                            }
                        }
                    }
                }
            }
            .separator {
                border-top: 1px solid #EBEDF1;
                height: 1px;
            }
            .request-tool-container {
                display: flex;
                flex-direction: column;
                gap: 8px;
            }
        }
    }
    .header-wrapper {
        background-color: #EBEDF1;
        padding: 20px 20px 16px 20px;
        @include respond-to(md) {
            padding: 40px 40px 16px 40px;
        }
        display: flex;
        flex-direction: column;
        gap: 16px;
        .dropdown-toggle {
            max-width: 640px;
        }
        .search-wrapper {
            padding: 8px;
            background-color: #fff;
            border-radius: 8px;
            max-width: 640px;
            .search-icon-wrapper {
                width: 24px;
                height: 24px;
                min-width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            input {
                padding: 1px 4px;
            }
        }
        .search-modal {
            background-color: #fff;
            border-radius: 4px;
            padding: 4px;
            z-index: 1;
            .section-title {
                font-size: 12px;
                font-weight: 400;
                line-height: 18px;
                padding: 4px 12px;
            }
            .list-item {
                padding: 4px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 16px;
                cursor: pointer;
                &:hover {
                    background-color: #F6F8FB;
                }
                .icon-wrapper {
                    border: 1px solid #EBEDF1;
                }
                .name-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 16px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 22px;
                }
                .tags {
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    display: none;
                    @include respond-to(md) {
                        display: block;
                    }
                }
            }
        }
    }
    .main-body {
        padding: 20px;
        @include respond-to(md) {
            padding: 24px 40px;
        }
        .integrations-container {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 12px;

            @media (max-width: 2000px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media (max-width: 1500px) {
                grid-template-columns: 1fr 1fr;
            }
        
            @media (max-width: 1100px) {
                grid-template-columns: 1fr;
            }
        }
    }
}