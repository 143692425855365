@use '../../../breakpoints.scss' as *;

.attack-overview-wrapper {
  .all-progresses {
    progress {
      border-radius: 7px;
      width: 100%;
      height: 5px;

      box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
    }
    progress::-webkit-progress-bar {
      background-color: #ebedf1;
      border-radius: 7px;
    }
    progress::-webkit-progress-value {
      background-color: #008fd4;
      border-radius: 7px;
      box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
    }
    progress::-moz-progress-bar {
      /* style rules */
    }
  }

  .overview-banner {
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
    }
    .left-side,
    .right-side {
      width: 100%;
      @include respond-to(md) {
        width: 50%;
      }
    }
    .left-side {
      border-bottom: 1px solid #ebedf1;
      @include respond-to(md) {
        border-bottom: none;
        border-right: 1px solid #ebedf1;
      }
    }
  }

  .attack-progress {
    display: grid;
    grid-template-columns: auto 50px;
    grid-template-areas: 
      "progress-title progress-title"
      "progress-line progress-value";
    @include respond-to(lg) {
      grid-template-columns: 48% 40% 50px;
      grid-template-areas: "progress-title progress-line progress-value";
    }
    @include respond-to(xl) {
      grid-template-columns: 28% 60% 11%;
    }
    .progress-title {
      grid-area: progress-title;
    }
    .progress-line {
      grid-area: progress-line;
    }
    .progress-value {
      grid-area: progress-value;
    }
  }

  .shield-image {
    background-image: url("../../../../public/images/attack-surface/overview-banner-image.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 100%;
    height: 215px;
    border-radius: 12px;
    .learn-more-btn {
      color: #0c0d11;
      background: #fff;
      border: none !important;
      &:active {
        border: none !important;
        color: #0c0d11;
        background: #fff !important;
      }
    }
  }

  .insights-notification-dropdown {
    .dropdown-toggle::after {
      display: none !important;
    }
    .dropdown-menu.show {
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
    }
  }


  // MFA UI CSS
  .mfa-table-row{
    display: grid;
    &.small-view {
      grid-template-columns: 280px 220px 150px 150px 150px !important;
    }
    @include respond-to(md) {
      grid-template-columns: 30% 23% 14% 14% 19%;
    }
    align-items: center;
    &.header {
      padding: 4px 10px;
      .cell {
        padding: 3px 0px;
        line-height: 18px;
      }
    }
    &.row-item {
      padding: 12px 10px;
      .email {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 8px;
      }
    }
  }
  .eol-table-row{
    display: grid;
    grid-template-columns: 50% 25% 25%;
    align-items: center;

  }
  
  .ase-device-issues-table {
    background-color: #F6F8FB;
    border-radius: 12px;
    .wrapper-header {
      background: #F6F8FB;
      color: #616778;
      display: grid;
      &.small-view {
        grid-template-columns: 280px 220px 150px 150px 150px !important;
      }
      @include respond-to(md) {
        grid-template-columns: 30% 25% 15% 15% 15%;
      }
      .cell {
        padding: 7px 14px;
      }
    }
  
    .wrapper-row {
      .cell {
        padding: 12px 14px;
        color: #0C0D11;
      }
      .line-row {
        display: grid;
        &.small-view {
          grid-template-columns: 280px 220px 150px 150px 150px !important;
        }
        @include respond-to(md) {
          grid-template-columns: 30% 25% 15% 15% 15%;
        }
        &:first-child {
          border-top: 1px solid #EBEDF1;
        }
        
        border-bottom: 1px solid #EBEDF1;
        
        .period-label {
          background-color: #EBEDF1;
          border-radius: 9999px;
          padding: 0 2px;
          display: flex;
          align-items: center;
          .icon {
            margin: 4px 2px;
          }
          .text {
            font-size: 12px;
            line-height: 18px;
            font-weight: 500;
            color: #616778;
            padding: 1px 4px;
          }
        }
      }
    }
  }
}
