.reset-settings-wrapper {
  margin-top: 2.5rem !important;
  width: 60%;
  margin: auto;

  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
    padding-bottom: 2px !important;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #616778;
  }
  .settings-div {
    cursor: pointer;
    margin-top: 1rem;
    background: #f6f8fb;
    border-radius: 8px;
    padding: 1.1rem;
  }
}

.reset-settings-modal {
  .modal-content {
    border-radius: 8px;
    max-height: 206px !important;
  }
  .modal-title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
  }
  .modal-subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* or 157% */

    letter-spacing: 0.02em;
  }
  .modal-footer {
    border-top: none;
    padding: 10px;
    .reset-btn {
      padding: 5px 5px !important;
      height: 32px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background-color: #f36060;
      border: none !important;
      &:active {
        background-color: #f36060 !important;
        border: none !important;
      }
    }
    .cancel-btn {
      &:active {
        background: #f6f8fb !important ;
        border: none !important;
        color: #616778 !important;
      }
      padding: 5px 5px !important;
      height: 32px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      background: #f6f8fb;
      border: none !important;
      color: #616778;
      margin-right: 10px;
    }
  }
}
