.risk-matrix-modal{
    .modal-content{
        border-radius: 4px;
        width: 1240px;
    }

    .modal-header{
        padding: 10px;
        border-bottom: 1px solid #EBEDF1 !important
    }
}