@use '../../breakpoints.scss' as *;

.main-block {
    width: 100% !important;
    padding: 20px;

    .img-block {
        width: fit-content;
        margin: auto;
        display: none;
    }

    .invite-block {
        width: 100%;
    }
}


@include respond-to(md) {
    .main-block {
        display: flex;
        align-items: start;
        padding: 20px;
        margin: auto;

        .img-block {
            display: block;
            width: 50%;
            margin: auto;
            
            img {
                width: 300px;
                max-width: 300px;
            }
        }
        
        .invite-block {
            margin-right: 20px;
            width: 50%;
            max-width: 250px;
            margin: 0px 20px auto 0px;
        }
    }
}

@include respond-to(lg) {
    .main-block {
        padding: 25px;

        .img-block {          
            img {
                width: auto;
                max-width: 360px;
            }
        }
        
        .invite-block {
            max-width: 400px;
        }
    }
}

@include respond-to(xl) {
    .main-block {
        padding: 80px;

        .img-block {
            img {
                width: 480px;
                max-width: 480px;
            }
        }
        
        .invite-block {
            max-width: 500px;
        }
    }
}

