.sideNav,
.horizontalMenu {
  height: 100%;
  background: #ffffff;
  border-right: 1px solid #ebecf1;
}

.sideNav {
  padding: 1em;
  z-index: 5;
}

.sideNav svg {
  height: 100%;
}

.sideNav ul.nav {
  padding: 1.5em 0.5em; //old
  padding: 1.5em 0em; // new
  margin-top: 1em; //new
  //margin-top: 2.75em;//old
}

.sideNav ul li {
  padding: 1em 0;
  width: 100%;
  color: #b6b6bf;
}

.sideNav ul li p ul {
  padding-top: 1.2em;
  padding-left: 2.5em;
}

.sideNav ul li p ul li {
  padding-top: 0.7em;
}

.sideNav ul li a>*,
.sideNav ul li>* {
  display: inline;
}

.smallSideMenu .sideNav ul li a>*,
.sideNav ul li>* {
  display: block;
}

.smallSideMenu .sideNav ul li {
  text-align: center;
}

.smallSideMenu .sideNav ul li p {
  margin-top: 12px;
  padding-left: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sideNav ul li p {
  /* font-family: Archivo, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.1rem;
    padding-left: 1em; */
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  padding-left: 1em;
}

.sideNav ul li img {
  width: 16px;
}

.sideNav li.active p {
  color: #101010;
}

.sideNav li:hover img {
  filter: invert(36%) sepia(40%) saturate(4928%) hue-rotate(178deg) brightness(99%) contrast(101%);
}

.sideNav li.active img {
  filter: brightness(0%);
}

.sideNav li:hover p {
  color: #008fd4 !important;
}

.userAvatar {
  border-radius: 15px;
  width: 30px;
}

.userInitials {
  background: #3d42de;
  border-radius: 15px;
  color: white;
  height: 100%;
  width: 30px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-content: center;
  /* Purple */
}

.userInitials>* {
  display: inline;
}

.userInitials p {
  /* padding: 3em 1em; */
  padding: 0.3em;
  margin: 0;
  font-family: Archivo;
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
}

.userDetails {
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
}

.userDetails p {
  margin-bottom: 0;
  font-size: 0.8em;
  line-height: 1em;
  color: #b6b6bf;
}

.userDetails p:first-child {
  font-weight: 500;
  font-size: 1.1em;
  line-height: 1.1em;
  color: #101010;
}

.bottomDetails {
  border-top: 1px solid #ebecf1;
  /* position: absolute; */
  /* bottom: 0; */
  padding: 1em;
  padding-right: 25%;
}

.bottomDetailsLogo {
  padding-bottom: 1em;
  padding-left: 1em !important;
}

.bottomDetailsLogo img {
  max-width: 100%;
}

.absoulteBottomContainer {
  position: relative;
  width: 100%;
}

.absoulteBottom {
  // position: absolute;
  bottom: 0;
  width: 100%;
}

.smallUpgrade {
  border: 1px solid #ebecf1 !important;
  border-radius: 8px !important;
}

.padding-clear {
  padding: 0;
}

.inactive {
  color: #b6b6bf;
  text-decoration: none;
}

.active p {
  color: #101010;
  text-decoration: none;
}

.nav .active img {
  filter: brightness(30%);
}

.active:hover {
  color: #101010;
}

.sideNav-burger svg g,
.sideNav-burger {
  fill: "#B6B6BF" !important;
  color: "#B6B6BF" !important;
  stroke: "#B6B6BF" !important;
}

.linkMenu ul li {
  padding-top: 20px;
}

.spannerIcon {
  color: #b6b6bf;
  padding: 0.35em;
  border-radius: 4px;
  cursor: pointer;
}

.spannerIcon:hover {
  color: #f36060;
}

.spannerIcon:hover {
  background: rgba(254, 95, 91, 0.08);
  /* padding: 0 .5em; */
}

$lightBlue: rgba(0, 143, 212, 0.08);
$mainBlue: #008fd4;
$lightGrey: #ebecf1;

.MSPButton {
  border: 2px solid $mainBlue;
  margin-top: 2em;
  padding: 1em;
  background-color: $lightBlue;
  border-radius: 0.9em;
  display: flex;
  width: 100%;
  height: 100%;

  #branding {
    font-size: 1.25em;
    font-weight: 600;
    color: $mainBlue;
    display: flex;
    justify-content: center;
    align-self: center;
    width: 35%;
    height: 100%;

    >* {
      padding: 0 0.3em;
    }

    p {
      padding-right: 0.5em;
      border-right: 2px solid $mainBlue;
    }
  }

  #companyDetails {
    width: 65%;
    display: inline-block;

    h2 {
      font-weight: bolder;
      font-size: 1.2em;
      margin-bottom: 0.15em;
    }

    h3 {
      font-size: 0.9em;
      font-weight: normal;
      margin-top: 0;
    }
  }
}

.horizontalMenu {
  display: flex;

  .sideNav {
    border-right: 0;

    .mspUserTypeNav {
      display: flex;
      text-align: center;
      background-color: $lightGrey;
      border-radius: 8px;
      padding: 0.2em;

      li {
        padding: 0.5em 0;
        cursor: pointer;
      }

      .active {
        background-color: $mainBlue;
        border-radius: 8px;
        color: white;
      }
    }

    .CompanyHeader {
      display: flex;

      >* {
        display: inline-block;
        margin: 0;
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        margin-right: 1em;
      }

      h1 {
        padding-left: 0.5em;
        font-size: 1.2em;
      }
    }
  }

  .VerticalNavBar {
    width: 14.5vw;
  }
}

@media (min-width: 1080px) and (max-width: 1480px) {
  .MSPButton {
    padding: 5px !important;
  }

  .MSPButton #branding {
    font-size: 16px !important;
  }

  .MSPButton #companyDetails h2 {
    font-size: 14px !important;
  }

  .MSPButton #companyDetails h3 {
    font-size: 13px !important;
  }

  .MSPButton #companyDetails {
    margin-left: 10px;
  }
}

.request-demo {
  background: #ebf6fc;
  border: none;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #008fd4;
  width: 100%;
  padding: 12px 10px !important;

  &:hover {
    background: #ebf6fc !important;
    color: #008fd4 !important;
  }

  .chev {
    width: 8px !important;
  }

  img {
    filter: brightness(100%) contrast(100%) !important;
  }

  &:active {
    color: #008fd4 !important;
    background: #ebf6fc !important;
    border: none !important;
  }

  &:focus {
    color: #008fd4 !important;
    background: #ebf6fc !important;
    border: none !important;
  }
}

.new-upgrade span {
  left: -25px !important;
  top: -2px !important;
}

.beta-tag {
  display: none !important;
}

.largeSideMenu {

  li {
    &:hover {
      .beta-tag {
        background: #D6EDF8;
        color: #008FD4;
      }
    }
  }

  .menumanagesecurity.active {
    .beta-tag {
      background: #D6EDF8;
      color: #008FD4;
      -webkit-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
      -moz-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
      box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
    }

  }

  .menumanagesecurity,
  li {
    position: relative;

    &:hover {
      .beta-tag {
        background: #D6EDF8;
        color: #008FD4;
        -webkit-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
        -moz-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
        box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
      }

    }

    .beta-tag {
      position: absolute;
      font-weight: 600;
      font-size: 9px;
      color: #616778;
      display: flex !important;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0px 6px;
      background: #EBEDF1;
      right: 8px;
      border-radius: 9999px;
      // width: 38px;
      height: 16px;
      top: 50%;
      transform: translate(0%, -50%);
      -webkit-box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.7);
      -moz-box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.7);
      box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.7);
    }
  }
}


.demo-pan{
&:hover{
  img{
    filter: invert(51%) sepia(60%) saturate(4324%) hue-rotate(332deg) brightness(120%) contrast(91%);
  }
  background: #FEF2F2;
}

}

.lockerTooltipImage {
  height: 16px;
  width: 16px;
  margin-right: 4px;
}