@use '../../breakpoints.scss' as *;

.action_center-fixed-scroll-body {
  height: calc(100vh - 64px) !important;
}

.action_center {
  &.main-body {
    height: calc(100vh - 101px);
  }
  .task-sidebar-background {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    left: -7px;
  }
  .archivedtasktxt {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #0c0d11;
  }

  .replotposture {
    &:focus {
      outline: none;
    }
  }

  .filter_header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebedf1;
    padding-bottom: 4px;
  }

  //  search styles
  .search_input {
    border: 0px;
    width: 200px;

    .input-group-text {
      background: #fff;
      border: 0px;
      border-radius: 0px;
    }

    input {
      border: 0px;
      border-radius: 0px;
      font-family: "Archivo";
      font-size: 14px;
      padding-left: 5px;
    }

    input::placeholder {
      color: #9499aa;
    }
  }

  //   filter wrapper
  .filter_wrapper {
    cursor: pointer;
    border-right: 1px solid #ebedf1;
    height: 20px;

    .greytxt {
      color: #616778;
    }
  }

  .comment-dropdown-menu {
    transform: translateX(-220px) !important;
  }

  // content table
  .content_table {
    // background-color: #f6f8fb;
    color: #0c0d11;
    overflow-x: auto;

    .tableheader {
      display: grid;
      &.scroll-columns {
        grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px auto !important;
      }
      .grid-area-1 {
        grid-area: col1;
      }
      .grid-area-2 {
        grid-area: col2;
      }
      .grid-area-3 {
        grid-area: col3;
      }
      .grid-area-4 {
        grid-area: col4;
      }
      .grid-area-5 {
        grid-area: col5;
      }
      .grid-area-7 {
        grid-area: col7;
      }
      .grid-area-8 {
        grid-area: col8;
      }
      .grid-area-9 {
        grid-area: col9;
      }
      background: #fff;
      color: #616778;
      font-size: 12px;
    }

    .tableheader2 {
      display: grid;
      // grid-template-columns: 43% 15% 13% 13% 1% 15% !important;
      grid-template-columns: 40% 14% 15% 15% 1% 15% !important;
      background: #fff;
      color: #616778;
      font-size: 12px;
      animation: moveright-header 5s;
      -webkit-animation: moveright-header 5s;
    }

    @keyframes moveright-header-- {
      0% {
        opacity: 0;
        grid-template-columns: 43% 10% 13% 16% 10% 8%;
      }

      100% {
        opacity: 0.5;
        grid-template-columns: 60% 14% 10% 16% !important;
      }
    }

    .status-center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .tablerow:last-child .table_item {
      border-bottom: 0px solid #ebedf1;
    }

    .tablerow2 {
      // grid-template-columns: 43% 16% 10% 10% 20% !important;
      grid-template-columns: 40% 14% 15% 15% 16% !important;
    }

    .tablerow {
      display: grid;
      &.scroll-columns {
        grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px auto !important;
      }
      .grid-area-1 {
        grid-area: col1;
      }
      .grid-area-2 {
        grid-area: col2;
      }
      .grid-area-3 {
        grid-area: col3;
      }
      .grid-area-4 {
        grid-area: col4;
      }
      .grid-area-5 {
        grid-area: col5;
      }
      .grid-area-7 {
        grid-area: col7;
      }
      .grid-area-8 {
        grid-area: col8;
      }
      .grid-area-9 {
        grid-area: col9;
      }

      .details {
        font-size: 12px;
        color: #616778;
      }

      .detailsinner {
        visibility: hidden;
      }

      .table_item {
        border-bottom: 1px solid #ebedf1;
        background-color: #f6f8fb;
      }

      background-color: #f6f8fb;

      &:hover {
        background: #ebedf1 !important;
        .table_item {
          background-color: #ebedf1 !important;
        }
        cursor: pointer;
      }

      &:hover .detailsinner {
        visibility: visible;
      }
    }

    .selected-task {
      background: #ebedf1 !important;
    }

    .table_item {
      padding: 13px 13px;
    }

    .titleCheckbox {
      height: 15px;
      width: 15px;
      display: initial;

      label {
        height: 15px;
        width: 15px;
        margin-top: 3px;
      }

      label::after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 5px;
        left: 2px;
        opacity: 0;
        position: absolute;
        top: 3px;
        transform: rotate(-45deg);
        width: 9px;
      }
    }

    .prfimg {
      display: inline-block;
      height: 20px;
      width: 20px;
      margin-right: 5px;
      border-radius: 50%;
    }

    .tableStatus {
      padding: 4px 8px;
      position: absolute;
      font-size: 12px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      line-height: 15px;

      .tablecircle {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    // div.tablerow:nth-child(odd){
    //     background-color: red;
    // }
  }

  .tableloader {
    background: #fff;
    font-size: 12px;
    color: #9499aa;
    padding: 5px 12px;
    display: inline-flex;
    width: 100%;

    .loader {
      border: 2px solid #f3f3f3;
      border-radius: 50%;
      border-top: 2px solid #9499aa;
      width: 20px;
      height: 20px;
      top: 0px;
      position: relative;
      animation: spin 2s linear infinite;
    }

    /* Safari */
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }

      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  .tabletabs {
    display: inline-flex;

    .navtabinput {
      input {
        padding: 6px 12px;
        border-radius: 4px;
        border: 1px solid #99d2ee;
        font-family: Archivo, sans-serif;
        margin-right: 4px;
        background-color: #f6f8fb;

        &:focus {
          border: 1px solid #99d2ee;
        }
      }
    }

    .navtab {
      background-color: #f6f8fb;
      border-radius: 4px;
      cursor: pointer;
      padding: 5px 12px;
      margin-right: 4px;

      &.active {
        background-color: #008fd4;
        color: #fff;

        &:hover {
          background-color: #008fd4;
        }
      }

      &:hover {
        background: #ebedf1;
      }
    }
  }

  .table-drop-down {
    .dropdown-menu {
      width: 250px;
      border: 1px solid #ebedf1;
    }

    .dropdown_menu_list {
      .list_item_header {
        font-size: 12px;
        padding: 0px 12px;
        color: #9499aa;
      }

      .list_item {
        padding: 5px 12px;
        cursor: pointer;

        &:hover {
          background: #f6f8fb;
        }

        .menudropdown {
          visibility: hidden;
        }

        &:hover .menudropdown {
          visibility: visible;
        }
      }
    }

    .btn-primary {
      padding: 0px;
      background: transparent;
      border: 0px;

      &::after {
        display: none;
      }
    }
  }

  .actioncenterdropdown {
    line-height: 10px;

    .dropdown-menu {
      padding: 5px 0px !important;
      height: auto !important;
      background: #fff !important;
      border-radius: 4px !important;
      width: 185px !important;
      color: #212529 !important;
      top: 0px !important;

      .child_drop {
        font-weight: 400 !important;
        padding: 10px 12px;
        margin: 0px !important;

        &:hover {
          background: #f6f8fb !important;
        }
      }
    }
  }

  .action_center_wrapper {
    width: 100%;
    display: flex;
  }

  // sidebar
  .tabSidebar-client {
    width: calc(100% - 63px);
    @include respond-to(lg) {
      width: 40%;
    }
    padding: 30px 0px 0px 0px !important;
    transition: transform .5s cubic-bezier(.23, 1, .32, 1) !important;
    height: 100%;
    z-index: 2;
    position: relative;

    border-left: 2px solid #EBEDF1 !important;
    border-radius: 8px !important;

    .navtabinput {
      input {
        padding: 6px 12px;
        border-radius: 4px;
        border: 2px solid #99d2ee;
        font-family: Archivo, sans-serif;
        margin-right: 4px;
        background-color: #f6f8fb;

        &:focus {
          border: 2px solid #99d2ee;
        }
      }
    }

    .datepicker_cutom {
      .custom_date_picker {
        background-color: transparent;
        border: 1px solid #fff;

        &:before {
          content: "";
          background: url("../../../public/images/actioncenter/dateicon.svg");
          height: 15px;
          width: 16px;
          background-repeat: no-repeat;
          background-position: 2px -1px;
          position: relative;
          left: 4px;
        }
      }
    }

    .greyclr2 {
      color: #9499aa;
      font-size: 12px;
    }

    .actiontext {
      width: 100%;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      padding: 4px 5px;
      min-height: 100px;
      line-height: 22px;

      letter-spacing: 0.02em;
      margin-top: 15px;
      color: #0c0d11;

      &.archived {
        color: #9499aa !important;
      }
    }

    .comments_wrapper {
      padding-top: 12px;

      .comment_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .usercomments {
        // overflow-y: auto;
        max-height: max-content;

        .list-group-item {
          padding-left: 0px;
          padding-right: 0px;
          border: 0px;
        }

        .user_txt {
          p {
            margin-bottom: 4px;
          }

          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #0c0d11;
        }

        .user_txt2 {
          color: #9499aa;
          font-size: 12px;
        }

        .daytime {
          background: #ebedf1;
          border-radius: 2px;
          padding: 4px 10px;
          border-radius: 2px !important;
          display: inline-block;
          margin-top: 10px;
        }
      }

      .addcomments {

        // .greyaddtxt{
        //     color: #9499AA;
        // }
        .addcommentwrap {
          position: relative;
        }

        .addlabelbtnblu {
          background: #008fd4 !important;
        }

        .addlabelbtn {
          position: absolute;
          right: 5px;
          bottom: 4px;
          cursor: pointer;
          padding: 4px;
          width: 32px;
          height: 30px;
          //background: #008FD4;
          text-align: center;
          background: #ebedf1;
          border-radius: 2px;
        }

        .addcommentinput {
          font-family: "Archivo";
          width: 100%;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          padding: 9px 40px 9px 12px;
          background: #f6f8fb;
          border-radius: 4px;
          min-height: 40px;

          &:focus-within {
            outline: 2px solid #99d2ee;
          }

          // &.placeholder {
          //   &:after {
          //     content: "Add a comment";
          //     color: #9499aa;
          //   }
          // }
        }
      }
    }

    // [contenteditable="true"]:empty:before {
    //   content: attr(placeholder);
    //   pointer-events: none;
    //   display: block; /* For Firefox */
    // }
    .tableStatus {
      padding: 4px 9px;
      font-size: 12px;
      border-radius: 20px;
      display: inline-flex;
      align-items: center;
      line-height: 15px;

      .tablecircle {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-right: 5px;

        &.archived {
          background-color: #616778 !important;
        }
      }

      &.archived {
        background-color: #ebedf1 !important;
        color: #616778 !important;
      }
    }

    .title {
      color: #0C0D11 !important;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
    }

    .input_custom_dropdown {
      // .drop_border{
      //     border: 2px solid #99D2EE !important;
      // }

      .dropdown_input {
        font-size: 14px;
        font-family: "Archivo";
        font-style: normal;
        border: 2px solid #fff;
        border-radius: 4px;
        width: 100%;
        line-height: 22px;
        padding: 0px 8px;
        border: 0px;
        display: flex;
        align-items: center;
        color: #0c0d11;

        .crossimg {
          height: 9px;
          margin-left: 5px;
          cursor: pointer;
        }

        &:focus {
          border: 2px solid #99d2ee;
        }

        &:focus-within {
          border: 2px solid #99d2ee;
        }
      }

      .custombadge {
        border: 1px solid #d4d6dd;
        border-radius: 9999px;
        padding: 0px 7px 0px 2px;
        font-size: 12px;
        line-height: 23px;
        color: #616778;
        font-weight: 500;
      }

      .custombadge2 {
        color: #0c0d11;
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        padding: 0px 7px 0px 2px;
        line-height: 23px;
      }

      .dropdown-toggle {
        width: 100%;
        border: 0px;
        background: transparent;

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        padding: 8px 0px;
        min-height: auto;
        // max-height: 350px;
        height: fit-content !important;
        transform: translate(16px, 57px) !important;

        .child_drop {
          padding: 4px 10px;
          cursor: pointer;

          &:hover {
            background: #f6f8fb;

            .hidden-dropdown {
              // display: block;
              visibility: visible;
            }
          }
        }
      }

      .dropdown-menu.show {
        width: 250px !important;
        height: 160px !important;
        overflow-y: scroll;
        scrollbar-width: none;
      }
    }

    .input_custom_dropdown-client {
      // .drop_border{
      //     border: 2px solid #99D2EE !important;
      // }

      .dropdown_input {
        font-size: 14px;
        font-family: "Archivo";
        font-style: normal;
        border: 2px solid #fff;
        border-radius: 4px;
        width: 100%;
        line-height: 22px;
        padding: 0px 8px;
        border: 0px;
        display: flex;
        align-items: center;
        color: #0c0d11;

        .crossimg {
          height: 9px;
          margin-left: 5px;
          cursor: pointer;
        }

        &:focus {
          border: 2px solid #99d2ee;
        }

        &:focus-within {
          border: 2px solid #99d2ee;
        }
      }

      .custombadge {
        border: 1px solid #d4d6dd;
        border-radius: 9999px;
        padding: 0px 7px 0px 2px;
        font-size: 12px;
        line-height: 23px;
        color: #616778;
        font-weight: 500;
      }

      .custombadge2 {
        color: #0c0d11;
        width: 100%;
        text-align: left;
        font-weight: 500;
        font-size: 14px;
        padding: 0px 7px 0px 2px;
        line-height: 23px;
      }

      .dropdown-toggle {
        width: 100%;
        border: 0px;
        background: transparent;

        &:after {
          display: none;
        }
      }

      .dropdown-menu {
        width: 100%;
        padding: 0px !important;
        min-height: auto;
        // max-height: 350px;
        height: fit-content !important;
        transform: translate(16px, 57px) !important;

        .child_drop {
          padding: 4px 10px;
          cursor: pointer;

          &:hover {
            background: #f6f8fb;

            .hidden-dropdown {
              // display: block;
              visibility: visible;
            }
          }
        }
      }

      .dropdown-menu.show {
        // width: 250px !important;
        height: 160px !important;
        overflow-y: scroll;
        scrollbar-width: none;
        transform: translate(16px, 57px) !important;

      }
    }
  }

  .module-dropdown-fix {
    position: relative;

    .dropdown-menu.show {
      height: fit-content !important;
    }
  }

  .task-title {
    cursor: text;
    width: 60%;
    padding: 3px;
    border-radius: 2px;

    &:hover {
      background: #f3f3f3;
    }
  }

  .edit-task-title {
    width: 60%;
    height: 32px;
    border: 1px solid #008fd429;
    font-size: 18px;
    font-weight: 600;
    border-radius: 3px;
    padding: 3px;
  }

  .edit-section-dropdown {
    .btn:active {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .task-cancel-btn {
    background: #f6f8fb;
    font-weight: 500;
    border-radius: 2px;
    color: #616778;
    border: none;
    height: 34px;

    &:active {
      border: none !important;
      color: #616778 !important;
      background: #f6f8fb !important;
    }
  }

  textarea::placeholder {
    color: #9499aa;
  }

  textarea.actiontext {
    border: 1px solid #fff;
    border-radius: 4px;
  }

  textarea.actiontext:focus {
    border: 1px solid #d4d6dd55;
    border-radius: 4px;
  }

  .comment-dropdown {
    .dropdown-menu.show {
      inset: -25px -18px auto 34px !important;
    }
  }

  .drop-drag-issue {
    z-index: 6;
    width: auto;
    min-width: 215px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
    color: #212529 !important;
    padding: 5px 0px 5px 0px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #fff;
  }

  .edit-comment-field {
    width: 95%;
    border-radius: 4px;
    padding: 4px;
    border: 1px solid #ebebeb;
    margin-top: 10px;
    margin-left: 15px;
    margin-right: 15px;
    height: 90px;
  }

  .archived-header {
    color: #616778 !important;
  }

  .archived-text {
    color: #9499aa !important;
  }

  .unarchived {
    pointer-events: all !important;
  }

  .archived-filter {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }

  .archived-date {
    height: 50px;
    position: absolute;
    width: 61%;
    z-index: 5;

    pointer-events: all !important;
  }

  .f-500 {
    font-weight: 500;
  }

  .text-set {
    padding: 3px;
    margin-top: 3px;
  }

  .move-left- {
    width: 0%;
    animation: moveleft 0.3s ease-in-out;
    -webkit-animation: moveleft 0.3s ease-in-out;
    transition: all 300ms cubic-bezier(0, 0.41, 0.765, 1.01);
    /* custom test-transition */
  }

  @keyframes moveleft {
    0% {
      width: 50%;
    }

    100% {
      width: 0% !important;
    }
  }










  .move-right {
    width: 40%;
    // padding: 25px 0px 30px 35px;
    padding: 30px 0px 0px 0px !important;
    // animation: moveright 0.3s ease-in-out;
    // -webkit-animation: moveright 0.3s ease-in-out;
    transition: all 300ms cubic-bezier(0, 0.41, 0.765, 1.01);
    /* custom test-transition */
  }

  // @keyframes moveright {
  //   0% {
  //     width: 0%;
  //   }

  //   100% {
  //     // width: 50% !important;
  //     width: 70%;
  //     padding: 25px 0px 30px 35px;
  //   }
  // }


  .table-move-right {
    width: 100%;
    // animation: movetable2 0.3s ease-in-out;
    // -webkit-animation: movetable2 0.3s ease-in-out;
    transition: all 300ms cubic-bezier(0, 0.41, 0.765, 1.01);
    /* custom test-transition */
  }

  @keyframes movetable2 {
    0% {
      width: 50%;
    }

    100% {
      width: 100%;
    }
  }





  .table-move {
    width: 60%;
    animation: movetable 0.3s ease-in-out;
    -webkit-animation: movetable 0.3s ease-in-out;
    transition: all 300ms cubic-bezier(0, 0.41, 0.765, 1.01);
    /* custom test-transition */
  }

  // @keyframes movetable {
  //   0% {
  //     width: 100%;
  //   }

  //   100% {
  //     width: 50% !important;
  //   }
  // }

  .editcomment-wrapper {
    margin: 14px 13px 10px 14px;
    border: 1px solid #e7e7e7;
    border-radius: 4px;
    padding: 8px;
  }

  .zeroSearchResult {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
  }

  .f-16 {
    font-size: 16px;
  }

  // Css for overflow scrolling case START
  .scrolling-div-issue {
    height: 100vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0px;
      display: none;
    }
  }

  .drop-drag-issue-menu {
    right: 0;

    .dropdown-menu.show {
      inset: 0px 0px auto 43px !important;
    }
  }

  // Css for overflow scrolling case END
}

.archived_task {
  .archivemsg {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background: #f6f8fb;
    border-radius: 4px;
    padding: 8px 12px;
    color: #616778;
  }

  .unarchivetxt {
    color: #008fd4;
  }
}

#root .color-dropdown {
  button {
    padding: 0px;
  }

  .color-circle {
    width: 16.5px;
    height: 16.5px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .dropdown-menu.show {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
    // padding: 5px 15px;
    background: #0c0d11;
    width: 160px;
    border-radius: 4px;
  }

  .color-divider {
    border: 0px !important;
    width: 77%;
    margin: auto;
  }

  .rename-status-border {
    border-top: 1px solid #b7b7b7;
    margin-top: 10px !important;
  }

  .color-wrapper {
    &:hover {
      background: #cecece40;
    }

    cursor: pointer;
    padding: 3px 13px;
  }

  .color-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.64);
  }

  .choose-color {
    padding: 3px 13px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.32);
  }
}

.hidden-dropdown {
  // display: none;
  visibility: hidden;
}

.actionCentreWelcomeModal {
  .image-loader {
    min-width: 550px;
    min-height: 235px;
    background-color: #ededed;
    border-radius: 10px;
  }
}

.spinner_loader {
  border: 0.25em solid #bbbec9;
  border-right-color: transparent;
  width: 11px;
  height: 11px;
  margin-left: 8px;
}

#root .black-dropdown {
  .dropdown-menu.show {
    background: #0c0d11 !important;
    color: rgba(255, 255, 255, 0.64) !important;
  }

  .child_drop:hover {
    background: #f6f8fb30 !important;
  }
}


.task_description_box {
  // min-height: 6rem;
  // padding: .5rem;
  // margin-top: .8rem;
  // margin-bottom: 10px;
  // border: 1px solid transparent;

  .task-detail-editor {
    cursor: text;
    min-height: 5rem;
    padding: .5rem;
    // margin-top: .8rem;
    margin-bottom: 0px !important;

    &:focus-within {
      outline: 2px solid #99d2ee;
      border-radius: 4px;
    }

  }

  &:hover {
    border-radius: 4px;
    border: 1px solid #ebecf1;
  }

  .public-DraftEditor-content {
    min-height: 5rem;
  }
}

.task_description_text {
  min-height: 7.5rem;
  padding: .5rem;
  border-radius: 4px;

  // padding: .1rem;
  // margin-top: .8rem;
  &:hover {
    background: #ebecf1;
  }

  p {
    margin-bottom: 0px;
  }
}


.disabled-due-to-subscription {

  .tablerow {
    color: #616778 !important;

    img {
      filter: grayscale(100%);
    }

    .prfimg {
      div {

        background: #616778 !important;
      }
    }

    .tableStatus {
      color: #616778 !important;
      background: #61677830 !important;

      .tablecircle {
        background: #616778 !important;

      }
    }
  }

  .replotposture {
    color: #9499AA !important;
    background: #EBEDF1 !important;

    img {
      filter: invert(30%) sepia(11%) saturate(380%) hue-rotate(189deg) brightness(82%) contrast(87%) !important;
    }
  }

  p {
    color: #616778 !important;
  }
}

.dateForTasksSideBare {
  width: 270px !important;
  padding-left: 0px !important;

  .dropdown-menu.show {
    // inset: auto 180px auto auto !important;
    transform: translate(0px, 6px) !important;
  }

  .dropdown-toggle {
    width: 100%;
    ;
  }
}

.dateForTasksModal {
  padding-left: 0px !important;

  .dropdown-menu.show {
    // inset: auto 0px auto auto !important;
    transform: translate(0px, 6px) !important;
  }

  .dropdown-toggle {
    width: 100%;
    ;
  }
}

.dateForTasksModalAudit {
  .dropdown-menu.show {
    inset: auto 40px auto auto !important;
    transform: translate(0px, 2px) !important;
  }

  .dropdown-toggle {
    width: 100%;
    ;
  }
}