.security-incident {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #f36060;
}
.v2-item-color {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #616778 !important;
}

.header-v2 .sideBarDropdown .dropdown-menu {
  inset: auto 6px auto auto !important;
  transform: translate(0px, 0px) !important;
  padding: 7px 5px !important;
  background: #fff !important;
  border-radius: 4px !important;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
  width: 200px;
  border: 1px solid #ebedf1;
  /* Shadow / Bottom / Medium / M */
}
.header-v2 .sideBarDropdown .dropdown-item {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #616778 !important;
  margin-bottom: 4px;
  padding: 0.38rem 1rem;
}
.header-v2 .sideBarDropdown .dropdown-item:hover {
  border-radius: 2px;
  background-color: #fbfbfb;
}
.divider {
  color: lightgray;
  margin: 0px !important;
}

.header-v2 {
  cursor: default !important;
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }
}

.header-v2 .header-logo-dropdown {
  .dropdown-menu {
    width: 250px;
  }
  .dropdown-item {
    padding: 0.3rem 1rem;
  }

  .dropdown-item.disabled {
    opacity: 1 !important;
    cursor: default !important;
  }

  .thumbnail-pic {
    object-fit: contain;
    width: 31px;
    border-radius: 50%;
  }
}
