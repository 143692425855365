.upgradePremium .card_number_wrapper div.cardnumber {
  width: 50% !important;
}
.upgradePremium .card_number_wrapper div.csvnumber {
  width: 23% !important;
}

.upgradePremium {
  .modal-content {
    width: 640px !important;
  }
  .annual_wrapper {
    border-right: 1px solid #ebecf1;
    padding-right: 16px !important;
  }
  .mt40 {
    margin-top: 40px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .listtxt {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #b6b6bf;
  }
  .h32 {
    height: 32px;
  }
  .d24 {
    height: 24px;
    width: 100%;
    //  background: red;
  }
  hr {
    margin: 0px;
  }
  .payment_method_wrapper {
    display: grid !important;
    grid-template-columns: auto auto auto;

    .w30 {
      width: 95% !important;
      height: 100px;
    }
  }
  .mt-10px {
    margin-top: 10px;
  }
  .order_summary {
    background: #f6f8fb;
    padding: 12px;
    border-radius: 8px;
  }
  .order_r {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .blubtn {
    background: #008fd4;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    height: 31px;
    min-height: 41px;
  }
  .gtxt {
    font-size: 12px;
    line-height: 13px;
    color: #b6b6bf;
    padding-top: 4px;
  }
  .greytxt {
    color: #b6b6bf;
    margin-bottom: 8px;
  }
  .gryClr {
    color: #b6b6bf;
  }
  .grey-input {
    margin-bottom: 8px;
    .form-control {
      height: 32px;
    }
  }
  .round-checkbox {
    height: 13px;
    width: 13px;
    label {
      height: 13px;
      width: 13px;

      &::after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 5px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 2px;
        transform: rotate(-45deg);
        width: 8px;
      }
    }
    .form-check-input {
      margin-top: 0px;
    }
  }

  .txtcard {
    white-space: pre;
    font-size: 14px;
  }

  .brdBlu {
    border: 1px solid #008fd4;
  }
  .card_number_wrapper {
    display: flex;
    flex-direction: row;
    flex: 0.6 0.2 0.2;
  }

  .card_number_wrapper div.cardnumber {
    width: 58%;
  }
  .card_number_wrapper div.datemonth {
    width: 27% !important;
  }
  .card_number_wrapper div.csvnumber {
    width: 17%;
  }
  .responseList {
    width: 400px;
    margin: auto;
    p {
      margin-bottom: 5px;
      img {
        height: 12px;
        margin-right: 5px;
        width: 12px;
      }
    }
  }
  .txtsucess {
    font-family: Archivo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #40d88e;
  }
  .txtfail {
    font-family: Archivo;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    color: #f36060;
  }
  .loader {
    height: 128px;
    width: 144px;
    left: 249px;
    border-radius: 16px;
    padding: 24px;
    background: #f6f8fb;
    border: 1px solid #ebecf1;
  }
  .loader_wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    background: #ffffffd9;
  }
  .loader_circle {
    border: 6px solid #c8dff7;
    border-radius: 50%;
    border-top: 6px solid #008fd4;
    width: 38px;
    height: 38px;
    margin: auto;
    margin-top: 8px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .carderr {
    background-color: rgba(254, 95, 91, 0.08);
    background-image: url("../../../public/images/card_error.svg");
    background-repeat: no-repeat;
    background-position: calc(100% - 10px) 10px;
  }
  .carderr1 {
    background-color: rgba(254, 95, 91, 0.08);
    background-image: url("../../../public/images/card_error.svg");
    background-repeat: no-repeat;
    background-position: 5px 8px;
    text-indent: 15px;
  }

  .cardicon {
    background-image: url("../../../public/images/subscription/card.svg");
    background-repeat: no-repeat;
    background-position: 5px 9px;
    text-indent: 15px;
  }
  .cardicon2 {
    background-image: url("../../../public/images/subscription/month_icon.svg");
    background-repeat: no-repeat;
    background-position: 8px 10px;
    text-indent: 15px;
  }
}

.proService_tooltip {
  left: 86px !important;
  top: -35px !important;
}
.proService_tooltip .tooltip-inner {
  padding: 7px 23px !important;
  border-radius: 0px !important;
}
.bgred {
  background-color: rgba(254, 95, 91, 0.08) !important;
}
