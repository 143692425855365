@use '../../../../breakpoints.scss' as *;

.expandable-modal {
  width: 300px;
  &.expanded {
    position: fixed !important;
    height: 100%;
    width: 100%;
    @include respond-to(lg) {
      width: 45%;
    }
    top: 151px !important;
    transform: none !important;
    z-index: 2;
    @include respond-to(lg) {
      top: 102px !important;
    }
    .expand-icon {
      transform: rotate(135deg);
    }
  }
}
