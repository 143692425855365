@use '../../../breakpoints.scss' as *;

.membersAccess-wrapper {
    margin-top: 2.5rem !important;
    width: 100%;
    padding: 0 20px;
    @include respond-to(lg) {
      max-width: 60%;
      width: 100%;
      padding: 0;
      margin: auto;
    }
    .heading {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
  
      letter-spacing: 0.02em;
  
      color: #0c0d11;
    }
    .mb-24px {
        margin-bottom: 24px;
      }
    .add-member-btn {
      background: #008fd4;
      border-radius: 2px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #fff;
      border: none;
    }
    .disable-btn {
      background: #ebecf1;
      border-radius: 2px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #b6b6bf;
      border: none;
    }
    .py-4px{
        padding-top: 4px;
        padding-bottom: 4px;
    }
    table {
      margin-top: 12px;
      .grid-container {
        display: grid;
        grid-template-columns: 428px 320px;
        gap: 12px;
        @include respond-to(md) {
          grid-template-columns: 58% 40%;
        }
      }
      tr {
        th {
          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: flex-end;
          letter-spacing: 0.02em;
          color: #616778;
        }
      }
  
      .table-data {
        padding: 1rem;
      }
      .user-name {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
  
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: #0c0d11;
      }
      .user-email {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
  
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: #616778;
      }
      .dots.icon {
        margin-left: 3rem;
        cursor: pointer;
      }
  
      .user-detail-dropdown {
        .device-dropdown button {
          background: transparent;
          border: none;
          &::after {
            display: none;
          }
        }
        .dropdown-toggle {
          background: transparent !important;
          border: none !important;
        }
        .show {
          .dropdown-toggle {
            border: none;
          }
        }
        .dropdown-menu.show {
        //   background: #0c0d11;
          box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
          border-radius: 4px;
          width: 224px;
        }
        .notification-btn {
          cursor: pointer;
          color: rgba(255, 255, 255, 0.64);
          padding: 4px 13px;
        }
        .remove-btn {
          cursor: pointer;
          color: #f36060;
          padding: 5px 10px 5px 13px;
        }
      }
    }
  }

.memberAccessModal {
  .m-24px{
    margin: 24px 24px 0 24px;
  }

  .mt-24px{
    margin-top: 24px;
  }

  .mt-16px{
    margin-top: 16px;
  }

  .mt-14px{
    margin-top: 14px;
  }

  .w-12{
    width: 12px;
  }

    .msp-service-tool-tip-parent {
    position: relative;

    .msp-service-tool-tip-content {
        position: absolute;
        display: none;
    }

    &:hover {
        .msp-service-tool-tip-content {
            display: flex !important;
            flex-direction: column !important;
            z-index: 555;
            box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
            top: -100px;
            left: 23px;
        }
      }
  }
}

.members-multiselect-dropdown{


  .hide-focus-outline {
      &:focus-visible {
          outline: -webkit-focus-ring-color auto 0px;
      }
  }


  &.show {
      .dropdown-toggle {
          // border: 2px solid #99D2EE !important;
          width: -webkit-fill-available;
          background: #f6f8fb !important;

          input {
              background: transparent !important;
          }

          // img {
          //     margin-left: 5px;
          // }
      }
  }

  .dropdown-toggle:focus-within {
      // border: 2px solid #99D2EE !important;
      width: -webkit-fill-available;
      background: #f6f8fb !important;
  }


  .dropdown-toggle {
      input {
          background: transparent !important;
      }

      border: 2px solid transparent !important;

      &::after {
          display: none;
      }

      height: auto;
      min-height: 32px;
      border-radius: 4px;
      // max-width: 200px;
      // min-width: 200px;
      padding: 3px;
      padding-left: 5px;
      background: transparent !important;
      border-color: transparent
  }

  .dropdown-menu.show {
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto 0px !important;
  }

  .dropdown-toggle {
      &:focus-within {
          .typing-input {
              display: flex !important;
          }

          .selected-value {
              display: none !important;
          }
      }

      .typing-input {
          display: none;
      }

      .selected-value {
          display: flex;
      }
  }
}

.bottomMarginUserRoleBlocks {
  margin-bottom: 100px !important;
}

.bigLockerImage {
  height: 20px;
  margin-right: 4px;
  filter: brightness(0.5);

}

.minHeight-75 {
  min-height: 75vh;
}