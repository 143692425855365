$link: #008FD4;
$background: #F6F8FB;
$cardBackground: #FFFFFF;

.TicketOptions{
    display: flex;
}
.TicketOptions > p{
    margin-right: 1em;
    margin-bottom: 0em !important; 
    padding: 16px; 
}
.TicketOptions .activeTicket{
    color: $link;
    border-bottom: 1px solid #008FD4;
    padding: 16px;
}

.TicketsView
{
    background-color: $background;
    padding: 2em;
    border-radius: 8px;
    > *{
       margin-bottom: 1em; 
    }
}


.TicketCards 
{
    font-size: .9em;
    .tableElement
    {
        display: flex;
        flex-direction: column;
    }
    .tableElement > *
    {
        padding: .3em;
    }
    .tableContainer
    {
        width: 100%;
        display: flex;
        margin-bottom: 1em;
        border-radius: 4px;
        background-color: $cardBackground;
        padding: .5em;
        > *{
            padding-right: .5em;
        }
    }
    .tableMessageContainer
    {
        margin-left: auto;
        display: flex;
        align-items: center;
        p
        {
            padding: .2em;
        }
    }
    p
    {
        margin-bottom: 0;
    }
}
