@use '../../../../../breakpoints.scss' as *;

.templatesModal {
  max-width: calc(100%);
  .modal-content {
    padding: 0px;
    width: calc(100% - 16px);
    @include respond-to(lg) {
      width: 990px;
    }
    @include respond-to(xl) {
      width: 1300px;
    }
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100vh;
    border: transparent !important;
  }

  .modal-header {
    border-bottom: 1px solid #EBEDF1 !important;
  }

  .modal-body {
    padding: 0px !important;
    display: flex;
    flex: 1;
    overflow: hidden;
  }

  .templatesSidebar {
    position: relative !important;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #EBEDF1;
    padding: 10px;
    min-width: fit-content;
    @include respond-to(lg) {
      min-width: 280px;
    }
    box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);

    .selected-menu-item {
      color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }

    .menu-item:hover {
      color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }
  }

  .mainBlock {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .tagsDisplay {
      display: ruby-text !important;
    }

    .widthMaxContent {
      width: max-content !important;
    }
  }

  .menuBlocks {
    flex-wrap: wrap;
    @include respond-to(lg) {
      flex-wrap: nowrap;
      justify-content: center;
    }
  }

  .menuBlock {
    width: 100%;
    @include respond-to(md) {
      width: 145px;
    }
    @include respond-to(lg) {
      width: -webkit-fill-available;
    }
    height: 96px;
  }

  .mainTopBlock {
    background-color: #5244B3 !important;
    height: 164px !important;
    padding: 40px 40px 0px 40px;
  }

  .leftBlock {
    width: 700px !important;
  }

  .rightBlock {
    width: 200px !important;
  }

  .leftBlockBottom {
    max-width: 440px !important;
    min-height: 162px !important;
  }

  .rightBlockBottom {
    max-width: 100% !important;
    @include respond-to(md) {
      max-width: 200px !important;
    }
    min-height: 162px !important;
  }

  .leftBlockDetails {
    width: 100%;
    @include respond-to(xl) {
      width: 455px !important;
    }
  }

  .rightBlockDetails {
    width: 100%;
    @include respond-to(xl) {
      width: 455px !important;
    }
    background-color: #F6F8FB;
    border-radius: 8px;
  }

  .packages-table {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    @include respond-to(md) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include respond-to(xl) {
      grid-template-columns: repeat(3, 1fr);
    }
    gap: 16px;
  }

  .package-sections-wrapper {
    flex-direction: column-reverse;
    @include respond-to(xl) {
      flex-direction: row;
    }
  }

  .services-view-container {
    @include respond-to(xl) {
      margin: 0 130px;
    }

    .service-components-block {
      display: grid;
      grid-template-columns: 30% 35% 35%;
      // height: 3rem;
      // align-items: center;
  }
  
  }

  .features-wrapper {
    flex-direction: column;
    @include respond-to(md) {
      flex-direction: row;
    }
    .features-title {
      width: 100%;
      @include respond-to(md) {
        width: 30%;
      }
    }
    .features-data {
      width: 100%;
      @include respond-to(md) {
        width: 70%;
      }
    }
  }

  .nist-framework-container {
    gap: 16px;
    .nist-left-block {
      width: 100%;
      @include respond-to(md) {
        width: 30%;
      }
      .blocks {
        display: flex;
        flex-wrap: wrap;
        @include respond-to(md) {
          flex-wrap: nowrap;
          flex-direction: column;
        }
      }
    }
    .nist-right-block {
      width: 80%;
      margin-left: 10%;
      @include respond-to(md) {
        width: 50%;
        margin-left: 100px;
      }
    }
  }

  .product-view-container {
    @include respond-to(xl) {
      margin: 130px;
    }
  }

  .package-column {
    display: flex;
    flex-direction: column;
  }

  .score-question-dropdown {
    .custom_drop-down {
      background: transparent;
    }

    .custom_drop-down .dropdown_item:hover {
      background-color: rgba(0, 143, 212, 0.0);
    }
  }

  .table-title {
    display: grid;
    grid-template-columns: 5% 95%;
    padding: 4px 8px 4px 12px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    background: var(--Grayscale-Gray-05, #EBEDF1);
  }

  .dropdown-history {
    position: relative;

    .history {
      z-index: 1;
      position: absolute;
      display: none;
      padding: 4px 0px;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;
      border-radius: 4px;
      border: 1px solid var(--grayscale-gray-05, #EBEDF1);
      background: var(--white-white-100, #FFF);
      box-shadow: 0px 12px 24px 0px rgba(16, 16, 16, 0.08);
    }

    &:hover {
      .history {
        display: flex;
      }
    }
  }

  .searchInputField {
    border: none;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    background: #F6F8FB !important;
    border: 1px solid #EBEDF1 !important;

    input {
      border: none;
      background: transparent;
      outline: none;
    }

    &:focus-within {
      border: 1px solid #9499AA !important;
      border-radius: 4px;
      background: #f6f8fb !important;
    }
  }

  .searchInputFieldWithoutBorder {
    border: none;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    background: #F6F8FB !important;

    input {
      border: none;
      background: transparent;
      outline: none;
    }

    &:focus-within {
      border-radius: 4px;
      background: #f6f8fb !important;
    }
  }

  .templates-dropdown {
    .dropdown-toggle {
      background: #F6F8FB;
      padding: 4px 8px 4px 8px;
      border: none;

      &::active {
        background: #F6F8FB;
        padding: 4px 8px 4px 8px;
        border: none;
      }

      &::after {
        display: none;
      }
    }

    &.show {
      .dropdown-toggle {
        background: #F6F8FB;
        padding: 4px 8px 4px 8px;
        border: none;
      }
    }

    .dropdownItem {
      &:hover {
        color: #101010;
        background: #ebecf1;
        cursor: pointer;
      }
    }


    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      // color: #fff;
      background: #F6F8FB;
      padding: 4px 8px 4px 8px;
      border: none;
    }

    .dropdown-menu.wrapper-dropdown.show {
      transform: translate(0px, 0px) !important;
      inset: 150% -98px auto auto !important;
    }

    .left-dropdown-dir {
      transform: translate(0px, 0px) !important;
      inset: auto 0 auto auto !important;
    }

    .dropdown-menu {
      padding: 10px !important;
    }
  }

  .borderBottom {
    border-bottom: 1px solid #EBEDF1 !important;
  }

  .mt-300px {
    margin-top: 300px !important;
  }

  .container-block {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .item {
    flex: 0 0 auto;
    width: auto;
  }

  .product-p1-table {
    display: grid;
    grid-template-columns: 50% 35% 15%;

  }

  .labour-list-summary-table {
    display: grid;
    grid-template-columns: 200px 80px 150px 80px 150px 80px 80px;
    @include respond-to(md) {
          grid-template-columns: 26% 11% 16% 10% 22% 10% 5%;
    }
    background-color: #F6F8FB;
    padding: 2px 4px;
  }
}