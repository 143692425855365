@use '../../../breakpoints.scss' as *;

.auth-layout {
  background-color: #F6F8FB;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  z-index: 20;

  .auth-body {
    background-color: #fff;
    box-shadow: 0px 40px 80px 0px #1010100A;
    border: 1px solid #EBEDF1;
    border-radius: 24px;
    min-height: 440px;
    position: relative;
    width: auto;
    margin: 0 16px;
    .authenticator-text {
      max-width: 300px;
    }
    input:-webkit-autofill {
      background-color: transparent !important;
    }
    .continue-btn {
      padding: 5px !important;
      height: 32px !important;
    }
    .disabled-btn {
      background-color: #EBEDF1;
      color: #9499AA;
      opacity: 1;
    }
    .auth-default-sections {
      flex-direction: column;
    }
    .auth-default-sections,
    .auth-bottom-info-sections {
      display: flex;
      gap: 16px;
      padding: 40px;
      width: 100%;

      .left-section {
        display: flex;
        flex-direction: row;
        gap: 24px;
        align-items: center;
        .info {
          display: flex;
          flex-direction: column;
          gap: 2px;
          .title {
            font-size: 20px;
            font-weight: 500;
            line-height: 36px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #0C0D11;
          }
          .description {
            font-size: 14px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.02em;
            text-align: left;
            color: #616778;
            max-width: 400px;
          }
        }
      }
      .label {
        font-size: 14px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-align: left;
      }
      .right-section {
        padding: 64px 0;
        width: 100%;
      }
    }
    .change-input {
      position: relative;
      border: 1px solid #EBEDF1;
      border-radius: 4px;
      padding: 5px;
      .input-icon {
        position: absolute;
        left: 0;
        top: 0;
      }
      &.rounded {
        border-radius: 9999px !important;
      }
    }
    .section-input {
      position: relative;
      border: 1px solid #EBEDF1;
      border-radius: 4px;
      padding: 5px 8px;
      height: 32px;
      &:focus-within {
        border: 1px solid #99d2ee;
        border-radius: 4px;
      }
      input {
        border: none;
        outline: none;
        height: 18px;
        background-color: transparent !important;
      }
      &.error {
        border: 1px solid #F36060;
        .input-icon {
          color: #F36060 !important;
        }
      }
    }
    .input-icon {
      position: absolute;
      left: 2px;
      top: -1px;
      pointer-events: none;
      color: #9499aa;
      fill: currentColor;
    }
    .error-section {
      margin-top: 4px;
    }
    .error-message {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #B2494A;
      font-size: 12px;
      line-height: 18px;
    }
    .auth-signup-sections {
      display: flex;
      width: 100%;
      overflow: hidden;
      .left-section {
        padding: 40px;
        width: 100%;
        max-width: 404px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        .section-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 28px;
          letter-spacing: 0.02em;
        }
        .section-step {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          letter-spacing: 0.02em;
        }
        .block {
          display: flex;
          flex-direction: column;
          gap: 12px;
          .block-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.02em;
          }
        }
        .upload-btn {
          background-color: #F6F8FB;
          border: none;
          outline: none;
          padding: 3px 6px;
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.02em;
          display: flex;
          align-items: center;
          gap: 4px;
          border-radius: 2px;
          &:focus {
            border-color: #99d2ee !important;
            box-shadow: 0 0 5px #99d2ee !important;
            outline: none;
          }
        }
        .input-label {
          font-size: 12px;
          font-weight: 500;
          line-height: 18px;
          letter-spacing: 0.02em;
        }
        .email-section {
          border: 1px solid #F6F8FB !important;
        }
        .dropdown-toggle {
          background-color: #fff !important;
          border: 1px solid #EBEDF1 !important;
          height: 32px !important;
          padding: 5px 8px !important;
        }
        .account-type-item {
          padding: 8px;
          border: 1px solid #EBEDF1;
          border-radius: 4px;
          &.selected {
            border: 1px solid #008FD4;
            .account-type-title {
              color: #008FD4;
            }
          }
          .account-type-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0.02em;
            color: #616778;
          }
          .account-type-description {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            letter-spacing: 0.02em;
            color: #9499AA;
          }
        }
      }
      .right-section {
        padding: 112px 80px;
        position: relative;
        display: none;
        .img-container {
          width: 666px;
          height: 416px;
          box-shadow: 0px 12px 24px 0px #10101014;
          border: 1px solid #EBEDF1;
          border-radius: 12px;
        }
        .hovered-container {
          position: absolute;
          left: 0;
          top: 0;
          padding: 112px 80px;
          transition: opacity 0.5s;
          opacity: 0;
          &.hovered {
            opacity: 1;
          }
          img {
            z-index: 2;
          }
        }
      }
    }
    .bar-not-active, 
    .bar-filled-red,
    .bar-filled-orange,
    .bar-filled {
      margin-left: 0 !important;
      height: 4px !important;
      &:not(:first-child) {
        margin-left: 4px !important;
      }
    }
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 66px;
  }

  .btn-blue {
    border-radius: 2px;
  }

  .auth-bottom-info-sections {
    flex-direction: column;
    padding: 40px 40px 24px 40px !important;
    height: 100%;
    .right-section.remove-paddings {
      padding: 32px 0 80px 0 !important;
    }
    .terms-info-wrapper {
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-bottom: 24px;
      padding-right: 40px;
      width: 100%;
      .terms-info {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0.02em;
        text-align: center;
        word-break: break-word;
        padding-right: 40px;
      }
    }
  }
  .clean-button {
    border: none;
    outline: none;
    background: transparent;
    line-height: 18px;
    padding: 1px 4px;
  }
}

@include respond-to(sm) {
  .auth-layout {
    .auth-body {
      .terms-info-wrapper {
        white-space: pre-wrap;
        .terms-info {
          padding-right: 0;
        }
      }
    }
  }
}

@include respond-to(lg) {
  .auth-layout {
    .auth-body {
      width: auto;
      .authenticator-text {
        max-width: 100%;
      }
      .terms-info-wrapper {
        width: auto;
      }
      .auth-default-sections {
        flex-direction: row;
      }
      .auth-bottom-info-sections {
        .right-section.remove-paddings {
          padding: 0 !important;
        }
      }
      .auth-bottom-info-sections,
      .auth-default-sections {
        .left-section {
          flex-direction: column;
          width: 256px;
          min-width: 256px;
          align-items: flex-start
        }
        flex-direction: row;
        gap: 80px;
        width: 800px;
      }
    }
  }
}

@include respond-to(lg) {
  .auth-layout {
    .auth-body {
      .auth-signup-sections {
        .left-section {
          max-width: 100%;
          gap: 32px;
        }
        .right-section {
          display: block;
          max-width: 500px;
        }
      }
    }
  }
}

@include respond-to(xl) {
  .auth-layout {
    .auth-body {
      .auth-signup-sections {
        width: 1120px;
        .left-section {
          width: 464px;
        }
      }
    }
  }
}