@use './breakpoints.scss' as *;

body,
* {
  font-family: "Archivo" !important;
}

// 1366 X 768 ( Mackbook 11 inch  )
@media only screen and (max-width: 1366px) and (max-height: 768px) {
  .sideNav ul.nav {
    margin-top: 15px;
  }

  .sideNav .linkMenu ul li {
    padding-top: 10px;
  }

  .largeSideMenu .sideNav .img_wrapper .logo {
    height: 30px !important;
    padding-left: 15px !important;
  }

  .smallSideMenu .sideNav ul.nav {
    padding: 5px 0px 0px 0px;
  }

  .smallSideMenu .sideNav ul li p {
    margin-top: 5px;
  }

  .sideNav ul li img {
    height: 17px;
  }

  // home page
  .dashboard_screen {
    .row_split {
      padding-bottom: 0px;
    }

    .dash_container {
      padding-top: 0px;
    }
  }

  // cyber trend data
  .trend_data .dashboard {
    padding-bottom: 35px;
  }

  //system settings
  .system_settings .dashboard {
    padding-bottom: 35px;
  }
}

// 13inch mackbook pro 1280 X 800 Working fine
@media only screen and (max-width: 1280px) and (max-height: 800px) {
  .largeSideMenu .fullHeight.sideNav {
    max-width: 17.666667%;
  }

  #mainBody.fullHeight {
    // max-width: 82.333333%;
  }

  .sideNav ul.nav {
    margin-top: 15px;
  }

  .sideNav .linkMenu ul li {
    padding-top: 15px;
  }

  .sideNav ul li img {
    height: 17px;
  }

  .largeSideMenu .sideNav .img_wrapper .logo {
    height: 30px !important;
    padding-left: 15px !important;
    margin-right: 15px;
  }

  // home page
  .ceodashboard {
    .row_split {
      padding-bottom: 0px;
    }

    .dash_container {
      padding-top: 0px;
    }

    .dashboard {
      padding-right: 0px;
    }

    .connectIntegrationBtn {
      width: 185px !important;
    }

    .threat_card .mt-5 .d-flex img {
      height: 25px;
    }

    .threat_card .card-body div.mt-5 {
      margin-top: 15px !important;
    }

    .sideBar .bar__container .bar {
      height: 195px !important;
    }

    .sideBar .bar__wrapper {
      font-size: 14px !important;
    }

    .btmSideBar .proServiceCredit {
      flex-direction: column;
    }

    .btmSideBar .proServiceCredit p {
      margin-bottom: 0px;
    }
  }

  // cyber trend data
  .trend_data .dashboard {
    padding-bottom: 35px;
  }

  // compliance manager
  .Compliance_Manager {
    .webFilteringBox {
      overflow: hidden;
    }
  }
}

.btndisabled {
  border-color: transparent !important;
}

#mainBody .dashboard {
  height: 100%;
  padding-bottom: 20px;
}

#mainBody {
  overflow: hidden;
  overflow-y: auto;
}

.Dashboard-Screen-Wrapper .dashboard .row_split {
  padding-bottom: 40px;
}

@media (min-width: 1200px) and (max-width: 5099px) {
  .innerLoginWrapper {
    height: auto !important;
  }
}

@media (min-width: 1200px) and (max-width: 1299px) {
  // #mainRow {
  //   zoom: 0.85;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }
 
}

@media (min-width: 1300px) and (max-width: 1399px) {
  // #mainRow {
  //   zoom: 0.9;
  // }
 
}

@media (min-width: 1400px) and (max-width: 1499px) {
  // #mainRow {
  //   zoom: 0.95;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 0.95;
  // }
 
}

@media (min-width: 1500px) and (max-width: 1599px) {

  // #mainRow {
  //   zoom: 0.9;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 0.9;
  // }
 
}

@media (min-width: 1600px) and (max-width: 1699px) {

  // #mainRow {
  //   zoom: 1.14;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }
  
}

@media (min-width: 1700px) and (max-width: 1799px) {

  // #mainRow {
  //   zoom: 1.1;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 0.9;
  // }
  
}

@media (min-width: 1800px) and (max-width: 1899px) {

  // #mainRow {
  //   zoom: 1.15;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 0.9;
  // }
  
}

@media screen and (min-width: 1900px) and (max-width: 1999px) and (height: 1080px) {

  // #mainRow {
  //   zoom: 1.1;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 0.9;
  // }
 

  @-moz-document url-prefix() {
    #root {
      width: 84%;
      height: 84%;
      transform: scale(1.18);
      transform-origin: 50% 1%;
    }
  }
}

@media (min-width: 1900px) and (max-width: 1999px) and (height: 1200px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }


  @-moz-document url-prefix() {
    #root {
      width: 84%;
      height: 84%;
      transform: scale(1.18);
      transform-origin: 50% 1%;
    }
  }
}

@media (min-width: 2000px) and (max-width: 2099px) {

  // #mainRow {
  //   zoom: 1.25;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }

}

@media (min-width: 2100px) and (max-width: 2199px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }
  
}

@media (min-width: 2200px) and (max-width: 2299px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1;
  // }
}

@media (min-width: 2300px) and (max-width: 2399px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.1;
  // }
}

@media (min-width: 2400px) and (max-width: 2499px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.1;
  // }
}

@media (min-width: 2500px) and (max-width: 2599px) {
 

  // #mainRow {
  //   zoom: 1.65;
  // }
  body {
    line-height: inherit;
  }

  .modal {
    zoom: 1.3;
  }

  .modal .modal-content {
    height: -webkit-fill-available !important;
  }

  body {
    line-height: inherit;
  }

  .collapContainer80,
  .collapContainer50 {
    height: auto;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
  @-moz-document url-prefix() {
    #root {
      width: 65%;
      height: 65%;
      transform: scale(1.53);
      transform-origin: 50% 0%;
    }
  }
}

@media (min-width: 2600px) and (max-width: 2699px) {

  // #mainRow {
  //   zoom: 1.3;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.1;
  // }
}

@media (min-width: 2600px) and (max-width: 2699px) {

  // #mainRow {
  //   zoom: 1.5;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.1;
  // }
}

@media (min-width: 2700px) and (max-width: 2799px) {

  // #mainRow {
  //   zoom: 1.5;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.2;
  // }
}

@media (min-width: 2800px) and (max-width: 2899px) {

  // #mainRow {
  //   zoom: 1.55;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.2;
  // }
}

@media (min-width: 2900px) and (max-width: 2999px) {

  // #mainRow {
  //   zoom: 1.55;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.2;
  // }
}

@media (min-width: 3000px) and (max-width: 3099px) {

  // #mainRow {
  //   zoom: 1.55;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
}

@media (min-width: 3100px) and (max-width: 3199px) {

  // #mainRow {
  //   zoom: 1.55;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
}

@media (min-width: 3200px) and (max-width: 3299px) {

  // #mainRow {
  //   zoom: 1.6;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
}

@media (min-width: 3300px) and (max-width: 3399px) {

  // #mainRow {
  //   zoom: 1.6;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
}

@media (min-width: 3400px) and (max-width: 3499px) {

  // #mainRow {
  //   zoom: 1.65;
  // }
  
  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.45;
  // }
}

@media (min-width: 3500px) and (max-width: 3599px) {

  // #mainRow {
  //   zoom: 1.65;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.55;
  // }
}

@media (min-width: 3600px) and (max-width: 3699px) {

  // #mainRow {
  //   zoom: 1.7;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.55;
  // }
}

@media (min-width: 3700px) and (max-width: 3799px) {

  // #mainRow {
  //   zoom: 1.75;
  // }
  

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 1.9;
  // }
}

@media (min-width: 3800px) and (max-width: 3899px) {

  // #mainRow {
  //   zoom: 2.35;
  // }
  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2;
  // }
 
  body {
    line-height: inherit;
  }

  // modals
  .modal {
    zoom: 2;
  }

  .modal .modal-content {
    height: -webkit-fill-available !important;
  }

  .upgradePremium .modal-content,
  .centerModal .modal-content {
    height: 80% !important;
  }

  .collapContainer80,
  .collapContainer50 {
    height: auto;
  }

  // firefox
  @-moz-document url-prefix() {
    #root {
      width: 43%;
      height: 43%;
      transform: scale(2.3);
      transform-origin: 50% 0;
    }

    .centerModal {
      width: 43%;
      height: 43%;
      transform: scale(2.3);
      transform-origin: 0% 0;
    }

    .rightModal.right.modal-medium .modal-content {
      width: 99%;
      height: 46%;
      transform: scale(2.1);
      transform-origin: 100% 50%;
    }

    .right.modal-medium .modal-content {
      width: 99%;
      height: 46%;
      transform: scale(2.11);
      transform-origin: 98% -1%;
    }

    .right.modal-large .modal-content {
      width: 99%;
      height: 46%;
      transform: scale(2.11);
      transform-origin: 85% -1%;
    }

    .imageuploadModal.rightModal.right.modal-medium .modal-content {
      width: 99%;
      height: 48%;
      transform: scale(2);
      transform-origin: 100% 0%;
    }

    // .right.modal-large .modal-content{ width: 99%;height: 46%; transform: scale(2.11);transform-origin: 98% -1%; }
  }
}

@media (min-width: 3900px) and (max-width: 5099px) {

  // #mainRow {
  //   zoom: 2.3;
  // }
 

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2;
  // }
  // firefox
  @-moz-document url-prefix() {
    #root {
      width: 50%;
      height: 50%;
      transform: scale(2);
      transform-origin: 50% 0;
    }
  }
}

@media (min-width: 4000px) and (max-width: 4199px) {

  // #mainRow {
  //   zoom: 2.3;
  // }

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2.3;
  // }
}

@media (min-width: 4200px) and (max-width: 4299px) {

  // #mainRow {
  //   zoom: 2.35;
  // }


  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2.3;
  // }
}

@media (min-width: 4300px) and (max-width: 4799px) {

  // #mainRow {
  //   zoom: 2.4;
  // }


  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2.3;
  // }
}

@media (min-width: 4800px) and (max-width: 5099px) {

  // #mainRow {
  //   zoom: 2.5;
  // }

  body {
    line-height: inherit;
  }

  // #mainRow.empty-Screen-Wrapper {
  //   zoom: 2.3;
  // }
}

.border-bottom-v2-header {
  border-bottom: 1px solid #e9e6e6;
  background: white;
}

.pointer {
  cursor: pointer;
}

.modal-backdrop {
  z-index: 1041;
}

.modal-backdrop.show {
  zoom: 5;
}

.modal-backdrop.fade {
  transition: opacity 0s linear;
}

.vaultDialog {
  zoom: 0.9;
}

body {
  font-family: "Archivo", sans-serif !important;
}

html {
  body {
    div {
      iframe {
        // .dialogContentFixed {
        //   zoom: 5 !important;
        // }
        // visibility: hidden !important;
      }
    }
  }
}

.userflow-issue-compliance {
  .modal-content {
    height: -webkit-fill-available !important;

    // height: auto !important;
    // @media (min-width: 1600px) {
    //   transform: scale(0.9);
    //   transform-origin: right;
    // }
    // @media (min-width: 2000px) {
    //   transform: scale(0.8);
    //   transform-origin: right;
    // }
    // @media (min-width: 2500px) {
    //   transform: scale(0.6);
    //   transform-origin: right;
    // }
    // @media (min-width: 4300px) {
    //   transform: scale(0.5);
    //   transform-origin: right;
    // }
  }
}

.modal-- {
  .modal-content {

    // transform-origin: 100% 0%;
    // height: auto !important;
    @media (max-width: 1500px) {
      // transform: scale(0.85);
      zoom: 0.9;
    }

    @media (min-width: 1500px) and (max-width: 1800px) {
      // transform: scale(0.85);
      // zoom: 1.34;
      zoom: 0.9;
    }

    @media (min-width: 1800px) and (max-width: 2000px) {
      // transform: scale(0.8);
      // zoom: 1.34;
      zoom: 0.9;
    }

    @media (min-width: 2000px) and (max-width: 2700px) {
      // transform: scale(0.77);
      // zoom: 1.34;
      zoom: 0.9;
    }

    @media (min-width: 2700px) and (max-width: 4000px) {
      // transform: scale(0.68);
      zoom: 0.9;
    }

    @media (min-width: 4000px) and (max-width: 5000px) {
      // transform: scale(0.68);
      // zoom: 1.34;
      zoom: 0.9;
    }
  }
}

.rightModal-- {
  .modal-content {
    @media (max-width: 1500px) {
      // transform: scale(0.85);
      //1.09 zoom tested
      zoom: 0.9;
      // right: 3rem !important;
    }

    @media (min-width: 1500px) and (max-width: 1800px) {
      // transform: scale(0.85);
      // zoom: 1.34;
      zoom: 0.9;
      // right: 3rem !important;
    }

    @media (min-width: 1800px) and (max-width: 2000px) {
      // transform: scale(0.8);
      // zoom: 1.34;
      zoom: 0.9;
      // right: 3rem !important;
    }

    @media (min-width: 2000px) and (max-width: 2700px) {
      // transform: scale(0.77);
      // zoom: 1.34;
      zoom: 0.9;
      // right: 3rem !important;
    }

    @media (min-width: 2700px) and (max-width: 4000px) {
      // transform: scale(0.68);
      zoom: 0.9;
      // right: 3rem !important;
    }

    @media (min-width: 4000px) and (max-width: 5000px) {
      // transform: scale(0.68);
      // zoom: 1.34;
      zoom: 0.9;
      // right: 3rem !important;
    }
  }
}

// .right.bigModal {
//   height: -webkit-fill-available !important;
// }

.fullHeight {
  .modal-content {
    height: -webkit-fill-available !important;
  }
}

.collapContainer80 {
  /* height: 83vh; */
  height: auto !important;
}

.userflow-issue {
  .modal-content {
    @media (max-width: 1500px) {
      zoom: 1;
      height: 106vh !important;
    }

    @media (min-width: 1500px) and (max-width: 1700px) {
      zoom: 1 !important;
      height: 106vh !important;
    }

    @media (min-width: 1700px) and (max-width: 1800px) {
      zoom: 1 !important;
      height: 86vh !important;
    }

    @media (min-width: 1800px) and (max-width: 2000px) {
      zoom: 1;
      height: 83vh !important;
    }

    @media (min-width: 2000px) and (max-width: 2700px) {
      zoom: 1;
      height: 76vh !important;
    }

    @media (min-width: 2700px) and (max-width: 4000px) {
      zoom: 1;
      height: 63vh !important;
    }

    @media (min-width: 4000px) and (max-width: 5000px) {
      zoom: 1;
      height: 40vh !important;
    }
  }
}

// VERSION 2 RESPONSIVE CSS

// LOGIN PAGE MEDIA QUERY
@media screen and (max-width: 767px) {
  .login-screen {
    .login-screen-side-banner {
      display: none !important;
    }

    .login-screen-content {
      width: 100% !important;
    }

    .login-screen-inputs {
      width: 100% !important;
    }
  }
}

// LOGIN PAGE LAPTOP VIEW
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .login-screen {
    .login-screen-side-banner {
      width: 350px !important;
    }

    .login-screen-content {
      width: 90% !important;
    }

    .login-screen-inputs {
      width: 90% !important;
    }
  }
}

// SIGNUP PAGE MEDIA QUERY
@media screen and (max-width: 767px) {
  .signup-screen {
    .signup-screen-side-banner {
      display: none !important;
    }

    .signup-screen-content {
      width: 100% !important;
    }

    .signup-screen-content-wrapper {
      width: 100% !important;
      height: 100% !important;
      flex-wrap: wrap;
      overflow-y: auto !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .signup-screen-usertype-block {
      height: auto !important;
      margin-right: 0px !important;
      margin-bottom: 15px !important;
    }

    .signup-screen-inputs {
      width: 100% !important;
    }
  }
}

// SIGNUP PAGE Tablet VIEW
@media screen and (min-width: 767px) and (max-width: 1024px) {
  .signup-screen {
    .signup-screen-side-banner {
      display: none !important;
    }

    .signup-screen-content {
      width: 100% !important;
    }

    .signup-screen-content-wrapper {
      width: 95% !important;
      height: 100% !important;
      flex-wrap: wrap;
      overflow-y: auto !important;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .signup-screen-usertype-block {
      height: auto !important;
      margin-bottom: 15px !important;
    }

    .signup-screen-inputs {
      width: 80% !important;
    }
  }
}



// Connect Integration Modal Mobile View
@media screen and (max-width: 767px) {
  .connect-integration-modal {
    .modal-content {
      width: 100% !important;
    }
  }
}


// Connect Integration Modal Mobile View
@media screen and (max-width: 767px) {
  .child-visible {
    visibility: visible !important;
  }
  .msp-clients-wrapper {
    .clients-table-parent {
      grid-template-columns: 80%  20% !important;
    }
    .div-hide-on-mobile {
      width: 0% !important;
      display: none;
      overflow: hidden !important;
    }
  }
}



// MSP Clients Mobile View
@media screen and (max-width: 1024px) {
  .msp-clients-wrapper {
    .feature-list {
      flex-wrap: wrap;
    }

    .msp-client-filters {
      display: none !important;
    }
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .right-block {
    min-width: 600px;
    max-width: 600px;
  }
}

