.small-workstation-modal {

    @keyframes slideLgRightAnimation {
        0% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
        }

        100% {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            -webkit-transform: translateX(0%);
            transform: translateX(0%);
        }
    }

    // overflow: auto;
    animation-name: slideLgRightAnimation;
    animation-duration: 0.56s;
    animation-timing-function: ease;
    // max-width: 500px;
    position: fixed;
    right: 5em !important;

    .modal-content {
        border: 1px solid #EBEDF1;
        border-radius: 8px;
        width: 25vw;
        min-width: 326px;
        height: auto;
        min-height: 420px;
        max-height: 560 px !important;
    }

    .modal-header {
        background: #F6F8FB;

        .border-item {
            padding: 2px;
        }
    }

    .modal-body {
        padding: 7px;
    }

    .modal-footer {
        padding: 0.5rem;

        button {
            padding: 6px;
        }
    }
}