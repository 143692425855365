.compliance-section{

    .change-due-date-picker{
    
        .highground-datpicker:focus-within {
            border: 2px solid transparent !important;
            width: -webkit-fill-available;
            background: transparent !important;
            border-radius: 4px;
        }
        .highground-datpicker.show .dropdown-toggle {
            background-color: #ffffff00;
            border: transparent;
        }
        .dropdown-toggle{
            input{
                display: none !important;
            }
    
            &:focus{
                border: none !important;
            }
        }
    }
    
    
    
    
    .secops-sidebar{
        z-index: 5;
        top: 101px;
        height: calc(100vh - 26px);
        
        overflow-y: auto;
        overflow-x: hidden;
        border-right: 1px solid #EBEDF1;
        padding: 10px;
        &.bar-expand {
            box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
                width: 17%;
        
                .menu-item {
                    opacity: 1;
                    transition: opacity 0.2s ease-in-out;
                }
        
                .trigger-icon {
                    transform: rotate(180deg);
                    transition: transform 0.2s ease-in-out;
                }
            }
    
        &.bar-collapse{
            width: 4%;
            .menu-item{
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }
            .trigger-icon {
                transform: rotate(0deg);
                transition: transform 0.2s ease-in-out;
            }
        }
    
        .selected-menu-item{
            color: #616778;
          background: #f6f8fb;
          border-radius: 4px;
        }
    
        .menu-item:hover{
            color: #616778;
            background: #f6f8fb;
            border-radius: 4px;
        }
    }
    
    .slidable-body{
        &.open{
    
            width: 45%;
        }
        width: 100%;
        display: flex;
        .main-body{
            // transition: width 0.3s linear 0s;
            margin-left: auto;
            margin-right: auto;
            &.panel-open{
                // transition: width 0.3s linear 0s;
                width: 100%;
                position: relative;
                left: 0;
                padding-left: 7%;
                padding-right: 7%;
                top: 37px;
                height: calc(100% - 75px);
                overflow-y: auto;
            }
    
            &.panel-closed{
                // transition: width 0.3s linear 0s;
            width: 45%;
            }
    
        }
        .sidepanel{
            transition: width 0.3s linear 0s;
            // margin-left: auto;
            // margin-right: auto;
             &.panel-open{
                width: 45%;
                border-left: 1px solid #EBEDF1;
                position: fixed;
                right: 0;
                top: 101px;
                height: 100vh;
                transition: width 0.3s linear 0s;
            }
    
            &.panel-closed{
                 transition: width 0.3s linear 0s;
                width: 0%;
                overflow-x: hidden;
                height: 0px !important;
            }
            .panel-header{
                position: sticky;
                z-index: 1;
              border-bottom: 1px solid #EBEDF1;
            }
            .panel-body{
                height: calc(100% - 75px);
                overflow-y: auto;
            }
        }
    }
    
    .slidable-body-new2 {
        &.open{
    
            width: 45%;
        }
        width: 100%;
        display: flex;
    
        .main-body {
            margin-left: auto;
            margin-right: auto;
    
            &.panel-open {
                width: 100%;
                position: relative;
                left: 0;
                padding-left: 7%;
                padding-right: 7%;
                top: 37px;
                bottom: 0; /* Adjusted */
                overflow-y: auto;
            }
    
            &.panel-closed {
                width: 45%;
            }
        }
    
        .sidepanel {
            &.panel-open {
                width: 45%;
                border-left: 1px solid #EBEDF1;
                position: fixed;
                right: 0;
                top: 101px;
                bottom: 0; /* Adjusted */
                overflow-y: auto;
            }
    
            &.panel-closed {
                width: 0%;
                overflow-x: hidden;
                height: 0px;
            }
    
            .panel-header {
                position: sticky;
                z-index: 1;
                border-bottom: 1px solid #EBEDF1;
            }
    
            .panel-body {
                height: calc(100% - 75px); /* Adjusted */
                overflow-y: auto;
                overflow-x: hidden;
            }
        }
    }
    
    
    .banner {
        background: #f6f8fb;
        border-radius: 8px;
        margin: 1.3rem 0rem;
    
        .banner-element-border {
            border-right: 1px solid #ebedf1;
        }
    
        .banner-element {
            flex-basis: 0;
            flex-grow: 1;
            padding: 1.15rem;
    
            .banner-element-name {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #616778;
            }
    
            .banner-element-value {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #0c0d11;
            }
        }
    }
    
    .employeeModal{
        .modal-content{
            height: 320px;
        }
        
    }
    
    .secops-cert-modal{
        .modal-content{
            border-radius: 8px;
            overflow-x: hidden;
        }
    
        .modal-header{
            border-bottom: 1px solid #EBEDF1 !important;
        }
    
        .nav-link.active{
            color: #008fd4 !important;
            
            border-bottom: 1px solid #008fd4;
        }
    }
    
    .msp-add-member-modal{
        .modal-content{
            border-radius: 8px;
            overflow-x: hidden;
            height: 50%;
        }
    
        .modal-header{
            border-bottom: 1px solid #EBEDF1 !important;
        }
    
        .nav-link.active{
            color: #008fd4 !important;
            
            border-bottom: 1px solid #008fd4;
        }  
    }
    
    .expand-textarea-link{
        width: 100%;
        // min-height: 16px; /* Set the initial minimum height */
        height: 17px;
        resize: none; /* Disable manual resizing */
        box-sizing: border-box;
        // transition: height 0.2s; /* Add smooth transition for height changes */
        position: relative;
        white-space: pre-wrap;
    
        &::-webkit-scrollbar {
            display: none;
            scrollbar-width: none;
          }
          scrollbar-width: none;
        &:focus, :focus-within{
            // height: max-content;
            height: auto;
        }
        // &::placeholder {
        //     position: absolute;
        //     top: 50%;
        //     left: 20%;
        //     transform: translate(-50%, -50%);
        // }
    }



    .unread-blue-dot{
        background: #008FD4;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        display: inline-flex;
       
    }
}

