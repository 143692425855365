@use '../../breakpoints.scss' as *;

.dashboard {
  background: #FFFFFF;
  height: 100vh;
  width: 100%;
  padding: 24px;
  overflow: auto;
  .cyber-security-posture {
    .score {
      &__container {
        width: 180px !important;
      }
    }
  }
}

.client-right-sideBar-btn {
  position: fixed;
  right: 4px;
  margin-top: 4px;
  padding: 8px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  @include respond-to(xl) {
    display: none;
  }
}

.client-right-sideBar {
  right: 0;
  position: fixed;
  z-index: 2;
  width: 280px;
  min-width: 280px;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  &.open {
    transform: translateX(0);
  }
  @include respond-to(xl) {
    position: relative;
    transform: translateX(0);
    box-shadow: none;
  }
}

.client-right-sideBar-bg {
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  @include respond-to(xl) {
    display: none;
  }
}

.dashboard h1 {
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */
  /* Black */
  color: #101010;
}

.dashboard .dashHeader {
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  margin-right: 8px;
  /* identical to box height */
  /* Black */
  color: #101010;
}

.dashboard h2 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* Gray */
  color: #b6b6bf;
}

.dashboard header > * {
  display: inline;
}

.dashboard header p {
  padding-left: 1em;
  color: #b6b6bf;
  line-height: 15px;
  font-weight: 500;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  /* Gray */
  color: #b6b6bf;
}

.dash_container {
  padding-top: 15px;
  padding-right: 0px;
  padding-left: 0px;
  position: relative;
  height: 97.5%;
}

.score_container {
  z-index: 2 !important;
  border-radius: 8px;
}

.row_split {
  padding-bottom: 80px;
}

.sideBar {
  border-left: 1px solid #ebecf1;
  background: #ffffff;
}
.whitepre{
  white-space: pre;
}

.smallGrey {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  /* or 20px */
  display: flex;
  align-items: flex-end;
  /* Gray */
  color: #b6b6bf;
}

.modal.right.fade .modal-dialog {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
  -o-transition: opacity 0.3s linear, right 0.3s ease-out;
  transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}

.headerStyle {
  padding-top: 2.5%;
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #101010;
}

.containerPadding {
  padding: 1.5em;
}

.greyLink {
  color: #b6b6bf;
}

.threatLevelResult {
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dashboardModal .modal-header {
  border-bottom: 1px solid #dee2e6 !important;
}
.dashboardModal .companyDetailsForm,
.dashboardModal .suggestIntegrationForm {
  padding-top: 0px !important;
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.dashboardModal .input-group-text {
  border: 1px solid transparent;
  background: #f6f8fb;
}
.dashboardModal .searchTools {
  border: 1px solid transparent !important;
  background: #f6f8fb !important;
  height: 32px !important;
  padding-top: 6px !important;
  padding-bottom: 13px !important;
  border-radius: 8px !important;
  padding-left: 9px !important;
}
.dashboardModal .thanksModal {
  margin-top: 80px;
  text-align: center;
  padding-top: 0px;
  padding-left: 120px !important;
  padding-right: 120px !important;
}
.disableNextButton {
  background: #ebecf1 !important;
  color: #b6b6bf !important;
  border: none !important;
  pointer-events: none;
}
.dashboardModal .communityForm {
  text-align: center;
  margin-top: 80px;
}
.dashboardModal .slackButton {
  border: 1px solid #ebecf1;
  border-radius: 4px;
  padding: 16px 24px;
  margin: 0 auto;
  width: 240px;
  color: #101010;
  cursor: pointer;
}
.communityForm h1 {
  padding: 0 7px;
}
.modalIcons {
  border-radius: 16px !important;
}
/* .integrationToolSearch input,
.integrationToolSearch span {
  height: 30px !important;
} */
.integrationToolSearch .input-group-prepend {
  margin-right: -19px !important;
}
.suggest {
  width: 100%;
  height: 100%;
  border-radius: 16px !important;
  border: 1px solid #008fd4;
  background: rgba(0, 143, 212, 0.08);
  min-height: 81px;
  min-width: 78px;
  margin-bottom: 5px;
  position: relative;
}
.suggest span {
  position: absolute;
  top: 38%;
  left: 37%;
  font-size: 25px;
  color: #008fd4 !important;
  cursor: pointer;
}
.addIntegration p {
  color: #008fd4 !important;
  cursor: pointer;
}
.integrationTools .activeIntegration {
  position: relative;
}
/* .integrationTools {
  width: 82px;
  height: 107px;
} */
.overlayImage .overlay {
  position: absolute;
  top: -88px;
  left: 1px;
  /* width: 100%;
    height: 100%; */
  background-color: rgba(0, 123, 212, 0.7);
  color: #ffffff;
  width: 82px;
  height: 84px;
  border-radius: 14px;
}
.overlayImage .activeIcon {
  position: absolute;
  top: -49px;
  left: 41%;
}
.activeList {
  color: #008fd4;
}
.securityIncidentBtn :hover {
  background: rgba(248, 238, 241, 0.5);
  cursor: pointer
}
.blueText {
  color: #008fd4;
}
.preData {
  font-size: 14px !important;
  color: #101010 !important;
}

.blockHide {
  display: none;
}
.blockShow {
  display: block;
}

.scoreTile {
  display: flex !important;
  flex-wrap: nowrap !important;
}

.mr15 {
  margin-right: 30px;
}

.ml15 {
  margin-left: 30px;
}

/* 
.loader-wrapper {
    width: 150px;
    height: 100px;
    float: left;
}

.loader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: green;
    z-index: 9999;
} */

.parentHover{
  .visibleDiv{
    display: block;
  }
  .visibleOnHoverDiv{
    display: none;
  }
  &:hover{
    .visibleDiv{
      display: none !important;
    }
    .visibleOnHoverDiv{
      display: block !important;
    }

  }
}

.lockerGreyImage {
  height: 14px;
  width: 13px;
  margin-bottom: 17px;
}