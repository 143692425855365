.dynamic-tooltip-container {
    position: relative;
    display: inline-block;
}
.dynamic-tooltip {
    position: absolute;
    background-color: #000;
    color: #fff; 
    padding: 10px;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 3s ease-in ;
    z-index: 1000;  
    white-space: normal;  
    max-width: 200px; 
    width: max-content;
    min-width: 100px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.2);
}
.dynamic-tooltip-container:hover .dynamic-tooltip {
    opacity: 1;
    visibility: visible;
}
.dynamic-tooltip::after {
    content: '';
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent transparent;
}
.dynamic-tooltip.top {
    bottom: 120%;
    left: 50%;
    transform: translateX(-50%);
}
.dynamic-tooltip.top::after {
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: #000 transparent transparent transparent;
}
.dynamic-tooltip.right {
    top: 50%;
    left: 100%; /* Changed from 150% to 100% */
    transform: translateY(-50%) translateX(10px); /* Adjust the offset to be closer */
}
.dynamic-tooltip.right::after {
    top: 50%;
    left: -10px; /* Adjust the arrow position */
    transform: translateY(-50%);
    border-color: transparent #000 transparent transparent;
}
.dynamic-tooltip.bottom {
    top: 120%;
    left: 50%;
    transform: translateX(-50%);
}
.dynamic-tooltip.bottom::after {
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-color: transparent transparent #000 transparent;
}
.dynamic-tooltip.left {
    top: 50%;
    right: 100%; /* Changed from 150% to 100% */
    transform: translateY(-50%) translateX(-10px); /* Adjust the offset to be closer */
}
.dynamic-tooltip.left::after {
    top: 50%;
    right: -10px; /* Adjust the arrow position */
    transform: translateY(-50%);
    border-color: transparent transparent transparent #000;
}