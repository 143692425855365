.custom_input-group{
  width: 100%;
  height: 46px;
  position:relative;
  background: #F6F8FB;
  border:1px solid #F6F8FB;
  pointer-events: none;
  display:inline-flex;
  border-radius: 4px;
  align-items: center;

  .input-label{
    display: inline-flex;
    margin: 0px;
    white-space: nowrap;
    align-items: center;
    padding-left: 16px;
    color: #B6B6BF;
  }

  .input-field{
    pointer-events: auto;
    position: relative;
    height: 46px;
    color:#101010;
    font-family: 'Archivo';
    font-style: normal;
    font-weight: 500;
    background: transparent;
    border:1px solid transparent;
    border-radius: 4px; 
  }

  &.read-only{
    background: transparent !important;
    border:1px solid transparent;
    pointer-events: auto ;

    &:focus-within{
      border:1px solid transparent;
       background-color: transparent;
     }
   
     &:focus-within .input-label{
       color:#B6B6BF
      }
      &:hover{
       color:#B6B6BF;
       border:1px solid transparent;
     }
     
     &:hover .input-label{
       color:#B6B6BF
     }
  }


  &:focus-within{
   border:1px solid #008FD4;
    background-color: rgba(0, 143, 212, 0.08);
  }

  &:focus-within .input-label{
    color:#008FD4
   }
   &:hover{
    color:#008FD4;
    border:1px solid #008FD4;
  }
  
  &:hover .input-label{
    color:#008FD4
  }

  

}


// textarea
.custom_textarea-group{
  width: 100%;
  position:relative;
  background: #F6F8FB;
  border:1px solid #F6F8FB;
  display:inline-flex;
  border-radius: 4px;
  align-items: center;
  padding: 16px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;

  &:focus-within{
    border:1px solid #008FD4;
     background-color: rgba(0, 143, 212, 0.08);
   }
 
    &:hover{
     border:1px solid #008FD4;
   }
   
 &::placeholder{
  color:#B6B6BF
 }
}

.input-field-search{
  border: 1px solid transparent;
  height:25px;
  font-family: 'Archivo';
  font-style: normal;
  font-weight: 500;
  padding:16px 16px 16px 30px;
  font-size: 14px;
  background-image: url("../../../public/images/theme/icons/search.svg");
  background-repeat: no-repeat;
  background-position: left 0px center;


  &::placeholder{
    color:#B6B6BF
   }

  &:hover{
    background-image: url("../../../public/images/theme/hovericons/search.svg");
  }
  &:focus{
    background-image: url("../../../public/images/theme/hovericons/search.svg");
  }
}