.companyContainer {
    height: 900px;
    /* Light gray BG */
    background: #F6F8FB;
    border-radius: 8px;
    padding: .7em;
    font-family: 'Archivo';
}

.companyModal .imageContainer img {
    width: .5em;
    height: .5em;
    margin-right: .5em;
}

.containerLeftSide>* {
    display: inline-block;
    font-family: 'Archivo';
}

.companyModal h1 {
    font-size: .9em;
    font-weight: 400;
    margin: 0;
}

.companyModal {
    border-radius: 4px !important;
    border: 1px solid white !important;
    margin-bottom: .5em !important;
}

.companyModalContent {
    padding: 1em 2em;
}

.updateButton {
    /* Light blue BG */
    background: rgba(0, 143, 212, 0.08);
    border-radius: 4px;
    display: inline-block;
    font-size: 14px;
    padding: 8px 16px;
    margin-right: .5em;
    color: #008FD4;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
}

.updateButton:hover {
    transform: scale(1.01);
    box-shadow: none;
}

.greyContainer,.greyContainer-4 {
    background: #F6F8FB;
    border-radius: 8px;
    width: 100%;
    padding: .3em;
}


.greyContainer>*, .greyContainer-4>* {
    display: inline-block;
    width: 33.33%;
    text-align: center;
    margin-bottom: 0;
    padding: .5em;
    border-radius: 4px 0px 0px 4px;
    cursor: pointer;
}
.greyContainer-4>*{width: 25%;}

.companyModalContent .informationMessage {
    padding: .5em 0 0 1em;
    text-align: center;
    color: #B6B6BF;
}

.demoUsersContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.demoUsersContainer p {
    margin-bottom: 0;
}

.demoUsersContainer>div {
    width: 50%;
    display: flex;
    align-items: center;
}

.demoUsersContainer div>div {
    display: inline-block;
    padding: .3em;
    width: auto;
}


.demoUsersContainer .secondaryText {
    font-size: .85em;
}

.secondaryText {
    color: #B6B6BF;
}
.hauto{
    height: auto !important;
}
/* .demoUsersContainer span > * {
    display: inline-block;
} */