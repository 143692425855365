.premium_center_cancel{
    width: 400px;
    margin: auto;
    display: flex;
    align-items: center;
    .img img{
        height: 45px;
        width: 45px;
        margin-right: 10px;
    }

    .content_wrapper{
        align-items: center;
        flex-direction: column;
    }
    .txtblue{
        color: #008FD4;
    }
 
}