@use "../../../../breakpoints.scss" as *;

.client-details {
  display: grid;
  grid-template-columns: 1fr;
  border-radius: 8px;
  overflow: hidden;
  .section {
    padding: 12px;
    width: 100%;
    background-color: #F6F8FB;
    display: flex;
    flex-direction: column;
    gap: 4px;
    &:hover {
      background-color: #ebedf1;
      .link-icon {
        display: block;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid #EBEDF1;
    }
    .title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
    }
    .value {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
    }
    .link-icon {
      display: none;
      cursor: pointer;
    }
  }
  @include respond-to(md) {
    grid-template-columns: 1fr 1fr;
    .section {
      &:not(:last-child) {
        border-bottom: none;
      }
      &:not(:nth-last-child(-n + 2)) {
        border-bottom: 1px solid #EBEDF1;
      }
      &:nth-child(odd) {
        border-right: 1px solid #EBEDF1;
      }
    }
  }
  @include respond-to(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    .section {
      &:not(:last-child) {
        border-bottom: none;
        border-right: 1px solid #EBEDF1;
      }
    }
  }
}
