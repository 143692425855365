.titletxt{
  margin: 0;
  font-size: 14px;
  line-height: 15px;
  color: #101010;
  padding-bottom: 4px;
  font-weight: 500;
}

.titlesm{
font-size: 12px;
line-height: 13px;
color: #B6B6BF;
}
.brdtb{
  border-top: 1px solid #EBECF1;
  border-bottom: 1px solid #EBECF1;
}

.arricon{
  background-image: url("../../../../public/images/icons/arrow.svg");
  background-repeat: no-repeat;
  background-position: 100% 10px;
  text-indent: 15px;
}
.sendNotificationWapper{
  // background: green !important;
  position: absolute;
  top: 5px;
  left: 15px;
  width: 94%;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  height: 92%;
  overflow: hidden;
  justify-content: space-between;
}
.lbltxt{
  margin-top: 1px;
}
.brddiv{
  height: 1px;
  background-color: #EBECF1;
  width: 100%;
  margin-bottom: 14px;
  margin-top: 14px;
}
.compsalewrapper{
  padding: 0px 30px 0px 30px;
}
.lbltitle{
  font-size: 14px;
line-height: 15px;
color: #101010;
padding-left: 10px;
}

.sendNotificationFooter{
  // background-color: red;
  width: 100%;
  height: 18vh;
  bottom: 10px;
}
.sendNotificationContent{
  overflow: auto;
  height: 50vh;

  // background-color: orange;
}
.footer_inpt{
 .input-group-text{
    background-color: #fff !important;
  }
  .form-control{
    background-color: #fff !important;
    color: #101010;
    font-weight: 500;
  }

  border: 1px solid #EBECF1;
  
}

.disabled_btn{
  background: #EBECF1 !important;
  border: 1px solid #EBECF1 !important;
  color: #B6B6BF !important;
}

.loader_wrapper2 {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  left: 0;
  background: #ffffffd9;
}
.loader2 {
  height: 128px;
  width: 144px;
  left: 249px;
  border-radius: 16px;
  padding: 20px 15px;
  background: #f6f8fb;
  border: 1px solid #ebecf1;
}
.loader_circle {
  border: 6px solid #c8dff7;
  border-radius: 50%;
  border-top: 6px solid #008fd4;
  width: 38px;
  height: 38px;
  margin: auto;
  margin-top: 8px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}
.fnt12{
  font-size: 12px;
}
.loader_outer_noti{
  word-wrap: break-word;
}
.loader_success{
  min-width: 175px;
  max-width: 225px;
  left: 249px;
  border-radius: 16px;
  padding: 20px 15px;
  background: #40D88E;
  border: 1px solid #40D88E;
  display: flex;
  align-items: center;
  justify-content: center;
}
.clrwht{
  color:white;
}