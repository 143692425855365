.supplier-profiler-note-modal{
    .modal-content{
        border-radius: 4px;
        width: 30vw;
    }

    .modal-header{
        padding: 10px;
        border-bottom: 1px solid #EBEDF1 !important
    }
}