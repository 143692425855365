@use '../../breakpoints.scss' as *;

.governance-wrapper {
  .governance-charts-container {
    display: flex;
    flex-direction: column;
    @include respond-to(xl) {
      display: grid;
      grid-template-columns: 24% 48% 28%;
    }
    .chart-item {
      &:not(:last-child) {
        border-bottom: 1px solid #ebedf1;
        @include respond-to(xl) {
          border-bottom: none;
          border-right: 1px solid #ebedf1;
        }
      }
    }
  }
  .components-container {
    display: flex;
    flex-direction: column;
    @include respond-to(md) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .component-item {
      &:not(:last-child) {
        border-bottom: 1px solid #ebedf1;
      }
      @include respond-to(md) {
        &:not(:nth-child(3n)) {
          border-right: 1px solid #ebedf1;
        }
        &:nth-last-child(-n + 3) {
          border-bottom: none;
        }
      }
    }
  }
}

.explore-mapping-icon {
  @include respond-to(md) {
    width: 450px;
  }
}

.cyber-essentials-audit-results-table {
  table {
    table-layout: fixed;
    td, th {
      width: 180px;
      &.saving-row-wrapper {
        width: 80px;
      }
      @include respond-to(xl) {
        width: 16%;
        &.saving-row-wrapper {
          width: 5%;
        }
      }
    }
  }
}

.cyberReviewTable-wrapper {
  overflow-x: auto;
  padding: 0 8px;
  display: grid;
  @include respond-to(xl) {
    overflow-x: visible;
  }
}

#cyberReviewTable {
  overflow-y: visible !important;
  .row {
    flex-wrap: nowrap;
  }
}

.ir-plan-templates-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include respond-to(md) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include respond-to(xl) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .threat_card {
    height: 100%;
  }
}

.infosec-cards-container {
  .infosec-card-item {
    width: 100%;
    @include respond-to(md) {
      min-width: 150px;
      width: fit-content;
    }
  }
}