.notification {
    background: blue;
    border-radius: 13px;
    /* height: 112px; */
}

.notification>* {
    display: inline-block;
}

.notificationBody {
    background: #F6F8FB;
    /* backdrop-filter: blur(33.2019px); */
    border-radius: 13px;
    height: 100%;
    width: 88%;
    margin-left: -1px;
}

.notificationSend {
    width: 12%;
    cursor: pointer;
    border-radius: 13px;
    height: 118px;
    position: absolute;
}

.notificationSend>div {
    margin-top: 3em;
    margin-left: .9em;
}

.notificationSend img {
    left: 25%;
    top: 45%;
    position: absolute;
    animation-name: scaleAnimationHide;
    animation-duration: 1s;
}

.notificationSend:hover img {
    animation-name: scaleAnimation;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes scaleAnimationHide {
    from {
        transform: scale(1.5);
    }
    to {
        transform: scale(1.0);
    }
}

@keyframes scaleAnimation {
    from {
        transform: scale(1.0);
    }
    to {
        transform: scale(1.5);
    }
}

.notificationText {
    padding: 1.2em;
}

.notificationText>* {
    padding: 0 10px;
    font-family: 'Archivo';
}

.notificationText .main-heading {
    color: #B6B6BF;
    text-transform: uppercase;
    font-size: 0.9em;
}

.notificationText h2 {
    margin: 0;
    font-size: 1.1em;
    color: #101010;
    font-weight: bold;
    padding-top: .5em;
    padding-bottom: .2em;
}

.notificationIcon {
    display: flex;
    align-items: center;
    padding-left: 0;
}

.notificationText p {
    font-size: 1em;
    font-size: 0.97em;
    line-height: 18px;
    /* or 120% */
    /* Black */
    color: #101010;
}

.logoContainer>* {
    display: inline-block;
}

.logoContainer img {
    width: 1.9em;
    padding-right: .3em;
}

.notificationSubMessage {
    font-size: 0.9;
    padding: .4em 0 .2em 0;
    color: #B6B6BF;
    text-align: center;
}