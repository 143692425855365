.oauth2-btn{
    button{
        background-color: #008fd4;
        border: none;
        width: 100%;
        padding: 20px;
        color: white;
        margin-top: 5px;
        border-radius: 4px;
    }
}