.search-filter-component {
  .visibility {
    .menu {
      min-width: 240px;
      padding: 4px 0;
    }
    .visibility-wrapper {
      padding: 4px;
    }
    .item-wrapper,
    .visibility-wrapper {
      cursor: pointer;
      &:hover {
        background-color: #f6f8fb;
      }
      .item-content {
        padding: 4px 12px;
        .text {
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
        }
      }
      .arrow-icon {
        rotate: -90deg;
      }
    }
    .separator {
      margin: 4px 12px;
      border-top: 1px solid #EBEDF1;
    }
    .switch-button-2 {
      scale: 0.65;
    }
  }  
}

.selected-filters-component {
  padding: 4px 8px;
  .items-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    .selected-item-wrapper {
      max-width: 100%;
      .menu {
        z-index: 1;
        padding: 12px;
        .date-filter-dropdown.dashboardDateFilter {
          transform: translate(-24px, -14px);
        }
      }
    }
    .selected-item {
      max-width: 100%;
      padding: 2px;
      border-radius: 9999px;
      background-color: #F6F8FB;
      display: flex;
      align-items: center;
      cursor: pointer;
      .label {
        padding: 1px 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #616778;
        white-space: pre-wrap;
        word-wrap: break-word;
        text-align: start;
      }
      &.has-value {
        background-color: #008FD4;
        .label {
          color: #FFFFFFE0;
        }
      }
    }
  }
  .reset-btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    padding: 3px 0px;
    align-self: flex-start;
  }
  .remove-button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .modal-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .label {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      white-space: nowrap;
    }
    input {
      padding: 2px 4px;
      max-width: fit-content !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .hg-cancel-btn,
    .hg-blue-btn {
      padding: 2px 4px !important;
    }
  }
}

.selected-filters-component {
  padding: 4px 8px;
  .items-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    .selected-item-wrapper {
      .menu {
        z-index: 1;
        padding: 12px;
        .date-filter-dropdown.dashboardDateFilter {
          transform: translate(-24px, -14px);
        }
      }
    }
    .selected-item {
      padding: 2px;
      border-radius: 9999px;
      background-color: #F6F8FB;
      display: flex;
      align-items: center;
      cursor: pointer;
      .label {
        padding: 1px 4px;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        color: #616778;
      }
      &.has-value {
        background-color: #008FD4;
        .label {
          color: #FFFFFFE0;
        }
      }
    }
  }
  .reset-btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    cursor: pointer;
    padding: 3px 0px;
    align-self: flex-start;
  }
  .remove-button {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .modal-input {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .label {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      white-space: nowrap;
    }
    input {
      padding: 2px 4px;
      max-width: fit-content !important;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .hg-cancel-btn,
    .hg-blue-btn {
      padding: 2px 4px !important;
    }
  }
}

.applied-filter-item {
  background-color: #f6f8fb;
  .text {
    color: #616778;
  }
  .cross-icon {
    fill: #616778;
  }
  &.has-value {
    background-color: #008fd4;
    .text {
      color: #ffffffe0;
    }
    .cross-icon {
      fill: #ffffff;
    }
  }
}