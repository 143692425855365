.custom-collapse-table{
    border: 1px solid #F6F8FB;
    cursor: pointer;
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 24px 33px;
    flex-wrap: wrap;
    
    .custom-accordian-wrapper{
        &:hover .left_text{
            color:#008FD4
        }
    
     }
    
    .custom-collapse-table_inner{
        pointer-events: none;
        display: flex;
        cursor: pointer;
        align-items: center;
        width:100%;
        justify-content: space-between;
        font-family: 'Archivo';
        font-style: normal;
        font-weight: 400;

        .right_text{
            color: #B6B6BF;
        }

        .left_text{
          color: #101010
        }
        .arrow_image{
            cursor:pointer
        }
    }

    .custom_table{
        table {
           
            thead{
                th{
                    border-bottom: 1px solid #EBECF1;
                    padding-bottom: 17px;
                    color: #B6B6BF;
                    font-family: 'Archivo';
                    font-style: normal;
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            
            tbody {
             
                tr:nth-child(even){
                    background-color: #F6F8FB;
                }
                td{
                    font-family: 'Archivo';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 13px;
                    color: #101010;
                    padding-top:17px;
                    padding-bottom:17px;
                    vertical-align: middle;
                    .profile_image{
                        height:25px;
                        width: 25px;
                        border-radius: 50%;
                        margin-right: 10px;
                    }
                }
            }
        }
    }

    .clrgrey{
        color: #B6B6BF
    }
    .clrgreen{
        color: #40D88E !important;
    }
    .font14{
        font-size: 14px !important;
        color: #101010 !important;
    }

}