.noitificaton-wrapper {
  margin-top: 2.5rem !important;
  max-width: 60%;
  margin: auto;
  .header {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
    width: 100%;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616778;
  }
  .allow-notification-switch {
    transform: scale(1.5);
    cursor: pointer;
  }
}

.cyberscore-notification-modal {
  .score-modal-dropdown {
    .dropdown-menu.show {
      background: #0c0d11;
    }
    .dropdown_item {
      color: rgba(255, 255, 255, 0.64) !important;
      font-weight: 400 !important;
    }
  }
  .hand {
    cursor: pointer;
  }
  .modal-content {
    min-height: 543px;
    height: auto;
    border-radius: 7px;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616778;
  }
  .search_dropdown {
    .dropdown-toggle {
      height: 38px;
      padding: 8px 10px;
      border: 1px solid #ebedf1;
      background-color: #fff;
    }
    .search_input {
      padding: 2px 15px;
    }
    .dropparent {
      padding: 8px 16px;
      color: #9499aa;
    }
    .textsellabel {
      color: #616778;
    }
  }
  .scale-switch {
    transform: scale(1.5);
    cursor: pointer;
  }
  .toggle-selected-item {
    margin-right: 15px;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
  }
  .meter-label {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .modal-footer {
    border-top: 1px solid #ebedf1;
    padding: 13px;
  }
  .update-btn {
    background: #008fd4;
    border-radius: 2px;
    padding: 4px 6px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
  .cancel-btn {
    background: #f6f8fb;
    border-radius: 2px;
    padding: 4px 6px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #616778;
    margin-right: 5px;
    width: 62px;
    height: 32px;
    &:active {
      background: #f6f8fb !important;
      border: none !important;
      color: #616778 !important;
    }
  }
}
label {
  cursor: pointer;
}
