@use '../../../breakpoints.scss' as *;

.integration-list-item {
  padding: 6px 0;
  @include respond-to(md) {
    width: 516px;
  }
  .rename-input {
    padding: 2px 4px !important;
  }
  .apply {
    background: #008FD4;
    color: #FFFFFF;
  }
  .cancel {
    background: #EBEDF1;
    color: #616778;
  }
  .apply, .cancel {
    padding: 4px;
    border: 0;
    border-radius: 2px;
  }
  .soc-container {
    display: flex;
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    .soc-txt {
      line-height: 22px;
    }
    .round-checkbox input[type=checkbox] + label:after {
      transform: scale(0.8) rotate(-45deg);
      top: 5px;
    }
    .blue-checkbox {
      transform: scale(0.8);
    }
    .round-checkbox.blue-checkbox.disabled input[type=checkbox]:checked + label {
      background-color: #BBBEC9;
      border-color: #BBBEC9;
    }
  }
}

.tooltip-integrations-soc .tooltip-inner,
.tooltip-request-access .tooltip-inner {
  width: 148px;
  border-radius: 4px !important;
  padding: 4px 8px !important;
  text-align: start !important;
  span {
    color: #fff !important;
    font-size: 12px;
    line-height: 18px;

  }
}

.tooltip-request-access .tooltip-inner {
  transform: translate3d(2px, 0, 0) !important;
}

.tooltip-request-access .arrow {
  transform: translate3d(12px, 0, 0) !important;
  &::before {
    border-width: 0.4rem 0.6rem !important;
  } 
}

.tooltip-integrations-soc .arrow {
  transform: translate3d(5px, 0, 0) !important;
  &::before {
    border-width: 0.4rem 0.6rem !important;
  } 
}

.integration-actions-dropdown {
  position: fixed !important;
  z-index: 10000;
  box-shadow: 0px 12px 24px 0px #10101014;
  border: 1px solid #EBEDF1;
  min-width: 0rem;
  max-width: fit-content;
  max-height: fit-content;
  .item {
    color: #616778;
  }
  .delete {
    color: #F36060;
  }
  .integration-item {
    cursor: pointer;
    padding: 5px 16px;
  }
  .integration-item:active {
    background: #F8F9FA;
  }
}

.review-more-tools-tooltip {
  margin-top: 24px;
  position: relative;
  z-index: 9999;
  left: -4px;
  .arrow {
    width: 0;
    height: 0;
    left: 6px;
    position: relative;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #000;
  }
  .inner-body {
    padding: 4px 5px;
    background: #000;
    border-radius: 4px;
    width: 320px;
    p {
      color: #fff !important;
      font-size: 14px;
      line-height: 18px;
      white-space: pre-wrap;
    }
    button {
      border: none;
      outline: none;
      background: none;
      padding: 2px;
      border-radius: 2px;
      color: #fff;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
    .review-btn {
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: #008FD4;
      &:disabled {
        background-color: #BBBEC9;
      }
    }
    .lump-icon {
      width: 16px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .review-title {
      color: #FFFFFFA3;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .checkbox {
      input:checked ~ label:before {
        background-color: #008FD4 !important;
        border: transparent !important;
      }
      input:checked ~ label:after {
        color: #fff !important;
        scale: 0.8;
        transform: translateY(-2px);
      }
    }
    .tech-item {
      padding: 1px 0;
      label {
        color: #FFFFFFA3;
        font-size: 14px;
        line-height: 22px;
      }
      &.in-your-stack {
        .checkbox {
          input:checked ~ label:before {
            background-color: #FFFFFF3D !important;
          }
        }
      }
    }
  }
}
