.managed-by-provider {
  padding: 4px;
  display: flex;
  align-items: center;
  .managed-label {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    padding: 1px 4px;
  }
  .info-icon-wrapper {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

#manged-by-provider-tooltip {
  .arrow {
    left: 33px !important;
  }
  .arrow::before{
    right: 0;
    left: auto;
  }
  .tooltip-inner {
    padding: 4px 8px;
    border-radius: 4px !important;
    text-align: start;
  }
}