.score_linktext {
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 15px;
    text-align: right;
    /* Gray */
    color: #B6B6BF;
}

.cyberscore_text {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 1.1em;
    line-height: 17px;
    /* Black */
    color: #101010;
}

.score__message {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 1.5em;
    line-height: 20px;
    /* identical to box height */
    /* Black */
    color: #101010;
}

.csPadding {
    padding-left: 7.5%;
}
.drilldownModal{
    width: 100%;
    text-align: center;
    padding-top: 40px;
}
/* 
@media (min-width: 768px) {
    .mini .score__wrapper .status--dangerous {
        left: 0% !important;
        top: 80% !important;
    }
    .mini .score__wrapper .status--poor {
        top: 15px !important;
        left: 5% !important;
    }
    .mini .score__wrapper .status--good {
        right: 5% !important;
        top: 20px !important;
    }
    .mini .score__wrapper .status--excellent {
        right: 0% !important;
        top: 80% !important;
    }
    .mini .score {
        top: 10%;
        left: 20%;
    }
    .mini .score__message {
        font-size: 1.2em;
        width: 92%;
    }
    .mini .score__number {
        width: 65%;
        height: 65%;
    }
    /* .mini #indicatorB{
        width: 60% !important;
        height: 60% !important;
    } 
    FUTURE ENHANCEMENT
    */
/* }  */