.msp-donut-chart {


    .donut-chart {
        position: relative;

        margin: 1rem;
        border-radius: 100%
    }



    p.center {
        background: #ffffff;
        position: absolute;
        text-align: center;
      font-size: 28px;
        top:0;left:0;bottom:0;right:0;
        width: 130px;
        height: 130px;
        margin: auto;
        border-radius: 50%;
        line-height: 35px;
        padding: 15% 0 0;
        z-index: 1;
      }

      .portion-block {
        border-radius: 50%;
        clip: rect(0px, 200px, 200px, 100px);
        height: 100%;
        position: absolute;
        width: 100%;
       
      }
    .circle {
        border-radius: 50%;
        clip: rect(0px, 100px, 200px, 0px);
        height: 100%;
        position: absolute;
        width: 100%;
        font-family: monospace;
        font-size: 1.5rem;
       
      }
}


.zoom-imp{
  zoom: 1 !important
}