@use '../../../../breakpoints.scss' as *;

.technology-spend {
  .data-container {
    background-color: #F6F8FB;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    @include respond-to(lg) {
      flex-direction: row;
    }
    .section {
      padding: 10px 8px;
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid #EBEDF1;
      }
      .text {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
      }
      .list {
        .list-item {
          padding: 3px 4px;
          border-radius: 4px;
          &:hover {
            background-color: #EBEDF1;
          }
        }
      }
      .link-section {
        padding: 3px 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .right-section {
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .chart-container {
        padding: 22px 16px;
      }
      .currency-container {
        .text {
          font-weight: 400 !important;
        }
      }
    }
  }
}
