.settings_container {
  background: #FFFFFF;
  border: 1px solid #EBECF1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 24px;
}
.mt40px {
  margin-top: 40px;
}
.mt16px {
  margin-top: 16px !important;
}
.settings_table h1 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #101010;
}
.greyHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 34px;
}
.custom-switch .custom-control-label::before {
  background-color: #EBECF1;
  border: none;
}
.custom-switch .custom-control-label::after {
  background-color: #FFFFFF;
}
.toogle-switch .custom-control-label::before {
  background-color: #007BFF;
}
.toogle-switch .custom-control-label::after {
  background-color: #FFFFFF;
}
.icon-bar {
  background: #FFFFFF !important;
}
.notif-dropdown .dropdown-menu {
  opacity: 1 !important;
  padding: 24px;
  background: #F6F8FB;
  width: 240px;
}
.notif-dropdown .dropdown-toggle {
  background: transparent !important;
  border: none;
  padding: 0;
  box-shadow: none;
}
.notif-dropdown {
  height: 2px;
}
.notif-dropdown .dropdown-item {
  padding: 6px;
}
.notif-dropdown .dropdown-item:active {
  background: none;
}
.settings_container .small-input select.form-control {
  border: 1px solid transparent;
  background: #F6F8FB;
  height: 32px;
  padding-top: 8px;
  padding-bottom: 8px;
}
.settings_container .input-group-text {
  border: 1px solid transparent;
  background: #F6F8FB;
}
.report-box {
  background: #F6F8FB;
  border-radius: 8px;
}
.report-box>div {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.report-btn {
  padding: 8px 100px !important;
}
.upgradeLink{
  color:#007BFF;
}
.addUserDisable{
  background: #ebecf1;
  color: #b6b6bf;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.txt-green{
  color:#40d88e!important;
  pointer-events: none;
}
.removingText{
  pointer-events: none;
}
.billingTab.integration_tabs2  {
 display: flex;
 justify-content: space-between;
}

.customArrowSelect{
  margin-right:10px;
  padding-right: 2rem;
}