@use '../../../../breakpoints.scss' as *;

.select-tool-btn {
  background: transparent;
  border: none !important;
  &:hover {
    background: transparent;
  }
  &::after {
    display: none !important;
  }
}

.select-tool-btn.dropdown-toggle {
  background: transparent !important;
}

.select-tool-wrapper {
  position: relative;
}

.select-tool-menu {
  width: 100%;
  color: #9499aa;
  box-shadow: 0px 12px 24px 0px #10101014;
  border: 1px solid #EBEDF1;
  background-color: #fff;
  padding: 2px;
  transform: translateY(0) !important;
  &.cyber-score {
    width: 100%;
    @include respond-to(md) {
      width: 460px;
    }
  }
  .modal-list {
    max-height: 250px;
    overflow-y: scroll;
  }
}

.all-tools-title,
.no-available-title {
  font-weight: 400;
}

.scrollable-div {
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.build-stack-btn {
  background: none;
  color: #008FD4;
  font-weight: 500;
  border: none;
}

.integration-add,
.integration-options {
  display: none;
}
.integration-item {
  cursor: default;
}
.result-add-btn {
  overflow: hidden;
  word-break: break-all;
  text-align: left;
}
.result-add-btn,
.result-spinner {
  color: #008FD4 !important;
}

.integration-item:hover {
  background: #F6F8FB;
}

.integration-item:hover .integration-add,
.integration-item:hover .integration-options {
  display: block;
}

.integration-item:active {
  background: #F8F9FA;
}

button {
  &:active, &:focus {
    outline: none;
  }
}

.integration-item-wrapper {
  .apply {
    background: #008FD4;
    color: #FFFFFF;
  }
  .cancel {
    background: #EBEDF1;
    color: #616778;
  }
  .apply, .cancel {
    padding: 4px;
    border: 0;
    border-radius: 2px;
  }
  .rename-input {
    padding: 2px !important;
  }
  .integration-item {
    padding: 5px 16px !important;
  }
}