.custom_date_picker {
  width: 100%;
  height: 46px;
  position: relative;
  background: #fcfdfe; //#f6f8fb;
  border: 1px solid #f6f8fb;
  pointer-events: none;
  display: inline-flex;
  border-radius: 4px;
  align-items: center;

  .custom_date_picker_input {
    pointer-events: auto;
    position: relative;
    height: 46px;
    color: #101010;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    background: transparent;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 0.375rem 0.75rem;
  }
  .input-label {
    display: inline-flex;
    margin: 0px;
    white-space: nowrap;
    align-items: center;
    padding-left: 16px;
    color: #b6b6bf;
  }

  &:focus-within {
    border: 1px solid #008fd4;
    background-color: rgba(0, 143, 212, 0.08);
  }

  &:focus-within .input-label {
    color: #008fd4;
  }
  &:hover {
    color: #008fd4;
    border: 1px solid #008fd4;
  }

  &:hover .input-label {
    color: #008fd4;
  }
}

.date_picker_placeholder {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9499aa !important;
}
.date_picker_input {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #101010 !important;
}
///#fcfdfe
// datepicker
.daterangepicker {
  &.single {
    width: 310px;
    border-radius: 4px;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);

    .drp-calendar.left .calendar-table {
      width: 300px;
    }
  }

  // multi select style
  // width:310px;
  border-radius: 4px;
  border: 1px solid #f6f8fb;
  box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);

  .monthselect {
    border: 0px;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
  }

  .yearselect {
    border: 0px;
    width: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #b6b6bf;
  }

  .calendar-table td {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 40px;
  }

  .table-condensed {
    tr {
      td.active {
        background-color: #008fd4;
      }
      th.month {
        padding-bottom: 20px;
        padding-top: 10px;
      }
      th.prev {
        padding-bottom: 10px;
      }
      th.next {
        padding-bottom: 10px;
      }
    }
  }

  thead tr:nth-child(0) th {
    font-size: 12px;
  }
}
