.borderless table {
    border-top-style: none;
    border-left-style: none;
    border-right-style: none;
    border-bottom-style: none;
}

/* .table td {
    vertical-align: middle;
} */

.table-striped tbody tr.colourCoordinated:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, .05);
}

.table-row td:first-child { border-top-left-radius: 4px; }
.table-row td:last-child { border-top-right-radius: 4px; }
.table-row td:first-child { border-bottom-left-radius: 4px; }
.table-row td:last-child { border-bottom-right-radius: 4px; }
.table-row th:first-child { border-top-left-radius: 4px; }
.table-row th:last-child { border-top-right-radius: 4px; }

.table td, .table th {
    border-top: none;
}

.table thead tr {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    /* identical to box height */
    /* Gray */
    color: #B6B6BF;
}

.scrollableTable table thead th {
    position: sticky;
    top: 0;
}

.scrollableTable table tbody {
    overflow-y: scroll !important;
    overflow-x: hidden;
    height: 100px;
}
.tech-table {
    height: auto;
}
.web-filtering-table .tech-table{
    height: auto;
}
.tech-table table thead {
    position: sticky;
    top: 0;
}

.tech-table table tbody {
    overflow-y: scroll !important;
    overflow-x: hidden;
}

.table-striped>tbody>tr.colourCoordinated:nth-child(2n+1)>td, .table-striped>tbody>tr.colourCoordinated:nth-child(2n+1)>th {
    background-color: white;
}

.table-striped>tbody>tr.colourCoordinated:nth-child(even)>td {
    background-color: #F6F8FB;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    background: #EBECF1;
    border-radius: 2px;
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.na {
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 15px;
    color: #B6B6BF;
}

/* .table-striped tbody tr:nth-of-type(odd), .table-striped tbody tr:nth-of-type(even) {
    background-color: white !important;
} */

/* .table-striped tbody tr.colourCoordinated:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}
.table-striped tbody tr.colourCoordinated:nth-of-type(even) {
    background-color: #F6F8FB;
} */

/* .table-striped tbody tr.colourCoordinated:first {
    background-color: rgba(0, 0, 0, .05);
} */

.inline-icon>* {
    display: inline;
}

.inline-icon img {
    padding-left: 0.2em;
    padding-bottom: .1em;
}

.techTableInitials {
    background: #3D42DE;
    border-radius: 30px;
    color: white;
    height: 100%;
    width: 30px;
    padding: .6em .5em;
    margin-right: .3em;
}

.techtableImageInline {
    width: 50px;
}
.tech-header {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    padding: 18px 24px !important;
    border-bottom: 16px solid #F6F8FB;
}
.table-row td {
  padding: 18px 24px !important;
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
}