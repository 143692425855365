.upload-template-modal{
    .modal-content{
        border-radius: 4px;
        width: 80vw;
    }

    .modal-header{
        padding: 10px;
        border-bottom: 1px solid #EBEDF1 !important
    }

.table-parent-grid{
display: grid;
grid-template-columns: 25% 25% 25% 25%;
}









    
}