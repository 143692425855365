.supplier-profiler-files-wrapper{

.files-table-wrapper{
display: grid;
grid-template-columns: 80% 10% 10%;
}

}
.edit-file-modal {
    .modal-content {
        border-radius: 8px;
        height: 300px;
        width: 90%;

    }

    .modal-header {
        border-bottom: 1px solid #ebecf1 !important;
        padding-bottom: 10px;
    }
}

.open-link-modal {
    .modal-content {
        border-radius: 8px;
        height: auto;
        width: 80%;

    }

    .modal-header {
        border-bottom: 1px solid #ebecf1 !important;
        padding-bottom: 10px;
    }
}