input[type="range"] {
  width: 100%;
  height: 16px;
  -webkit-appearance: none;
  // background-color: red;

  outline: none;
  border-radius: 50px;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: #ffffff;

  border: 1px solid #ebedf1;

  // box-shadow: 0px 4px 8px rgba(16, 16, 16, 0.1);
  // box-shadow: -407px 0 0 400px #008fd4;
  border-radius: 4px;
}
