@use '../../breakpoints.scss' as *;

.msp-header-sidebar {
  position: fixed;
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  transform: translateX(101%);
  display: flex;
  justify-content: flex-end;
  transition: transform 0.3s ease-in-out;
  z-index: 9999;
  &.open {
      transform: translateX(0);
      .sidebar-container {
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.9);
      }
  }
  .sidebar-container {
    position: relative;  
    z-index: 9999;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 0, 0, 0.95);
      @include respond-to(sm) {
          width: 300px;
      }
  }
  .header {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ffffff33;
  }
  .body {
      padding: 16px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      overflow-y: auto;
      height: calc(100% - 73px);
  }
  .separator {
      height: 1px;
      min-height: 1px;
      background-color: #ffffff33;
      margin: 8px 0;
  }
}