.insights_sidebar {
    .folder-name {
        color: #9499AA;
    }

    .query-text {
        color: #BBBEC9;
    }

    .folder-hover {
        &:hover {
            cursor: pointer;
            background: #F6F8FB;
            border-radius: 4px;

            .folder-name {
                color: #616778;
            }

            .query-text {
                color: #9499AA;
            }
        }
    }

    .collection-panel-sidebar {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s ease-out;
        border-left: 1px solid #EBEDF1;
    }


    .folder-icon.open {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background-image: url("../../../public/images/attack-surface/insights-folder-open.svg");
            background-repeat: no-repeat;
            top: 0;
            left: 0;
        }
    }

    .folder-icon.close {
        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 14px;
            height: 14px;
            background-image: url("../../../public/images/attack-surface/insights-folder-closed.svg");
            background-repeat: no-repeat;
            top: 0;
            left: 0;
        }
    }


}



.insights-body-header {
    .grey-border {
        border: 1px solid #EBEDF1;
    }

    .border-right {
        border-right: 1px solid #EBEDF1;
    }

    .border-bottom {
        border-right: 1px solid #EBEDF1;
    }

    .insights-env-dropdown {

        .dropdown-toggle {
            background: transparent !important;
            border: none !important;
            padding: 0.18rem 0.6rem;

            &::after {
                display: none;
            }
        }


        .dropdown-menu.show {
            inset: auto auto auto 0px !important;
            transform: translate(-5px, 7px) !important;

            .drop-item {
                &:hover {
                    background: #f9f9fa;
                }
            }
        }
    }
}