@use '../../breakpoints.scss' as *;

.settings-v2-sidenav {
  width: 68px;
  padding-top: 8px;
  @include respond-to(lg) {
    width: 250px;
    min-width: 250px;
    padding: 10px 7px;
  }

  .tab-heading {
    font-size: 12px;
    font-weight: 500;
    color: #9499aa;
    display: none;
    @include respond-to(lg) {
      display: block;
    }
  }
  .tab-name {
    display: none;
    font-size: 14px;
    font-weight: 500;
    color: #9499aa;
    @include respond-to(lg) {
      display: block;
    }
  }
  .small-coming-soon-tag {
    @include respond-to(lg) {
      display: none;
    }
  }
  .coming-soon-tag {
    display: none;
    @include respond-to(lg) {
      display: block;
    }
  }
  .tab-icon {
    @include respond-to(lg) {
      margin-right: 12px;
    }
  }
  .profile-thumbnail {
    width: 16px;
    object-fit: contain;
    border-radius: 50%;
    filter: grayscale(0%) brightness(100%) !important;
    -webkit-filter: grayscale(0%) brightness(100%) !important;
  }
  .tab-wrapper {
    width: 38px;
    height: 38px;
    justify-content: center;
    @include respond-to(lg) {
      padding: 0.5rem 0.6rem;
      width: auto;
      height: auto;
      justify-content: flex-start;
    }
  }
  .tab-wrapper:hover {
    background-color: #f6f8fb;
    border-radius: 4px;
    cursor: pointer;

    & .tab-name {
      color: #616778;
    }
    & img {
      filter: grayscale(68%) brightness(62%);
      -webkit-filter: grayscale(68%) brightness(62%);
      -moz-filter: grayscale(68%) brightness(62%);
    }
  }

  .active-tab {
    background-color: #f6f8fb;
    border-radius: 4px;
    cursor: pointer;

    & .tab-name {
      color: #616778;
    }
    & img {
      filter: grayscale(68%) brightness(62%);
      -webkit-filter: grayscale(68%) brightness(62%);
      -moz-filter: grayscale(68%) brightness(62%);
    }
  }
  .thumbnail-pic {
    object-fit: contain;
    width: 31px;
    border-radius: 50%;
  }
}
.header-title {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #0c0d11;
}
.back-to-home {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #616778;
}

.settings-v2-header-wrapper {
 width: 100%;
 height: 100%;
 .body-wrapper {
  height: calc(100dvh - 45px);
  overflow: hidden;
 }
 .main-wrapper {
  overflow-y: scroll;
  width: 100%;
 }
 .upgrade-popup-settingsv2 {
    display: none;
    @include respond-to(lg) {
      display: block;
    }
  }
}

.settings-v2-header {
  background: #ffffff;
  display: flex;
  align-items: center;
  @include respond-to(md) {
    display: grid;
    grid-template-columns: 35% 65%;
  }
}
.search-bar-v2 {
  width: 100%;
  background: #f6f8fb;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 7.5px;
  margin-right: 1rem;
  min-width: 200px;
  position: relative;
  @include respond-to(lg) {
    margin-right: 9rem;
    width: 50rem;
  }
}
.search-bar-v2-input {
  border: none;
  background: #f6f8fb;
  width: 100%;
  &::placeholder {
    color: #9499aa;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
}

.settings-v2-header {
  .search-v2-Dropdown {
    position: absolute;
    left: 0;
    right: 0;
    background: #fff;

    top: 49px;
    max-height: 200px;
    overflow-y: auto;

    z-index: 500;
    border-radius: 4px;
    border: 1px solid #ebedf1;

    box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
  }
  .suggestion {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .suggested-page-name {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
  .suggested-page {
    cursor: pointer;
    &:hover {
      background: #e4e5e787;
      transition: all 200ms;
    }
  }

  .thumbnail-pic {
    object-fit: contain;
    width: 31px;
    border-radius: 50%;
  }
  .header-logo-dropdown {
    .dropdown-menu {
      width: 250px;
    }
    .dropdown-item {
      padding: 0.35rem 1rem;
    }
    .thumbnail-pic {
      object-fit: contain;
      width: 31px;
      border-radius: 50%;
    }
    .dropdown-menu.show {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;

      transform: translate(0px, 0px) !important;
    }
    .dropdown-item {
      color: #212529 !important;
      &:hover {
        color: #212529 !important;
      }
    }

    .dropdown-item {
      color: #212529 !important;
      &:hover {
        color: #212529 !important;
      }
    }
    .dropdown-item.disabled {
      opacity: 1 !important;
    }
    .dropdown-toggle {
      background: transparent;
      border: none;
      width: fit-content;
      padding: 0px !important;
      &:active {
        background: transparent !important;
        border: none I !important;
      }
      &:focus {
        background: transparent !important;
        border: none I !important;
      }
    }
  }
}
