@use '../../../breakpoints.scss' as *;

.change-due-date-picker{

    .highground-datpicker:focus-within {
        border: 2px solid transparent !important;
        width: -webkit-fill-available;
        background: transparent !important;
        border-radius: 4px;
    }
    .highground-datpicker.show .dropdown-toggle {
        background-color: #ffffff00;
        border: transparent;
    }
    .dropdown-toggle{
        input{
            display: none !important;
        }

        &:focus{
            border: none !important;
        }
    }
}

.secops-wrapper {
    width: 100%;
    flex-direction: column-reverse;
    @include respond-to(lg) {
        width: 70%;
        flex-direction: row;
    }
    .left-section {
        width: calc(100% - 32px);
        margin: 16px;
        margin-top: 0;
        @include respond-to(lg) {
            width: 70%;
            margin: 0 14px;
            margin-bottom: 14px;
        }
    }
    .right-section {
        width: calc(100% - 32px);
        margin: 16px;
        @include respond-to(lg) {
            width: 30%;
            margin: 48px 14px 0 14px;
        }
    }
}

.secops-sidebar{
    z-index: 5;
    height: calc(100vh - 101px) !important;
    
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #EBEDF1;
    padding: 6px;
    @include respond-to(md) {
        padding: 10px;
    }
    .side-menu-item {
        font-size: 12px;
        padding: 2px;
        @include respond-to(md) {
            font-size: 14px;
            padding: 8px;
        }
    }
    &.bar-expand {
        box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
            width: 140px;
            @include respond-to(md) {
                width: 300px;
            }

            .menu-item {
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
    
            .trigger-icon {
                transform: rotate(180deg);
                transition: transform 0.2s ease-in-out;
            }
        }

    &.bar-collapse{
        width: 4%;
        z-index: 1;
        .menu-item{
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        .trigger-icon {
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
        }
    }

    .selected-menu-item{
        color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }

    .menu-item:hover{
        color: #616778;
        background: #f6f8fb;
        border-radius: 4px;
    }
}

.secops-sidebar-no-scrolabel {
    position: relative;
    z-index: 5;
    // top: 55px;
    height: calc(100vh - 26px) !important;
    
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #EBEDF1;
    padding: 10px;
    min-width: fit-content;
    white-space: nowrap;
    &.bar-expand {
        box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
    
            .menu-item {
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
    
            .trigger-icon {
                transform: rotate(180deg);
                transition: transform 0.2s ease-in-out;
            }
        }

    &.bar-collapse{
        .menu-item{
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        .trigger-icon {
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
        }
    }

    .selected-menu-item{
        color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }

    .menu-item:hover{
        color: #616778;
        background: #f6f8fb;
        border-radius: 4px;
    }
}

.sell-body-wrapper {
    height: calc(100dvh - 56px);
    overflow: hidden;
}

.secops-sidebar-2new{
    z-index: 5;
    // top: 55px;
    height: calc(100dvh - 56px);
    
    overflow-y: auto;
    overflow-x: hidden;
    border-right: 1px solid #EBEDF1;
    padding: 10px;
    &.bar-expand {
        box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
            width: 17%;
    
            .menu-item {
                opacity: 1;
                transition: opacity 0.2s ease-in-out;
            }
    
            .trigger-icon {
                transform: rotate(180deg);
                transition: transform 0.2s ease-in-out;
            }
        }

    &.bar-collapse{
        width: 4%;
        .menu-item{
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        .trigger-icon {
            transform: rotate(0deg);
            transition: transform 0.2s ease-in-out;
        }
    }

    .selected-menu-item{
        color: #616778;
      background: #f6f8fb;
      border-radius: 4px;
    }

    .menu-item:hover{
        color: #616778;
        background: #f6f8fb;
        border-radius: 4px;
    }
}


.slidable-body-new {
    width: 100%;
    display: flex;

    .main-body {
        margin-left: auto;
        margin-right: auto;

        &.panel-open {
            width: 55%;
            left: 0;
            padding-left: 7%;
            padding-right: 7%;
            bottom: 0; /* Adjusted */
            overflow-y: auto;
            margin: 0 !important;
        }

        &.panel-closed {
            width: 45%;
        }
    }

    .sidepanel {
        &.panel-open {
            width: 45%;
            border-left: 1px solid #EBEDF1;
            right: 0;
            bottom: 0; /* Adjusted */
            overflow-y: auto;
        }

        &.panel-closed {
            width: 0%;
            overflow-x: hidden;
            height: 0px;
        }

        .panel-header {
            position: sticky;
            z-index: 1;
            border-bottom: 1px solid #EBEDF1;
        }

        .panel-body {
            height: calc(100% - 75px); /* Adjusted */
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.slidable-body-2new {
      /* width: inherit; */
      display: flex;
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
      /* margin: auto; */
      height: 100vh;

    &::-webkit-scrollbar {
        width: 0; 
        background: transparent; 
      }

    .main-body {
        margin-left: auto;
        margin-right: auto;

        &.panel-open {
            width: 55%;
            position: fixed;
            left: 0;
            padding-left: 7%;
            padding-right: 7%;
            top: 101px;
            bottom: 0; /* Adjusted */
            overflow-y: auto;
        }

        &.panel-closed {
            width: 45%;
        }
    }

    .sidepanel {
        &.panel-open {
            width: 45%;
            border-left: 1px solid #EBEDF1;
            position: fixed;
            right: 0;
            top: 101px;
            bottom: 0; /* Adjusted */
            overflow-y: auto;
        }

        &.panel-closed {
            width: 0%;
            overflow-x: hidden;
            height: 0px;
        }

        .panel-header {
            position: sticky;
            z-index: 1;
            border-bottom: 1px solid #EBEDF1;
        }

        .panel-body {
            height: calc(100% - 75px); /* Adjusted */
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}

.slidable-body{
    width: 100%;
    display: flex;
    .main-body{
        // transition: width 0.3s linear 0s;
        margin-left: auto;
        margin-right: auto;
        &.panel-open{
            // transition: width 0.3s linear 0s;
            width: 0;
            display: none;
            margin: 0 16px;
            @include respond-to(lg) {
              width: 55%;
              display: block;
              margin: 0 auto;
            }
            position: fixed ;
            left: 0;
            padding-left: 7%;
            padding-right: 7%;
            top: 101px ;
            height: calc(100% - 75px);
            overflow-y: auto;
        }

        &.panel-closed{
            // transition: width 0.3s linear 0s;
            width: 100%;
            margin: 0 16px;
            @include respond-to(lg) {
                width: 45%;
                margin: 0 auto;
            }
        }

    }
    .sidepanel{
        transition: width 0.3s linear 0s;
        // margin-left: auto;
        // margin-right: auto;
         &.panel-open{
            width: 100%;
            @include respond-to(lg) {
                width: 45%;
            }
            border-left: 1px solid #EBEDF1;
            position: fixed;
            right: 0;
            top: 101px ;
            height: 100vh ;
            transition: width 0.3s linear 0s;
        }

        &.panel-closed{
             transition: width 0.3s linear 0s;
            width: 0%;
            overflow-x: hidden;
            height: 0px ;
        }
        .panel-header{
            position: sticky;
            z-index: 1;
          border-bottom: 1px solid #EBEDF1;
        }
        .panel-body{
            height: calc(100% - 75px);
            overflow-y: auto;
        }
    }
}


.banner {
    background: #f6f8fb;
    border-radius: 8px;
    margin: 1.3rem 0rem;

    .banner-element-border {
        border-right: 1px solid #ebedf1;
    }

    .banner-element {
        flex-basis: 0;
        flex-grow: 1;
        padding: 1.15rem;

        .banner-element-name {
            font-family: "Archivo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #616778;
        }

        .banner-element-value {
            font-family: "Archivo";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #0c0d11;
        }
    }
}

.banner-secops {
    background: #f6f8fb;
    border-radius: 8px;
    margin: 1.3rem 0rem;
    flex-direction: column;
    @include respond-to(lg) {
        flex-direction: row;
    }

    .banner-element {
        flex-basis: 0;
        flex-grow: 1;
        padding: 1.15rem;
        &:not(:last-child) {
            border-bottom: 1px solid #ebedf1;
        }

        @include respond-to(lg) {
            &:not(:last-child) {
                border-bottom: none;
                border-right: 1px solid #ebedf1;
            }
        }

        .banner-element-name {
            font-family: "Archivo";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: #616778;
        }

        .banner-element-value {
            font-family: "Archivo";
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #0c0d11;
        }
    }
}

.employeeModal{
    .modal-content{
        height: 320px;
    }
    
}

.secops-cert-modal{
    .modal-content{
        border-radius: 8px;
        overflow-x: hidden;
    }

    .modal-header{
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .nav-link.active{
        color: #008fd4 !important;
        
        border-bottom: 1px solid #008fd4;
    }
}

.secops-side-modal{
    .modal-content{
        border-radius: 8px;
        overflow-x: hidden;
    }

    .modal-header{
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .nav-link.active{
        color: #008fd4 !important;
        
        border-bottom: 1px solid #008fd4;
    }
}

.links {
    .nav-link.active{
        color: #008fd4 !important;
        
        border-bottom: 1px solid #008fd4;
    }
}

.msp-add-member-modal{
    .modal-content{
        border-radius: 8px;
        overflow-x: hidden;
        height: auto;
    }

    .modal-header{
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .nav-link.active{
        color: #008fd4 !important;
        
        border-bottom: 1px solid #008fd4;
    }  
}

.expand-textarea-link{
    width: 100%;
    // min-height: 16px; /* Set the initial minimum height */
    height: 17px;
    resize: none; /* Disable manual resizing */
    box-sizing: border-box;
    // transition: height 0.2s; /* Add smooth transition for height changes */
    position: relative;
    white-space: pre-wrap;

    &::-webkit-scrollbar {
        display: none;
        scrollbar-width: none;
      }
      scrollbar-width: none;
    &:focus, :focus-within{
        // height: max-content;
        height: auto;
    }
    // &::placeholder {
    //     position: absolute;
    //     top: 50%;
    //     left: 20%;
    //     transform: translate(-50%, -50%);
    // }
}


.hide-span {
    span {
        visibility: hidden;
        width: 0px !important;
    }
}

.searchInputField {
    border: none;
    -webkit-transition: width 1s ease-in-out;
    -moz-transition: width 1s ease-in-out;
    -o-transition: width 1s ease-in-out;
    transition: width 1s ease-in-out;
    background: #F6F8FB !important;
    border: 1px solid #EBEDF1 !important;

    input {
      border: none;
      background: transparent;
      outline: none;
    }

    &:focus-within {
      border: 1px solid #9499AA !important;
      border-radius: 4px;
      background: #f6f8fb !important;
    }
  }

.operation-security-wrapper {
    width: calc(100% - 32px);
    margin: 0 16px;
    @include respond-to(lg) {
        width: 50%;
        margin: 0 auto;
    }
}
