@use '../../../breakpoints.scss' as *;

.integration-header {
    .menuItem {
        color: rgba(255, 255, 255, 0.88);
    }

    .selectedMenuItem {
        background: rgba(255, 255, 255, 0.24);
        ;
    }
    .msp-search {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 4px;
        height: 32px;

        input {
            background: transparent;
            border: none;
            color: rgba(255, 255, 255, .8);

            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
                opacity: 0.6;
            }
        }
    }
    .common-sidebar {
        .list-view {
            overflow-y: auto;
            height: calc(100vh - 210px);
        }
    }
    .search {
        background-color: #fff;
    }
    .item {
        border-radius: 4px;
        &:hover {
            background: #F6F8FB !important;
            .integration-add {
                display: block;
            }
        }
    }
    .mapped-by-ai-item {
        background-color: #E3E1F3;
        .text {
            color: #5244B3;
        }
    }
    .wrong-type-item {
        background: #f6f8fb;
    }

    .header-list-wrapper {
        display: none;
        @include respond-to(lg) {
            display: flex;
        }
    }

    .header-selected-wrapper {
        display: flex;
        @include respond-to(lg) {
            display: none;
        }
    }

    .continue-sync-btn {
        display: none;
        @include respond-to(md) {
            display: block;
        }
    }
}

.sec-stack-map-integration-modal {
    width: 100dvw;
    @include respond-to(md) {
        width: 424px !important;
    }
}

.sidebar-client-header {
    width: 100%;
    display: flex;
    .user-details {
        transition: opacity 1s ease-in-out, margin 1s ease-in-out;
    }
}
.expanded {
    .sidebar-client-header {
        .user-details {
            opacity: 1;
            margin-left: 8px;
        }
    }
    .sidebar-container {
        justify-content: center;
    }
}
.minimized {
    .sidebar-client-header {
        .user-details {
            opacity: 0;
            margin-left: 0;
        }
    }
    .sidebar-container {
        justify-content: flex-start;
    }
}

#ai-assets-item {
    .tooltip-inner {
        width: 160px;
        border-radius: 4px !important;
        padding: 4px 8px;
    }
    .arrow {
        transform: translateX(4px) !important;
    }
    .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
    }
}
#wrong-type-item {
    .tooltip-inner {
        border-radius: 4px !important;
        padding: 4px 8px;
    }
    .text {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        text-align: start;
    }
}

.set-default-integration-modal {
    .modal-content {
        width: 400px;
    }

    .round label:after {
        border-right: none;
        content: "";
        height: 16px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 1px;
        width: 16px;
        background: #008fd4;
        border-radius: 50px;
        border: 1px solid white;
    }

    .modal-content {
        height: auto;
        max-height: 500px !important;
        // width: fit-content;
    }

    .modal-body {
        padding: 16px
    }

    .modal-footer {
        border-top: 1px solid #EBEDF1;
    }




    .disabled-radio {
        label::after {
            background: #BBBEC9 !important;

        }

        input[type="radio"]:checked+label {

            background-color: #BBBEC9 !important;
            border-color: #BBBEC9 !important;
        }



    }


}

// MAP CLIENTS SCREEN

.map-clients-screen {
    width: calc(100% - 40px);
    margin: 0 20px;
    @include respond-to(md) {
        margin: auto;
        width: 65%;
    }
    @include respond-to(lg) {
        width: 55%;
    }
    @include respond-to(xl) {
        width: 45%;
    }
    .round-checkbox .form-check-input {
        display: none;
    }
    .apply-all-filter-notification {
        margin-right: 10px !important;
        @include respond-to(md) {
            margin-right: 8px !important;
        }
    }
    .clients-wrapper,
    .contacts-wrapper,
    .products-wrapper,
    .memebrs-wrapper {
        flex-direction: column;
        @include respond-to(md) {
            flex-direction: row;
        }
    }
    .integration-block {
        padding-right: 20px !important;
        @include respond-to(md) {
            padding-right: 1px !important;
        }
    }
    .highground-block {
        padding-left: 10px !important;
        padding-right: 17px !important;
        @include respond-to(md) {
            padding-left: 10px !important;
            padding-right: 10px !important;
        }
    }
    .integration-block,
    .highground-block {
        width: 100%;
        @include respond-to(md) {
            width: 50%;
        }
    }
    .msp-cat-dropdown {
        .custom_drop-down .dropdown_item .tick {
            
            margin-top: 3px !important;
        }

        .custom_drop-down .dropdown_item.selected {
            background-color: rgba(0, 143, 212, 0);
        }

        .custom_drop-down .dropdown_item:hover {
            background-color: rgba(0, 143, 212, 0);
        }

        .selected-option {
            color: #616778;
            font-weight: 400;
        }

        .dropdown-menu.show {
            .dropdown_item .tick {
                margin-top: 0px !important;
            }
        }
    }

    .msp-cat-panel{

        // height: 0px;
        max-height: 0;
       overflow: hidden;
        transition: max-height 0.4s ease-out;

    } 

    .blue-checkbox-minus .form-check-input {
        width: 16px;
        height: 16px;
        background-color: #FFFFFF;
        border: 1px solid #9E9E9E;
        border-radius: 2px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        outline: none;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        position: relative;
    }

    .blue-checkbox-minus .form-check-input:checked {
        background-color: #008FD4;
        border: none;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked+label {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        border: 1px solid #000;
        border-radius: 2px;
        outline: none;
        cursor: pointer;
    }

    .blue-checkbox-minus input[type="checkbox"]:checked::before {
        content: '−';
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        line-height: 16px;
        color: white;
        background-color: #008FD4;
        border-radius: 2px;
        width: 16px;
        height: 16px;
        border: 1px solid #008FD4 !important;
        font-weight: bold;
    }
    
}

// MAP CLIENTS DROPDOWN
.map-clients-dropdown {
    &.show {
        .dropdown-toggle {
            &::after {
                display: none;
            }

            background: transparent !important;
            border: none !important;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }

        &:active {
            background: transparent !important;
            border: none !important;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        // inset: auto 13px auto 5px !important;
        // transform: translate(0px, 0px) !important;
        width: 100% !important;
        &.assets-columns {
            width: 240px !important;
            z-index: 1;
        }
        .modal-search {
            margin: 0 8px;
            margin-bottom: 4px;
            padding: 4px;
            border-radius: 4px;
            background-color: #F6F8FB;
            input {
                padding: 1px 4px;
                &::placeholder {
                    color: #9499AA;
                }
                border: none;
                background-color: #F6F8FB;
                width: 100%;
            }
        }
        .separator {
            margin: 4px 12px;
            border-top: 1px solid #EBEDF1;
        }
        .modal-item {
            border-radius: 2px;
            &:hover {
                background: #F6F8FB;
            }
        }
    }
}

// .map-clients-dropdown-assets {
//     &.show {
//         .dropdown-toggle {
//             &::after {
//                 display: none;
//             }

//             background: transparent !important;
//             border: none !important;
//         }
//     }

//     .dropdown-toggle {
//         &::after {
//             display: none;
//         }

//         &:active {
//             background: transparent !important;
//             border: none !important;
//         }

//         background: transparent !important;
//         border: none !important;
//     }

//     .dropdown-menu.show {
//         // inset: auto 13px auto 5px !important;
//         // transform: translate(0px, 0px) !important;
//         width: 100% !important;
//         height: 200px;
//     }
// }

.map-clients-dropdown-assets {
    &.show {
        .dropdown-toggle {
            &::after {
                display: none;
            }

            background: transparent !important;
            border: none !important;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }

        &:active {
            background: transparent !important;
            border: none !important;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        // inset: auto 13px auto 5px !important;
        // transform: translate(0px, 0px) !important;
        width: 100% !important;
        height: 300px;
        
        overflow: auto; 
        scrollbar-width: none; 
        
        &::-webkit-scrollbar {
            display: none; 
        }
        &.assets-columns {
            height: auto;
        }
        .separator {
            margin: 4px 12px;
            border-top: 1px solid #EBEDF1;
        }
        .modal-item {
            border-radius: 2px;
            &:hover {
                background: #F6F8FB;
            }
        }
    }
}




// MAP INTEGRATOINS SCREEN:::::::::::::::

.map-integrations-dropdown {

    &.show {
        .dropdown-toggle {
            &::after {
                display: none;
            }

            background: transparent !important;
            border: none !important;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }

        &:active {
            background: transparent !important;
            border: none !important;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        // inset: auto auto auto 0px !important;
        // transform: translate(0px, 0px) !important;
       
    }

    .tool-creator-field {
        button {
            padding: 1px 5px;
        }

        input {
            border: none;
            width: 100%;
            background: transparent;
        }

        border: 1px solid #99d2ee;
        padding: 3px;
        border-radius: 4px;
        height: 32px;
        background: #F6F8FB;

    }






}

.connect-integration-modal {


    .modal-content {
        width: 400px !important;
        border-radius: 8px;
        min-height: 490px;


    }

    .modal-body {
        .spinner-border-sm {
            border-width: .15em;
        }
    }

    .modal-footer {
        padding: 10px !important;
    }

    .round label:after {
        border-right: none;
        content: "";
        height: 16px;
        left: 1px;
        opacity: 0;
        position: absolute;
        top: 1px;
        width: 16px;
        background: #008fd4;
        border-radius: 50px;
        border: 1px solid white;
    }

    .modal-content {
        height: auto;
        max-height: 550px !important;
        // width: fit-content;
    }

    .modal-body {
        padding: 16px
    }

    .modal-footer {
        border-top: 1px solid #EBEDF1;
    }




    .disabled-radio {
        label::after {
            background: #BBBEC9 !important;

        }

        input[type="radio"]:checked+label {

            background-color: #BBBEC9 !important;
            border-color: #BBBEC9 !important;
        }



    }

    .combined_images {
        .left-image {
            transform: translate(1.2rem, 0px);
            z-index: 3;
            transition: all .5s;
        }

        .middle-image {
            z-index: 5;
            position: relative;
            transform: translate(0.25rem, 1.3rem);
            background: white;
            border-radius: 50%;
            border: 2px solid #fff;
            transition: all .5s;
        }

        .right-image {
            transform: translate(-1.2rem, 0px);
            transition: all .5s;
        }

    }


}


.bgGrey_on_hover {
    &:hover {
        background: #F6F8FB;
        color: #616778;
    }
}

.grey_selected {
    background: #F6F8FB;
    color: #616778;
}

.disconnectv2_banner {
    transform: scale(0) translate(-20px, -500px) rotateX(360deg);
    // transform: translate3d(-50%, -50%, 0) scale3d(0, 0, 1);
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1);


    &.show {
        transform: scale(1) translate(0px, 0px) rotateX(0deg) !important;
        // transform: translate3d(-50%, -50%, 0) scale3d(1, 1, 1) !important;
        transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1);
    }
}




.integrationv2-oauth2-btn {
    button {
        background-color: #008fd4;
        border: none;
        width: 100%;
        padding: 10px;
        color: white;
        margin-top: 5px;
        border-radius: 4px;

        &:focus {
            border: none !important;
            outline: 0px auto -webkit-focus-ring-color;
        }
    }

}

.importedClientDetailModal {
    @include respond-to(md) {
        width: 400px;
    }
    .msp-client-modalheader {
        border-bottom: 1px solid #EBEDF1;
    }
}

.msp-cat-dropdown {
    .tick {
        margin-top: 0px;
    }

    .custom_drop-down .dropdown_item.selected {
        background-color: rgba(0, 143, 212, 0);
    }

    .custom_drop-down .dropdown_item:hover {
        background-color: rgba(0, 143, 212, 0);
    }

    .selected-option {
        color: #616778;
        font-weight: 400;
    }

    .dropdown-menu.show {
    }
}

.map-confirm-modal{
     
        max-width: 300px;
        .modal-content {
          border-radius: 8px;
          max-height: 240px !important;
        }
        .modal-footer {
          border-top: none;
          padding: 9px;
          &:hover{
              border-top: none;
              padding: 9px;

          }
        }
        .permission {
          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #0c0d11;
          // text-align: center;
        }
        .delete-btn {
          background: #f36060;
          border-radius: 2px;
          padding: 4px 7px;
          color: #fff;
          border: none;
          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          &:active {
            border: none !important;
            background: #f36060 !important;
            color: #fff !important;
          }
        }
        .cancel-btn {
          background: #f6f8fb;
          border-radius: 2px;
          color: #616778;
          border: none;
          margin-right: 9px;
          font-family: "Archivo";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          padding: 4px 7px;
          &:active {
            background: #f6f8fb !important;
            border: none !important;
            color: #616778 !important;
          }
        }
      
       
      
}

.msp-map-integartion-nested-dropdown{
    .dropdown-menu.show{
        width: 100%;
        padding-left: 1rem !important;
        padding-top: .7rem !important;
    }
    .dropdown-toggle{
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}


.msp-connected-integration-modal{
    progress {
        border-radius: 8px;
        width: 100%;
        height: 10px;
  
        box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
      }
      progress::-webkit-progress-bar {
        background-color: #ebedf1;
        border-radius: 7px;
      }
      progress::-webkit-progress-value {
        background-color: #008fd4;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
      }
}

.map-assets {
    display: grid;
    grid-template-columns: 200px 200px 150px;
    @include respond-to(md) {
        grid-template-columns: 42% 42% 16%;
    }
    // grid-template-columns: 52% 12% 12% 12% 12%;
    // height: 3rem;
    // align-items: center;
}

.map-assets-header {
    display: grid;
    grid-template-columns: 200px 200px 150px;
    @include respond-to(md) {
        grid-template-columns: 42% 42% 16%;
    }
    // grid-template-columns: 52% 12% 12% 12% 12%;
    // height: 3rem;
    // align-items: center;
}

.map-assets-modal {
    @include respond-to(md) {
        width: 400px;
    }
}

.msp-open-integration {
    .header-wrapper {
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        @include respond-to(md) {
            flex-direction: row;
            align-items: center;
            padding: 42px;
        }
        .summary-container {
            width: 100%;
            @include respond-to(md) {
                width: 75%;
            }
        }
    }
    .details-container {
        flex-direction: column;
        @include respond-to(md) {
            flex-direction: row;
            gap: 20px;
        }
        .resources-block {
            width: 100%;
            padding: 20px;
            @include respond-to(md) {
                width: 22%;
                padding: 42px 0 0 42px;
            }
        }
        .about-block {
            width: 100%;
            padding: 20px;
            @include respond-to(md) {
                width: 78%;
                padding: 14px 21px 0 0;
            }
            .about-text {
                padding: 14px 0 0 0;
                @include respond-to(md) {
                    padding: 14px 42px 0 0;
                }
            }
        }
    }
}

.map-client-header {
    display: grid;
    grid-template-columns: 280px 280px 150px;
    @include respond-to(md) {
        grid-template-columns: 40% 40% 20%;
    }
    // grid-template-columns: 52% 12% 12% 12% 12%;
    // height: 3rem;
    // align-items: center;
}

.psa-integration-flow {
    .modal-content {
        border-radius: 8px;
        min-height: 490px;
        height: fit-content;
        width: fit-content;
    }

    .modal-body {
       .title {
            font-size: 16px;
            font-weight: 500;
            line-height: 28px;
            text-align: center;
            font-family: Archivo;
       }
       .step-section {
            padding: 0 8px;
            .step-number {
                width: 32px;
                height: 32px;
                border-radius: 9999px;
                background-color: #EBEDF1;
                color: #9499AA;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .step-line {
                height: 1px;
                width: 138px;
                background-color: #D4D6DD;
            }
            .step-title {
                font-size: 14px;
                font-weight: 500;
                line-height: 22px;
            }
            .work-item-txt {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
            .loader {
                animation: rotate 5s linear infinite
            }
            &.done {
                .step-number {
                    background-color: #008FD4;
                    color: #fff;
                }
                .step-line {
                    background-color: #008FD4;
                }
                .work-item-txt {
                    color: #0C0D11;
                }
            }
            &.next {
                .step-number {
                    color: #008FD4;
                    border: 1px solid #008FD4;
                }
                .work-item-txt {
                    color: #616778;
                }
            }
            &.inactive {
                .work-item-txt {
                    color: #616778;
                }
            }
       }
       .bottom-description-wrapper {
            background-color: #F6F8FB;
            border-radius: 8px;
            display: flex;
            align-items: center;
            gap: 12px;
            padding: 8px;
            .txt {
                font-size: 14px;
                font-weight: 400;
                line-height: 22px;
            }
       }
    }

    .modal-footer {
        padding: 12px !important;
        border-color: #EBEDF1;
        margin: 0 !important;
        button {
            background-color: #F6F8FB;
            width: 100%;
            padding: 5px 12px;
            color: #616778;
            border-radius: 2px;
            border: 0px solid transparent !important;
            font-size: 14px;
            font-weight: 500;
            line-height: 22px;
            margin: 0 !important;
        }
    }
}

#ml-client-status-tooltip .tooltip-inner {
    border-radius: 4px !important;
    padding: 4px 8px !important;
    text-align: start;
    min-width: fit-content;
}

.connected-tool-card {
    width: 100%;
    @include respond-to(md) {
        width: 350px;
    }
}

.msp-connected-integration-modal-wrapper {
    @include respond-to(md) {
        width: 400px;
    }
}
