.dashboard-right-side-bar-wrapper {
  height: calc(100dvh - 101px);
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 0;
  position: 'fixed';
}

.dashboard-right-side-bar {
  width: 280px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  .section {
    .title {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
    }
    .row {
      padding: 5px 8px;
      display: flex;
      align-items: center;
      gap: 4px;
      width: 100%;
      justify-content: space-between;
      margin: 0 !important;
      .header {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .percentage {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        
        padding: 1px 6px;
        border-radius: 9999px;
        &.grey {
          background-color: #EBEDF1;
          color: #616778;
        }
        &.orange {
          background-color: #FFF2DD;
          color: #FFAC2C;
        }
        &.green {
          background-color: #E0F9ED;
          color: #40D88E;
        }
      }
      .select-arrow {
        transition: all 0.3s ease-in-out;
        &.open {
          transform: rotate(180deg);
        }
      }
    }
    .list {
      margin-top: 4px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      .item {
        padding: 4px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: #F6F8FB;
        }
        .icon-container {
          width: 30px;
          display: flex;
          justify-content: center;
        }
        .name-strikethrough {
          text-decoration: line-through;
          color: #9499AA;
        }
      }
    }
  }
}
