@use '../../../../breakpoints.scss' as *;

.technology-spend-sliding-modal {
  @include respond-to(lg) {
    width: 480px;
  }
}

.technology-spend-modal {
  overflow-y: scroll;
  scroll-behavior: smooth;
  overflow-x: hidden;
  .highground-datpicker .dropdown-menu.show {
    &.disable-transform {
      transform: none !important;
      inset: auto !important;
      &[x-placement="top-end"],
      &[x-placement="top-start"] {
        bottom: 100% !important;
      }
    }
    &.small {
      header {
        padding: 8px 12px !important;
        padding-bottom: 0px !important;
      }
      .calendar {
        padding: 0 8px !important;
      }
      .wrapper {
        width: 210px !important;
        .inner-menu {
          .date-item {
            width: 35px;
          }
        }
      }
      .w-fit-content {
        padding: 7px 8px !important;
      }
      .days {
        margin-bottom: 10px;
      }
      li {
        font-size: 12px !important;
        line-height: 18px !important;
        margin-top: 12px !important;
        &::before {
          width: 25px !important;
          height: 25px !important;
        }
      }
    }
  }
  .highlight-input {
    &.bordered {
      border: 1px solid #ebecf1 !important;
    }
  }

  // Tabs Css
  .tab-header {
    border-bottom: 1px solid #ebedf1;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    border-bottom: 1px solid #008fd4;
    color: #008fd4 !important;
    font-family: "Archivo";
    font-style: normal;
    background: transparent;

    img {
      filter: invert(51%) sepia(55%) saturate(5380%) hue-rotate(173deg) brightness(91%) contrast(101%)
    }
  }

  .nav-pills .nav-link {
    border-radius: 0rem;
    font-family: "Archivo";
    font-style: normal;
    padding: 0.5rem 1.1rem;
  }

  .invoice-row {
    display: grid;
    grid-template-columns: 45% 40% 15%;
  }
  .subscription-invoice-row{
    display: grid;
    grid-template-columns: 60% 25% 15%;
  }

  .invoice-summary-table{
    display: grid;
    grid-template-columns: 45% 20% 12% 24%;
  }

  .msp-date-picker{
    .highground-datpicker:focus-within {
      border: 2px solid transparent !important;
      width: -webkit-fill-available;
      background: #ffffff !important;
      border-radius: 4px;
  }
  }




.add-item-spend-modal-wrapper{
  .add-item-in-spend-modal{
    position: fixed;
    border: 1px solid #ebedf1;
    border-radius: 4px;
   
    bottom: 20%;
   
    &.add-spend-draggable-parent{
      right: 0;
      width: calc(100% - 16px);
      margin: 0 8px;
      @include respond-to(sm) {
        margin: 0;
        width: auto;
        right: 14px;
        min-width: 450px;
      }
    }
    z-index: 1000;
    background: white;
  }
  &.show{
    .add-item-in-spend-modal{
      -webkit-box-shadow: 0px 0px 47px 19px rgba(0,0,0,0.1);
      -moz-box-shadow: 0px 0px 47px 19px rgba(0,0,0,0.1);
      box-shadow: 0px 0px 47px 19px rgba(0,0,0,0.1);
    }
  }
}


.add-item-category-dropdown{
  .dropdown-toggle {
      padding: 0px;
      background: transparent !important;
      border: none !important;

      &::after {
          display: none;
      }

  }

  .dropdown-menu {
      inset: auto 0px auto auto !important;
      transform: translate(0px, 0px) !important;
      // .blue-icon {
      //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
      // }
    }

}
}

.placeholderBlackBoldText::placeholder {
  font-weight: 600;
  color: black !important;
}

.h-16 {
  height: 32px;
}

.h-0 {
  height: 0px;
}

.tech-spend-modal {
  .checkbox {
    input:checked ~ label:before {
      background-color: #008FD4 !important;
      border: transparent !important;
    }
    input:checked ~ label:after {
      color: #fff !important;
      scale: 0.8;
    }
  }
}

.resultLineForDropdown {
  size: 12px;
  line-height: 18px;
  font-weight: 400;
  margin: 4px 12px;
  color: #9499AA;
}

.dropdownSearchResult {
  size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin: 4px 12px;
  color: #616778;
}

.pointer {
  cursor: pointer;
}

.f-blue {
color: #008fd4 !important;
}

.w-0{
  width: 0px;
}

.generate-invoice-modal{
  max-width: 450px;
  width: 100%;
  .modal-header {
    padding: 13px 10px;
    // border-bottom: 1px solid #dee2e6 !important;
  }
  .modal-content {
    border-radius: 8px;
    max-height: 500px !important;
    height: auto;
  }
  .modal-footer {
    border-top: none;
    padding: 9px !important;
    // border-top: 1px solid #dee2e6 !important;
  }
  .invoice-row-summary-table{
    display: grid;
    grid-template-columns: 45% 35% 20%;
    align-items: center;
  }
  .hg-blue-btn {
    background: #008fd4;
    border-radius: 2px;
    padding: 5px 8px !important;
    color: #fff;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:active {
      border: none !important;
      background: #008fd4 !important;
      color: #fff !important;
    }
    &:hover {
      border: none !important;
      background: #008fd4 !important;
      color: #fff !important;
    }
  }
  .cancel-btn {
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    border: none;
    margin-right: 9px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 5px 8px !important;
    &:active {
      background: #f6f8fb !important;
      border: none !important;
      color: #616778 !important;
    }
    &:hover {
      background: #f6f8fb !important;
      border: none !important;
      color: #616778 !important;
    }
  }
}

.subcategory-item-menu {
  .sub-item {
    padding: 4px 12px;
  }
}