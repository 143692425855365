.audit-compliance-dropdown{
    border: 0px solid transparent !important;
    .parent-dropdown-toggle{
        &::after {
            content: " ";
            background-image: url("../../../../../../public/images/big-chevron-down.svg");
            background-position: 0 6px;
            height: 20px;
            width: 20px;
            position: absolute;
            background-repeat: no-repeat;
            border: 0;
            right: 0px;
          }
        

          color: #616678 !important;
    }

    .dropdown-toggle{
        background: transparent !important;
        border: none !important;
        &:focus-within{
            border: 0px solid transparent !important;

        }
    }
    .dropdown-menu.show.parent-dropdown-menu{
        min-width: 240px;
        inset: auto 0px auto auto !important;
    }

}



.security-policy-dropdown{


    .dropdown-toggle{
        color: #616778 !important;
        height: 25px;
        padding: 0px !important;
        background: transparent !important;
        border: none !important;
        &::after{
            display: none;
        }
    }
    .dropdown-menu.show.parent-dropdown-menu{
        min-width: 275px;
        // inset: auto 0px auto auto !important;
    }


}


#root .audit-compliance-dropdown:focus-within{
    border: 2px solid transparent !important;
    

}
#root .audit-compliance-dropdown{
    border: 2px solid transparent !important;
    width: fit-content !important;

}