.cyberCareContainer {
    background: #FFFFFF;
    border: 1px solid #EBECF1;
    box-sizing: border-box;
    border-radius: 16px;
    overflow: auto;
}

.cyberCareContainer .title {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    /* Black */
    color: #101010;
    padding: 1.5em 0em 1em 1em ;
}