.flexCenter {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

.chartDiagram {
    width: 100%;
    height: 100%;
    padding: 1em;
}

.p2 {
    /* height: 100%; */
    height: 220px;
    background: #F6F8FB;
    border-radius: 8px;
    padding-bottom: 1em;
}

.recharts-wrapper {
    width: 100% !important;
}

.trendMain_Text {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 17px;
    text-transform: capitalize;
    /* Black */
    color: #101010;
}

.trendDataTitleContainer {
    padding: 1em 1.5em;
    background: #FFFFFF;
    border: 1px solid #EBECF1;
    border-bottom: none;
    box-sizing: border-box;
    border-radius: 16px 16px 0 0;
    text-align: center;
    width: 35%;
}

.trend_cc_background {
    background: #FFFFFF;
    border: 1px solid #EBECF1;
    box-sizing: border-box;
    border-radius: 0 0 16px 16px;
}

.subscription-restriction-in-cyber-trend{
   div,p,span{
color: #616778 !important;
   } 

.chartDiagram{
    pointer-events: none;
    
}

}