.cc_background {
  background: #ffffff;

  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 16px;
}

.cc_text {
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  text-transform: capitalize;

  /* Black */

  color: #101010;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  align-self: flex-start;
  /* margin: 0px 16px; */
}

.cc_row {
  margin: 7.5% 0;
}

.greyShadow {
  box-shadow: 0px 16px 32px rgba(235, 236, 241, 0.32)!important;
}
