.spend-filter-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        min-width: 190px;
    }
}

.amount-filter-dropdown {

    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent !important;
        border: none !important;
    }
    .dropdown-menu.show {
        min-width: 190px;
        transform: translate(0px,0px) !important;
        inset: 108% auto auto 6px !important;
    }


}

.subcategory-filter-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        min-width: 190px;
        transform: translate(0px,0px) !important;
        inset: 108% auto auto 6px !important;
    }

}

.date-filter-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        min-width: 190px;
        margin-left: 10px  !important;
        max-width: 264px;

        .rdrDateRangePickerWrapper {
            flex-direction: column-reverse;
            width: 264px;
            padding: 12px;
            gap: 12px;
            
            .rdrDefinedRangesWrapper {
                position: relative;
                margin-top: -8px;  
            }

            .rdrDateDisplayWrapper {
                background-color: transparent;
                height: 32px;
            }

            .rdrDateDisplay {
                margin: 0px !important;
            }

            .rdrDateDisplayItem {
                box-shadow: none !important;
                border-color: #EBEDF1;
                &:focus-within {
                    border: 2px solid #99D2EE;
                    color: #0C0D11;
                    font-weight: 400;
                    size: 14px;
                    line-height: 22px;
                  }
            }

            .rdrDateInput input{
                text-align: start;
                height: 32px;
                width: 118px;
                color: #0C0D11;
                font-weight: 400;
                size: 14px;
                line-height: 22px;
                padding: 8px;
            }

            .rdrMonthAndYearWrapper {
                position: relative;
                height: 40px;
                width: 240px;
            }
              
              .rdrMonthAndYearPickers {
                display: flex;
                justify-content: flex-start;
                align-items: start;
                margin-top: 4px;
                margin-bottom: 4px;
              }


              .rdrMonthPicker, .rdrYearPicker {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #0C0D11;
                    align-items: start;
                    margin: 0px;
                    

                    select {
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        display: flex;
                        justify-items: start;
                        justify-self: start;
                        align-items: start;
                        text-align: start;
                        font-weight: 500;
                        padding: 0px;
                        margin: 0px;
                        align-items: start;
                        // width: calc(100% + 6px) !important;
                    }

                // select option {
                //     font-weight: 500;
                //   }

                .selected-option {
                    font-weight: 500;
                    size: 14px;
                    line-height: 22px;
                    color: #0C0D11;
                    align-items: start;
                  }

                select:hover{
                    background-color: transparent !important;
                }
              }

              .rdrMonthAndYearPickers {
                position: absolute;
              }

              .rdrNextPrevButton{
                padding: 0px;
                width: 20px;
                height: 18px;
                background: white;
                background-size: cover;

                i{
                    visibility: hidden;
                }
              }

              .rdrNextPrevButton:focus {     
                outline: none;
            }

              .rdrPprevButton {
                position: absolute;
                right: 25px;
                background-image: url('../../../../../public/images/supply-chain/show-less-icon.svg');
                transform: rotate(270deg);
              }

              .rdrNextButton {
                position: absolute;
                right: 0;
                background-image: url('../../../../../public/images/supply-chain/show-less-icon.svg');
                transform: rotate(90deg);
              }
              
              select {
                padding: 5px;
              }

            .rdrStaticRange {
                // height: 30px;
                width: 240px;
                padding: 4px, 12px 4px, 12px !important;
                border: 0px !important;
            }
            .rdrStaticRangeLabel{
padding: 7px 24px;
            }

            // display none to bottom inputs
            .rdrInputRanges {
                display: none;
            }

            // different color to all the button other than start and end range
            .rdrInRange {
                color: #EBF6FC !important;

            }

            .rdrMonths {
                width: 240px;
                padding: 8px;
                padding-top: 0px;
                padding-bottom: 0px;
            }

            .rdrMonth {
                width: 224px;
                padding: 0px;
            }

            .rdrWeekDays{
                width: 224px;
                height: 26px;
            }

            .rdrDay {
                width: 32px;
                height: 32px;
            }

            .rdrDefinedRangesWrapper {
                p {
                    visibility: hidden;
                    height: 0px;
                    line-height: 0px;
                    margin: 0px;
                    color: white;   
                }
                p:first-child {
                    padding-left: 10px;
                    padding-top: 2px;
                    display: flex;
                    align-items: center;
                    visibility: visible;
                    color: #9499AA;
                    font-weight: 500;
                    height: 30px !important;
                    size: 12px;
                    line-height: 18px !important;
                }
            }

            .rdrStaticRange {
                color: #616778;
                border: none !important;
            }

            .rdrStaticRange:hover{
                border: none !important;
                background-color: black !important;
            }

            .rdrStaticRangeSelected {
                position: relative;
                color: #616778 !important;
                fill: #616778;
                &::after{
                    content: "";
                    font-weight:300;
                    background: url('../../../../../public/images/msp/dropdown-grey-tick.svg') no-repeat 9% 65%;
                    // padding-left: 20px;
                    // &:hover {
                    //     padding-left: 0px;
                    //     background: url('/images/msp/dropdown-grey-tick.svg') no-repeat center center !important;
                    // }
                    width: 15px;
                    /* display: flex; */
                    height: 15px;
                    position: absolute;
                    top: 50%;
                    left: 13px;
                    transform: translate(-50%, -50%);

                }
            }


            .rdrDayNumber:hover {
                border: 1px solid #5CB7E3;
                border-radius: 4px;

              }

            .rdrInRange~.rdrDayNumber span {
                height: 32px;
                color: black !important;
            }

            .rdrStartEdge{
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .rdrEndEdge {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
               
            }
            .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
                // border-top-right-radius: 4px;
                // border-bottom-right-radius: 4px;
                
            }
            .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
                // border-top-left-radius: 4px;
                // border-bottom-left-radius: 4px;
               
            }

            .rdrDayHovered{
                border:  0px solid transparent !important;
            }
            .rdrDayStartPreview{
                border:  0px solid transparent !important;

            }
            .rdrDayEndPreview{
                border:  0px solid transparent !important;

            }
            .rdrDayInPreview{
                border:  0px solid transparent !important;

            }
        }
    }



}



.msp-client-dahsboard-date-filter-dropdown {
    .dropdown-toggle {
        &::after {
            display: none;
        }

        background: transparent !important;
        border: none !important;
    }

    .dropdown-menu.show {
        min-width: 190px;
        margin-left: 10px  !important;

        .rdrDateRangePickerWrapper {
            flex-direction: column-reverse;

            .rdrDefinedRangesWrapper {
                width: 100%;


            }

            // display none to bottom inputs
            .rdrInputRanges {
                display: none;
            }

            // different color to all the button other than start and end range
            .rdrInRange {
                color: #EBF6FC !important;


            }

            .rdrInRange~.rdrDayNumber span {
                color: black !important;
            }

            .rdrStartEdge{
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
            .rdrEndEdge {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
               
            }
            .rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                
            }
            .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
               
            }

            .rdrDayHovered{
                border:  0px solid transparent !important;
            }
            .rdrDayStartPreview{
                border:  0px solid transparent !important;

            }
            .rdrDayEndPreview{
                border:  0px solid transparent !important;

            }
            .rdrDayInPreview{
                border:  0px solid transparent !important;

            }
        }
    }



}

.pointer {
    cursor: pointer;
}

.clearBlockHeight {
    height: 42px;
    border-top: 1px solid #EBEDF1;
    padding-left: 30px !important;
  }

