@use '../../../breakpoints.scss' as *;

.members-wrapper {
  margin-top: 2.5rem !important;
  max-width: 60%;
  margin: auto;
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;

    color: #0c0d11;
  }
  .add-member-btn {
    background: #008fd4;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    border: none;
  }
  .disable-btn {
    background: #ebecf1;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #b6b6bf;
    border: none;
  }
  table {
    margin-top: 34px;
    .grid-container {
      display: grid;
      grid-template-columns: 35% 15% 20% 20% 10%;
    }
    tr {
      th {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: #616778;
      }
    }

    .table-data {
      // background: #f6f8fb;
      // border-radius: 8px;
      padding: 1rem;
    }
    .user-name {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.02em;
      color: #0c0d11;
    }
    .user-email {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.02em;
      color: #616778;
    }
    .dots.icon {
      margin-left: 3rem;
      cursor: pointer;
    }

    .user-detail-dropdown {
      .device-dropdown button {
        background: transparent;
        border: none;
        &::after {
          display: none;
        }
      }
      .dropdown-toggle {
        background: transparent !important;
        border: none !important;
      }
      .show {
        .dropdown-toggle {
          border: none;
          // border: 1px solid #f36060;
          // border-radius: 2px;
        }
      }
      .dropdown-menu.show {
        background: #0c0d11;
        box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
        border-radius: 4px;
        transform: translate(0px, 0px) !important;
        inset: auto 0px auto auto !important;
        width: 200px;
      }
      .notification-btn {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.64);
        padding: 4px 13px;
      }
      .remove-btn {
        cursor: pointer;
        color: #f36060;
        padding: 5px 10px 5px 13px;
      }
    }
  }
}

.user-wrapper {
  margin-top: 2.5rem !important;
  width: 100%;
  padding: 0 20px;
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    padding: 0;
    margin: auto;
  }

  .topic-heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  }
  .user-title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .userName {
    /* Titles / Title 3 */

    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #0c0d11;
  }
  .userEmail {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #9499aa;
  }
  .user-avatar {
    object-fit: contain;
    border-radius: 50%;
    width: 60px;
  }
  .profile-divider {
    border: 1px solid #ebedf1;
  }
  .devices-wrapper {
    background-color: #f6f8fb;
    border-radius: 8px;

    .device-wrap {
      padding: 1.2rem 0.6rem;
    }
    .device-name {
      color: #0c0d11;
      font-weight: 500;
      font-size: 14px;
    }
    .device-date {
      font-weight: 400;
      font-size: 12px;
      color: #9499aa;
      margin-right: 9px;
    }
    .device-dropdown button {
      background: transparent;
      border: none;
      &::after {
        display: none;
      }
    }
    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }
    .show {
      .dropdown-toggle {
        border: none;
        // border: 1px solid #f36060;
        // border-radius: 2px;
      }
    }
    .dropdown-menu.show {
      background: #f6f8fb;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      border-radius: 4px;
      inset: 0px auto auto -104px !important;
    }
    .notification-btn {
      cursor: pointer;

      padding: 4px 12px;
      margin: 10px 0px;
    }
    .remove-btn {
      cursor: pointer;
      margin: 10px 0px 7px 0px;
      padding: 4px 12px;
    }
    .notification-divider {
      // border: 1px solid rgba(255, 255, 255, 0.24);
      margin-bottom: 10px !important;
    }
  }
  .devices {
    padding: 0px 12px;
  }

  .settings-wrapper {
    background-color: #f6f8fb;
    border-radius: 8px;
    .settings-wrap {
      padding: 1.2rem 1rem;
    }
    .title {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #0c0d11;
    }
    .subtitle {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #616778;
    }
  }
  .delete-user-btn {
    border: none !important;
    padding: 4px 6px;
    color: #fff;
    background: #f36060;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    &:active {
      border: none !important;
      padding: 4px 6px;
      color: #fff !important;
      background: #f36060 !important;
      border-radius: 2px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .reset-btn {
    padding: 4px 6px;
    background: #fef2f2;
    border-radius: 2px;
    color: #f36060;
    border: none !important;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:active {
      padding: 4px 6px;
      background: #fef2f2 !important;
      border-radius: 2px;
      color: #f36060 !important;
      border: none !important;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      border: none !important;
    }
  }
  .settings-divider {
    border: 1px solid #ebedf1;
    width: auto;
  }
  .user-settings {
    padding: 0px 12px;
  }
  .tab-title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;

    /* Grayscale / Gray 100 */

    color: #0c0d11;
  }
  .goto-btn {
    cursor: pointer;
  }
  .date-status {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }

  .show-more-btn {
    background: #f6f8fb;
    border-radius: 2px;
    cursor: pointer;
    color: #616778;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
    padding: 4px 24px 4px 6px;
    position: relative;
  }
  .arrow-down:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 45%;
    right: 9%;
  }
  .arrow-up:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    bottom: 34%;
    right: 9%;
  }
}

.delete-user-modal {
  max-width: 400px;
  .modal-content {
    border-radius: 8px;
    max-height: 300px !important;
    height: auto;
  }
  .modal-footer {
    border-top: none;
    padding: 9px;
  }
  .permission {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c0d11;
    // text-align: center;
  }
  .delete-btn {
    background: #f36060;
    border-radius: 2px;
    padding: 4px 7px;
    color: #fff;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    &:active {
      border: none !important;
      background: #f36060 !important;
      color: #fff !important;
    }
  }
  .cancel-btn {
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    border: none;
    margin-right: 9px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 7px;
    &:active {
      background: #f6f8fb !important;
      border: none !important;
      color: #616778 !important;
    }
  }
  .user-avatar {
    object-fit: contain;
    width: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .user-name {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    display: flex;
    align-items: flex-end;
    letter-spacing: 0.02em;

    /* Grayscale / Gray 100 */

    color: #0c0d11;
  }
  .user-email {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
}

.invitation-modal {
  max-width: 480px !important;
  .modal-content {
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 8px;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    .close-icon {
      width: 11px;
      object-fit: contain;
    }
  }
  .label-input {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .link-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .field-wrapper {
    background: #f6f8fb;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border-radius: 3px;
  }
  .copy-link-btn {
    background: #008fd4;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 7px;
  }
  .item-wrapper {
    width: fit-content;
    margin: auto;
  }
  .email-input {
    border: none;
    background: transparent;
    width: 100%;
  }
  .email-field-wrapper {
    background: transparent;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border: 1px solid #ebedf1;
    border-radius: 4px;
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  .email-item-wrapper {
    .custom_drop-down .dropdown-toggle {
      background: white !important;
      border: 1px solid transparent;
    }
    width: 90%;
    margin: auto;
  }
  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
}
