// suggest modal

.suggestmodalresolvezoomissue {
  .modal-content {
    @media (max-width: 1500px) {
      zoom: 1 !important;
      height: 106vh !important;
    }
    @media (min-width: 1500px) and (max-width: 1700px) {
      zoom: 1 !important;
      height: 106vh !important;
    }
    @media (min-width: 1700px) and (max-width: 1800px) {
      zoom: 1 !important;
      height: 86vh !important;
    }
    @media (min-width: 1800px) and (max-width: 2000px) {
      zoom: 1 !important;
      height: 83vh !important;
    }
    @media (min-width: 2000px) and (max-width: 2700px) {
      zoom: 1 !important;
      height: 76vh !important;
    }
    @media (min-width: 2700px) and (max-width: 4000px) {
      zoom: 1 !important;
      height: 63vh !important;
    }
    @media (min-width: 4000px) and (max-width: 5000px) {
      zoom: 1 !important;
      height: 40vh !important;
    }
  }
}
