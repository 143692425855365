@use '../../../../breakpoints.scss' as *;

.technologies-ptractices {
  .data-container {
    background-color: #F6F8FB;
    border-radius: 8px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    @include respond-to(xl) {
      flex-direction: row;
    }
    .section {
      padding: 10px 0px;
      width: 100%;
      &:not(:last-child) {
        border-right: 1px solid #EBEDF1;
      }
      .section-header {
        padding: 0px 8px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
        border-bottom: 1px solid #EBEDF1;
      }
      @include respond-to(sm) {
        .spend-item-name {
          white-space: nowrap;
        }
      }
      .text {
        font-size: 14px;
        line-height: 22px;
        font-weight: 500;
      }
      .list {
        padding: 0px 8px;
        .list-item {
          padding: 3px 4px;
          border-radius: 4px;
          &:hover {
            background-color: #EBEDF1;
            .connected-tool {
              background-color: #D4D6DD;
            }
          }
          &.child {
            padding: 3px 0;
          }
        }
        .incomplete-missing-txt {
          font-size: 12px;
          line-height: 18px;
          font-weight: 400;
        }
        .status-value {
          font-size: 14px;
          line-height: 22px;
          font-weight: 400;
        }
      }
      .link-section {
        padding: 3px 4px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
    .right-section {
      padding: 2px 4px;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .chart-container {
        padding: 22px 16px;
      }
      .currency-container {
        .text {
          font-weight: 400 !important;
        }
      }
    }
  }
}
