@use '../../../breakpoints.scss' as *;

.company-settings-v2-wrapper {
  height: calc(100dvh - 56px);
  overflow-y: auto;
}

.company-settings-v2 {
  margin-top: 2.5rem !important;
  width: 100%;
  padding: 0 20px;
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    padding: 0;
    margin: auto;
  }
  .change-photo-btn {
    background-color: #f6f8fb;
    color: #616778;
    font-weight: 500;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    &:active {
      background-color: #f6f8fb !important;
      color: #616778 !important;
      border: none !important;
    }
  }
  .dropdown {
    button {
      word-break: break-word;
      white-space: pre-wrap;
    }
  }
  .remove-photo-btn {
    background-color: transparent;
    color: #616778;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    &:active {
      background-color: transparent !important;
      color: #616778 !important;
      border: none !important;
    }
  }
  .rounded-circle {
    width: 35px !important;
    height: 35px !important;
    @include respond-to(sm) {
      width: 70px !important;
      height: 70px !important;
    }
  }
  .user-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }
  .name-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
  }
  .name-label {
    color: #0c0d11;
    font-weight: 500;
  }
  .name {
    color: #616778;
    font-weight: 400;
  }
  .details-divider {
    border: 1px solid #ebedf1;
  }
  .details-arrow {
    margin: 0rem 1rem 0rem 1.5rem;
    cursor: pointer;
  }
  .role-arrow {
    margin: 0rem 1rem;
    cursor: pointer;
  }
  .security-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }
  .reset-password-wrapper {
    padding: 1rem;
  }
  .security-wrapper {
    margin-top: 1rem;
  }

  .language-text {
    .tooltipx {
      position: relative;
      display: inline-block;
    }

    .tooltipx .tooltiptext {
      visibility: hidden;
      width: 215px;
      background-color: black;
      color: #fff;
      text-align: start;
      border-radius: 6px;
      padding: 5px 8px;
      position: absolute;
      z-index: 1;
      top: 150%;
      margin-left: -60px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      right: 12%;
    }

    .tooltipx .tooltiptext::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 95%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }

    .tooltipx:hover .tooltiptext {
      visibility: visible;
    }
  }
  .custom_drop-down {
    button {
      text-align: end;
    }
  }
  .timezone-menu {
    width: 100% !important;
    @include respond-to(md) {
      width: 360px !important;
    }
  }

  .industry-drop {
    .tick {
      margin-top: 0px;
    }
    .custom_drop-down .dropdown_item.selected {
      background-color: rgba(0, 143, 212, 0);
    }
    .custom_drop-down .dropdown_item:hover {
      background-color: rgba(0, 143, 212, 0);
    }

    .selected-option {
      color: #616778;
      font-weight: 400;
    }
    .dropdown-menu.show {
      width: max-content;
      height: 320px;
      overflow-y: scroll;
    }
  }

  .employee-drop {
    .tick {
      margin-top: 0px;
    }
    .custom_drop-down .dropdown_item.selected {
      background-color: rgba(0, 143, 212, 0);
    }
    .custom_drop-down .dropdown_item:hover {
      background-color: rgba(0, 143, 212, 0);
    }

    .selected-option {
      color: #616778;
      font-weight: 400;
    }
    .dropdown-menu.show {
      width: max-content;
    }
  }

  .timezone-drop {
    .tick {
      margin-top: 0px;
      margin-left: 10px;
    }
    .custom_drop-down .dropdown_item.selected {
      background-color: rgba(0, 143, 212, 0);
    }
    .custom_drop-down .dropdown_item:hover {
      background-color: rgba(0, 143, 212, 0);
    }

    .selected-option {
      color: #616778;
      font-weight: 400;
    }
    .dropdown-menu.show {
      width: max-content;
    }
  }

  .profile-upload-btn {
    background: #008fd4;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
  }
}


.temporarySupportAccessModal {
  max-width: 100%;
  .modal-content {
    width: calc(100% - 16px);
    padding: 0px;
    border-radius: 8px;
    height: 614px; 
    @include respond-to(lg) {
      width: 800px;
    }
  }

  .modal-header {
    border-bottom: 1px solid #EBEDF1 !important;
  }

  .modal-body {
    padding: 0px !important;
    display: flex; 
    flex: 1; 
    overflow-y: auto; 
  }

  .border-bottom {
    border-bottom: 1px solid #EBEDF1 !important;
}

}