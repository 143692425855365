.priceChangedModal {

    .modal-content {
        width: 320px !important;
        height: 394px !important;
        // padding: 12px;
    }

    .modal-footer {
        padding: 0px;
        border: none !important;
    }

   
}

.changesModal {

    .modal-content {
        width: 340px !important;
        height: fit-content !important;
        // padding: 12px;
    }

    .modal-footer {
        padding: 0px;
        border: none !important;
    }

   
}