@use '../../breakpoints.scss' as *;

#create-task-modal-2 {
  height: auto;
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 3000; /* Sit on top */
  background: white;
  left: 0;
  right: 0;
  margin: auto;
  top: 25%;
  // width: 100%; /* Full width */
  // height: 100%; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  //   background-color: yellow; /* Fallback color */
  // background-color: white; /* Black w/ opacity */
}

/* Modal Content */
// .modal-content- {
//   background-color: #fefefe;
//   margin: auto;
//   padding: 20px;
//   border: 1px solid #888;
//   width: 80%;
// }

/* The Close Button */
// .close- {
//   color: #aaaaaa;
//   float: right;
//   font-size: 28px;
//   font-weight: bold;
// }

// .close:hover,
// .close:focus {
//   color: #000;
//   text-decoration: none;
//   cursor: pointer;
// }

.create-task-modal-2 {
  width: 100%;
  max-width: calc(100% - 32px);
  margin: 0 16px;
  @include respond-to(md) {
    max-width: 450px;
  }
  .dragger-icon {
    cursor: move;
  }
  .task-name {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #0c0d11;
    cursor: text;
    width: 100%;
    margin-right: 15px;
    padding: 3px 4px;
    &:hover {
      background: #ecedee;
      transition: all 0.6s;
      border-radius: 3px;
    }
  }

  .new-name {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #9499aa;
    cursor: text;
    width: 100%;
    margin-right: 15px;
    padding: 3px 4px;
    &:hover {
      background: #ecedee;
      transition: all 0.6s;
      border-radius: 3px;
    }
  }

  .divider-task {
    margin-bottom: 9px;
    margin-top: 9px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .submit-btn {
    padding: 5px 9px;
    border: none;
  }
  .border-right {
    border-right: 1px solid #ebedf1;
  }
  .delete-icon {
    margin-right: 15px;
  }
  .input-header {
    width: 100%;
    height: 30px;
    border-radius: 4px;
    border: none;
    margin-right: 2rem;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
  }

  border: 1px solid #ebedf1;
  padding: 0.2rem 1.3rem;
  box-shadow: 0px 0px 40px rgba(16, 16, 16, 0.08);
  border-radius: 8px;
  .input_custom_dropdown {
    .dropdown_input {
      height: 42px;
      font-size: 14px;
      font-family: "Archivo";
      font-style: normal;
      border: 2px solid #fff;
      border-radius: 4px;
      width: 100%;
      line-height: 22px;
      padding: 0px 8px;
      border: 0px;
      display: flex;
      align-items: center;
      color: #0c0d11;
      background-color: #fcfdfe;

      .crossimg {
        height: 9px;
        margin-left: 5px;
        cursor: pointer;
      }

      &:focus {
        border: 2px solid #99d2ee;
      }

      &:focus-within {
        border: 2px solid #99d2ee;
      }
    }
    .custombadge {
      border: 1px solid #d4d6dd;
      border-radius: 9999px;
      padding: 0px 7px 0px 2px;
      font-size: 12px;
      line-height: 23px;
      color: #616778;
      font-weight: 500;
    }
    .custombadge2 {
      color: #0c0d11;
      width: 100%;
      text-align: left;
      font-weight: 500;
      font-size: 14px;
      padding: 0px 7px 0px 2px;
      line-height: 23px;
    }

    .dropdown-toggle {
      width: 100%;
      border: 0px;
      background: transparent;
      &:after {
        display: none;
      }
    }

    .dropdown-menu {
      // &::-webkit-scrollbar {
      //   display: none;
      // }
      // overflow-y: auto;
      width: 100%;
      padding: 10px 0px;
      min-height: auto;
      // max-height: 311px;
      height: fit-content !important;
      transform: translate(16px, 57px) !important;

      .child_drop {
        padding: 5px 16px;
        cursor: pointer;

        &:hover {
          background: #f6f8fb;
          .hidden-dropdown {
            // display: block;
            visibility: visible;
          }
        }
      }
    }
  }
  .tableStatus {
    padding: 4px 9px;
    font-size: 12px;
    border-radius: 20px;
    display: inline-flex;
    align-items: center;
    line-height: 15px;

    .tablecircle {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 5px;
      &.archived {
        background-color: #616778 !important;
      }
    }

    &.archived {
      background-color: #ebedf1 !important;
      color: #616778 !important;
    }
  }
  .navtabinput {
    input {
      padding: 6px 12px;
      border-radius: 4px;
      border: 2px solid #99d2ee;
      font-family: Archivo, sans-serif;
      margin-right: 4px;
      background-color: #f6f8fb;

      &:focus {
        border: 2px solid #99d2ee;
      }
    }
  }
  .task-description {
    width: 100%;
    padding: 15px;
    outline: none;
    border: none;
    margin-top: 15px;
    border-radius: 4px;
    color: #0c0d11;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background: white;
    &::placeholder {
      color: #9499aa;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }

  .datepicker_cutom .custom_date_picker:before {
    content: "";
    background: url("../../../public/images/actioncenter/dateicon.svg");
    height: 15px;
    width: 16px;
    background-repeat: no-repeat;
    background-position: 2px -1px;
    position: relative;
    left: 4px;
  }

  .color-dropdown {
    .dropdown-toggle {
      &:active {
        background: transparent;
      }
    }
    button {
      padding: 0px;
    }
    .color-circle {
      width: 16.5px;
      height: 16.5px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .dropdown-menu.show {
      inset: auto 0px auto auto !important;
      transform: translate(0px, 0px) !important;
      // padding: 5px 15px;
      background: #ebedf1;
      width: 160px;
      border-radius: 8px;
    }
    .color-divider {
      // border: 1px solid #616778;
      width: 77%;
      margin: auto;
    }

    .color-wrapper {
      &:hover {
        background: #cecece40;
      }
      cursor: pointer;
      padding: 3px 13px;
    }

    .color-text {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.9);
    }
    .choose-color {
      padding: 3px 13px;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #9499aa;
    }
  }

  .hidden-dropdown {
    // display: none;
    visibility: hidden;
  }
}

#root .color-dropdown {
  .dropdown-toggle {
    &:active {
      background: transparent;
    }
  }
  button {
    padding: 0px;
  }
  .color-circle {
    width: 16.5px;
    height: 16.5px;
    border-radius: 50%;
    margin-right: 10px;
  }
  .dropdown-menu.show {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
    // padding: 5px 15px;
    background: #0c0d11;
    width: 160px;
    border-radius: 8px;
  }
  .color-divider {
    // border: 1px solid #616778;
    width: 77%;
    margin: auto;
  }

  .color-wrapper {
    &:hover {
      background: #cecece40;
    }
    cursor: pointer;
    padding: 3px 13px;
  }

  .color-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.64);
  }
  .choose-color {
    padding: 3px 13px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
}

.hidden-dropdown {
  // display: none;
  visibility: hidden;
}
