.integrationsmalltxt ,  .integrationsmalltxtstate{
    font-size: 12px;
    font-weight: 400;
}
.integrationsmalltxtstate{
    margin-left: 5px;
}
.clrblack{
  color:#101010;
}
.integrationBox {
  padding: 8px;
}
.integrationBox h3 {
  max-width: 100px;
}
.custom_tooltip .arrow {
  display: none !important;
}
.custom_tooltip_wrapper{
  padding:10px 5px;
  max-width:180px !important;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  font-family: Archivo;
}
.tooltip_subtitle{
  color: #B6B6BF;
  font-size: 12px;
  font-weight: normal;
  display: inline-flex;
  margin-top: 5px;
}
.tooltip_header{
  padding-bottom: 12px;
  font-style: normal;
  font-family: Archivo;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  border-bottom: 1px solid #B6B6BF;
}
.tooltip-inner{
  border-radius: 10px !important;
}
.icon-img {
  width: 24px;
  height: 24px;
  border: 0.5px solid #EBECF1;
  border-radius: 4px;
}
.circle img {
  border-radius: 50%;
  padding: 5px;
}