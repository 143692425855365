.fileUploadBox {
    background: #FFFFFF;
    border: 1px dashed #B6B6BF;
    box-sizing: border-box;
    border-radius: 4px;
    height: 243px;
    margin: 1em 0;
    padding-top: 2em;
    position: relative;
}

.fileUploadBox>* {
    margin: auto;
    display: flex;
    flex-flow: column;
    text-align: center;
    padding-bottom: .7em;
}

.fileUploadBox input[type='file'] {
    position: absolute;
    height: 100%;
    width: 100%;
    margin-top: -1em;
    opacity: 0;
    cursor: pointer;
}

.reusableModalForm .dropdown {
    min-height: 27px !important;
    padding: .1em !important;
}

.reusableModalForm .ui.fluid.dropdown {
    width: 100% !important;
}

.reusableModalForm .dropdown.icon {
    top: 40% !important;
}