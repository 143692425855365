// Quantity of Assets Modal

.qty-asset-modal {
  .reset-to-default {
    display: flex;
    margin-top: 9px;
    cursor: pointer;

    span {
      font-weight: 500;
    }

    img {
      object-fit: contain;
      margin-right: 5px;
    }
  }

  .font-bold-14 {
    font-size: 14px;
    font-weight: 500;
  }

  .font-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #9499aa;
    padding: 0.6rem 3rem 1rem 1rem;
  }

  .modal-header {
    padding: 22px 26px !important;
  }

  .modal-body {
    padding-top: 1.5rem;
  }

  .grey-select {
    border-radius: 4px !important;
    border: 1px solid #eeeff1 !important;
  }

  .inptLbl {
    background-color: #fff;
  }

  .form-control {
    background-color: #fff;
    padding: 0px !important;
  }

  input::placeholder {
    color: #b6b6bf !important;
  }

  .save-button {
    padding: 0.6rem 0.9rem !important;
    background: #008fd4 !important;
    font-weight: 500 !important;
    line-height: 1.1rem !important;
  }

  .cancel-button {
    background-color: #f6f8fb;
    border: none;
    color: #616778;
    margin-right: 9px;
    padding: 0.6rem 0.9rem !important;
    line-height: 1.1rem !important;
  }
}

//Questionaire Modal

.questionaireModal {
  .switch-shadow-none .custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
  }

  .font-bold-14 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 3px;
  }

  .modal-header {
    padding: 22px 26px !important;
  }

  .modal-body {
    padding-top: 1.5rem;
  }

  .radio-heading {
    font-weight: 600;
    margin-bottom: 3px;
  }

  .disabled {
    color: #9499aa !important;
  }

  .answered-question-detail {
    font-size: small;
    color: #9499aa;
    width: -webkit-fill-available;
  }

  .ques-type {
    cursor: pointer;
    position: relative;

    &:hover {
      background-color: #eeeff1;
      border-radius: 5px;
      transition: all 200ms ease-in-out;

      &:before,
      &:after {
        border-right: 2px solid;
        content: "";
        display: block;
        height: 8px;
        margin-top: -6px;
        position: absolute;
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);
        right: 5%;
        top: 50%;
        width: 0;
      }

      &:after {
        margin-top: -1px;
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
    }
  }

  .save-button {
    padding: 0.6rem 0.9rem !important;
    background: #008fd4;
    font-weight: 500 !important;
    line-height: 1.1rem !important;
  }

  .selected {
    background: #008fd4 !important;
    color: #fff !important;
  }

  .not-selected {
    background-color: #f6f8fb !important;
    border: none;
    color: #616778;
  }

  .yes-button {
    background: #008fd4;
    padding: 8px 15px;
  }

  .no-button {
    background-color: #f6f8fb;
    border: none;
    color: #616778;
    padding: 8px 15px;
  }

  .cancel-button {
    background-color: #f6f8fb;
    border: none;
    color: #616778;
    margin-right: 9px;
    padding: 0.6rem 0.9rem !important;
    line-height: 1.1rem !important;
  }

  .date-form {
    width: 40%;
  }

  input[type="date"] {
    background-color: #f6f8fb;
    border: none;
  }

  .font-500 {
    font-size: 14px;
    font-weight: 500;
  }

  .subtitle {
    color: #9499aa;
  }

  .scale-switch {
    transform: scale(1.8);

    label {
      cursor: pointer;
    }
  }

  .scale-radio {
    transform: scale(1.5);
    transform-origin: 10% 10%;
    margin-right: 5px;

    input {
      cursor: pointer;
    }
  }

  .change-button {
    cursor: pointer;
    color: #fff;
    font-weight: 500;
    background-color: #008fd4;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    border-radius: 5px;
    height: fit-content;
    width: auto;
  }

  .disabled-btn {
    // background-color: #eeeff1 !important;
    // color: #9499aa !important;
    background-color: #f6f8fb !important;
    color: #616778 !important;
    border: none;
    opacity: 1;
  }

  .incomplete-status {
    background-color: #d6edf8;
    padding: 6px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: 500;
    height: fit-content;
    margin-right: 10px;
    margin-top: 3px;
  }

  .load-icon {
    object-fit: contain;
    margin-right: 3px;
    margin-bottom: 6px;
  }

  .status {
    width: max-content;
    color: #008fd4;
  }

  .questionnarire-loader {
    .loader_wrapper {
      top: 0;
    }

    .loader {
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .change-ques-button {
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    right: 33px;
    top: 23px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    /* color: #616778; */
    width: auto;
    height: 32px;
    background: #008fd4;
    border-radius: 4px;
    color: white;
  }

  .capture-automatic {
    bottom: 20px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    right: 33px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    /* color: #616778; */
    width: auto;
    height: 32px;
    background: #008fd4;
    border-radius: 4px;
    color: white;
  }

  .circle-indicator {
    transform: rotate(-90deg);
  }
}

//Custom Toast
#root #custom-toastz {
  background-color: #0c0d11;
  color: #fff;
  padding: 1rem;
  display: flex !important;
  justify-content: space-between;
  border-radius: 5px;
  visibility: hidden;
  position: fixed;
  z-index: 2000;
  // left: calc(50% - 183px);
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 30px;
  // min-width: 350px;
  width: auto;
  max-width: 400px;

  .toast-message {
    // max-width: 80%;
    line-height: 1.6;
    margin-right: 1rem;
  }

  .undo-text {
    color: #008fd4;
    font-weight: 500;
    margin-right: 1rem;
    cursor: pointer;
  }

  .v2-undo-text {
    color: rgba(255, 255, 255, 0.64);
    font-weight: 500;
    margin-right: 1rem;
    cursor: pointer;
  }
}

.showw {
  visibility: visible !important;
  -webkit-animation: fadeInToast 0.5s, fadeOutToast 0.5s 2.5s;
  animation: fadeInToast 0.5s, fadeOutToast 0.5s 2.5s;
}

@-webkit-keyframes fadeInToast {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadeInToast {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutToast {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeOutToast {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

.pointer-cursor {
  cursor: pointer;
}

// Custom toast for Right side coming

//Custom Toast
#root #right-side-custom-toastz {
  background-color: #0c0d11;
  color: #fff;
  padding: 1rem;
  display: flex !important;
  justify-content: space-between;
  border-radius: 5px;
  visibility: hidden;
  position: fixed;
  z-index: 2000;
  right: 25px;
  top: 60px;
  max-width: 288px;

  .toast-message {
    max-width: 70%;
    line-height: 1.6;
    margin-right: 1rem;
  }

  .gift-message {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.64);
  }
}

.showFromRight {
  visibility: visible !important;
  -webkit-animation: fadeInFromRight 0.5s, fadeOutToRight 0.5s 5s;
  animation: fadeInFromRight 0.5s, fadeOutToRight 0.5s 5s;
}

@-webkit-keyframes fadeInFromRight {
  from {
    right: -40px;
    opacity: 0;
  }

  to {
    right: 25px;
    opacity: 1;
  }
}

@keyframes fadeInFromRight {
  from {
    right: -40px;
    opacity: 0;
  }

  to {
    right: 25px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeOutToRight {
  from {
    right: 25px;
    opacity: 1;
  }

  to {
    right: -40px;
    opacity: 0;
  }
}

@keyframes fadeOutToRight {
  from {
    right: 25px;
    opacity: 1;
  }

  to {
    right: -40px;
    opacity: 0;
  }
}