.support-ticket-v2-modal {
  .title {
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }

  .small-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }

  .modal-content {
    border-radius: 7px;
    // height: auto;
    width: 455px;
    height: 600px;
    zoom: 1 !important;
  }

  .modal-body {
    overflow-y: auto;
  }

  .modal-header {
    border-bottom: 1px solid lightgray !important;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .active-tab {
      font-weight: 500;
      font-size: 14px;
      color: #0c0d11;
    }

    .active-chat-tab {
      font-weight: 500;
      font-size: 14px;
      color: #008FD4;
    }

    .normal-tab {
      font-weight: 500;
      font-size: 14px;
      color: #9499aa;
    }
  }

  .knowledgebase-wrapper {
    cursor: pointer;
    background: #f6f8fb;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }

  .opened-list {
    padding: 1rem;

    &:hover {
      background: #f6f8fb;
      border-radius: 4px;
      cursor: pointer;
    }
  }

  .start-conversation-wrapper {
    background: #ffffff;
    padding: 0.92rem;
    border: 1px solid #ebedf1;
    border-radius: 4px;
  }

  .create-ticket-btn {
    background: #008fd4;
    border-radius: 2px;
    color: white;
    padding: 7px;
    border: none;

    &:active {
      border: none !important;
    }
  }

  .modal-footer {
    border-top: none;
    padding: 1rem ;
  }

  .avatar {
    margin-left: -11px;
    width: 24px;
    border-radius: 41px;
    object-fit: cover;
    border: 2px solid #ffffff;
    height: 24px;
  }

  .closed-tag {
    font-size: 8px;
    font-weight: 600;
    // display: inline-block;
    padding: 4px;
    background: #ebedf1;
    border-radius: 16px;
    width: 35px;
    height: 12px;
    margin-left: 7px;
  }

  .ticket-input {
    background: #f6f8fb;
    border: none;
    margin-bottom: 0.75rem !important;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #f6f8fb inset !important;
      box-shadow: 0 0 0 30px #f6f8fb inset !important;
    }
  }

  .message-typer {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 4px;

    input {
      border: none;
      background: #f6f8fb;
      flex-grow: 1;
      height: 25px;
    }

    button {
      padding: 7px 9px;
    }
  }

  .ticket-closed-btn {
    background: #f6f8fb;
    border-radius: 4px;
    width: 100%;
    border: none;
    color: #9499aa;
  }

  .submitted {
    background: #f6f8fb !important;
    border-radius: 4px;
    width: 100%;
    border: none !important;
    color: #9499aa !important;
  }

  @keyframes slideLgRightAnimation {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }

    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }

  @keyframes slideLgRightBackAnimation {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }

    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }
  }

  &.right-side-modal {
    overflow: auto;
    // height: 96vh;
    // right: 10.6em;
    // margin: 16px 0;
    animation-name: slideLgRightAnimation;
    animation-duration: 0.56s;
    animation-timing-function: ease;
    // max-width: 500px;
    position: fixed;

    right: 1em !important;
    bottom: 0;
  }

  &.rightBackModal {
    overflow: auto;
    // height: 96vh;
    // right: 10.6em;
    // margin: 16px 0;
    animation-name: slideLgRightBackAnimation;
    animation-duration: 0.56s;
    animation-timing-function: ease;
    // max-width: 500px;
    position: fixed;

    right: 1em !important;
    bottom: 0;



  }

  .unread {
    color: #008fd4;

    &:after {
      content: attr(data-count);
      position: absolute;
      width: 14px;
      height: 14px;
      color: #fff;
      font-size: 9px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f36060;
      border-radius: 50%;
      top: -13px;
      right: -8px;
    }
  }

  .chat-search {
    border: none;
    width: 100%;
  }

  .chat-list-item {
    &:hover {
      background: #F6F8FB;

    }
  }

  .media-present{
    position: relative;
    &::after{
      position: absolute;
      content: "";
      width: 5px;
      height: 5px;
      border-radius: 9999px;
      background: #008FD4;
      top: 0.1rem;
      right: 0.2rem;
    }

  }
}

.ticket-message {
  margin-top: 1rem;

  .message-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 999px;
  }

  .title {
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }

  .small-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }

  .message-text {
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
    padding-left: 2.8rem;
    padding-right: 2rem;
    padding-top: 0.5rem;
  }

  .message-image-wrapper {
    cursor: pointer;
    max-width: 100%;
    padding-left: 2.8rem;
    padding-right: 2rem;
    padding-top: 0.5rem;

    img {
      max-width: 100%;
      object-fit: contain;
      max-height: 230px;
      border: 1px solid #ebedf1;
      border-radius: 4px;
      -webkit-box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 0.1);
      box-shadow: 0px 0px 24px -9px rgba(0, 0, 0, 0.1);
    }

    .message-image-container:hover {
      .overlay {
        opacity: 1;
      }
    }

    .overlay {
      position: absolute;
      right: 5px;
      bottom: 5px;
      opacity: 0;
      transition: all 0.5s;
    }
  }

  .message-file-wrapper {
    cursor: pointer;
    max-width: 70%;
    border: 1px solid #ebedf1;
    border-radius: 4px;
    position: relative;
    margin-left: 2.8rem;
    margin-right: 2rem;
    margin-top: 0.5rem;
    padding: 0.5rem;

    &:hover {
      .overlay {
        opacity: 1;
      }
    }

    .overlay {
      position: absolute;
      right: 5px;
      bottom: 0;
      top: 0;
      margin: auto;
      opacity: 0;
      transition: all 0.5s;
    }
  }
}