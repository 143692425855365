@use '../../../breakpoints.scss' as *;

.add-tool-to-sec-stack-modal {
  &.prompt {
    .modal-content{
      height: 240px;
    }
    .modal-dialog {
      width: 300px;
    }
  }
  .modal-dialog {
    width: fit-content;
  }
  .modal-content{
    height: fit-content;
  }
  .modal-body {
    max-width: 100%;
    @include respond-to(md) {
      max-width: 320px;
    }
    overflow-y: auto;
    padding: 16px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .title {
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
    }
    .description {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
    .technologies-section {
      padding: 4px 8px;
      border-radius: 4px;
      background-color: #F6F8FB;
      display: flex;
      flex-direction: column;
      gap: 4px;
      
    }
    .checkbox {
      input:checked ~ label:before {
        background-color: #008FD4 !important;
        border: transparent !important;
      }
      input:checked ~ label:after {
        color: #fff !important;
        scale: 0.8;
      }
    }
  }
  .modal-footer {
    padding: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    border: 0px solid transparent;
    .btn {
      padding: 5px 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
    }
    .no-btn {
      background: #F6F8FB;
    }
    .add-btn {
      background: #008FD4;
      color: #FFFFFF;
      &:disabled {
        background: #EBEDF1;
        color: #9499AA;
      }
    }
  }
}