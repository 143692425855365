.security-ratings-wrapper {
    .table-grid {
        display: grid;
        grid-template-columns: 20% 16% 15% 3%;
    }
    .table-grid-template {
      display: grid;
      grid-template-columns: 40% 16% 15%;
  }
    .highground-datpicker:focus-within {
        border: 2px solid #99D2EE00 !important;
        width: -webkit-fill-available;
        background: transparent !important;
        border-radius: 4px;
    }
    .edit-task {
        border: 1px solid transparent;
        border-radius: 3px;
        padding: 3px;
        white-space: pre-wrap;
        resize: none;
        height: max-content;
        background-color: #fff;
      }
      .edit-title:focus-visible, .edit-title:focus{
        border-radius: 4px;
        border:
          2px solid var(--primary-primary-10, #99D2EE);
        background: #F6F8FB;
        // padding: 4px;
      }
}