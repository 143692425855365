$slide-count: 5;
$slide-width: 10rem;

ul {
    list-style-type: none;
    padding: 0;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  .carousel__wrap {
      margin-top: 3%;
      align-items: start;
      display: flex;
    //   flex: 1;
      justify-content: center;
      position: relative;
    //   width: 80%;
      left: 50%;
      transform: translateX(-50%);
  }
  
  .carousel__inner {
      height: 30rem;
      position: relative;
      width: calc(#{$slide-width * 3});
  }
  
  .carousel__container {
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
  }
  
  .carousel__slide-list {
      height: 100%;
      left: 50%;
      list-style-type: none;
      margin: 0;
      padding: 0;
      position: absolute;
      transform: translateX(-50%);
      width: calc(#{($slide-count + 0.5) * $slide-width * 2});
  }
  
  .carousel__slide-item {
      display: inline-block;
      height: $slide-width;
      margin: 0;
      padding: 1rem;
      position: absolute;
      transition: all 0.3s;
      width: $slide-width;
  }
  
  .carousel__slide-item-img-link {
      cursor: zoom-in;
      display: flex;
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
  
      img {
          height: 100%;
          object-fit: cover;
          transition: all 0.5s ease;
          width: 100%;
      }
  
      &::after {
          align-items: center;
        //   background: rgba(black, 0.5);
          color: white;
          content: 'read more';
          display: flex;
          height: 100%;
          justify-content: center;
          opacity: 0;
          position: absolute;
          transition: all 0.5s ease;
          width: 100%;
      }
  
      &:hover {
          &::after {
              opacity: 1;
          }
  
          img {
              transform: scale(1.3);
          }
      }
  }
  
  .carousel-slide-item__body {
      bottom: -2.5rem;
      height: 10%;
      position: absolute;
  
      h4 {
          margin: 0.7rem 0 0;
          text-transform: uppercase;
      }
  
      p {
          font-size: 1.2rem;
          line-height: 1.3;
          margin: 0.7rem 0 0;
      }
  }
  
  .carousel__btn {
      align-items: center;
      background: 0;
      border: 0;
      cursor: pointer;
      display: flex;
      justify-content: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
  
      &--prev {
          left: -10rem;
      }
  
      &--next {
          right: -10rem;
      }
  }
  
  .carousel__btn-arrow {
      border: solid black;
      border-width: 0 0.4rem 0.4rem 0;
      height: 6rem;
      padding: 3px;
      width: 6rem;
      z-index: 10;
  
      &--left {
          transform: rotate(135deg);
      }
  
      &--right {
          transform: rotate(-45deg);
      }
  }
  
  .carousel__dots {
      display: inline-block;
      left: 50%;
      margin-top: 2rem;
      position: absolute;
      transform: translateX(-50%);
      top: 23.5rem;
      position: absolute;
  
      .dot {
        position: relative   !important  ;
          background: #BBBEC9;
          border: 0;
          border-radius: 50%;
          cursor: pointer;
          height: 1.5rem;
          margin: 0 0.1rem;
          outline: none;
          transform: scale(0.5);
          width: 1.5rem;
  
          &.active {
              background: #008FD4;
          }
      }
  }
  