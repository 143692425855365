.messageTop {
  height: 10%;
}

.messageBody {
  height: 90%;
  overflow: auto;
}

.messageBottom {
  height: 10%;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
}

.messageContainer {
  background: #f6f8fb;
  border-radius: 8px;
  height: 827px;
  padding: 1.5em;
}

.messageHeading {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
}

.messageHeading span > * {
  display: inline-block;
  padding: 0 0.2em;
  color: #101010;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
}

.messageHeading span p:last-child {
  color: #b6b6bf;
}

.message {
  padding-bottom: 2.5em;
}

.messageDate {
  display: flex;
  justify-content: center;
}

.messageDate p {
  text-align: center;
  color: #b6b6bf;
  padding: 0.35em 0.5em;
  font-size: 0.85em;
  background: #ebecf1;
  border-radius: 4px;
}

.messageMessage {
  padding-top: 1em;
}

.messageUserInfo {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
}

.messageUserInfo span > * {
  display: inline-block;
}

.messageUserInfo span > *:nth-child(n + 2) {
  padding-left: 0.7em;
}

.messageUserInfo .messageTime {
  color: #b6b6bf;
  font-size: 0.9em;
}

.messageUserInfo .userName {
  color: #101010;
}

.messageUserInfo .userType {
  color: #b6b6bf;
  font-size: 0.85em;
  font-weight: 300;
}

.messageMessage {
  line-height: 1.4em;
  color: #101010;
  font-weight: 500;
}

/*  bottom input */

.messageInput > * {
  display: inline-block;
}

.messageInput {
  padding: 0.5em;
  width: 100%;
  background: #ffffff;
  /* Light garay */
  border: 1px solid #ebecf1;
  border-radius: 4px;
}

.messageInput input {
  border: none;
  padding-left: 1em;
  /* width: 73%; */
  height: 100%;
}

.messageInput input::placeholder {
  color: #b6b6bf;
}

.messageInput button {
  background-color: #008fd4;
  padding-left: 1em;
  padding-right: 1em;
  border-radius: 4px;
}

.fileIcon {
  position: relative;
  font-size: 1.2em;
  width: 4%;
  height: 100%;
}

.fileIcon input {
  position: absolute;
  /* display: none; */
  padding: 0;
  opacity: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  overflow-y: hidden;
}
.bgIRBtn {
  background-color: #ebecf1 !important;
  float: right!important;
}
