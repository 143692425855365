@use '../../../breakpoints.scss' as *;

.attack-networks-wrapper {
  max-width: calc(100% - 36px);
  margin: 0 16px;
  @include respond-to(md) {
    max-width: 70%;
    margin: auto;
  }
}
