@use '../../../breakpoints.scss' as *;

.personal-profile-settings-v2 {
  margin-top: 2.5rem !important;
  width: calc(100% - 40px);
  max-width: 100%;
  @include respond-to(sm) {
    margin: 0 20px;
  }
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    margin: auto;
  }

  @media only screen and (max-width: 500px) {
    max-width: 95%;

    .user-email-Id {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 10px;
    }

    .account-delete-wrapper {
      flex-direction: column;
      align-items: flex-start !important;

      .delete-input {
        justify-content: space-between;
        width: 100%;
      }
    }
  }

  .rounded-circle {
    width: 35px !important;
    height: 35px !important;
    @include respond-to(sm) {
      width: 70px !important;
      height: 70px !important;
    }
  }

  .change-photo-btn {
    font-family: "Archivo";
    font-style: normal;
    background-color: #f6f8fb;
    color: #616778;
    font-weight: 500;
    border: none;

    &:active {
      background-color: #f6f8fb !important;
      color: #616778 !important;

      border: none !important;
    }
  }

  .profile-upload-btn {
    background: #008fd4;
    border-radius: 2px;
    font-weight: 500;
    font-size: 14px;
  }

  .remove-photo-btn {
    font-family: "Archivo";
    font-style: normal;
    background-color: transparent;
    color: #616778;
    border: none;

    &:active {
      background-color: transparent !important;
      color: #616778 !important;
      border: none !important;
    }
  }

  .user-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }

  .name-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
  }

  .name-label {
    color: #0c0d11;
    font-weight: 500;
  }

  .name {
    color: #616778;
    font-weight: 400;
  }

  .details-divider {
    border: 1px solid #ebedf1;
  }

  .details-arrow {
    margin: 0rem 1rem 0rem 1.5rem;
    cursor: pointer;
  }

  .role-arrow {
    margin: 0rem 1rem;
    cursor: pointer;
  }

  .security-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }

  .reset-password-wrapper {
    flex-direction: column;
    align-items: flex-start;
    @include respond-to(sm) {
      flex-direction: row;
      align-items: center;
    }
    padding: 1rem;
  }

  .security-wrapper {
    margin-top: 1rem;
    .security-btn {
      width: 100%;
      @include respond-to(sm) {
        min-width: 70px;
        max-width: 70px;
      }
    }
  }

  .role-drop {
    .dropdown-menu.show {
      width: 215px;
    }
  }

  .code-input {
    height: 0px;
    overflow: hidden;
    transition: height 1s;
  }

  .code-input.open {
    height: 100%;
    transition: height 1s;
    // overflow: hidden;
    // transition: height .3s ;
  }

}

.security-wrapper {

  .device-dropdown button {
    background: transparent;
    border: none;
  
    &::after {
      display: none;
    }
  }
  
  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
  }
  
  .show {
    .dropdown-toggle {
      border: none;
    }
  }
  
  .dropdown-menu.show {
    box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
    border-radius: 4px;
    transform: translate(0px, 0px) !important;
    inset: auto auto auto 0px !important;
    width: 224px;
  }
  
  .notification-btn {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.64);
    padding: 4px 13px;
  }
  
  .remove-btn {
    cursor: pointer;
    color: #f36060;
    padding: 5px 10px 5px 13px;
  }
}

.userName {
  /* Titles / Title 3 */

  font-family: "Archivo";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #0c0d11;
}
.userEmail {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #9499aa;
}

