.add-member-modal {
  &.prompt {
    .modal-content{
      height: 240px;
    }
    .modal-dialog {
      width: 300px;
    }
  }
  .modal-dialog {
    width: 480px;
  }
  .modal-content{
    height: 640px;
  }
  .modal-header {
    padding: 7px 12px !important;
    h3 {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.02em;
    }
  }
  .modal-body {
    overflow-y: auto;
    padding: 8px !important;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .search-wrapper {
      padding: 4px;
      border: 1px solid #EBEDF1;
      border-radius: 4px;
      input {
        padding: 1px 4px;
        border: none;
        outline: none;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #616778;
        &::placeholder {
          color: #9499AA;
        }
      }
    }
    .invited-tag {
      padding: 3px 10px;
      border: 1px solid #616778;
      border-radius: 9999px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #616778;
      .resend-txt {
        display: none;
      }
      &:hover {
        .invited-txt {
          display: none;
        }
        .resend-txt {
          display: block;
        }
      }
    }
  }
}