.row-ordering{
  padding: 4px 0;
  .section-title, .section-item  {
    padding: 4px 12px;
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
  }
  .section-item {
    background-color: #fff;
    &.disabled {
      display: none !important;
    }
  }
}
