@use '../../../../breakpoints.scss' as *;

.dotsMenu {
    margin-right:  20px;
    button {
        color: transparent !important;
        background-color: transparent !important;
        border-color: transparent !important;
    }
    width: 32px !important;
    height: 32px !important;

    .dropdown-menu.show {
        width: 200px;
        margin-right: 20px;
    }
}

.hg-transparent-btn {
    padding: 6px 8px !important;
    background: transparent;
    border: none;
    font-weight: 500;
    color: #fff;
  
    &:hover {
      background: transparent !important;
      color: #fff;
    }
    &:active {
      background: transparent !important;
      color: #fff;
    }
  }

.audit-modal-wrapper {
  width: calc(100% - 16px);
  max-width: calc(100% - 16px);
  .height_block_scroll_left {
    min-width: 100px;
  }
  .modal-content {
    width: calc(100% - 16px);
    max-width: calc(100% - 16px);
    margin: 0 8px;
    height: fit-content;
  }
  .audit-modal-body {
    width: 100%;
    padding: 12px;
    height: 500px;
  }
  .audit-details-item {
    display: grid;
    grid-template-columns: 1fr;
  }
  .audit-details-wrapper {
    gap: 12px;
  }
  @include respond-to(md) {
    .audit-modal-body {
      width: 500px;
    }
    .audit-details-item {
      grid-template-columns: 20% 80%;
    }
    .audit-details-wrapper {
      gap: 8px;
    }
  }
  @include respond-to(lg) {
    .modal-content {
      width: 800px;
    }
    .audit-modal-body {
      width: 500px;
      padding: 20px;
    }
    .height_block_scroll_left {
      min-width: 200px;
    }
  }
 
  @include respond-to(xl) {
    .modal-content {
      width: 1200px;
    }
    .audit-modal-body {
      width: 640px;
      padding: 30px;
    }
  }
}

.audit-header-container {
  .tabs-list {
    display: none;
  }
  .tab-list-item {
    display: flex;
    align-items: center;
    gap: 8px;
    .arrow-btn {
      padding: 4px;
      &.hide {
        opacity: 0;
        pointer-events: none;
      }
      &.reverse {
        transform: rotate(180deg);
      }
    }
  }
  @include respond-to(xl) {
    .tabs-list {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 8px;
    }
    .tab-list-item {
      display: none;
    }
  }
}

.audit-header-container {
  display: grid;
  grid-template-areas: 
    "top top2"
    "footer footer";
  padding: 8px 4px;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  gap: 8px;
  .footer-item {
    grid-area: footer;
    border-top: 1px solid #ffffff14;
    padding-top: 8px;
  }
  // .tabs-list {
  //   display: flex;
  //   align-items: center;
  //   flex-wrap: wrap;
  //   justify-content: flex-start;
  //   gap: 8px;
  // }
  // @include respond-to(md) {
  //   .tabs-list {
  //     justify-content: center;
  //   }
  // }
  @include respond-to(lg) {
    grid-template-columns: auto auto auto;
    gap: 12px;
    grid-template-areas: "top footer top2";
    grid-template-rows: auto;
    .footer-item {
      border-top: none;
      padding-top: 0;
    }
  }
}

.audit-summary-container {
  width: calc(100% - 32px);
  margin: 0 16px;
  @include respond-to(md) {
    width: 640px;
    margin: 0 auto;
  }
  .audit-details-item {
    display: grid;
    grid-template-columns: 1fr;
  }
  .audit-details-wrapper {
    gap: 12px;
  }
  @include respond-to(md) {
    .audit-details-item {
      grid-template-columns: 20% 80%;
    }
    .audit-details-wrapper {
      gap: 8px;
    }
  }
}