.export-client-left-section {
  .draggable-container {
    position: relative;
    background-color: #fff;
    &:hover > .grapper {
      display: block;
    }
    .grapper {
      position: absolute;
      top: 14px;
      left: 7px;
      display: none;
      &.is-dragging {
        display: block;
      }
    }
    .section {
      padding: 14px 24px;
    }
  }
  .header {
    padding: 14px 26px;
  }
  .switch {
    transform: scale(0.8);
  }
}