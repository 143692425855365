.subscription-overview{
    .account-chip{ 
        padding: 10px 40px 10px 10px;
        border-radius: 4px;
        border: 1px solid #EBEDF1;
        .title{
            color:#616778;
            font-weight: 500;
        }
        .subtitle{
            font-size: 12px;
            color: #9499AA;
        }


     &.selected{
        border: 1px solid #008FD4;
        .title{
            color:#008FD4;
            font-weight: 500;
        }
        .subtitle{
            font-size: 12px;
            color: #9499AA;
        }
     }   
    }

    progress {
        vertical-align: middle;
        border-radius: 7px;
        width: 100%;
        height: 5px;
      
        box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
      }
      
      progress::-webkit-progress-bar {
        background-color: transparent;
        border-radius: 7px;
      }
      
      progress::-webkit-progress-value {
        background-color: #008fd4;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
      }
}