.parentContainer:nth-child(odd) {
    background-color: #f6fbf8;
}


.audit-view-modal {
    .modal-content {
        max-height: 500px !important;
    }

    .modal-header {

        font-size: 16px;
        font-weight: 600;
        border-bottom: 1px solid #f6fbf8 !important;
    }
}

#scrollable-table {

    .custom-collapse-table {
        padding: 0px 33px 24px 33px;

        .custom-accordian-wrapper {
            display: none !important;
        }
    }

    .filterdropd-wn {
        .custom_drop-down .dropdown_item:hover {
            background: transparent !important;
        }

        .custom_drop-down .dropdown_item {
            background: transparent !important;

            &.selected {
                background: transparent !important;
            }
        }


        .custom_drop-down .dropdown-menu.show {
            width: max-content;


        }
    }
}