.threshold-slider {
  input[type="range"] {
    cursor: pointer;
    width: 100%;
    height: 11px;
    -webkit-appearance: none;

    outline: none;
    border-radius: 50px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: transparent;
    background-image: url("../../../public/images/knob.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    border: 1px solid transparent;

    // border-radius: 4px;
  }
  .normal {
    color: #b6b6bf;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
  .active {
    color: #008fd4;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
}
// senstivity slider

.senstivity-slider {
  input[type="range"] {
    cursor: pointer;
    width: 100%;
    height: 4px;
    -webkit-appearance: none;
    // background: #d8d8d8;
    background: linear-gradient(
      to right,
      #008fd4 0%,
      #008fd4 1%,
      #d8d8d8 1%,
      #d8d8d8 24%,
      #008fd4 24%,
      #008fd4 25%,
      #d8d8d8 25%,
      #d8d8d8 49%,
      #008fd4 49%,
      #008fd4 50%,
      #d8d8d8 50%,
      #d8d8d8 74%,
      #008fd4 74%,
      #008fd4 75%,
      #d8d8d8 75%,
      #d8d8d8 99%,
      #008fd4 99%,
      #008fd4 100%,
      #d8d8d8 100%
    );
    outline: none;
    border-radius: 50px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
  input[type="range"]::-webkit-slider-thumb {
    z-index: 10;
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #f36060;
    // &::after {
    //   content: "||";
    //   color: black;
    //   z-index: 5;
    //   width: 24px;
    //   height: 24px;
    // }
    background: #e9e9e9;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    // border: 1px solid transparent;

    // border-radius: 4px;
  }

  .red {
    color: #f36060 !important;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
  .normal {
    color: #b6b6bf;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
  .green {
    color: #40d88e !important;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
  .medium {
    color: #008fd4 !important;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }

  &.low {
    input[type="range"]::-webkit-slider-thumb {
      background-image: url("../../../public/images/low.svg");
    }
  }

  &.medium {
    input[type="range"]::-webkit-slider-thumb {
      background-image: url("../../../public/images/medium.svg");
    }
  }

  &.high {
    input[type="range"]::-webkit-slider-thumb {
      background-image: url("../../../public/images/high.svg");
    }
  }

  .dot {
    width: 8px;
    height: 8px;
    background: #008fd4;
  }
  .dots {
    display: flex;
    justify-content: space-between;
  }

  .normal {
    color: #b6b6bf;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
  .active {
    color: #008fd4;
    font-size: 12px;
    font-family: Archivo;
    font-style: normal;
  }
}
