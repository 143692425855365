.sources-settings {
  position: relative;
  .menu {
    position: fixed !important;
    padding: 8px 0px;
    min-width: 240px;
    border: 1px solid #EBEDF1;
    box-shadow: 0px 12px 24px 0px #10101014;
    background-color: #fff;
    max-height: 340px;
    overflow-y: scroll;
    .title {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }
    .item {
      cursor: pointer;
      .text {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
      }
      .arrow-icon {
        transform: rotate(-90deg);
      }
      .switcher {
        transform: translateX(8px);
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }
    .title, .item {
      padding: 4px 16px;
    }
  }
}