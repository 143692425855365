.supply-chain-pop-up {
    .dropdown-toggle {
        background: transparent !important;
        border: none !important;
        padding: 0px;
    }

    .dropdown-menu.show {
        padding: 0px;
        min-width: 300px;
        width: auto;

        header {
            height: 28px;
        }
    }
}