@use '../../../breakpoints.scss' as *;

.membersAccess-wrapper {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;

  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;

    letter-spacing: 0.02em;

    color: #0c0d11;
  }

  .mb-24px {
    margin-bottom: 24px;
  }

  .add-member-btn {
    background: #008fd4;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    border: none;
  }

  .disable-btn {
    background: #ebecf1;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #b6b6bf;
    border: none;
  }

  .py-4px {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  table {
    margin-top: 12px;

    .grid-container {
      display: grid;
      grid-template-columns: 60% 40%;
    }

    tr {
      th {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        letter-spacing: 0.02em;
        color: #616778;
      }
    }

    .table-data {
      padding: 1rem;
    }

    .user-name {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.02em;
      color: #0c0d11;
    }

    .user-email {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;

      display: flex;
      align-items: flex-end;
      letter-spacing: 0.02em;
      color: #616778;
    }

    .dots.icon {
      margin-left: 3rem;
      cursor: pointer;
    }


    .user-detail-dropdown {
      .device-dropdown button {
        background: transparent;
        border: none;

        &::after {
          display: none;
        }
      }

      .dropdown-toggle {
        background: transparent !important;
        border: none !important;
      }

      .show {
        .dropdown-toggle {
          border: none;
        }
      }

      .dropdown-menu.show {
        box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
        border-radius: 4px;
        width: 234px !important;
      }

      .notification-btn {
        cursor: pointer;
        color: rgba(255, 255, 255, 0.64);
        padding: 4px 13px;
      }

      .remove-btn {
        cursor: pointer;
        color: #f36060;
        padding: 5px 10px 5px 13px;
      }
    }
  }
}

.memberAccessModal {
  .m-24px {
    margin: 24px 24px 0 24px;
  }

  .mt-24px {
    margin-top: 24px;
  }

  .mt-16px {
    margin-top: 16px;
  }

  .mt-14px {
    margin-top: 14px;
  }

  .w-12 {
    width: 12px;
  }

  .msp-service-tool-tip-parent {
    position: relative;

    .msp-service-tool-tip-content {
      position: absolute;
      display: none;
    }

    &:hover {
      .msp-service-tool-tip-content {
        display: flex !important;
        flex-direction: column !important;
        z-index: 555;
        box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
        top: -100px;
        left: 23px;
      }
    }
  }
}

.members-multiselect-dropdown {


  .hide-focus-outline {
    &:focus-visible {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }


  &.show {
    .dropdown-toggle {
      width: -webkit-fill-available;
      background: #f6f8fb !important;

      input {
        background: transparent !important;
      }

      // img {
      //     margin-left: 5px;
      // }
    }
  }

  .dropdown-toggle:focus-within {
    width: -webkit-fill-available;
    background: #f6f8fb !important;
  }


  .dropdown-toggle {
    input {
      background: transparent !important;
    }

    border: 2px solid transparent !important;

    &::after {
      display: none;
    }

    height: auto;
    min-height: 32px;
    border-radius: 4px;
    padding: 3px;
    padding-left: 5px;
    background: transparent !important;
    border-color: transparent
  }

  .dropdown-menu.show {
    transform: translate(0px, 0px) !important;
    inset: auto 0px auto 0px !important;
  }

  .dropdown-toggle {
    &:focus-within {
      .typing-input {
        display: flex !important;
      }

      .selected-value {
        display: none !important;
      }
    }

    .typing-input {
      display: none;
    }

    .selected-value {
      display: flex;
    }
  }
}

.defaultRole {
  background-color: #EBEDF1 !important;
  height: 24px;
  width: 92px;
  // width: 64px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  color: #616778 !important;
  font-weight: 500;
  size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
}

.horisontalDotsButton {
  border: none;
  background-color: transparent;
  fill: #616778;
  height: 32px;
  width: 32px;
  margin-left: 8px;

  &:focus {
    border: none !important;
  }
}

.self-end {
  justify-self: end;
}

.roleMenuDropdown {
  .device-dropdown button {
    background: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  .roleMenuDropdownToggle {
    display: flex;
    background: transparent !important;
    border: none !important;
    align-items: center;

    &:focus {
      border: none !important;
    }

  }

  .roleMenuDropdownToggle.show {
    border: none !important;
  }

  .dropdown-menu.show {
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 4px !important;
    margin-left: -80px !important;
    background: #0C0D11;
    color: white;
    box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
    border-radius: 4px;
    width: 120px !important;
  }

  .roleDropdownItem {
    display: flex;
    align-items: center;
    line-height: 22px;
    size: 14px;
    font-weight: 400;
    height: 30px;
    padding-left: 12px;

    .lockerImage {
      height: 13px;
      margin-right: 4px;
    }
  }
}

.duplicatingRoleBlock {
  padding: 4px;
  display: flex;
  align-items: center;
  height: min-content !important;
  td {
    height: min-content !important;
    align-items: center;
  }
}

.imgLoader {
  height: 24px;
  width: 24px;
  margin-right: 8px;
  animation: rotate 4s linear infinite; 
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.roleDuplicateContainer {
  display: flex;
  align-items: center;

}

.paginationButtonContainer {
  display: flex !important;
  justify-content: space-between;

  button {
    margin-left: 0px !important;
  }
}

.addMemberTextBlock {
  display: flex;
  padding-left: 10px;
  height: 38px;
  align-items: center;
  
  p {
    color: #616778;
    font-weight: 400;
    size: 14px;
    line-height: 22px;
    letter-spacing: 2%;
  }
}

.addEmailImgWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  background-color: #EBEDF1;
  color: #9499AA;

  img{
    margin: 0px !important;
  }
}

.addEmailTextWrapper {
  height: 38px;
  padding-left: 6px;

  p {
    margin: 0px !important;
    color: #616778;
    font-weight: 400;
    size: 14px;
    line-height: 22px;
    letter-spacing: 2%;
  }

  .emailP {
    margin: 0px !important;
    color: #0C0D11;
    font-weight: 400;
    size: 14px;
    line-height: 22px;
    letter-spacing: 2%;
  }
}

.addMemberAddEmail {
  display: flex !important;
  align-items: center;
  padding-left: 10px;

}

.changeMemberPhotoButton {
  height: 32px;
  border-radius: 2xp;
  background-color: #F6F8FB;
  color: #616778;
  font-weight: 500;
  size: 14px;
  line-height: 22px;
  border-color: transparent;
  text-align: start;
  vertical-align: auto;
  margin-right: 6px;

  &:hover, &:active, &:focus {
    background-color: #EBEDF1 !important;
    color: #0C0D11 !important;
    border-color: transparent !important;
  }
}

.removeMemberPhotoButton {
  height: 32px;
  border-radius: 2xp;
  background-color: transparent;
  color: #616778;
  font-weight: 500;
  size: 14px;
  line-height: 22px;
  border-color: transparent;
  text-align: start;
  vertical-align: auto;

  &:hover, &:active, &:focus {
    background-color: #F6F8FB !important;
    color: #0C0D11 !important;
    border-color: transparent !important;
  }
}

.logoutAllMemberDevicesModal-container {
  .modal-content {
    width: 320px !important;
    height: 170px !important;
  }
}

.logoutAllMemberDevicesModal-header {
  padding: 16px;
  padding-bottom: 0px !important;
}

.logoutAllMemberDevicesModal-body {
  display: flex;
  align-items: center;
}

.logoutAllMemberDevicesModal-footer {
  width: 320px;
  height: 56px;
  border-top: none !important;
}

.modalTitle {
  width: 290px;
  height: 28px;
  font-weight: 500;
  size: 16px;
  line-height: 28px;
  color: #0C0D11;
}

.modalContent {
  padding-right: 10px;
  padding-left: 10px;
  height: 44px;
  font-weight: 400;
  size: 14px;
  line-height: 22px;
  color: #0C0D11;
}

.modalCancelButton {
  height: 32px;
  width: 64px;
  background-color: #F6F8FB !important;
  border-radius: 2px;
  padding: 4px !important;
  font-weight: 500;
  size: 14px;
  line-height: 22px;
  color: #616778;
  margin-right: 6px;
  border: none;

  &:hover {
    background-color: #F6F8FB;
    color: #0C0D11;
    border-color: none;
  }
}

.modalLogoutButton {
  height: 32px;
  width: 64px;
  background-color: #F36060 !important;
  border-radius: 2px;
  padding: 4px !important;
  font-weight: 500;
  size: 14px;
  line-height: 22px;
  color: #FFFFFF;
  border: none;
  margin-right: 10px;

  &:hover {
    background-color: #B2494A;
    color: #FFFFFF;
    border-color: none;
  }
}

.preview-access-modal {
  max-width: 100%;
}

.previewAccessModal-container {
  .modal-content {
    width: calc(100% - 16px);
    @include respond-to(md) {
      width: 480px !important;
    }
    height: 630px !important;
  }

  .modal-body {
    width: 100%;
    @include respond-to(md) {
      width: 480px !important;
    }
    height: 578px !important;
    padding: 24px !important;
  }

  .modalContent {
    padding: 0px !important;
  }
}

.previewAccessModal-header {
  height: 52px !important;
  display: flex;
  justify-items: end;
  border-bottom: 1px solid #ebedf1 !important;
}

.previewAccessModal-footer {
  width: 480px;
  height: 56px;
  border-top: none !important;
}

.previewAccessModalTitle {
  align-items: center;
  text-align: center;
  height: 32px;
  width: 100%;
  font-weight: 500;
  size: 14px;
  line-height: 22px;
  color: #0C0D11;
}

.previewAccessModalBodyHeader {
  font-weight: 400;
  size: 12px;
  line-height: 18px;
  color: #9499AA;
  margin-bottom: 4px;
}

.previewAccessModalBodyContent {
  align-items: center;
  height: 32px;
  font-weight: 400;
  size: 14px;
  line-height: 22px;
  color: #0C0D11;
}

.inviteClock {
  height: 16px;
  width: 16px;
}

.textGray {
  color: gray;
}

.bottomMarginUserRoleBlocks {
  margin-bottom: 100px !important;
}

.bigLockerImage {
  height: 20px;
  margin-right: 4px;
  filter: brightness(0.5);

}

.backButtonPosition {
  max-width: 60%;
  margin: auto;
  margin-top: 2.5rem !important;
}

.minusTopMargin {
  padding-top: -2.5rem !important;
}