$borderColour: #EBECF1;
$secondaryButton: #B6B6BF;

.VerticalNavBar{
    height: 100vh;
    width: 4.5vw;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid $borderColour;
    .NavGroup{
        img{
            width: 100%;
            max-width: 140px;
            padding: 1.5em 1.5em;
        }
        .nav{
            margin-top: 3em;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: $secondaryButton;
            li{
                margin-bottom: 2em;
                p{
                    margin-top: .5em;
                    font-size: .9em;
                }
                a > *
                {
                    display: block;
                }
            }
            >*{
                text-align: center;
            }
            .active{
                color:  #101010;
                filter: brightness(0%);
            }
        }
    }
    .UserAvatarSection{
        border-top: 1px solid $borderColour;
        display: flex;
        justify-content: center;
        padding: 1.5em;
    }
}