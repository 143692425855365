.mfa_grey
{
    
font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 140%;
/* or 20px */

text-align: center;

/* Gray */

color: #B6B6BF;
}

.mfa_grey a:link
{
   
cursor: pointer;
}

.mfa_grey a
{
    color:#3D42DE;
}

.click-continuemfa
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 140%;
/* or 20px */


/* Black */

color: #101010;
}

.qr_code_wrapper{
    padding-bottom: 24px;
    padding-top: 6px;
}
.scan_link{
    color:#3D42DE !important;
}


@media (min-width: 1367px)  and (max-width: 1680px){
   
   
    #scan_mfa_wrapper .paddingTop{
        padding-top:0px !important;
    }
    #scan_mfa_wrapper .innerLoginWrapper .mfa_grey{
      padding: 0 75px;
    }
    
    }

    @media only screen  and (min-width:1681px) { 

        #scan_mfa_wrapper .paddingTop{
            padding-top:50px !important;
        }
    }  

    @media (min-width: 1024px)  and (max-width: 1366px){
   
        #scan_mfa_wrapper .imageLoginWrapper img{
            position: relative;
            top:-50px;
            left:-20px;
         }
         #scan_mfa_wrapper .paddingTopWrap{
            padding-top: 0px;
            zoom: 0;
            z-index: 1;
            position: relative;
            width:456px ;
        }
        #scan_mfa_wrapper .paddingTop{
            padding-top: 52px;
        }
        #scan_mfa_wrapper .innerLoginWrapper .wrapperContainer{
          margin-top:-20px;
        }

        #scan_mfa_wrapper .paddingTop{
            padding-top:0px !important;
        }
        #scan_mfa_wrapper .innerLoginWrapper .mfa_grey{
          padding: 0 75px;
        }
        
        
    }