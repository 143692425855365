.dots-loader {
    width: 17px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;



    .dot-inside {
        height: 4px;
        border-radius: 50%;
        margin: 0 1px;
        background-color: #9499AA;
        flex-grow: 1;
        flex-basis: 0;
    }
    
    .dots-animation{
        animation: dotsloader 0.8s infinite ease-in-out;

    }
    
  }
  
  
  .dots-animation:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .dots-animation:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes dotsloader {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }