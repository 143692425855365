.access-level-modal {
  .modal-body::-webkit-scrollbar {
    display: none;
  }

  .modal-header {
    border-bottom: 1px solid #ebedf1 !important;
  }

  .modal-footer {
    padding: 10px !important
  }

  .modal-content {
    border-radius: 6px;
    height: auto;
    min-height: 600px;
  }

  .show-on-hover {
    visibility: hidden;
  }

  .level {
    border-radius: 4px;

    &:hover {
      .show-on-hover {
        visibility: visible;
      }
    }
  }

  .level-dropdown {
    .device-dropdown button {
      background: transparent;
      border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .show {
      .dropdown-toggle {
        border: none;
      }
    }

    .dropdown-menu.show {
      background: #0C0D11;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      border-radius: 4px;
      // inset: 0px auto auto 0px !important;
      color: rgba(255, 255, 255, 0.64);

      width: 165px;
      font-weight: 500;

      .drop-item {
        padding: 5px 9px;

        &:hover {
          background: #ffffff20;
        }
      }
    }
  }

  .access-level-dropdown {
    .custom_drop-down-adjustable {
      background: transparent;

      .dropdown-menu.show {
        width: max-content;
      }
    }
  }

  .disabled-dropdown {
    pointer-events: none;

    .selected-option {
      margin-right: 0px;
    }

    .drop-placeholder {
      display: none;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-toggle {
      padding-right: 2px;
    }
  }
}

.discard-modal {
  .modal-content {
    height: auto;
    max-width: 65%;
    border-radius: 8px;
  }
}