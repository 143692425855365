.accessRoleBlock {
    margin-top: 6px;
    align-items: center;
    height: 40px;
    display: flex;
    justify-content: space-between;

    p {
        margin: 0px;
    }
}

.dashboardBlock {
    align-items: center;
    height: 40px;
    display: flex;
    justify-content: space-between;

    p {
        margin: 0px;
    }


    .hg-white-dropdown {
        .dropdown-menu.show {
            inset: auto 0px auto auto !important;
            transform: translate(0px, 0px) !important;
            width: 320px;
            z-index: 1000 !important;
        }
    }
}

.custom-dropdown-toggle {
    color: #616778 !important;

    &:hover {
        color: #616778 !important;
    }

    &:active {
        color: #616778 !important;
    }

    background: transparent !important;
    border: none !important;

    &:active {
        background: transparent !important;
        border: none !important;
    }

    &.hide-after {
        &::after {
            display: none !important;
        }
    }

    &::after {
        background-image: url("../../../../public/images/theme/icons/dropdownarrow.svg");
        background-repeat: no-repeat;
        width: 8px;
        height: 6px;
        border: none !important;
        margin-bottom: -3px;
        transform: scale(1.45);
        margin-left: 0.8rem;
    }
}

.hg-white-dropdown {
    .dropdown-menu.show {
        overflow-y: visible !important;
        width: 240px;
        z-index: 1000 !important;
    }
}


// .modal-footer {
//     height: 56px !important;
//     margin: 0px;
//     padding: 0px;
//     align-items: center;
//     display: flex;
//     justify-content: end;

// }

.modal-cancel-button {
    height: 32px;
    width: 62px;
    margin-right: 8px;
    background-color: #F6F8FB !important;
    padding: 4px !important;
    border-radius: 2px;
    color: #616778;
    font-weight: 500;
    size: 14px;
    border: none;
    line-height: 22px;
    letter-spacing: 2%;

    &:hover {
        background-color: #EBEDF1;
        color: #0C0D11;
    }
}

.modal-invite-button {
    height: 32px;
    width: 62px;
    margin-right: 10px;
    background-color: #008FD4 !important;
    padding: 4px !important;
    border-radius: 2px;
    color: white;
    font-weight: 500;
    size: 14px;
    border: none;
    line-height: 22px;
    letter-spacing: 2%;

    &:hover {
        background-color: #036B9D;
    }
}

// .modal-content {
//     min-height: 270px !important;
//     max-height: 280px;
//     overflow: visible !important;
// }

// .modal-body {
//     padding: 8px !important;
//     overflow: visible !important;
// }

.dropdownDashboardsLabel {
    height: 30px;
    color: #9499AA;
    font-weight: 400;
    size: 14px;
    line-height: 22px;
    letter-spacing: 2%;
    margin-left: 6px;
}