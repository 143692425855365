.add-item-in-spend-modal {
    .modal-content {
        height: auto;
        border-radius: 4px;
        width: 380px;
    }

    .modal-header {
        border-bottom: 1px solid #EBEDF1 !important;
    }

    .modal-footer {}


    .add-item-category-dropdown{
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            inset: auto 0px auto auto !important;
            transform: translate(0px, 0px) !important;
            // .blue-icon {
            //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            // }
        }
    }
}

#tax-rate-tooltip {
    .tooltip-inner {
        border-radius: 4px !important;
        padding: 4px 8px !important;
        max-width: 248px;
    }
    .arrow::before {
        transform: translateX(calc(-3px));
    }
}