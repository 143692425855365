.settings_emailHeader
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    
    /* Black */
    
    color: #101010;
}

.settings_emailSubHeader
{
    font-family: Archivo;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 13px;
    /* identical to box height */
    
    
    /* Gray */
    
    color: #B6B6BF;
}

.settingsEmailNotification .inputControl
{
  background-color:  #EBECF1!important;
  border: none;
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  
  /* Black */
  
  color: #101010;
}

.settingsEmailNotification .input-group-text
{
  border: none;
  font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;

/* Gray */

color: #B6B6BF;
}

.pushNotificationForm select
{
  background-color:  #EBECF1!important;
  border: none;
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  
  /* Black */
  
  color: #101010;
}

.pushNotificationForm .input-group-text
{
  border: none;
  font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;

/* Gray */

color: #B6B6BF;
}

.settings_emailSubsubHeader
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;

/* Black */

color: #101010;
}