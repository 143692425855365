.user-details-modal {
  .modal-header {
    align-items: center;
  }
  .header {
    border-bottom: 1px solid lightgray !important;
  }
  .label-input {
    margin-bottom: 3px;
    font-weight: 500;
    color: #0c0d11;
  }
  .footer {
    .cancel-btn {
      background-color: #f6f8fb;
      color: #616778;
      height: 32px;
      padding: 4px 6px;
      border-radius: 4px;
      border: none;
      margin-right: 10px;
      &:active {
        border: none !important;
        background-color: #f6f8fb !important;
        color: #616778 !important;
      }
    }
    .submit-btn {
      padding: 4px;
      height: 32px;
      padding: 4px 6px;
      border-radius: 4px;
      border: none;
    }
  }
  .modal-footer {
    padding: 10px 5px;
    border-top: none;
  }
  .empty-div {
    height: 20px;
  }
  .modal-content {
    height: auto;
    border-radius: 8px;
    max-width: 86%;
  }
  .user-name:focus {
    border-color: #ced4da;
  }
  .input-error {
    border: 1px solid #f36060 !important;
  }
  .error-text {
    color: #f36060 !important;
    font-size: 12px;
  }

  .current-email {
    font-weight: 600;
  }
  .new-email {
    height: 40px;
    border: 1px solid #ebedf1;
    padding-left: 1.1rem;
    &::placeholder {
      color: #9499aa;
    }
  }
  .labelled-input-password:focus-within {
    outline: none !important;
  }
}

// Image crop modal css
.image-crop-modal {
  .modal-footer {
    border-top: none;
    padding: 8px 8px;
    button {
      padding: 4px 8px;
    }
  }
  .slider-wrapper {
    position: absolute;
    bottom: 10px;
  }
  input[type="range"] {
    width: 400px;
    height: 16px;
    -webkit-appearance: none;
    // background-color: red;

    outline: none;
    border-radius: 50px;

    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
  }
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    background: #ffffff;

    border: 1px solid #ebedf1;

    // box-shadow: 0px 4px 8px rgba(16, 16, 16, 0.1);
    // box-shadow: -407px 0 0 400px #008fd4;
    border-radius: 4px;
  }
  .modal-content {
    max-height: 530px !important;
    min-height: 430px;
    zoom: 1 !important;
    height: auto !important;
  }
}

.rounded-circle {
  height: 70px;

  width: 70px;
  object-fit: contain;
}
