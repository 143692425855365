.switch-button-2 {
  width: 44px;
  height: 24px;
  background-color: #D4D6DD;
  border-radius: 9999px;
  padding: 2px;
  display: flex;
  align-items: center;
  transition: all 300ms ease-in-out;
  &.active {
    background-color: #008FD4;
    justify-content: flex-end;
    .circle {
      margin-left: 0;
      margin-right: 1px;
    }
  }
  &.disabled {
    background-color: #D4D6DD;
    cursor: default;
    opacity: 0.5;
  }
  .circle {
    background-color: #fff;
    border-radius: 9999px;
    width: 18px;
    height: 18px;
    margin-left: 1px;
  }
}