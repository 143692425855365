// button primary
.button-primary {
    background: #008fd4;
    border-radius: 4px;
    font-family: "Archivo";
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: #fff;
    min-width: 320px;
    height: 47px;
    border: 0px;
  
    // Large Button
    &.large {
      min-width: 486px;
      height: 47px;
    }
  
    // flex buton
    &.flex {
      min-width: 139px;
      height: 47px;
      display: inline-block;
      background: #008fd4;
      border-radius: 4px;
      padding: 16px 24px;
      color: #fff;
      border: 0px;
      font-family: "Archivo";
    }
  
    // small button
    &.small {
      min-width: 66px !important;
      height: 31px;
      font-size: 14px;
      line-height: 15px;
      padding: 8px 16px;
      border-radius: 4px;
      font-family: "Archivo";
      font-style: normal;
    }
  
    // button primary hover
    &:hover {
      background: #007bd4;
      color: #fff;
    }
  
    &:focus {
      box-shadow: none;
      outline: none;
    }
  
    // disabled Button
    &.disabled {
      background: #ebecf1;
      border-radius: 4px;
      font-family: "Archivo";
      font-style: normal;
      font-size: 14px;
      line-height: 15px;
      color: #b6b6bf;
      border: 0px;
      &:hover {
        background: #ebecf1;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &.icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img{
            margin-left: 8px;
        }

      }
  }
  
  // button Secondary
  
  .secondary-button {
    background: #ebecf1;
    border-radius: 4px;
    font-family: "Archivo";
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: #101010;
    min-width: 120px;
    height: 47px;
    border: 0px;
  
    // Large Button
    &.large {
      min-width: 486px;
      height: 47px;
    }
  
    &:hover {
      background: #b6b6bf;
      color: #101010;
    }
  
    &:focus {
      box-shadow: none;
      outline: none;
    }
  
    &.flex {
      height: 31px;
      display: inline-block;
      background: #ebecf1;
      border-radius: 4px;
      padding: 8px 16px;
      color: #101010;
      border: 0px;
      font-family: "Archivo";
  
      &:hover {
        background: rgba(0, 143, 212, 0.08);
        color: #008fd4;
      }
  
      &:focus {
        box-shadow: none;
        outline: none;
      }

      &.icon{
        display: inline-flex;
        align-items: center;
        justify-content: center;

        img{
            margin-left: 8px;
        }

      }
    }
  
    // disabled Button
    &.disabled {
      background: #ebecf1;
      border-radius: 4px;
      font-family: "Archivo";
      font-style: normal;
      font-size: 14px;
      line-height: 15px;
      color: #b6b6bf;
      border: 0px;
      &:hover {
        background: #ebecf1;
        color: #b6b6bf;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }

  //additional button
  .additional-button {
    background: rgba(0, 143, 212, 0.08);
    color: #008FD4;
    border-radius: 4px;
    font-family: "Archivo";
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    min-width: 320px;
    height: 47px;
    border: 0px;
  
    // Large Button
    &.large {
      min-width: 486px;
      height: 47px;
    }
  
    // flex buton
    &.flex {
      min-width: 139px;
      height: 47px;
      display: inline-block;
      background: rgba(0, 143, 212, 0.08);
      color: #008FD4;
      border-radius: 4px;
      padding: 16px 24px;
      border: 0px;
      font-family: "Archivo";
    }
  
    // small button
    &.small {
      min-width: auto !important;
      height: 31px;
      font-size: 14px;
      white-space: nowrap;
      line-height: 15px;
      padding: 8px 16px;
      border-radius: 4px;
      font-family: "Archivo";
      font-style: normal;
    }
  
    // button primary hover
    &:hover {
      background: #007bd4;
      color: #fff;
    }
  
    &:focus {
      box-shadow: none;
      outline: none;
    }
  
    // disabled Button
    &.disabled {
      background: #ebecf1;
      border-radius: 4px;
      font-family: "Archivo";
      font-style: normal;
      font-size: 14px;
      line-height: 15px;
      color: #b6b6bf;
      border: 0px;
      &:hover {
        background: #ebecf1;
      }
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
  

    //additional button
    .additional-button-color {
        background: #fe5f5b29;
        color: #F36060;
        border-radius: 4px;
        font-family: "Archivo";
        font-style: normal;
        font-size: 14px;
        line-height: 15px;
        min-width: 320px;
        height: 47px;
        border: 0px;
      
        // Large Button
        &.large {
          min-width: 486px;
          height: 47px;
        }
      
        // flex buton
        &.flex {
          min-width: 139px;
          height: 47px;
          display: inline-block;
          background: #fe5f5b29;
          color: #fff;
          border-radius: 4px;
          padding: 16px 24px;
          border: 0px;
          font-family: "Archivo";
        }
      
        // small button
        &.small {
          min-width: 66px !important;
          height: 31px;
          font-size: 14px;
          line-height: 15px;
          padding: 8px 16px;
          border-radius: 4px;
          font-family: "Archivo";
          font-style: normal;
        }
      
        // button primary hover
        &:hover {
          background: #F36060;
          color: #fff;
        }
      
        &:focus {
          box-shadow: none;
          outline: none;
        }
      
        // disabled Button
        &.disabled {
          background: #ebecf1;
          border-radius: 4px;
          font-family: "Archivo";
          font-style: normal;
          font-size: 14px;
          line-height: 15px;
          color: #b6b6bf;
          border: 0px;
          &:hover {
            background: #ebecf1;
          }
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        &.icon{
            display: inline-flex;
            align-items: center;
            justify-content: center;
    
            img{
                margin-left: 8px;
            }
    
          }
      }

    .custom_switch_toggle_button{
      
      .custom-control-input:checked~.custom-control-label::before{
        border-color: #008FD4;
        background-color: #008FD4
      }
      .toogle-switch .custom-control-label::before{
        background-color: #EBECF1;
      }
      
    }

    .custom_switch_multi_toggle_button{
      .custom-control-input:checked~.custom-control-label::before{
        border-color: #008FD4;
        background-color: #008FD4
      }
      .toogle-switch .custom-control-label::before{
        background-color: #008FD4;
      }
    }