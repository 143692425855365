.forgotPass
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;
text-align: center;

/* Gray */

color: #B6B6BF!important;
}

.btnControl{
    font-size: 14px;
    height: 44px;
}
.loginBg{
    /* background-image: url("../../../public/images/login-bg.svg"); */
    background: #f6f8fb !important;
    height: 100%;
}

.lgn_HeaderText
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
text-align: center;

/* Black */

color: #101010;
}

.lgn_HeaderText2
{
    
font-family: Archivo;
font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 20px;
/* identical to box height */

text-align: center;

/* Black */

color: #101010;
}

.lgnLinks
{
    
font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 170%;
/* identical to box height, or 24px */


/* Black */

color: #101010 !important;
}
.terms{
    font-size: 12px!important;
}

.barcodeLink{
    color:blue!important
}

.lgnGreyText
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 140%;
/* or 20px */

text-align: center;

/* Gray */

color: #B6B6BF!important;
}

.wrapperContainer
{background:#fff;
    border: 1px solid #EBECF1;
    box-shadow: 0px 40px 80px rgba(16, 16, 16, 0.04);
    border-radius:16px;
    margin:100px 0;
}

.userLoginForm .form-group ,.user2FAForm .form-group{
    border: 1px solid #B6B6BF;
    border-radius: 4px!important;
}

.userLoginForm .inputControl{
    /* background-color: #ffffff!important; */
    border: none;
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    
    /* Black */
    
    color: #101010!important;
  }
  .passwordIcon{
    color: #616778 !important;
  }

  .userLoginForm .inputControl:focus {
      box-shadow: none;
  }

  .user2FAForm .inputControl
  {
    /* background-color:  #EBECF1!important; */
    border: none;
    font-family: Archivo;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    
    /* Black */
    
    color: #101010;
  }
  .forgotPassWrapper{
    display: block!important;
  }

  .user2FAForm .input-group-text
  {
    border: none;
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
background-color:#ffffff;

/* Gray */

color: #B6B6BF;
  }


.userLoginForm .input-group-text
{
    border: none;
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
background-color:white;

/* Gray */



color: #B6B6BF;
}

.bytext
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;
text-align: center;

/* Gray */

color: #B6B6BF;
}

.makingcyber
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
text-align: center;

/* Black */

color: #101010;
}


.totp_grey
{
    
font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 140%;
/* or 20px */

text-align: center;

/* Gray */

color: #B6B6BF;
}

.totp_grey a:link
{
   
cursor: pointer;
}

.totp_grey a
{
    color:#3D42DE;
}


.loginBg{
    display: flex;
    align-self: stretch;
    justify-content: center;
}


@media (min-width: 1024px)  and (max-width: 1366px){
    .imageLoginWrapper img{
        position: relative;
        top:-5px;
        left:30px;
        height:650px;
        width:650px;
     }
     .paddingTopWrap{
        padding-top: 0px;
        z-index: 1;
        position: relative;
        width:716px ;
    }
    .loginBg{
        height:100%;
    }
    .paddingTop{
        padding-top: 52px;
    }
    .innerLoginWrapper .col-md-4.offset-md-1{
      display:flex;
      align-items: center;
      justify-content: center;
    }
    .innerLoginWrapper{
        height: 100%;
    }
    .innerLoginWrapper .wrapperContainer{
        margin: 0px 0;
    }
    
}

@media (min-width: 1367px)  and (max-width: 1680px){
   
.imageLoginWrapper img{
    position: relative;
    top:-20px;
    left:-60px;
    position: absolute;
 }
 
.imageLoginWrapper{
    height: 100%;
    padding:0px;
}
.innerLoginWrapper{
    width: 100%;
    padding:0px;
    overflow: hidden;
    align-items: center;
}
.paddingTop{
    padding-top: 152px;
}

.paddingTopWrap{
    padding-top: 0px;
    z-index: 1;
    position: relative;
    width:456px ;
}
}

 @media only screen  and (min-width:1681px) { 
 

.imageLoginWrapper img{
    position: relative;
    top:-30px;
    left:0px;
 }
 
.imageLoginWrapper{
    height: 100%;
    padding:0px;
    display:flex;
    align-items: center;
    justify-content: flex-start;
}
.innerLoginWrapper{
    width: 100%;
    padding:0px;
    overflow: hidden;
    align-items: center;
    height: 100vh;
}
.paddingTop{
    padding-top: 250px;
}

.paddingTopWrap{
    padding-top: 20px;
    z-index: 1;
    position: relative;
    width:550px ;
}
.loginBg{
    height:100%;
}

}

.login_alert{
    position: absolute;
    width: 70%;
    z-index:10;
}

.disabled-continue-btn{
   color: #9499AA !important;
}

.disabled-continue-btn:hover{
    color: #9499AA !important;
    background-color: #EBEDF1 !important;
}

.label-sign-up {
    text-align: left;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    margin-top: 10px;
  }
  

  .error-text{
    color: #dc3545;
    font-family: 'Archivo';
font-style: normal;
font-weight: 400;
font-size: 12px;

  }
  .success-text{
    color: #40D88E;
    font-family: 'Archivo';
font-style: normal;
font-weight: 400;
font-size: 12px;
  }

  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa !important;
  }

  .blueText{
    color:#008FD4 !important;
    cursor: pointer;
   

  }
  .blueText:active{
    color:#008FD4 !important;
  }
  .blueText:focus{
    color:#008FD4 !important;
  }
  .blueText:hover{
    color:#008FD4 !important;
  }

  .skip-text{
    font-family: 'Archivo';
font-style: normal;
font-weight: 500;
font-size: 14px;
color: #008FD4;
text-align: center;
cursor: pointer;
  }

  .button-disabled {
    background: #ebedf1 !important;
    color: #9499aa !important;
    border: none !important;
   
  }
  .button-disabled:active {
    background: #ebedf1 !important;
    color: #9499aa !important;
    border: none !important;
  }
