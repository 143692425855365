$labelColour: #B6B6BF;
$buttonColour: #008FD4;

.InvestmentForm label{
    font-weight: 500;
    color: $labelColour;
}

.InvestmentForm button{
    background-color: $buttonColour;
    padding: .35em 1.4em;
}

.flexFormInline{
    display: flex;
    width: 100%;
    margin-top: 1.2em;
}
.flexFormInline > *:not(:first-child){
    padding-left: .8em;
}
.flexFormInline.bottom > *:not(:last-child), .flexFormInline.top > *{
    align-items: stretch;
    flex-grow: 1;
    width: 20%;
}
.flexFormInline.bottom > *:last-child{
    margin-left: .5em;
}

.blurBackground{
    opacity: 0.5;
}

.disabled{
    opacity: 0.5 !important; 
}