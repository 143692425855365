.export-client-report-modal {
  .modal-dialog {
    max-width: 100vw !important;
  }

  .modal-content {
    border-radius: 8px;

    .custom-switch {
      transform: scale(1.5);
    }
  }
}

.page-footer,
.page-header {
  display: none;
}

.page-number {
  font-weight: 500;
}

.page-number::after {
  counter-increment: page;
  // content: counter(page);
  font-weight: 500;
}

.powered-container {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 12px;

  span {
    font-weight: 400;
    flex-shrink: 0;
    width: 75px;
  }
}

.main-page-wrapper {
  .security-package-service-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
  }
}

.choose-package {
  height: 35px;

  span {
    position: relative;
    top: -2px;
  }
}

.per_asset_financial_breakdown_table {
  display: grid;
  grid-template-columns: 55% 15% 15% 15%;
  min-height: 3rem;
  align-items: center;
}

.contribution_table {
  display: grid;
  grid-template-columns: 43% 13% 13% 13% 13% 5%;
  min-height: 3rem;
  align-items: center;
}

.included_features_table {
  display: grid;
  grid-template-columns: 50% 50%;
  min-height: 2rem;
}

.security_capabilities_table {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // align-items: center;
}

.security_capabilities_table > div {
  padding: 16px;
  border-right: 1px solid #EBEDF1;
  border-bottom: 1px solid #EBEDF1;
}

.security_capabilities_table > div:nth-child(3n) {
  border-right: none !important;
}

.no-border-bottom {
  border-bottom: none !important;
}

.border-gray {
  border: 1px solid #EBEDF1;
}
