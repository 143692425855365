@use '../../breakpoints.scss' as *;

.drilldown-modal {
  .modal-content {
    width: 1000px !important;
    border-radius: 14px !important;
  }

  .big-modal.right {
    max-width: 1000px !important;
  }

  .modal-body {
    background: #f6f8fb;
    border-radius: 8px;
    margin: 9px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .big-modal .modal-header {
    border-bottom: 1px solid white !important;
    padding: 2rem;
  }

  .header {
    padding: 1.5rem;
    display: grid;
    // grid-template-columns: 25% 25% 25% 25%;
    grid-template-columns: 25% 14% 38% 25%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .point:nth-child(odd) {
    background: #ffffff;
  }

  .point:nth-child(even) {
    background: #f6f8fb;
  }

  .points-wrapper {
    .point {
      border-radius: 4px;
      // padding: 1.5rem;
    }
  }

  .option-dropdown {
    .custom_drop-down {
      background: transparent;
    }
  }

  .tool-creator-field {
    border: 1px solid #99d2ee;
    padding: 3px;
    border-radius: 4px;
    height: 32px;

    input {
      border: none;
      width: 100%;
      background: transparent;
    }

    button {
      padding: 1px 5px;
    }
  }

  .checkbox-margin {
    margin-bottom: 20px;
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }

  .tool-breach-header {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .prerequisite-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ebf6fc;
    border-radius: 8px;
    border: 1px solid #008fd4;
    padding: 1.7rem;
  }

  .round-checkbox label:after {
    border-right: none;
    content: "";
    height: 16px;
    left: 1px;
    opacity: 0;
    position: absolute;
    top: 1px;
    width: 16px;
    background: #008fd4;
    border-radius: 50px;
    border: 1px solid white;
  }
}


.cybercomplinace-Modal-v2 {
  .modal-content {
    width: 1000px !important;
    border-radius: 14px !important;
  }

  .big-modal.right {
    max-width: 1000px !important;
  }

  .modal-body {
    // background: #f6f8fb;
    border-radius: 8px;
    margin: 9px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .big-modal .modal-header {
    border-bottom: 1px solid #EBEDF1 !important;
    padding: 1.24rem !important;
  }

  .modal-content {
    border-radius: 8px !important;
  }

  .header {
    padding: 1.2rem;
    display: grid;
    grid-template-columns: 40% 20% 35% 5%;
    // border-top-left-radius: 8px;
    // border-top-right-radius: 8px;
  }


  .point:nth-child(odd) {
    background: #ffffff;
  }

  .point:nth-child(even) {
    background: #f6f8fb;
  }

  .points-wrapper {
    .point {
      border-radius: 4px;
      // padding: 1.5rem;
    }
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }

  .panel-row {
    display: grid;
    grid-template-columns: 48% 16% 12% 20%;
  }
}

.greyText {
  color: #BBBEC9;
}

.cyber-group-modal{
  @include respond-to(lg) {
    width: 60%;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    border-bottom: 1px solid #008fd4;
    color: #008fd4 !important;
    font-family: "Archivo";
    font-style: normal;
    background: transparent;

    img {
      filter: invert(51%) sepia(55%) saturate(5380%) hue-rotate(173deg) brightness(91%) contrast(101%)
    }
  }

  .nav-pills .nav-link {
    border-radius: 0rem;
    font-family: "Archivo";
    font-style: normal;
    padding: 0.5rem 1.1rem;
  }

  .modal-content{
    border-radius: 8px;
    width: 44vw;
  }
  .modal-header{

    border-bottom: 1px solid #ebedf1 !important;
    padding-bottom: 0px !important;
    padding-top: 1rem !important;
  }

 
}
.cyber-score-table-row{
  display: grid;
  grid-template-columns: 30% 50% 20%;
}

.question-list-panel {
  display: flex;
  flex-direction: column;
  gap: 12px;
  @include respond-to(md) {
    gap: 0;
  }
}

.question-list-item {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto auto;
  grid-template-areas: 
    "top top2"
    "footer footer";
  margin: 0 12px;
  padding: 2px 5px;
  .top-item {
    grid-area: top;
  }
  .top2-item {
    grid-area: top2;
  }
  @include respond-to(md) {
    grid-template-columns: 20% 50% 21%;
    grid-template-rows: auto;
    grid-template-areas: "top footer top2";
  }
  @include respond-to(lg) {
    grid-template-columns: 30% 49% 21%;
  }
}

.question-list-item-yes-no {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin: 0 12px;
  padding: 2px 5px;
}

.question-list-tool-modal {
  width: 100%;
  @include respond-to(sm) {
    width: 300px;
  }
}


.tool-creator-field {
  border: 1px solid #99d2ee;
  padding: 3px;
  border-radius: 4px;
  height: 32px;

  input {
    border: none;
    width: 100%;
    background: transparent;
  }

  button {
    padding: 1px 5px;
  }
}

.bordered-option {
  border: 2px solid #99d2ee !important;
  border-radius: 4px;
}

#root .audit-compliance-dropdown.required-option,
#root .audit-compliance-dropdown:focus-within.required-option {
  border: 2px solid #F36060 !important;
  border-radius: 4px;
}

.tab-content {
  padding: 8px;
}

.header-section {
  padding: 7px 16px;
}

.row-section {
  padding: 8px 16px;
}

.search-container {
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.component-title {
  line-height: 22px;
}

.component-question {
  line-height: 18px;
}