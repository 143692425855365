 
 
  
  .shadow-top::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 1px;
    // background-color: rgba(233, 233, 233 , 0.84);
    // box-shadow: black 0px 5px 14px;
  }
  
  .shadow-bottom::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0px;
    width: 100%;
    height: 1px;
    // background-color: rgba(239 ,236 ,236 , 0.84);
    // box-shadow: black 0px -4px 10px;
  }
 .shadow-top{
  height: 0px;
  top: -1px;
  left: 0px;
  right: 0.8rem;
 }
 .shadow-bottom{
  height: 0px;
  bottom: -1px;
  left: 0px;
  right: 0.8rem;
 }


  