.risky-user-tooltip {
  position: relative;
  .anchor {
    width: fit-content;
  }
  .menu {
    background-color: #fff;
    min-width: 296px;
    border: 1px solid #EBEDF1;
    border-radius: 8px;
    z-index: 9999;
    box-shadow: 0px 0px 40px 0px #10101014;
    .header {
      display: flex;
      gap: 12px;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #EBEDF1;
      .name-email-container {
        .name {
          font-size: 14px;
          line-height: 22px;
        }
        .email {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .body {
      padding: 8px 12px 12px 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
      .list {
        display: flex;
        flex-direction: column;
        gap: 2px;
        .item {
          display: flex;
          .title {
            width: 36%;
          }
          .title, .value {
            font-size: 12px;
            line-height: 18px;
            font-weight: 400;
          }
        }
      }
    }
  }
}