.mt-16px {
  margin-top: 16px;
}
.integration_tabs a,
.suggestIntegrationModal input {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  padding-bottom: 16px;
  color: #101010 !important;
}
.integration_tabs {
  border-bottom: 0px solid;
}
.integration_tabs .nav-link.active {
  background-color: transparent !important;
  border: 1px solid transparent;
  border-bottom: 1px solid #008fd4;
  color: #008fd4 !important;
}

.integration_tabs .nav-link:focus,
.integration_tabs .nav-link:hover {
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid #008fd4;
}
.supportBtn {
  cursor: pointer;
}
.integrationTitle {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  padding-left: 32px;
}
.searchInput input,
.searchInput .input-group-text {
  min-height: 50px;
  border: none;
  border-bottom: 1px solid #ebecf1;
  background-color: transparent;
}
.searchInput input:focus {
  box-shadow: none;
  border-bottom: 1px solid #ebecf1;
  background-color: transparent;
}
.searchInput .form-control::-webkit-input-placeholder {
  color: #b6b6bf;
}
.suggestIntegration {
  min-height: 185px;
  text-align: center;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  background: rgba(0, 143, 212, 0.08);
  padding: 5px;
  border: 1px solid #008fd4;
  border-radius: 16px;
}
.modal.left .modal-dialog,
.modal.right .modal-dialog {
  position: fixed;
  margin: auto;
  height: 100%;
}

.modal.left .modal-content,
.modal.right .modal-content {
  overflow-y: auto;
  overflow-x: hidden;
}

/*Left*/
.modal.left.fade .modal-dialog {
  left: -534px;
}

.modal.left.fade.in .modal-dialog {
  left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
  right: -534px;
}

.modal.right.fade.in .modal-dialog {
  right: 0;
}
.integrationModal .modal-header {
  padding: 30px 33px;
}
.integrationModal .modal-body {
  /* padding-top: 0px; */
  padding-left: 24px;
  padding-right: 24px;
}
.centerModal .modal-content {
  min-height: 80%;
}
.integrationModal .mainImg {
  border-radius: 8px;
  border: 0.5px solid #ebecf1;
  margin-bottom: 5px;
  width: 100%;
}
.integrationModal .img2 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.integrationModal h1 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #101010;
}
.integrationModal h2 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #101010;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-footer {
  padding: 16px 24px 24px;
}
.modal-footer,
.integrationModal .txt-grey {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  display: block;
  color: #b6b6bf;
  line-height: 22.4px;
}
.modal-footer button,
.submit-btn {
  padding: 15px 23px;
  background: #008fd4;
}
.cancel-btn,
.cancel-btn:focus,
.cancel-btn:hover {
  padding: 15px 23px;
  background: #fff;
  color: #b6b6bf;
  border: 1px solid #fff;
}
.suggestIntegrationModal {
  width: 534px;
}
.suggestIntegrationModal .modal-header {
  padding: 49px 49px 46px 49px;
  /* padding: 28px 18px 16px 30px; */
}
.suggestIntegrationModal .modal-body {
  padding: 0px 40px 21px 40px;
}
.suggestIntegrationModal input.form-control,
.suggestIntegrationModal .input-group-text,
.suggestIntegrationModal select.form-control {
  border: 1px solid transparent;
  background: #f6f8fb;
  height: 46px;
  padding-top: 13px;
  padding-bottom: 13px;
}
.suggestIntegrationModal input.form-control,
.suggestIntegrationModal select.form-control {
  border-left: 0;
}
.suggestIntegrationModal .input-group-text {
  border-right: 0;
  padding-left: 16px;
}
.suggestIntegrationModal textarea {
  border: 1px solid transparent;
  background: #f6f8fb;
  padding: 16px;
}
.suggestIntegrationModal.right.modal-medium .modal-content {
  overflow: auto;
  height: 96vh;
  /* right: -19em; */
  margin: 2vh 0;
  animation-name: slideSuggestMdRightAnimation;
  animation-timing-function: ease;
  animation-duration: 0.56s;
}
.suggestIntegrationModal.modal-medium.slideHide .modal-content {
  animation-name: slideSuggestMdHideAnimation;
  animation-timing-function: ease;
  animation-duration: 0.56s;
}
.cancelModalWidth .modalContent {
  margin: 0 35px;
  text-align: center;
  margin-top: 33px;
}
.square-checkbox .label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  left: 0;
  position: absolute;
  top: 2px;
}
.modalContent {
  position: relative;
}
.playStoreButton {
  position: absolute;
  display: flex !important;
  bottom: 25px;
  justify-content: center !important;
  /* padding-left: 23px!important; */
}
.imageHeight{
  width: 549px;
  height: 244px;
  background: #FCFDFE;
}

@keyframes slideSuggestMdRightAnimation {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    /* transform: translateY(-50%);
    opacity: 1; */
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    /* transform: translateY(-50%); */
    /* opacity: 1; */
  }
}
@keyframes slideSuggestMdHideAnimation {
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    /* transform: translateY(-50%); */
  }

  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    /* transform: translateY(-50%); */
  }
}
.border-grey {
  border: 0.5px solid #ebecf1;
  border-radius: 8px;
}
.setup-instructions {
  background: #f6f8fb;
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
  color: #101010;
}
.setup-instructions a {
  color: #007bff !important;
}
.link-txt {
  color: #3d42de !important;
  padding-left: 12px;
}


 /* Upload image modal */
