.kpi-modal {
  display: none;
  z-index: 5;
  background: white;
  position: absolute;
  max-width: 309px;
  border-radius: 4px;
  border: 1px solid #ebedf1;
  .dividerx {
    border-top: 1px solid #ebedf1;
  }
  box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
}


.attestation-modal {
   
  z-index: 50;
  background: white;
  // position: absolute;
  max-width: 320px;
  border-radius: 4px;
  border: 1px solid #ebedf1;
  .dividerx {
    border-top: 1px solid #ebedf1;
  }
  box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
}
