.cs_background {
    background: #FFFFFF;
    border: 1px solid #EBECF1;
    box-sizing: border-box;
    border-radius: 16px;
}

.cs_text {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    /* Black */
    color: #101010;
    /* Inside Auto Layout */
    flex: none;
    order: 0;
    align-self: flex-start;
    /* margin: 0px 16px; */
}

.cs_row {
    margin: 7.5% 0;
}

.csMain{
    padding: .5em;
}

.cc_background .smallGrey{
    padding-top: .8em;
}

.csMain .logo{
    padding: .7em 0;
}
.csmain_background {
    background: #F6F8FB;
    border-radius: 8px;
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: -10%;
    line-height: 15px;
}

.saving_text {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    /* Gray */
    color: #B6B6BF;
}

.margin-clear {
    margin: 0;
}

.saving-maintext
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 26px;

/* Black */

color: #101010;
}