.collapsabileModalContainer {
    background: #FFFFFF;
    /* Light garay */
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 4px;
}

.collapsabileModalContainer .containerHeaders {
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
}

.collapsabileModalContainer .containerHeaders span{
    /* font-size: 1.3em; */
}

.collapsabileModalContainer .containerContent {
    padding: 1em;
}

.scoreText
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;

/* Black */

color: #101010;
}