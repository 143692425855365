.hg-tooltip-top {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.hg-tooltip-top .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    left: 50%;
    margin-left: -19px;
    max-width: 220px;
    height: auto;
    padding: 5px 9px;
}

.hg-tooltip-top .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 12px;
    margin-left: -4px;
    border-width: 7px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.hg-tooltip-top:hover .tooltiptext {
    visibility: visible;
}




// For Bottom Tooltip

.hg-tooltip-bottom {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.hg-tooltip-bottom .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    left: 50%;
    margin-left: -19px;
    max-width: 220px;
    height: auto;
    padding: 5px 9px;
}

.hg-tooltip-bottom .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 12px;
    margin-left: -4px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.hg-tooltip-bottom:hover .tooltiptext {
    visibility: visible;
}

// For ToolTop Top Left:

.hg-tooltip-topLeft {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.hg-tooltip-topLeft .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 130%;
    right: 8%;
    margin-left: -19px;
    max-width: 220px;
    height: auto;
    padding: 5px 9px;
}

.hg-tooltip-topLeft .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    right: 10px;
    margin-left: -4px;
    border-width: 7px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}

.hg-tooltip-topLeft:hover .tooltiptext {
    visibility: visible;
}





// testt========================================





// Bottom Left

.hg-tooltip-bottomLeft {
    position: relative;
    display: inline-block;
    // border-bottom: 1px dotted black;
}

.hg-tooltip-bottomLeft .tooltiptext {
    visibility: hidden;
    width: max-content;
    background-color: black;
    color: #fff;
    text-align: start;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 150%;
    right: 18%;
    // margin-left: -19px;
    transform: translateX(10px);
    max-width: 220px;
    height: auto;
    padding: 5px 9px;
}

.hg-tooltip-bottomLeft .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    right: 9px;
    margin-left: -4px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent black transparent;
}

.hg-tooltip-bottomLeft:hover .tooltiptext {
    visibility: visible;
}