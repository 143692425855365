.remove_method{
  height: 110px;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #EBECF1;

}
.crlblu{
  color:#008FD4;
}
.crlgrn{
  color:#40D88E;
}
.fnt16{
  font-size: 16px;
  font-weight: 600;
}
.remov_list{
  display: flex;
  margin-bottom: 18px;;

}
.price_month{
  padding-right: 10px;
  border-right: 1px solid #EBECF1;
}
.list_p{
  margin-top:2px;
}
.redbtn{
  background: #F36060 !important;
  border: 1px solid #F36060 !important;
}

.redclrr{
  color: #F36060 ;
}
.mt5{
  margin-top: 5px;
}
.grrclr{
  color:#B6B6BF;
  font-size: 14px;
}