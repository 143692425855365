// .container {
//   background-color: lightgrey;
//   padding: 30px 35px;
// }

// .PostEditor__container {
//   background-color: #fff;
//   box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.4);
//   transition: all 0.1s ease-out;
// }
// .PostEditor {
//   border: 0;
//   box-sizing: border-box;
//   font-family: ripplingFontBook;
//   font-size: 16px;
//   height: auto;
//   min-height: 140px;
//   outline: none;
//   padding: 20px;
//   resize: none;
//   width: 100%;
// }

// .PostEditor__input {
//   border: 0;
//   box-shadow: none;
//   box-sizing: border-box;
//   font-family: ripplingFontBook;
//   font-size: 16px;
//   height: auto;
//   min-height: 140px;
//   outline: none;
//   padding: 20px;
//   resize: none;
//   width: 100%;
// }

// .PostEditor__footer {
//   margin-top: -40px;
//   transition: margin-top 0.1s ease-out;
//   z-index: 1;
//   background-color: #182433;
//   padding: 10px;
// }

// .PostEditor__footer button {
//   opacity: 0.8;
//   color: #182433;
//   background-color: #f7f7f7;
//   border: 0;
//   border-radius: 3px;
//   box-shadow: none;
//   cursor: pointer;
//   font-size: 10px;
//   letter-spacing: 0.5px;
//   margin: 0 10px;
//   outline: none;
//   padding: 5px 10px 5px;
//   text-transform: uppercase;
// }

.mention-logo {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}

.mention_class {
  color: #008fd4;
  font-weight: 500;
  // cursor: pointer;

  &:hover {
    .popoverx {
      z-index: 1;
      display: inline-flex !important;
      align-items: center;
      animation: popupx 500ms ease-in-out;
      -webkit-animation: popupx 500ms ease-in-out;
      // animation-delay: 1s;
    }
  }

  .popoverx {
    width: 160px;
    bottom: 175%;
    left: 50%;
    margin-left: -80px;
    background: #0c0d11;
    border-radius: 4px;
    box-shadow: 0px 0px 21px rgba(16, 16, 16, 0.16);

    // border: 1px solid rgba(0, 0, 0, 0.15);
    .name {
      font-weight: 400;
      font-family: Archivo, sans-serif;
      color: #ffffff;
      font-size: 14px;
    }

    .detail {
      color: #9499aa;
      font-weight: 400;
      font-family: Archivo, sans-serif;
      font-size: 12px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid #0c0d11;
      top: 100%;
      left: 43%;
    }
  }

  .image-initials {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background: rgb(61, 66, 222);
    color: white !important;
    font-size: 14px;
    padding: 5px;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    font-weight: 400;
    font-family: Archivo, sans-serif;
    margin-right: 10px;
  }

  .popupx-image {
    border-radius: 100%;
    width: 27px;
    height: 27px;
  }
}

@keyframes popupx {
  0% {
    // transform: scale(0);
    // -webkit-transform: scale(0);
    opacity: 0;
  }

  100% {
    // transform: scale(1);
    // -webkit-transform: scale(1);
    opacity: 1;
  }
}