.spinnerContainer {
    position: absolute;
    height: 100%;
    width: 100%;
}

.flexCenterAlign {
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinnerBackground {
    padding: 14px 18px;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background: #F6F8FB;
    border: 1px solid #EBECF1;
    border-radius: 16px;
    z-index: 2;
    width: 144px;
    height: 128px;
    text-align: center;
}

.spinnerBackground>*:first-child {
    margin-bottom: .5em;
}