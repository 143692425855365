.bottomCont
{
    background: #FFFFFF;
border-radius: 0px 0px 4px 4px;
height: 64px;
}

.totalFont
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;

/* Gray */

color: #B6B6BF;
}