@use '../../../breakpoints.scss' as *;

.common-sidebar {
  position: relative;
  background: white;
  z-index: 0;
  border-right: 1px solid #EBEDF1;
  animation-fill-mode: forwards;
  .list-view {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 16px 13px;
  }
  .nav_bar_item {
    &:hover {
      .coming-soon-tag-msp-menu {
        background: #D6EDF8;
        color: #008FD4;
      }
    }
  }
  .sidebar-container {
    position: relative;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;
    min-height: 64px;
    border-bottom: 1px solid #EBEDF1;
  }
  .coming-soon-icon {
    display: none;
  }
  .sidebar-header-body {
    width: 100%;
  }
  white-space: nowrap;
  &.expanded{
      .nav_bar_item {
          .nav_bar_item_text,
          .tabIcon {
              color: #9499AA;
          }
          &:hover {
              .nav_bar_item_text,
              .tabIcon {
                  color: #616778;
              }
             
              background: #F6F8FB;
              border-radius: 4px;
              .nav_bar_item_image {
                filter: grayscale(68%) brightness(62%);
                -webkit-filter: grayscale(68%) brightness(62%);
              }
          }
          &.locked {
            pointer-events: none;
            &:hover {
                background: transparent;
            }
          }
          &.active{
              .nav_bar_item_text,
              .tabIcon {
                  color: #0C0D11;
              }
              background: #EBEDF1;
              border-radius: 4px;
              .nav_bar_item_image {
                filter: grayscale(68%) brightness(62%);
                -webkit-filter: grayscale(68%) brightness(62%);
              }
              .coming-soon-tag-msp-menu {
                background: #fff;
                color: #616778;
              }
          }
      }
      .sidebar-container {
        justify-content: center;
      }
  }
  &.minimized{
      .nav_bar_item {
          .nav_bar_item_text,
          .tabIcon {
              color: #9499AA;
          }
          &:hover {
              .nav_bar_item_image {
                  filter: grayscale(68%) brightness(62%);
                  -webkit-filter: grayscale(68%) brightness(62%);
              }
              background: #F6F8FB;
              border-radius: 4px;
          }
          &.locked {
            pointer-events: none;
            &:hover {
                background: transparent;
            }
          }
          &.active{
            .nav_bar_item_text,
            .tabIcon {
                color: #0C0D11;
            }
              .nav_bar_item_image {
                  filter: grayscale(68%) brightness(62%);
                  -webkit-filter: grayscale(68%) brightness(62%);
              }
              background: #EBEDF1;
              border-radius: 4px;
          }
      .sidebar-container {
        justify-content: flex-start;
      }
    }
    .coming-soon-tag-msp-menu {
        display: none !important;
    }
    .coming-soon-icon {
        display: block;
    }
    .collapse-btn {
      position: absolute;
      left: 0;
      z-index: 2;
      opacity: 0;
      width: 63px;
      height: 100%;
      &:hover {
        opacity: 1;
        background-color: #fff;
      }
    }
  }
  .collapse-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.reverse {
        transform: rotate(180deg);
    }
  }
  .nav_bar_item {
    padding: 8px 12px;
    height: 38px;
    gap: 4px;
  }
  .state-icon {
    display: flex;
    position: absolute;
    left: 25px;
    top: 3px;
  }
  .row-body {
    gap: 14px;
  }
  &.preload {
    animation-duration: 0s !important;
    .slideInFromBottom-text {
      animation-duration: 0s !important;
    }
    
    .slideOutToBottom-text {
      animation-duration: 0s !important;
    }
  }
}

@keyframes slideInFromBottomText {
  0% {
    transform: translateY(30%);
    opacity: 0;
    display: none;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
}

@keyframes slideOutToBottomText {
  0% {
    transform: translateY(0);
    opacity: 1;
    display: block;
  }
  100% {
    transform: translateY(30%);
    opacity: 0;
    display: none;
  }
}

@keyframes onCollapse {
  0% {
    width: 300px;
    min-width: 300px;
  }
  100% {
    width: 64px;
    min-width: 64px;
  }
}

@keyframes onExpand {
  0% {
    width: 64px;
    min-width: 64px;
  }
  100% {
    width: 300px;
    min-width: 300px;
  }
}


.onCollapse {
  animation: onCollapse 1s forwards;
}

.onExpand {
  animation: onExpand 1s forwards;
}

.slideInFromBottom-text {
  animation: slideInFromBottomText 1s forwards;
}

.slideOutToBottom-text {
  animation: slideOutToBottomText 1s forwards;
}