.share-post-modal {
  .modal-content {
    border-radius: 8px;
    height: auto;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
  }
  .modal-footer {
    border-top: 1px solid #ebedf1;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .desc {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
  }

  .share-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    /* Grayscale / Gray 80 */

    color: #616778;
    background: #f6f8fb;
    border-radius: 4px;
    border: none;
    &:active {
      color: #616778 !important;
      background: #f6f8fb !important;
      border: none !important;
    }
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    /* identical to box height, or 150% */

    letter-spacing: 0.02em;

    /* Grayscale / Gray 50 */

    color: #9499aa;
  }
}

.rewards-wrapper {
  margin-top: 2.5rem !important;
  max-width: 60%;
  margin: auto;
  .header {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #0c0d11;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .graytext {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #616778;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
  }

  .titleCheckbox {
    height: 15px;
    width: 15px;
    display: initial;

    label {
      height: 15px;
      width: 15px;
      margin-top: 3px;
    }

    label::after {
      border: 2px solid #fff;
      border-top: none;
      border-right: none;
      content: "";
      height: 5px;
      left: 2px;
      opacity: 0;
      position: absolute;
      top: 3px;
      transform: rotate(-45deg);
      width: 9px;
    }
  }

  .bigText {
    color: #0c0d11;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 20px !important;
  }
  .brownText {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #9499aa;
  }
  .blueText {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #008fd4;
  }
  .right-border {
    border-right: 1px solid #ebedf1;
  }
  .show-more-btn {
    background: #f6f8fb;
    border-radius: 2px;
    cursor: pointer;
    color: #616778;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: fit-content;
    padding: 4px 24px 4px 6px;
    position: relative;
  }
  .arrow-down:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    bottom: 45%;
    right: 9%;
  }
  .arrow-up:after {
    content: "";
    width: 5px;
    height: 5px;
    position: absolute;
    border: solid #616778;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 2px;
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
    bottom: 34%;
    right: 9%;
  }
}
.big-modal {
  .modal-header {
    border-bottom: 1px solid lightgray !important;
  }
  .modal-content {
    height: 108vh !important;
    zoom: 1 !important;
  }
  .heading {
    /* Titles / Title 4 */

    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .desc {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
  }
  .m-big {
    margin-bottom: 70px;
  }

  @keyframes slideLgRightAnimation {
    0% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(100%);
      transform: translateX(100%);
    }

    100% {
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
    }
  }

  &.right {
    overflow: auto;
    // height: 96vh;
    // right: 10.6em;
    // margin: 16px 0;
    animation-name: slideLgRightAnimation;
    animation-duration: 0.56s;
    animation-timing-function: ease;
    max-width: 500px;
    position: fixed;

    right: 1em !important;
  }

  .modal-content {
    width: 100%;
    // height: 90vh !important;
    border-radius: 7px;
  }
}

.rewards-invitation-modal {
  max-width: 480px !important;
  .modal-content {
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 8px;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    .close-icon {
      width: 11px;
      object-fit: contain;
    }
  }
  .label-input {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .link-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
    // width: 293px;
    width: 100%;
  }
  .field-wrapper {
    background: #f6f8fb;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border-radius: 3px;
    width: 100%;
  }
  .copy-link-btn {
    background: #008fd4;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 7px;
  }
  .item-wrapper {
    // width: fit-content;
    margin: auto;
  }
  .email-input {
    border: none;
    background: transparent;
    width: 100%;
  }
  .email-field-wrapper {
    background: transparent;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border: 1px solid #ebedf1;
    border-radius: 4px;
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  .email-item-wrapper {
    .custom_drop-down .dropdown-toggle {
      background: white !important;
      border: 1px solid transparent;
    }
    width: 90%;
    margin: auto;
  }
  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
}

.send-report-modal {
  max-width: 480px !important;
  .modal-content {
    height: fit-content !important;
    width: fit-content !important;
    border-radius: 8px;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    .close-icon {
      width: 11px;
      object-fit: contain;
    }
  }
  .label-input {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .link-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0c0d11;
    width: 293px;
  }
  .field-wrapper {
    background: #f6f8fb;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border-radius: 3px;
    width: 100%;
  }
  .copy-link-btn {
    background: #008fd4;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    padding: 4px 7px;
  }
  .item-wrapper {
    width: fit-content;
    margin: auto;
  }
  .email-input {
    border: none;
    background: transparent;
    width: 100%;
  }
  .email-field-wrapper {
    background: transparent;
    padding: 0px 0px 0px 6px;
    margin-right: 4px;
    border: 1px solid #ebedf1;
    border-radius: 4px;
    width: 100%;
    flex: 1;
    justify-content: space-between;
  }
  .email-item-wrapper {
    .custom_drop-down .dropdown-toggle {
      background: white !important;
      border: 1px solid transparent;
    }
    width: 97%;
    margin: auto;
  }
  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
  .preview-btn {
    background: #f6f8fb;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #616778;
    padding: 4px 6px;
    border: none;
    &:active {
      border: none !important;
      color: #616778 !important ;
      background: #f6f8fb !important;
    }
  }
}
