html,
body,
#root,
#root > .row {
  background: #fcfdfe;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  font-family: Archivo, sans-serif !important;
}
/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
box-shadow: 0 0 0 30px white inset !important;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.fullHeight {
  height: 100%;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit !important;
}

.linkColour {
  color: #008fd4 !important;
}

.greyLinkText {
  color: #b6b6bf;
  cursor: pointer;
}

.cyberCareButton {
  background: #ebecf1;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-weight: 500;
}

.pushRight {
  float: right;
}

hr {
  height: 1px;
  color: #ebecf1;
  width: 100%;
}

.searchInput {
  display: flex;
  flex-flow: row;
  align-content: flex-end;
}

.displayChildrenInline,
.displayChildrenInline * {
  display: inline;
}

.modal-header {
  border-bottom: 1px solid white !important;
  padding: 1.25em 1em;
}

.checkboxContainer hr {
  padding: 0.5em 0;
}

@keyframes loader-animation {
  0% {
    left: 15%;
    visibility: visible;
  }
  5% {
    left: 15%;
    visibility: visible;
  }
  10% {
    left: 15%;
    visibility: visible;
  }
  15% {
    left: 15%;
    visibility: visible;
  }
  20% {
    left: 25%;
    visibility: visible;
  }
  25% {
    left: 25%;
    visibility: visible;
  }
  30% {
    left: 25%;
    visibility: visible;
  }
  35% {
    left: 50%;
    visibility: visible;
  }
  40% {
    left: 50%;
    visibility: visible;
  }
  45% {
    left: 50%;
    visibility: visible;
  }
  50% {
    left: 75%;
    visibility: visible;
  }
  55% {
    left: 75%;
    visibility: visible;
  }
  60% {
    left: 75%;
    visibility: visible;
  }
  65% {
    left: 85%;
    visibility: visible;
  }
  70% {
    left: 85%;
    visibility: visible;
  }
  75% {
    left: 85%;
    visibility: visible;
  }
  80% {
    left: 85%;
    visibility: visible;
  }
  85% {
    left: 85%;
    visibility: visible;
  }
  90% {
    left: 85%;
    visibility: visible;
  }
  95% {
    left: 85%;
    visibility: visible;
  }
  100% {
    left: 85%;
    visibility: visible;
  }
}

@keyframes loader-animation-text {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 1;
  }
  35% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  45% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  55% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  65% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.custom_loader {
  width: 100%;
  overflow: hidden;
  position: relative;
  top: -20px;
  background-color: #ebecf1;;
}
.custom_loader .custom_bar {
  position: relative;
  height: 5px;
  width: 0%;
  background-color:  rgb(16, 16, 16);
  /* animation-name: loader-animation; */
  /* animation-delay: 1.5s; */
  animation-duration: 9s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: 0;
  -webkit-animation-iteration-count: 0; */
}

.outsideLoader{
  max-width: 315px;
  margin: auto;
}
.custom_text {
  opacity: 0;
  animation-name: loader-animation-text;
  animation-delay: 2.2s;
  animation-duration: 10s;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
  font-weight: 400 !important;
  font-size: 20px !important;
  animation-timing-function: ease-in-out;
  text-align: center !important;
  /* animation-iteration-count: 0;
  -webkit-animation-iteration-count: 0; */
}


.sticky_wrapper{
    position: -webkit-sticky;
    position: sticky;
    top: 310px;
    height: calc(100vh - 320px);
    overflow-y:auto;
    padding-right: 20px;
}
.sticky_wrapper::-webkit-scrollbar-thumb {
  border: 1px solid transparent;
  border-radius: 1px;
}
.sticky_wrapper::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.sticky_wrapper::-webkit-scrollbar-track {
  /* background: #979797; */
  border-radius: 4px;
}

/* Handle */
.sticky_wrapper::-webkit-scrollbar-thumb {
  /* background: #979797; */
  border-radius: 4px;
}

.sticky_wrapper div.crsrpoint:last-child {
  margin-bottom: 180px;
}

