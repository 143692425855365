.webFilteringBox
{
    /* White */

background: #FFFFFF;
/* Light garay */

border: 1px solid #EBECF1;
box-sizing: border-box;
border-radius: 16px;
height: 168px;
}



.webfiltering_background
{
    /* Light gray BG */
    padding: 1em 0;
    height: 100%;
    background: #F6F8FB;
    border-radius: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.webfiltering_background span{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
}
.webfiltering_background span h3{
    padding-left: .7em;
    margin-bottom: 0;
}

.emailMainText
{
    
font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
display: flex;
align-items: center;
text-align: center;

/* Black */

color: #101010;

    
}

.percentText
{
    
font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 13px;
/* identical to box height */


/* Gray */

color: #B6B6BF;

}

.drilldownSideText
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 15px;
display: flex;
align-items: center;
text-align: center;

/* Black */

color: #101010;

}

.link_textCol
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;

/* Gray */

color: #B6B6BF;
}
