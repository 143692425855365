.mapped-using-ai {
  display: flex;
  align-items: center;
  gap: 8px;
  .text {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #5244B3;
  }
}