.commonaccordion {
    color: #444;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
 .rightSelected{
  position: absolute;
  right: 30px;
 }
  
  .commonaccordion:after {
    content: '';
    background: url("../../../../public/images/calculator/arrow.svg") no-repeat ;
    color: #777;
    font-weight: bold;
    float: right;
    height: 20px;
    width: 20px;
    position: relative;
    top:8px;
  }
  
  .activeaccordian:after {
    transform: rotate(180deg);
    top: -8px;
    left: -8px;
  }
  
  .commonpanel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }