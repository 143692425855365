.add-compliance-profile-modal {
  .modal-header {
    border-bottom: 1px solid #ebedf1 !important;
  }

  .modal-content {
    border-radius: 6px;
    height: 100vh;
  }

  .complaince-profile-input {
    // margin-top: 9px;
    padding-left: 10px;
    border: none;
    height: 34px;
    background: #f6f8fb;
    border-radius: 4px;

    &::placeholder {
      color: #9499aa;
    }
  }

  .scale-switch {
    label {
      transform: scale(1.5);
    }
  }

  .complaince-profile-option {
    position: relative;
    cursor: pointer;

    &:hover {
      background: #f6f8fb;
      border-radius: 4px;

      &::after {
        content: "";
        position: absolute;
        background-image: url("../../../../public/images/chevron-right.svg");
        background-repeat: no-repeat;
        width: 10px;
        height: 11px;
        left: 94%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .head {
    background: #f6f8fb;
    border-radius: 8px;
    height: 210px;
    width: 100%;

    .graph {
      position: absolute;
      right: 0;
      left: 5rem;
      top: 1.5rem;

      &.complianceGraph {
        left: 1rem !important;
        top: 2rem !important;
      }
    }
  }

  .cyberscore {
    .status {
      display: none;
    }

    .bar__scale {
      display: none;
    }

    .slider-wrapper {
      .btn-typ {
        width: 95px;
      }
    }

    .left-17rem {
      left: 17rem;
    }
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }
}