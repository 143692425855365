$breakpoints: (
  xs: 320px,        // Extra small devices
  sm: 480px,        // Small devices
  md: 768px,        // Medium devices
  lg: 1024px,       // Large devices
  xl: 1440px,       // Extra large devices
  xxl: 1920px       // Ultra wide screens
);

@mixin respond-to($size) {
  $breakpoint: map-get($breakpoints, $size);
  @if $breakpoint {
    @media (min-width: #{$breakpoint}) {
      @content;
    }
  } @else {
    @warn "Breakpoint #{$size} is not defined in the map!";
  }
}