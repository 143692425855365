@use '../../../../breakpoints.scss' as *;

.add-edit-service-modal {
    width: 100%;
    @include respond-to(md) {
        width: 600px;
    }
    .nist-container {
        flex-direction: column;
        @include respond-to(md) {
            flex-direction: row;
        }
    }
    .nist-sidebar {
        width: 100%;
        @include respond-to(md) {
            width: 27%;
        }
    }
    .nist-chart {
        width: 100%;
        @include respond-to(md) {
            width: 70%;
            margin: auto;
        }
    }
    .features-container {
        flex-direction: column;
        @include respond-to(md) {
            flex-direction: row;
        }
        .title-side {
            width: 100%;
            @include respond-to(md) {
                width: 30%;
            }
        }
        .data-side {
            width: 100%;
            @include respond-to(md) {
                width: 70%;
            }
            .feature-item {
                @include respond-to(md) {
                    padding-left: 8px;
                }
            }
        }
    }

    .service-components-block {
        display: grid;
        grid-template-columns: 30% 35% 35%;
        // height: 3rem;
        // align-items: center;
    }

}

.msp-price-calculator {
    max-width: calc(100% - 40px);
    margin: 8px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;

    @include respond-to(md) {
        max-width: 80%;
        margin: 0 auto;
    }

    @include respond-to(xl) {
        flex-direction: row;
        align-items: flex-start;
        gap: 160px;
    }

    .security-posture-block {
        width: 100%;
        max-width: 650px;
        @include respond-to(xl) {
            width: 500px;
            min-width: 500px;
        }
    }

    .security-package-details-block {
        width: 100%;
        max-width: 650px;
    }

    .msp-calc-left-container {
        flex: 1 0 0;
    }

    .msp-calc-right-container {
        flex: 1 0 0;
    }

    .gauge-graph-text {

        top: 50%;
        left: 50%;
        font-weight: 700;
        font-size: 16px;
        transform: translate(-50%, -50%);

        width: 65px;
        height: 65px;
        border-radius: 50%;


    }

    .calculator-table-grid {
        display: grid;
        grid-template-columns: 34% 22% 22% 22%;
    }




    .msp-multiselect-package-selection-dropdown {
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            .blue-icon {
                filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            }

            .darkgray-icon {
                filter: invert(45%) sepia(5%) saturate(10%) hue-rotate(210deg) brightness(95%) contrast(85%);
            }

            .lightgray-icon {
                filter: invert(28%) sepia(7%) saturate(0%) hue-rotate(227deg) brightness(120%) contrast(90%);
            }
        }
    }

    .msp-service-update-loader {
        width: 12px;
        height: 12px;
        border: 2px solid #ccc;
        border-top: 2px solid #888;
        border-radius: 50%;
        animation: spin-loader 1s linear infinite;
      }
  
      @keyframes spin-loader {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

}


.msp-service-add-edit-modal {
    .dragging-row{
        background: #f6f8fb;
        border-radius: 4px;
        left: 31% !important;
    }
    .service-type-dropdown {
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            inset: auto 0px auto auto !important;
            transform: translate(0px, 0px) !important;
            // .blue-icon {
            //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            // }
        }
    }
        .locked {

            &::after {
                display: none;
            }

        }

    // Summary Dropdown
    .summary-period-dropdown {
        .custom_drop-down {
            background: white !important;
            box-shadow: none;
        }
    }

    .product-p1-table {
        display: grid;
        grid-template-columns: 50% 35% 15%;

    }

    .labour-cost-table {
        display: grid;
        grid-template-columns: 35% 30% 30% 5%;
    }

    .radar-overlay-div{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        -moz-box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.8);;
            // backdrop-filter: blur(10px); /* adjust the blur value as needed */
            z-index: -1;
        }
    }



    .product-child-dropdown {
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            width: 101%!important;
            inset: auto -2px auto auto !important;
            transform: translate(0px, -39px) !important;
            // .blue-icon {
            //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            // }
        }
    }

    .table-title{
        // display: flex;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // grid-template-columns: 5% 85% 10%;
        padding: 4px 8px 4px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: var(--Grayscale-Gray-05, #EBEDF1);
    }

    .products-list-summary-table{
        display: grid;
        grid-template-columns: 200px 150px 150px 150px;
        @include respond-to(md) {
            grid-template-columns: 40% 28% 18% 18%;
        }
        background-color: #F6F8FB;

        padding: 8px 44px 8px 12px;
        // display: flex;
        // align-items: center;
        // gap: 8px;
        // align-self: stretch;
     }
    .labour-list-summary-table{
       display: grid;
       grid-template-columns: 200px 80px 150px 80px 150px 80px 80px;
       @include respond-to(md) {
            grid-template-columns: 26% 11% 16% 10% 22% 10% 5%;
        }
       background-color: #F6F8FB;
       padding: 2px 4px;
    }
    .labour{
        .custom_drop-down .selected-option{
           color: #616778
       }
    }

    .black-placeholder::placeholder {
        color: #0c0d11 !important;
      }

}
.msp-add-edit-product-modal {
    width: 100%;
    @include respond-to(md) {
        width: 600px;
    }
}
.msp-product-add-edit-modal {
    .service-type-dropdown {
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            inset: auto 0px auto auto !important;
            transform: translate(0px, 0px) !important;
            // .blue-icon {
            //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            // }
        }
    }
        .locked {

            &::after {
                display: none;
            }

        }

    // Summary Dropdown
    .summary-period-dropdown {
        .custom_drop-down {
            background: white !important;
            box-shadow: none;
        }
    }

    .product-p1-table {
        display: grid;
        grid-template-columns: 50% 35% 15%;

    }

    .labour-cost-table {
        display: grid;
        grid-template-columns: 35% 30% 30% 5%;
    }

    .radar-overlay-div{
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        -moz-box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        box-shadow: 0px 0px 74px 44px rgba(255,255,255,0.8);;
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(255,255,255,0.8);;
            // backdrop-filter: blur(10px); /* adjust the blur value as needed */
            z-index: -1;
        }
    }



    .product-child-dropdown {
        .dropdown-toggle {
            padding: 0px;
            background: transparent !important;
            border: none !important;

            &::after {
                display: none;
            }

        }

        .dropdown-menu {
            width: 101% !important;
            inset: auto -2px auto auto !important;
            transform: translate(0px, -39px) !important;
            // .blue-icon {
            //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
            // }
        }
    }

    .table-title{
        // display: flex;
        display: grid;
        grid-template-columns: 5% 85% 10%;
        padding: 4px 8px 4px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        background: var(--Grayscale-Gray-05, #EBEDF1);
    }

    .products-list-summary-table{
        display: grid;
        grid-template-columns: 200px 150px 150px 150px;
        @include respond-to(md) {
            grid-template-columns: 42% 26% 18% 18%;
        }
        background-color: #F6F8FB;

        padding: 8px 44px 8px 12px;
        // display: flex;
        // align-items: center;
        // gap: 8px;
        // align-self: stretch;
     }
    .labour-list-summary-table{
       display: grid;
       grid-template-columns: 26% 11% 16% 10% 22% 10% 5%; // 30% 13% 20% 17% 10% 5% 5%;
    //    grid-template-columns: 30% 9% 15% 9% 22% 10% 5%; // 30% 13% 20% 17% 10% 5% 5%;
       background-color: #F6F8FB;
       padding: 2px 4px;
    }
    .labour{
        .custom_drop-down .selected-option{
           color: #616778
       }
    }

    .black-placeholder::placeholder {
        color: #0c0d11 !important;
      }
    .linked-products-dropdown {
        .menu {
            width: 350px !important;
            .add-btn {
                display: none;
            }
            .item:hover {
                .add-btn {
                    display: block;
                }
            }
        }
    }
}

.msp-service-dropdown{
    .custom_drop-down .dropdown-toggle {
        font-weight: 400;
        background: white !important;
        box-shadow: none;
    }
    .custom_drop-down-snap .dropdown-toggle {
        font-weight: 400;
        background: white !important;
        box-shadow: none;
    }
}

.msp-service-tool-tip-parent {
    position: relative;

    .msp-service-tool-tip-content {
        position: absolute;
        display: none;
    }

    &:hover {
        .msp-service-tool-tip-content {
            display: flex !important;
            flex-direction: column !important;
            z-index: 555;
            box-shadow: 0px 12px 24px rgba(16, 16, 16, 0.08);
            top: -100px;
            left: 23px;
        }

    }
}