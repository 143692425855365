@use '../../breakpoints.scss' as *;

.selectedMenuItem {
    background: rgba(255, 255, 255, 0.24);
    ;
}

.msp-header {
    height: auto;
    z-index: 6;
    .top-row {
        height: 56px
    }

    .menuItem {
        color: rgba(255, 255, 255, 0.88);
    }

    .header-logo-dropdown {
        .dropdown-menu {
            width: 250px;
        }

        .dropdown-item {
            padding: 0.3rem 1rem;
        }

        .dropdown-item.disabled {
            opacity: 1 !important;
            cursor: default !important;
        }

        .thumbnail-pic {
            object-fit: contain;
            width: 31px;
            border-radius: 50%;
        }
    }

    .sideBarDropdown .dropdown-menu {
        inset: auto 6px auto auto !important;
        transform: translate(0px, 0px) !important;
        padding: 7px 5px !important;
        background: #fff !important;
        border-radius: 4px !important;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
        width: 250px;
        border: 1px solid #ebedf1;
        /* Shadow / Bottom / Medium / M */
    }

    .sideBarDropdown .dropdown-toggle {

        background: transparent !important;
        border: none !important;
        padding-right: 0px !important;

    }





    .sideBarDropdown .dropdown-item {
        font-family: "Archivo";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #616778 !important;
        margin-bottom: 4px;
        padding: 0.38rem 1rem;
    }

    .sideBarDropdown .dropdown-item:hover {
        border-radius: 2px;
        background-color: #fbfbfb;
    }

    .divider {
        color: lightgray;
        margin: 0px !important;
    }

    .msp-search {
        background: rgba(255, 255, 255, 0.08);
        border-radius: 4px;
        height: 32px;

        input {
            background: transparent;
            border: none;
            color: rgba(255, 255, 255, .8);

            &::placeholder {
                color: rgba(255, 255, 255, 0.4);
                opacity: 0.6;
            }
        }
    }

}

.client-view-body {
    overflow-y: auto;
    height: calc(100dvh - 101px);
}