.drilldownBox {
    /* White */
    background: #FFFFFF;
    /* Light garay */
    border: 1px solid #EBECF1;
    box-sizing: border-box;
    border-radius: 16px;
    height: 86px;
}

@media (max-width: 576px) {
    .drilldownBox {
        height: 100%;
    }
}

.drilldownBox_Background {
    /* Light gray BG */
    padding: 1em 0;
    height: 100%;
    background: #F6F8FB;
    border-radius: 8px;
}

.drilldownBox_Background span {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-content: center;
    height: 100%;
}

.drilldownBox_Background span h3 {
    padding-left: .7em;
    margin-bottom: 0;
}

.drilldownText {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.drilldownSideText {
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    margin: 0 !important;
    /* Black */
    color: #101010;
}

#dropdown-category {
    background: #EBECF1;
    color: #000;
}

#dropdown-status {
    background: #EBECF1;
    color: #000;
}