@use './breakpoints.scss' as *;

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
// https://source.unsplash.com/random/300×300
body {
  font-family: "Archivo", sans-serif;
}
html {
  cursor: image-set(
        url("../public/cursor2/default.png") 1x,
        url("../public/cursor2/default@2x.png") 2x
      )
      8 8,
    default;
  input,
  textarea {
    cursor: image-set(
          url("../public/cursor2/text.png") 1x,
          url("../public/cursor2/text@2x.png") 2x
        )
        15 16,
      text;
  }
}

body {
  .highground-royal-font {
    //styleName: Headings/Heading 3;
    font-family: "Syne", sans-serif !important;
  }
}

select {
  font-family: "Archivo", sans-serif !important;
}

option {
  font-family: "Archivo", sans-serif !important;
}
:root {
  --context-menu-item-bg: #ececec90;
  --context-menu-item-red-bg: #f3606020;
  --mobile-menu-item-selected-background: #e8eaed;
}

.cybercare_container {
  background: #ffffff;
  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 24px;
}

.linkGreyText {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;

  /* Gray */

  color: #b6b6bf;
}

.btn-grey {
  background: #ebecf1;
  color: black !important;
  border: none;

  &:focus,
  &:hover,
  &:active {
    background: #ebecf1 !important;
    border: none !important;
    box-shadow: none !important;
  }
}

.btn-disable {
  background: #ebecf1 !important;
  color: #b6b6bf !important;
  border: 1px solid #ebecf1 !important;
  pointer-events: none;

  &:hover,
  &:active,
  &:focus {
    background: #ebecf1;
    color: #b6b6bf;
    border: 1px solid #ebecf1;
    box-shadow: none !important;
  }
}

.btn-blue {
  background: #008fd4;
  color: white;
}

.btn-blue:active {
  background: #008fd4;
  color: white;
}

.btn-blue:hover {
  background: #008fd4;
  color: white;
}

.btn-blue:focus {
  background: #008fd4;
  color: white;
}

.btn-red {
  background: #f36060;
  border: none;
}

.btn-red:focus {
  background: #f36060 !important;
  border: none !important;
  box-shadow: none;
}

.btn-red:active {
  background: #f36060 !important;
  border: none !important;
  box-shadow: none;
}

.btn-red:hover {
  background: #f36060 !important;
  border: none !important;
}

$emptyBacground: #ebecf1;
$red: #f36060;
$green: #40d88e;
$yellow: #ffd11d;
$orange: #ffac2c;
$blue: #60e4f3;
$darkTextColor: #101010;
$greyTextColor: #b6b6bf;

$linerGradientFill: linear-gradient(
  180deg,
  $red 0% $orange 34.38%,
  $yellow 69.79%,
  $green 100%
);

// Cyber Compliance
.wrapper {
  position: relative;
  width: 100%;

  canvas {
    position: relative;
    z-index: 2;
  }

  .percentage {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: $darkTextColor;
    width: 60%;
    height: 60%;
    border-radius: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #ffffff;
    box-shadow: 0px 16px 32px rgba(96, 228, 243, 0.32);
  }
}

// Cyber Score
.score {
  position: absolute;
  top: 15%;
  left: 22%;

  &__title {
    margin-bottom: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: $darkTextColor;
  }

  &__number {
    font-weight: 500;
    font-size: 2.5em;
    line-height: 44px;
    color: $darkTextColor;
    width: 65%;
    height: 65%;
    border-radius: 80px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;

    &--red-shadow {
      box-shadow: 0px 16px 40px rgba(255, 92, 91, 0.16);
    }

    &--orange-shadow {
      box-shadow: 0px 16px 40px rgba(255, 133, 58, 0.16);
    }

    &--yellow-shadow {
      box-shadow: 0px 16px 40px rgba(255, 209, 29, 0.16);
    }

    &--green-shadow {
      box-shadow: 0px 16px 40px rgba(59, 217, 143, 0.16);
    }
  }

  &__message {
    width: 100%;
    display: block;
    text-align: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: $darkTextColor;
  }

  &__wrapper {
    position: relative;
    max-width: 430px;
    height: 100%;

    // max-width: 430px;
    // height: 400px;
    canvas {
      position: relative;
      z-index: 2;
    }

    .status {
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: $greyTextColor;
      position: absolute;

      &.color {
        font-weight: bold;
      }

      &--dangerous {
        left: -3%;
        // top: 160px;
        top: 54%;

        &.color {
          color: $red;
        }
      }

      &--poor {
        top: -1%;
        left: 31.3953488372093%;

        &.color {
          color: $orange;
        }
      }

      &--good {
        right: 25%;
        top: 3%;

        &.color {
          color: $yellow;
        }
      }

      &--excellent {
        right: -3%;
        top: 54%;

        &.color {
          color: $green;
        }
      }
    }
  }

  &__container {
    margin: auto;
    // max-width: 300px;
    width: 70%;
    height: 100%;
    background: url("../public/images/lines.svg") no-repeat left center /
      contain;
  }
}

// Cyber Threat level
.bar {
  position: relative;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f36060),
    color-stop(69.79%, #ffd11d),
    to(#40d88e)
  );
  opacity: 0.8;
  width: 42px;
  height: 275px;
  border-radius: 4px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    color: $darkTextColor;
    margin-bottom: 30px;
  }

  &__inner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: $emptyBacground;
  }

  &__container {
    display: flex;
    position: relative;
  }

  &__scale {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: 30px;
    position: absolute;
    left: 50px;
    top: 0;
    bottom: 0;
    background: url("../public/images/vertical-lines.svg") no-repeat left center /
      contain;

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 0;
      color: $greyTextColor;

      &.color {
        font-weight: bold;
      }

      &.none.color {
        color: $green;
      }

      &.low.color {
        color: $yellow;
      }

      &.moderate.color {
        color: $orange;
      }

      &.high.color {
        color: $red;
      }

      &.critical.color {
        color: $red;
      }
    }
  }

  &__result {
    margin-top: 25px;
    width: 100%;
    max-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 15%;
  }

  &__text {
    padding-top: 15px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: $greyTextColor;
    text-align: center;
    text-transform: capitalize;
  }

  &__status {
    font-weight: 500;
  }
}

.bar-value {
  margin-top: 30px;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: $darkTextColor;
  position: relative;

  &:after {
    content: "";
    width: 16px;
    height: 2px;
    background: #008fd4;
    display: inline-block;
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    color: transparent;
  }
}

#myCanvas {
  border-radius: 4px;
  height: 0%;
  animation: fadeIn 2000ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
}

// @keyframes fadeIn {
//   to {
//     height: 100%;
//   }
// }

.line-chart-wrapper {
  max-width: 100%;
  height: 100%;
  position: relative;
}

.canvas-lable {
  width: 40px;
  height: 21px;

  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #ffffff;
  border-radius: 1px 4px 4px 1px;
  display: flex;
  padding: 4px 8px;
  position: absolute;
  right: 6.6px;
  z-index: 3;

  &:after {
    content: "";
    display: block;
    width: 666px;
    height: 1px;
    background-color: #ebecf1;
    position: absolute;
    left: -666px;
    top: 10px;
    z-index: -2;
  }

  &:before {
    content: "";
    display: block;
    width: 14.56px;
    height: 15.14px;

    transform: rotate(45deg);
    position: absolute;
    left: -7px;
    top: 3px;
    z-index: -1;
    background: inherit;
  }

  &--blue {
    background: #60e4f3;

    &:before {
      background: #60e4f3;
    }
  }

  &--red {
    background: #f36060;

    &:before {
      background: #f36060;
    }
  }

  &--orange {
    background: #ffac2c;

    &:before {
      background: #ffac2c;
    }
  }
}

// canvas {
//   animation-timing-function: cubic-bezier(.3, .8, .6, .2);
// }

.tabs {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 10%;
  margin-bottom: 10px;

  &__item {
    height: 21px;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 13px;
    color: #b6b6bf;
    padding: 4px 8px;
    list-style: none;
    margin-left: 8px;

    &:hover,
    &.active {
      color: #101010;
      background: #ebecf1;
      cursor: pointer;
    }
  }
}

.canvasjs-chart-credit {
  display: none;
}

.hide-trial {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  width: 100px;
  height: 12px;
  background-color: #ffffff;
}

.chartjs-render-monitor {
  height: 100% !important;
  width: 100% !important;
}

.textBlack {
  font-family: Archivo;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
}

.textBlackSmall {
  font-family: Archivo;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
}

.textBlackBold {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: black;
}

.cybercare-table tr th,
.cybercare-table tr td {
  border: 1px solid #ebecf1;
  vertical-align: middle;
  padding-right: 24px;
  padding-left: 24px;

  .linkGreyText {
    line-height: 140%;
  }
}

.cybercare-table tr td {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  padding-top: 16.5px;
  padding-bottom: 16.5px;
}

.cybercare-table2 {
  font-family: Archivo;
}

.cybercare-table2 tr td {
  vertical-align: middle;
  min-height: 64px;
}

.cybercare-table2 tr td .txt-small {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
}

.main-heading {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: #101010;
  line-height: 33.6px;
}

.main-bold-heading {
  font-family: Archivo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: #101010;
  line-height: 33.6px;
}

.txt-blue {
  color: #008fd4 !important;
}

.modal {
  opacity: 1 !important;
}

.modal-backdrop {
  opacity: 0.4 !important;
}
.fade {
  pointer-events: all !important;
}
[id^="left-tabs-example"] {
  opacity: 1 !important;
}

.alert-modal {
  z-index: 11111;

  .modal-content {
    width: 144px;
    padding-top: 20px;

    img {
      width: 42px;
    }

    p {
      color: #ffffff;
      padding-top: 20px;
      font-size: 12px;
      line-height: 13.6px;
    }
  }
}

.alert-success .modal-content {
  background-color: #40d88e;
}

.alert-error .modal-content {
  background-color: $red;
}

.right-alert .modal-dialog {
  margin-right: -135px;
}

.MSP .dashboard {
  height: auto;
  padding: 0 1em;
  overflow-x: hidden;
  background: white;
}

.MSP .dashboard header {
  display: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-family: "Archivo", sans-serif;
}

.linkGreyText {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;

  /* Gray */

  color: #b6b6bf;
}

.smallGreyText {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;

  /* Gray */

  color: #b6b6bf;
}

.btn-grey {
  background: #ebecf1;
  color: black;
}

.greenText {
  color: #40d88e !important;
}

.redText {
  color: #f36060 !important;
}

.orangeText {
  color: #ffa500 !important;
}

.blackText {
  color: #101010 !important;
}

.greyText {
  color: #b6b6bf !important;
}

.greyText2 {
  color: #616778 !important;
}

.p8px {
  padding: 8px !important;
}

.pl8px {
  padding-left: 8px !important;
}

.pr8px {
  padding-right: 8px !important;
}

.p16px {
  padding: 16px !important;
}

.p24px {
  padding: 24px;
}

.pl24px {
  padding-left: 24px !important;
}

.m8px {
  margin: 8px !important;
}

.m16px {
  margin: 16px !important;
}

.p8px {
  padding: 8px !important;
}

.p24px {
  padding: 24px !important;
}

.mt8px {
  margin-top: 8px !important;
}

.mb8px {
  margin-bottom: 8px !important;
}

.mr8px {
  margin-right: 8px !important;
}

.mr16px {
  margin-right: 16px !important;
}

.ml16px {
  margin-left: 16px !important;
}

.mb16px {
  margin-bottom: 16px !important;
}

.mt24px {
  margin-top: 24px !important;
}

.ml24px {
  margin-left: 24px !important;
}

.mr24px {
  margin-right: 24px !important;
}

.ml40px {
  margin-left: 40px !important;
}

.mr40px {
  margin-right: 40px !important;
}

.m40px {
  margin: 40px !important;
}

.mb40px {
  margin-bottom: 40px !important;
}

.ml56px {
  margin-left: 56px !important;
}

.heading2 {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 17px;
}

.heading3 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
}

.heading4 {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
}

.bar-filled {
  background: #40d88e;
  border-radius: 99px;
  width: 100%;
  height: 8px;
  margin-right: 2px;
}

.bar-filled-red {
  background: #f36060;
  border-radius: 99px;
  width: 100%;
  height: 8px;
  margin-right: 2px;
}

.bar-filled-orange {
  background: #ffac2c;
  border-radius: 99px;
  width: 100%;
  height: 8px;
  margin-right: 2px;
}

.bar-active {
  border: 1px solid #40d88e;
  border-radius: 99px;
  width: 100%;
  height: 8px;
  margin-right: 2px;
  margin-left: 2px;
}

.bar-not-active {
  background: #ebecf1;
  border-radius: 99px;
  width: 100%;
  height: 8px;
  margin-left: 2px;
}

.grey-input,
.grey-select {
  border: 1px solid transparent;
}

.grey-input .input-group-text,
.grey-input .form-control,
.grey-select .form-control {
  border: 1px solid transparent;
  background: #f6f8fb;
  height: 46px;
  padding-top: 13px;
  padding-bottom: 13px;
}

.grey-input .input-btn {
  background: rgba(0, 143, 212, 0.08);
  color: rgb(0, 143, 212);
  padding: 6px 14px;
  border-radius: 4px;
}

.grey-input .form-control::-webkit-input-placeholder {
  color: #b6b6bf;
}

.small-grey-select .form-control {
  border: 1px solid transparent;
  background: #f6f8fb;
  border-radius: 4px;
}

.small-grey-select {
  border: 1px solid transparent;
  border-radius: 4px;
}

.grey-select,
.small-grey-select {
  background: 
    #f6f8fb;
  background-size: 14px;
  background-position-x: 96%;
}

.grey-select select.form-control,
.small-grey-select select.form-control {
  background-color: transparent;
  -webkit-appearance: none;
  cursor: pointer;
}

.rightModal .form-control::-webkit-input-placeholder {
  color: #b6b6bf;
}

.rightModal textarea {
  border: 1px solid transparent;
  background: #f6f8fb;
  padding: 16px;
}

.grey-textarea {
  border: 1px solid transparent;
  background: #f6f8fb;
  padding: 16px;
}

.light-blue-btn {
  background: rgba(0, 143, 212, 0.125);
  color: rgb(0, 143, 212);
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}

.light-red-btn {
  background: rgba(254, 95, 91, 0.08);
  color: #f36060;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: rgba(254, 95, 91, 0.08) !important;
    border: none;
    color: #f36060 !important;
    box-shadow: none !important;
  }
}

.light-green-btn {
  background: rgba(64, 216, 142, 0.08);
  min-width: 80px;
  color: #40d88e;
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: rgba(64, 216, 142, 0.08) !important;
    border: none;
    color: #40d88e !important;
    box-shadow: none !important;
  }
}

.green-btn {
  background: #40d88e !important;
  color: #ffffff;
  padding: 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: #40d88e !important;
    border: none;
    color: #ffffff !important;
    box-shadow: none !important;
  }
}

.smallTxt {
  font-family: Archivo;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 140%;
}

.circle-blue {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #3d42de;
  color: #ffffff;
  text-align: center;
  padding-top: 6px;
  margin-right: 8px;
}

.circle-small {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  margin-right: 8px;

  img {
    width: 100%;
  }
}

.circle-green {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: #40d88e;
  color: #ffffff;
  text-align: center;
  padding-top: 24px;
  margin: auto;
}

.circle-light-green {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  background: rgba(64, 216, 142, 0.08);
  text-align: center;
  margin: auto;

  .main-heading {
    color: #40d88e !important;
  }
}

.round-checkbox {
  position: relative;
}

.round-checkbox label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.grey-checkbox label {
  width: 14px;
  height: 14px;
}

.round-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 12px;
}

.round-checkbox.grey-checkbox label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 4px;
  left: 3px;
  opacity: 0;
  position: absolute;
  top: 3px;
  transform: rotate(-45deg);
  width: 7px;
}

.round-checkbox input[type="checkbox"] {
  visibility: hidden;
}

.round-checkbox input[type="checkbox"]:checked + label {
  background-color: #40d88e;
  border-color: #40d88e;
}

.round-checkbox.blue-checkbox input[type="checkbox"]:checked + label {
  background-color: #008fd4;
  border-color: #008fd4;
}

.round-checkbox.blue-checkbox input[type="radio"]:checked + label {
  background-color: #008fd4;
  border-color: #008fd4;
}

.round-checkbox.grey-checkbox input[type="checkbox"]:checked + label {
  background-color: #b6b6bf;
  border-color: #b6b6bf;
}

.round-checkbox input[type="checkbox"]:checked + label:after {
  opacity: 1;
}

.round-checkbox input[type="radio"]:checked + label:after {
  opacity: 1;
}

.profile-container {
  position: relative;
  height: 80px;
  width: 80px;
  background: #ebecf1;
  border-radius: 50%;
}

.profile-container .profile-img {
  display: block;
}

.profile-container .camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-upload {
  position: relative;
  height: 80px;
  width: 80px;
  background: rgba(0, 143, 212, 0.08);
  border-radius: 16px;
  border: 1px dashed #008fd4;
}

.logo-upload .profile-img {
  display: block;
}

.logo-upload .camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn-group {
  padding: 4px;
  background-color: #ebecf1;
  border-radius: 8px;
}

.btn-group .btn {
  background: #ebecf1;
  padding: 12px 52px;
  color: #101010;
  border: 1px solid #ebecf1;
  padding: 8px 48px;

  &.focus,
  &.active,
  &:active,
  &:focus {
    background-color: #008fd4 !important;
    border: 1px solid #ebecf1 !important;
    color: #ffff;
    box-shadow: none;
  }
}

.main-container {
  display: block;
}

.center-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.container-grey {
  margin: 8px;
  border: 16px solid #f6f8fb;
  border-radius: 8px;
}

.inner-container {
  border: 1px dashed #b6b6bf;
  border-radius: 4px;
  height: 100%;
}

.modal-large .modal-content {
  // width: 950px;
  // right: 30em;
}

.modal-medium .modal-content {
  // width: 536px;
}

.circle-crop .ReactCrop__crop-selection {
  border-radius: 50%;
}

.integrationModal ::-webkit-scrollbar {
  width: 0px;
}

.integration_tabs a.logout-tab {
  color: #f36060 !important;
  margin-left: auto;

  &:hover {
    border-bottom: 1px solid #ebecf1 !important;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 1s;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 1s;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeOutIn {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 1s;
}

@-webkit-keyframes fadeOutIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutIn {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.small_container {
  background: #ffffff;
  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
}

.circle-light-blue {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: rgba(0, 143, 212, 0.08);
  color: #008fd4;
  text-align: center;
  padding-top: 6px;
  margin-right: 8px;
}

.cybercare-tabs {
  @include respond-to(md) {
    padding: 0 24px;
  }
}

.governance-tabs-content {
  .tab-content,
  .cybercare-tab-content {
    height: 100%;
  }
}

.cybercare-tab-content {
  padding: 24px 0;
}

.cybercare-tabs .nav-link.active,
.cybercare-tabs .nav-link:focus,
.cybercare-tabs .nav-link:hover {
  background-color: transparent !important;
  border: 1px solid transparent;
  border-bottom: 1px solid #008fd4;
  color: #008fd4 !important;
}

.rewardsTab {
  position: relative;
}

.rewardsTab .nav-link:nth-child(3)::after {
  content: "";
  position: absolute;
  border-radius: 50%;
  height: 6px;
  width: 6px;
  background-color: red;
}

.shareTab .nav-link:nth-child(2)::after {
  content: "";
  position: absolute;

  border-radius: 50%;
  height: 6px;
  width: 6px;
  background-color: red;
}

.centerModal .modal-header {
  padding: 30px 33px;
}

.centerModal .modal-body {
  padding: 0;
}

.rightModal.right.modal-medium .modal-content {
  overflow: auto;
  height: 96vh;
  right: 0;
  margin: 2vh 0;
  animation-name: slideSuggestMdRightAnimation;
  animation-timing-function: ease;
  animation-duration: 0.56s;
}

.modal-medium ::-webkit-scrollbar {
  width: 0px;
}

.modal-medium.slideHide .modal-content {
  animation-name: slideSuggestMdHideAnimation;
  animation-timing-function: ease;
  animation-duration: 0.56s;
}

@keyframes slideSuggestMdRightAnimation {
  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideSuggestMdHideAnimation {
  100% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  0% {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

.headerBtnModal .modal-header {
  padding: 36px 24px 36px 45px;
}

.grey-container {
  padding: 12px;
  background: #f6f8fb;
  border-radius: 8px;
}

.border-container {
  padding: 12px;
  border-radius: 8px;
  border: 1px solid #ebedf1;
}

.blueBorderCont {
  border: 1px solid #008fd4;
  border-radius: 8px;
  padding: 8px;
}

.greyBorderCont {
  border: 1px solid #ebecf1;
  border-radius: 8px;
  padding: 8px;
}

.blueBorderContNoPadding {
  border: 1px solid #008fd4;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
}

.greyBorderContNoPadding {
  border: 1px solid #ebecf1;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
}

.blurBorderCont {
  border: 1px solid #008fd4;
  border-radius: 8px;
  padding: 8px;
}

.centerModal .modal-content {
  height: 80vh;
}

.rightModal .modal-dialog {
  width: 100%;
  max-width: calc(100% - 30px);
  @include respond-to(md) {
    width: 538px !important;
  }
}

.add-qeustion-modal {
  .body-wrapper {
    height: calc(100% - 70px);
    overflow-y: auto;
  }
  .input-group {
    background-color: #f6f8fb;
  }
  .form-control {
    min-width: 200px;
  }
}

.share-slider::-webkit-scrollbar {
  height: 0px !important;
}

.slideLeft {
  -webkit-animation-name: slideLeft;
  animation-name: slideLeft;
  animation-duration: 0.5s;
}

@keyframes slideLeft {
  0% {
    transform-origin: 0 0;
    transform: translateX(100%);
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    transform-origin: 0 0;
    transform: translateX(0%);
    opacity: 1;
  }
}

.slideUp {
  -webkit-animation-name: slideUp;
  animation-name: slideUp;
  animation-duration: 0.5s;
}

@keyframes slideUp {
  0% {
    transform-origin: 0 0;
    transform: translateY(100%);
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  100% {
    transform-origin: 0 0;
    transform: translateY(0%);
    opacity: 1;
  }
}

.smallSideMenu .sideNav {
  max-width: 120px;
  // -webkit-transition: max-width 0.5s ease-in;
  // -moz-transition: max-width 0.5s ease-in;
  // -o-transition: max-width 0.5s  ease-in;
  // transition: max-width 0.5s ease;
}

.smallSideMenu .sideNav .nav {
  // animation: fadeIn 1000ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
}

.largeSideMenu .sideNav {
  // -webkit-transition: max-width 0.5s ease-in;
  // -moz-transition: max-width 0.5s ease-in;
  // -o-transition: max-width 0.5s  ease-in;
  // transition: max-width 0.5s ease;
}

#root .largeSideMenu .demo-btn {
  padding: 0px 0px !important;
}

.largeSideMenu .sideNav .nav {
  // new new new new new new new new new new new
  // animation: fadeOutIn 1000ms cubic-bezier(0.57, 0.25, 0.65, 1) 1 forwards;
  li span img {
    margin-left: 0.75em; //new
  }

  li {
    margin-bottom: 3px;
  }

  li:hover {
    cursor: pointer;
    background: #f6f8fb;
    border-radius: 4px;

    p {
      color: #616778 !important;
    }

    color: #616778 !important;

    & span img {
      filter: grayscale(68%) brightness(62%);
      -webkit-filter: grayscale(68%) brightness(62%);
      -moz-filter: grayscale(68%) brightness(62%);
    }
  }

  .active-tab {
    background: #f6f8fb;
    border-radius: 4px;

    p {
      color: #616778 !important;
    }

    color: #616778 !important;

    & span img {
      filter: grayscale(68%) brightness(62%);
      -webkit-filter: grayscale(68%) brightness(62%);
      -moz-filter: grayscale(68%) brightness(62%);
    }
  }

  li {
    padding: 0.8em 0 !important;
  }
}

.fill-current {
  fill: currentColor;
}

.gap-4 {
  gap: 4px;
}

.gap-8 {
  gap: 8px;
}

.gap-12 {
  gap: 12px;
}

.gap-14 {
  gap: 14px;
}

.gap-16 {
  gap: 16px;
}

.break-word {
  word-break: break-word;
}

.pre-wrap {
  white-space: pre-wrap;
}

.no-wrap {
  white-space: nowrap;
}

.largeSideMenu .img_wrapper {
  justify-content: space-between;
}

.smallSideMenu .bottomDetails .d-flex {
  justify-content: center;
  width: 100%;
}

.smallSideMenu .bottomDetails .d-flex a {
  margin-left: 0px !important;
  padding: 0px 15px;
}

.smallSideMenu .img_wrapper {
  justify-content: center;
}

.smallSideMenu .sideNav ul li p {
  font-size: 13px;
}

.smallSideMenu #mainBody {
  min-width: calc(100% - 120px);
  // -webkit-transition: min-width 0.5s ease-in;
  // -moz-transition: min-width 0.5s ease-in;
  // -o-transition: min-width 0.5s  ease-in;
  // transition: min-width 0.5s ease;
}

.border-left-rounded {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.border1 {
  border: 1px solid #ebecf1;
}
.border-bottom-gray {
  border-bottom: 1px solid #ebecf1;
}
.border-dashed {
  border: 1px dashed #ebecf1;
}
.border-dashed-dark-grey {
  border: 1px dashed #d4d6dd !important;
}
.border-dark-grey {
  border: 1px solid #d4d6dd !important;
}
.border-red {
  border: 2px solid #f36060;
}

.border-red-1 {
  border: 1px solid #f36060;
}

.border-right-rounded {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.flexCenterBottomAlign {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast {
  min-height: 56px !important;
  border-radius: 8px !important;
}

.Toastify__toast-container {
  width: 310 !important;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
}

.circle-background {
  stroke: #797777;
}

.circle-progress {
  stroke: #ffffff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.messageFileContainer {
  width: 64px;
  border-radius: 4px;
  background: black;
  position: relative;
}

.messageFile {
  height: 64px;
  width: 64px !important;
  border-radius: 4px;
}

.messageFileContainer:hover .messageFile {
  opacity: 0.5;
}

.messageFileContainer:hover .middle {
  opacity: 1;
}

.middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.circle-small-blue {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #008fd4;
  color: #ffffff;
  text-align: center;
  padding-top: 6px;
  margin-right: 16px;
  align-self: center;
}

.circle-small-green {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #40d88e;
  color: #ffffff;
  text-align: center;
  padding-top: 6px;
  margin-right: 16px;
  align-self: center;
}

.circle-small-grey {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #ebecf1;
  color: #b6b6bf;
  text-align: center;
  padding-top: 6px;
  margin-right: 16px;
  align-self: center;
}

.circle-small-orange {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: #ffac2c;
  color: #ffffff;
  text-align: center;
  padding-top: 6px;
  margin-right: 16px;
  align-self: center;
}

.orangeText {
  color: #ffac2c !important;
}

.w-20 {
  width: 19% !important;
  margin-right: 1.25%;

  &:last-child {
    margin-right: 0;
  }
}

.w-80 {
  width: 80%;
}
.w-83 {
  width: 83%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}

.w-40 {
  width: 40%;
}
.w-60 {
  width: 60%;
}
.w-66 {
  width: 66%;
}
.w-0 {
  width: 0px;
}
.w-5 {
  width: 5%;
}
.w-70 {
  width: 70%;
}
.w-75 {
  width: 75%;
}
.w-25 {
  width: 25%;
}
.w-22 {
  width: 22%;
}
.w-28 {
  width: 28%;
}
.w-72 {
  width: 72%;
}
.w-78 {
  width: 78%;
}
.w-30 {
  width: 30%;
}
.w-33 {
  width: 33%;
}
.h-4 {
  height: 4rem;
}
.w-35 {
  width: 35%;
}
.w-55 {
  width: 55%;
}
.w-45 {
  width: 45%;
}
.w-165 {
  width: 165px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(0.7);
  padding-right: 20px;
}

[class^="modalDatePicker"]::-webkit-calendar-picker-indicator {
  filter: invert(0.7);
  padding-right: 10px !important;
}

.form_que .icons {
  visibility: hidden;
}

.form_que:hover .icons {
  visibility: visible;
}

.grey_container {
  background-color: #f6f8fb;
  border-radius: 16px;
  padding: 20px;
}

.preview_container {
  pointer-events: none;
  background-color: #f6f8fb;
  border-radius: 8px;
  padding: 40px 24px 0 24px;

  & .innerContainer {
    background-color: #ffffff;
    border-radius: 8px 8px 0 0;
    padding: 24px 16px;
  }
}

.hideRow td {
  padding: 0px 9px !important;
  text-decoration: line-through;
  transition: padding 2s ease;
}

.hideRow td div {
  max-height: 0px;
  overflow-y: hidden;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  transition: all 2s ease;
}

.hideTechRow .row {
  display: none;
}

.hideTechRow {
  min-height: 0 !important;
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  transition: all 2s ease;
}

.fontBold {
  font-weight: 500 !important;
}

.globalError {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  height: 90%;
  border: 0px solid #ebecf1 !important;
}

.stepFormAccord {
  margin-bottom: 8px;
}

.stepFormAccord .card {
  border-radius: 16px;
}

.stepFormAccord .card button {
  height: 67px;
  text-align: left;
}

.stepFormAccord .card button {
  background-image: url("../public/images/complianceManager/accordianArrow.svg");
  background-repeat: no-repeat;
  background-position: right 24px center;
}

.stepFormAccord .card button:focus,
.stepFormAccord .card button:hover {
  text-decoration: none !important;
  box-shadow: none !important;
}

.addQuestionFocus {
  border: 1px solid #008fd4;
  border-radius: 5px;
  padding: 15px 20px;
}

@media screen and (max-width: 1024px) {
  .imageLoginWrapper {
    display: none !important;
  }

  .wrapperContainer {
    height: 94% !important;
    border: none !important;
    box-shadow: none !important;
    // margin: 40px 9px !important;
    margin-bottom: 0 !important;
    margin-top: 0px !important;
    padding-top: 0px !important;
    margin-left: 9px !important;
    margin-right: 9px !important;
    border-radius: none !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
  }

  // .wrapperContainer :first-child {
  //   padding-top: 0 !important;
  // }
  // .innerLoginWrapper :first-child {
  // max-width: 100% !important;
  // margin-left: 0 !important;
  // }
  .fullHeight {
    background: #ffffff !important;
  }

  .responsivePosition {
    flex: none !important;
    margin-left: 0 !important;
    max-width: 100% !important;
  }

  .loginBg {
    height: 100% !important;
  }

  // .bottomPostion {
  //   bottom: 40px !important;
  // }
  .mobilePaddingTop .form-check-label {
    padding-top: 1px !important;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .imageLoginWrapper {
    display: none !important;
  }

  .responsivePosition {
    flex: none !important;
    margin: 0 auto !important;
    max-width: 68% !important;
  }

  .mobileScreen {
    max-width: 48% !important;
  }
}

.cancelModalWidth {
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    max-width: calc(100% - 30px);
    width: 100%;
    min-width: 100%;
    @include respond-to(md) {
      min-width: 336px !important;
      width: 28% !important;
    }
    min-height: 24% !important;
    height: 200px !important;
  }
}

.compliance_wrapper {
  background: #ffffff;
  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 32px 24px;
}

.compilance-table-wrapper {
  border: none !important;
  padding: 8px 0 0 0 !important;
  border-top: 1px solid #EBECF1 !important;
  border-radius: 0px !important;
  @include respond-to(lg) {
    padding: 40px !important;
    border: 1px solid #EBECF1 !important;
    border-radius: 16px !important;
  }
}

.step_form_content {
  width: 100%;
}

@media screen and (min-width: 1400px) {
  .sticky_wrapper {
    position: -webkit-sticky;
    position: sticky;
    top: 305px;
    height: calc(98vh - 320px);
    overflow-y: auto;
    padding-right: 20px;
  }
}

.sticky_wrapper2 {
  border-top: 1px solid #ebecf1;
  padding-top: 20px;
  padding: 20px 0 0px 0;
  @include respond-to(xl) {
    padding: 20px 24px 0px 24px;
  }
  // position: -webkit-sticky;
  bottom: 2px;
  width: 100%;
  background-color: #fff;
  z-index: 1;
  padding-bottom: 20px;
}

.compilance-selector-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100px;
  flex: 1;
  padding-right: 8px;
}

.fnt32 {
  font-size: 32px;
}

.fnt14 {
  font-size: 14px;
}

.clrgrn {
  color: #40d88e;
}

.clryl {
  color: #ffac2c;
}

.brd_blue {
  border: 1px solid #008fd4 !important;
  border-radius: 5px;
}

.brd-bottom-blue {
  border-bottom: 1px solid #008fd4 !important;
}

.mb30px {
  margin-bottom: 10px;
  padding: 15px 20px;
}

.childQuestions_wrapper {
  border: 1px solid #ebecf1;
  border-radius: 16px;
  padding: 24px 24px 10px 24px;
}

.hidetxt {
  visibility: visible !important;
}

.wflex-20 {
  width: 19% !important;
  margin-right: 1.2%;

  &:nth-child(5n) {
    margin-right: 0 !important;
  }
}

.center-container-loader {
  position: fixed;
  background-color: #fcfdfe;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99999999;
}

.crsrpoint {
  cursor: pointer;
}

.DrillPre {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 307px;
}

.compliance_main_wrapper {
  overflow-x: hidden;
}

.compliance_main_wrapper {
  .step_form_right {
    padding-right: 0px !important;
  }

  .compliance_container {
    margin-left: 0px !important;
  }
}

.step_form_right {
  width: 100%;
  @include respond-to(xl) {
    width: 400px;
    min-width: 400px;
  }
  .steps-selector {
    width: 100%;
    @include respond-to(lg) {
      width: 400px;
    }
    @include respond-to(xl) {
      width: 100%;
    }
  }
}

.step_form_center {
  flex-direction: column-reverse;
  @include respond-to(xl) {
    flex-direction: row;
    gap: 80px;
  }
  .date-review-section {
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
    @include respond-to(md) {
      flex-direction: row;
    }
  }
}

.listwrap {
  max-width: 260px;
}

.compliance_header {
  background: #fff;
}

.compliance_main_wrapper header + div.row .col-12 div.row:first-child {
  background: #fff;
  position: sticky;
  padding-top: 70px;
  top: 58px;
  z-index: 1;
}

.compliance_main_wrapper .col-md-4 .settings_container {
  margin-right: 15px;
  padding-right: 10px;
}

.compliance_main_wrapper header + div.row .col-12 {
  margin-top: 0px !important;
}

.step_form_question {
  padding: 25px 25px 12px 25px;
  background-color: #fff;
  @include respond-to(xl) {
    max-width: calc(100% - 480px);
  }
  
}

.step_form_question_outer {
  position: static; // sticky;
  top: 234px;
  z-index: 1;
  background-color: #fff;
}

.mb0 {
  margin-bottom: 0px;
}

.add_question_bottom {
  border-bottom: 1px solid #ebecf1;
  padding-top: 20px;
  padding-bottom: 30px;
  padding-left: 15px;
}

.compliance_main_wrapper .drilldownHeaders .heading3 a {
  color: #008fd4 !important;
}

.drilldownHeaders {
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 8px;
}

.compliance_main_wrapper .compliance_header .d-flex.justify-content-end {
  margin-right: 22px;
}

.compliance_main_wrapper
  .compliance_header
  .d-flex.justify-content-end
  .btn-blue {
  margin-right: 0px !important;
}

.compliance_main_wrapper .compliance_header hr.mb0 {
  width: calc(100% - 22px);
  margin-top: 8px;
}

.scroll_wrapper_hide {
  z-index: 1000;
  position: absolute;
  height: 50px;
  width: 50px;
  top: -400px;
}

.sopListingWrapper {
  .settings_container {
    table {
      tbody {
        tr:nth-child(even) {
          background: #f6f8fb;
        }

        tr:hover {
          background: rgba(0, 0, 0, 0.075);
        }
      }
    }
  }
}

.cyberComplianceFileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stepInputFormat {
  input[type="date"] {
    height: 47px;
  }
}

.mb_20px {
  margin-bottom: 20px;
}

.wordSpacing {
  word-wrap: break-word;
}

.fonttxt {
  font-size: 24px !important;
  padding-top: 0px !important;
}

.switchFocus .btn.focus,
.switchFocus .btn:focus {
  background: #ebecf1 !important;
  color: black !important;
}

.imgScale {
  width: 48px;
  height: 48px;
  position: relative;
}

.imageScale {
  position: absolute;
  width: 90px;
  top: -20px;
  left: -18px;
}

.imgHeading {
  text-indent: 20px;
}

.billingTab {
  border-bottom: 1px solid #ebecf1 !important;
}

.borderGrey {
  border: 1px solid #ebecf1 !important;
}

.leftGreyBorder {
  border-right: 3px solid #ebecf1;
  margin: 0 10px;
  height: 20px;
}

.billingTab th {
  padding: 12px 10px;
}

.font600 {
  font-weight: 600;
}

.billingTab .nav-item {
  margin-right: 5%;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.TableSpinner:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  // top: 93px;
  // left: 37px;
  width: 11px;
  height: 11px;
  margin-top: 3px;
  // margin-left: 10px;
  border-radius: 50%;
  border: 2px solid #ccc;
  border-top-color: #ebecf1;
  animation: spinner 0.8s linear infinite;
}

.m12px {
  margin: 12px;
}

.PageHelperButton1 {
  background-color: #008fd4 !important;
  color: #fff !important;
  border: none !important;
  padding: 8px 16px;
}

.PageHelperButton1Disabled {
  background-color: #ebedf1 !important;
  color: #9499aa !important;
  border: none !important;
  padding: 8px 16px;
}

.PageHelperButton1:hover,
.PageHelperButton1:active,
.PageHelperButton1:focus {
  background-color: rgba(0, 143, 212, 0.9) !important;
  color: #ffffff !important;
  border-color: #008fd4 !important;
}

.BillingInfo {
  top: -15px !important;
  left: -16px !important;
}

.BillingInfo hr {
  margin: 6px 0;
}

.inptDate {
  position: relative;

  input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

.proServiceScroll {
  position: sticky;
  overflow: scroll;
  height: calc(96vh - 580px);
  overflow-x: hidden;
  min-height: 200px;
}

// .proServiceScroll2 {

// }
// .proServiceScroll2::after {
//   content  : "";
//   position : absolute;
//   z-index  : 1;
//   bottom   : 0px;
//   left     : 0;
//   pointer-events   : none;
//   background-image : linear-gradient(to bottom,
//                     rgba(255,255,255, 0.5),
//                     rgba(255,255,255, 1) 95%);
//   width    : 100%;
//   height   : 24px;
// }
.modalFooter::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: -22px;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 1) 95%
  );
  width: 100%;
  height: 24px;
}

.ScrollFadeText {
  height: 1px;
  width: 100%;
  background-color: #eee;
}

.packageradio {
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.ScrollFadeTop {
  box-shadow: 0px 6px 5px 9px rgba(243, 241, 239, 0.6);
  // box-shadow: 0px -15px 5px -3px #F6F8FB;
  border-top: 1px solid #eee;
  margin-top: 18px;
  // padding-top: 1em;
}

.description_pr {
  border-top: 1px solid #eee;
  margin-bottom: 24px;
  // padding-top: 1em;
}

@media (min-width: 1080px) and (max-width: 1480px) {
  .packsDivs {
    min-width: 200px;
    min-height: 65px !important;

    .round-checkbox {
      z-index: 1;
    }
  }

  .boxdiv {
    .heading3 {
      font-size: 14px;
    }
  }

  .boxdiv {
    position: relative;
  }
}

.packsDivs {
  min-width: 200px;
  min-height: 114px;
}

.errrText {
  color: #f36060;
}

.custom_tooltip2 {
  margin-top: 30px !important;
}

.custom_tooltip2.proService_tooltip .tooltip-inner {
  padding: 15px !important;
  border-radius: 10px !important;
}

.custom_tooltip_access {
  margin-right: 80px;
}
@media (min-width: 1100px) and (max-width: 1200px) {
  .custom_tooltip_access {
    margin-right: 0px;
  }
}
@media (min-width: 1200px) and (max-width: 1300px) {
  .custom_tooltip_access {
    margin-right: 220px;
  }
}
@media (min-width: 1300px) and (max-width: 1400px) {
  .custom_tooltip_access {
    margin-right: 155px;
  }
}
@media (min-width: 1400px) and (max-width: 1500px) {
  .custom_tooltip_access {
    margin-right: 80px;
    .arrow {
      left: -25px !important;
    }
  }
}
@media (min-width: 1500px) and (max-width: 1600px) {
  .custom_tooltip_access {
    margin-right: 175px;
  }
}
@media (min-width: 1600px) and (max-width: 1700px) {
  .custom_tooltip_access {
    margin-right: -200px;
  }
}
@media (min-width: 1700px) and (max-width: 1800px) {
  .custom_tooltip_access {
    margin-right: -150px;
  }
}
@media (min-width: 1800px) and (max-width: 2100px) {
  .custom_tooltip_access {
    margin-right: -240px;
    margin-left: -30px;
  }
}
@media (min-width: 2100px) {
  .custom_tooltip_access {
    margin-right: -555px;
  }
}
.arrow-right {
  .arrow {
    left: -7px !important;
  }
}
.custom_tooltip_access-integr {
  margin-left: 5px;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .custom_tooltip_access-integr {
    margin-left: 80px;
    .arrow {
      left: -25px !important;
    }
  }
}
@media (min-width: 1500px) and (max-width: 1600px) {
  .custom_tooltip_access-integr {
    margin-left: 75px;
    .arrow {
      left: -40px !important;
    }
  }
}
.custom_tooltip_access_risk {
  margin-left: 570px;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .custom_tooltip_access_risk {
    margin-left: 480px;
    // .arrow{
    //   left: -25px !important;
    // }
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .custom_tooltip_access_risk {
    margin-left: 450px;
    .arrow {
      left: 40px !important;
    }
  }
}
.custom_tooltip_access_matrix {
  margin-left: -42px;
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .custom_tooltip_access_matrix {
    margin-left: -30px;
    // .arrow{
    //   left: -25px !important;
    // }
  }
}
@media (min-width: 1600px) and (max-width: 1800px) {
  .custom_tooltip_access_matrix {
    margin-left: -30px;
    .arrow {
      left: 40px !important;
    }
  }
}
.custom_tooltip_access_assessmentmenu {
  margin-left: 0px;
}

.custom_tooltip_access_rightside {
  margin-left: -75px;
  .arrow {
    left: 75px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .custom_tooltip_access_rightside {
    margin-left: 30px;
    .arrow {
      left: -10px !important;
    }
  }
}
@media (min-width: 1500px) and (max-width: 1600px) {
  .custom_tooltip_access_rightside {
    margin-left: 30px;
    .arrow {
      left: -10px !important;
    }
  }
}

.custom_tooltip_access_series {
  margin-left: 82px;
  .arrow {
    left: -85px !important;
  }
}

.uptoHeader {
  position: relative;
  color: #101010;
  text-align: center;
  display: block;
  width: 100%;
  margin-bottom: 5px;
  font-size: 12px;
}

.hourlySliderWrapper {
  color: #101010;

  .hourlySliderContainer {
    display: flex;

    .sliderWrapper {
      width: 80%;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      display: flex;

      .slider {
        margin-left: 5px;
        margin-right: 5px;
        width: 85%;

        .rangeslider {
          height: 8px;
          background: #ebecf1;
          box-shadow: 0px 0px;
        }

        .rangeslider__fill {
          background: #008fd4;
        }

        .rangeslider__handle {
          height: 18px;
          width: 18px;
          border: 1px solid #008fd4;
        }

        .rangeslider__handle:after {
          background-color: transparent;
          box-shadow: 0px 0px;
        }

        .rangeslider__handle-label {
          position: relative;
          top: -25px;
          left: -1px;
          color: #b6b6bf;
        }

        .rangeslider__handle-label:after {
          content: "h";
        }

        .rangeslider__handle-label::selection {
          background: transparent;
          color: #b6b6bf;
        }
      }
    }

    .sliderTextWrapper {
      width: 20%;
    }
  }
}

.boxcheck {
  width: 95%;
  margin-top: 15px;
}

.incrementsWrapper {
  display: flex;
}

.boxdivcheck {
  min-height: 130px !important;
}

.packdivswidth {
  min-width: 20%;

  .heading3 {
    white-space: nowrap;
    font-size: 14px;
  }
}

.loader_enquiry {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.48);
}

.loader_enquiry_inner {
  background: #f6f8fb;
  border: 1px solid #ebecf1;
  border-radius: 16px;
  padding: 24px;
  width: 144px;
  height: 128px;
}

.loading_txt_enq {
  color: #b6b6bf !important;
  font-family: Archivo;
}

.counter_container {
  display: block;
  position: absolute;
  border: 0;
  bottom: 5px;
  width: 100%;
  left: 0;
}

.green_text {
  color: #40d88e !important;
}

.green_text:hover {
  color: #008fd4 !important;
}

.spinnerCustom.text-info {
  color: #008fd4 !important;
}

.input-btn-sorts {
  background: rgba(0, 143, 212, 0.08);
  color: #008fd4;
  padding: 6px 14px;
  border-radius: 4px;
}

.sorts-active {
  background: #008fd4;
  color: #ffffff;
}

.mspimage {
  background: url("../public/images/loginmsp.svg");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 65% 88%;
}

.toltip2 {
  position: absolute;
  left: -5px;
  background: transparent !important;
  border: 0px;
  height: 32px !important;
  opacity: 0;
}

.urgradeTextSidebar {
  position: relative;
  font-weight: 700;
  color: #008fd4 !important;

  &:hover {
    color: #008fd4 !important;
  }

  span {
    position: absolute;
    left: -30px;
    top: -8px;
  }
}

.upgradePro .closeButton {
  position: absolute;
  margin-top: 0px !important;
  right: 12px;
}

.textUpper {
  text-transform: capitalize;
}

.fit-content {
  height: fit-content;
}

.fwb4 {
  font-weight: 450;
}

.font16px {
  font-size: 16px;
}

#mainRow .complianceManagerDropdown .dropdown-item:hover {
  background: #ededed30 !important;
}

.billingCardIcon {
  margin-top: -5px;
}

.billingCardIconText {
  margin-top: 6px !important;
}

.txtcardspace {
  white-space: break-spaces;
  font-size: 14px;
  line-height: 14px;
}

.btn-grey2,
.btn-blue.btn-grey2 {
  background: #ebecf1 !important;
  color: #101010;
}

.btn-grey2:hover {
  background: #ebecf1 !important;
  color: #101010;
}

.btn-grey2:focus,
.btn-blue.btn-grey2:focus {
  background: #ebecf1 !important;
  color: #101010;
  box-shadow: none;
}

.btn-grey2:active,
.btn-blue.btn-grey2:active {
  background: #ebecf1 !important;
  color: #101010;
  box-shadow: none;
}

@media only screen and (min-width: 1366px) {
  .sideNav .linkMenu ul li {
    padding-top: 18px;
  }
}

.comingtooltip {
  background: #f6f8fb;
  margin-left: 10px;
  border-radius: 9999px;
  height: 24px;
  display: inline-flex;
  flex-direction: row;
  font-size: 12px;
  color: #616778;
  justify-content: center;
  align-items: center;
  padding: 2px 10px 2px 2px;
  position: relative;
  top: 2px;
}

.managedSecurityMenu {
  display: inline-flex;
  align-items: center;
}

.form-control:focus {
  box-shadow: none;
}

.no-focus-effect {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

/* To remove Spinner from Input type Number in Chrome */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* To remove Spinner from Input type Number in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.mt-auto {
  margin-top: auto;
}

.mt-8 {
  margin-top: 4rem !important;
}

.verify-banner {
  // background: #f6f8fb;
  height: 38px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);

  // margin-left: -15px;
  // margin-right: -15px;

  .link {
    color: #008fd4;
    cursor: pointer;
  }

  .close-icon {
    cursor: pointer;
  }
}

.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}

.cursor-default {
  cursor: default !important;
}

.autofill-gray {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #f6f8fb inset !important;
  }
}

header {
  padding-right: 0px !important;
}

#mainBody {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.sending-btn {
  background: #f5f5f5 !important;
  border: none !important;
  color: #ada8a8 !important;
}

.sent-btn {
  background: #f5f5f5 !important;
  border: none !important;
  color: #40d88e !important;
}

.border-4 {
  border-radius: 4px;
}

.border-8 {
  border-radius: 8px;
}

// Upload image modal

#root .upload-modal-resolve-zoom-issue {
  zoom: 1 !important;

  .modal-content {
    height: 100vh !important;
  }
}

div#mainBody::-webkit-scrollbar {
  display: none;
}

// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
.disabled-btn {
  background: #ebecf1 !important;
  border-radius: 2px;
  font-family: "Archivo";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #b6b6bf !important;
  border: none !important;
  cursor: default !important;
  pointer-events: none;
  padding: 3px 7px !important;

  &:hover {
    background: #ebecf1 !important;
    border-radius: 2px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #b6b6bf !important;
    border: none !important;
    cursor: default !important;
  }
}

.mr-10rem {
  margin-right: 10rem;
}

.min-400h {
  min-height: 400px;
}

.modal-700h {
  .modal-content {
    max-height: 700px !important;
  }
}

.modal-650-760h {
  .modal-content {
    min-height: 650px !important;
    max-height: 760px !important;
  }
  max-width: 100% !important;
  width: calc(100% - 16px) !important; 
  .modal-content {
    min-width: auto !important;
  }
  @include respond-to(md) {
    width: 600px !important;
  }
}

.f-600 {
  font-weight: 600;
}
.f-700 {
  font-weight: 700;
}

.f-400-imp {
  font-weight: 400 !important;
}

.f-400 {
  font-weight: 400;
}

.f-500 {
  font-weight: 500;
}

.f-blue {
  color: #008fd4;
  &:hover {
    color: #008fd4;
  }
}
#root .f-blue-imp {
  color: #008fd4 !important;
  &:hover {
    color: #008fd4 !important;
  }
  &:focus {
    color: #008fd4 !important;
  }
}
.border-blue {
  border: 1px solid #008fd4;
}
.border-transparent {
  border: 1px solid transparent;
}
.border-grey {
  border: 1px solid #ebecf1;
}

.f-11 {
  font-size: 11px;
}
.f-12 {
  font-size: 12px;
}

.f-8 {
  font-size: 8px;
}

.f-9 {
  font-size: 9px;
}
.f-10 {
  font-size: 10px;
}

.f-13 {
  font-size: 13px;
}


.f-14 {
  font-size: 14px;
}

.f-15 {
  font-size: 15px;
}

.f-16 {
  font-size: 16px;
}

.f-24 {
  font-size: 24px;
}

.f-20 {
  font-size: 20px;
}

.f-17 {
  font-size: 17px;
}

.f-18 {
  font-size: 18px;
}

.f-grey {
  color: #616778;
}

#root .f-grey-imp {
  color: #616778 !important;

  &:hover {
    color: #616778 !important;
  }
}

.f-dark-greyish {
  color: #bbbec9;
}

.f-italic {
  font-style: italic !important ;
}
.f-purple {
  color: #5244b3;
}
.f-white {
  color: #fff;
}
.f-white-light {
  color: #ffffff65;
}

.f-violet {
  color: #3d42de;
}

.f-warning {
  color: #ffac2c;
}

.f-danger {
  color: #f36060;
}

.attack-switch-error {
  label::before {
    border: 1px solid #f36060 !important;
  }
}

.text-justify {
  text-align: justify;
}

.small-workstation-modal {
  .attack-switch-error {
    label::before {
      border: 1px solid #f36060 !important;
    }
  }
}
.blue-dot {
  background: #008fd4;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  display: inline-flex;
  position: absolute;
  top: 33%;
  left: -9px;
}
.f-danger-imp {
  color: #f36060 !important;
}

.onactive-f-danger-imp {
  &:active {
    color: #f36060 !important;
  }
}

.f-darkgrey {
  color: #9499aa !important;
}

.f-black {
  color: #0c0d11;
}

.f-green {
  color: #40d88e;
}

.f-green-light {
  color: #319F6B;
}

#root .f-black-imp {
  color: #0c0d11 !important;
}

.bg-grey {
  background: #f6f8fb;
}
.bg-light-purple {
  background: #9087CE;
}
.bg-light-violet {
  background: #E0E1FA;
}
.bg-light-pink {
  background: #FDE6E6;
}
.bg-white {
  background: #fff !important;
}
.bg-dark-greyish {
  background: #bbbec9;
}
.bg-grey-imp {
  background: #f6f8fb !important;
}

.bg-grey-66 {
  background: #616778;
}

.bg-grey-light {
  background: #f6f8fb30;
}

.bg-black {
  background: #0c0d11;
}

.bg-greyish {
  background: #ebedf1;
}

.bg-greyish-imp {
  background: #ebedf1 !important;
}

.bg-darkgrey {
  background: #d4d6dd;
}

.bg-blue {
  background: #008fd4;
}

.bg-warning {
  background: #ffac2c;
}

.bg-light-warning {
  background: #fff8ee;
}

.bg-warning-light {
  background: #fef2f2;
}

.bg-lightblue {
  background: #ebf6fc;
}

.bg-lightblue-imp {
  background: #ebf6fc !important;
  &:hover {
    background: #ebf6fc !important;
  }
}

.f-danger {
  color: #f36060;
}

.bg-hover-grey {
  &:hover {
    background: #f6f8fb;
  }
}

.bg-hover-blue {
  &:hover {
    background: rgba(0, 143, 212, 0);
  }
}

.bg-hover-little-grey {
  &:hover {
    background: var(--context-menu-item-bg);
  }
}

.bg-hover-dark-grey {
  &:hover {
    background: #ebecf1;
  }
}
.bg-hover-light-grey {
  &:hover {
    background: #ebecf160;
  }
}

.border-ebc {
  border: 1px solid #ebecf1;
}

.bg-hover-grey-light {
  &:hover {
    background: #f6f8fb30;
  }
}

.bg-hover-row {
  cursor: pointer;

  &:hover {
    background: #ebedf1;
  }
}

.radio-shadow {
  label {
    &:hover {
      -webkit-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.4);
      transition: box-shadow 0.2s ease-in;
    }
  }
}

.w-240 {
  width: 240px !important;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-320 {
  width: 320px !important;
}

.radius-4 {
  border-radius: 4px;
}
.radius-24 {
  border-radius: 24px;
}

.radius-16 {
  border-radius: 16px;
}

.radius-8 {
  border-radius: 8px;
}

.radius-12 {
  border-radius: 12px;
}

.radius-2 {
  border-radius: 2px;
}

.h-32 {
  height: 32px;
}

.msp-white-btn {
  padding: 6px 8px !important;
  background: #ffffff;
  border: none;
  font-weight: 500;
  color: #0c0d11;

  &:hover {
    background: #ffffff;
    border: none;
    font-weight: 500;
    color: #0c0d11;
  }

  &:active {
    background: #ffffff !important;
    border: none;
    font-weight: 500;
    color: #0c0d11 !important;
  }
  &:focus {
    background: #ffffff !important;
    border: none;
    font-weight: 500;
    color: #0c0d11 !important;
  }
  &:focus-within {
    background: #ffffff !important;
    border: none;
    font-weight: 500;
    color: #0c0d11 !important;
  }
}
.msp-grey-btn {
  padding: 6px 8px !important;
  background: #ffffff3d !important;
  border: none;
  font-weight: 500;
  color: #ffffffe0 !important;
  border-radius: 2px;
  &:active {
    background: #ffffff3d !important;
    border: none;
    font-weight: 500;
    color: #ffffffe0 !important;
  }
}
.hg-blue-btn {
  padding: 6px 8px !important;
  background: #008fd4;
  border: none;
  font-weight: 500;
  color: #fff;

  &:hover {
    background: #008fd4;
    color: #fff;
  }
  &:active {
    background: #008fd4 !important;
    color: #fff;
  }
}

.hg-purple-btn {
  padding: 5px 7px !important;
  background: #E3E1F3;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: #5244B3;
  border-radius: 9999px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    background: #E3E1F3 !important;
    color: #5244B3 !important;
    border: 1px solid #5244B3;
  }
  &:active {
    background: #E3E1F3 !important;
    color: #5244B3 !important;
    border: 1px solid #5244B3;

  }
}


.hg-blue-btn-small {
  padding: 3px 5px !important;
  background: #008fd4;
  border: none;
  font-weight: 500;
  color: #fff;

  &:hover {
    background: #008fd4;
    color: #fff;
  }
  &:active {
    background: #008fd4 !important;
    color: #fff;
  }
}
.hg-white-btn {
  background: #fff;
  border: none;
  font-weight: 500;
  color: #0c0d11;

  &:hover {
    background: #fff;
    color: #0c0d11;
  }
  &:active {
    background: #fff !important;
    color: #0c0d11;
  }
}
.btn-blue-imp {
  background: #008fd4;
  border: none;
  font-weight: 500;
  color: #fff;

  &:hover {
    background: #008fd4;
    color: #fff;
  }
  &:active {
    background: #008fd4 !important;
    color: #fff;
  }
}

.voting-btn {
  background: #40d88e !important;
  color: #fff;
  border: none !important;

  &:active {
    color: #fff !important;
    border: none !important;
  }
}

.w-max-content {
  width: max-content;
}

.w-fit-content {
  width: fit-content;
}
.h-fit-content {
  height: fit-content;
}
.w-inherit {
  width: inherit;
}

.invert-filter {
  filter: invert(1);
}

.hg-grey-btn {
  padding: 6px 8px !important;
  background: #ebedf1 !important;
  color: #9499aa !important;
  border: none;

  &:active {
    background: #ebedf1 !important;
    color: #9499aa !important;
    border: none !important;
  }
}
.hg-red-btn {
  padding: 6px 8px !important;
  background: #f36060 !important;
  color: #fff !important;
  border: none;

  &:active {
    background: #f36060 !important;
    color: #fff !important;
    border: none !important;
  }
}

.hg-cancel-btn {
  padding: 6px 8px !important;
  background: #f6f8fb !important;
  color: #616778 !important;
  border: none;
  font-weight: 500;

  &:active {
    background: #f6f8fb !important;
    color: #616778 !important;
    border: none !important;
  }
}

.hg-cancel-btn-dark {
  padding: 6px 8px !important;
  background: #EBEDF1 !important;
  color: #9499AA !important;
  border: none;
  font-weight: 500;

  &:active {
    background: #EBEDF1 !important;
    color: #9499AA !important;
    border: none !important;
  }
}

.hg-warning-btn {
  padding: 6px 8px !important;
  background: #FEF2F2 !important;
  color: #F36060 !important;
  border: none;
  font-weight: 500;

  &:active {
    background: #FEF2F2 !important;
    color: #F36060 !important;
    border: none !important;
  }
}

.hg-dark-cancel-btn {
  padding: 6px 8px !important;
  background: #ebedf1 !important;
  color: #616778 !important;
  border: none;
  font-weight: 500;

  &:active {
    background: #ebedf1 !important;
    color: #616778 !important;
    border: none !important;
  }
}

.hg-tranparent-grey-btn {
  padding: 6px 8px !important;
  background: transparent !important;
  color: #616778 !important;
  border: none;
  font-weight: 500;

  &:active {
    background: transparent !important;
    color: #616778 !important;
    border: none !important;
  }
}

.checkboxstyle label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 3px;
  opacity: 0;
  background: none;
  position: absolute;
  top: 4px;
  transform: rotate(-45deg);
  width: 12px;
  border-radius: 0px;
}

.pointer-checkbox {
  input {
    cursor: pointer;
  }
}

.rounded-full {
  border-radius: 999999px;
}

.hg-border {
  border: 1px solid #ebedf1;
}

.hg-border-solid {
  border: 2px solid #ebedf1;
}

.hg-border-solid-imp {
  border: 2px solid #ebedf1 !important;
}

.hg-border-bottom {
  border-bottom: 1px solid #ebedf1;
}

.hg-border-bottom-blue {
  border-bottom: 1px solid #008FD4 !important;
}

.hg-border-top {
  border-top: 1px solid #ebedf1;
}

.hg-divider {
  border-top: 1px solid #ebedf1;
}

.w-max-content {
  width: max-content;
}

.equal-flex {
  flex-basis: 0;
  flex-grow: 1;
}
.equal-width-flex-child {
  flex: 1;
  flex-basis: 0;
}

.flex-1 {
  flex: 1;
}

.grey-input {
  background: #f6f8fb;
  border-radius: 4px;
  height: 40px;
}

.pointer-events-none {
  pointer-events: none;
}

.dropdown-bg-transparent {
  background: transparent !important;
  border: none !important;
}

.bg-transparent {
  background: transparent !important;
}

.onactive-bg-transparent {
  &:active {
    background: transparent !important;
    border: none !important;
  }
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }

  scrollbar-width: none;
}

.border-block {
  border: 1px solid #ebedf1;
  border-radius: 8px;
}

.border-block .border-item {
  border-bottom: 1px solid #ebedf1;
}

.border-block .border-top-item {
  border-top: 1px solid #ebedf1;
}

.border-parent .border-item {
  border-bottom: 1px solid #ebedf1;
}

.border-parent .border-item:last-child {
  border-bottom: 1px solid transparent;
}

.border-parent-right .border-item {
  border-right: 1px solid #ebedf1;
}

.border-parent-right .border-item:last-child {
  border-right: 1px solid transparent;
}

.border-parent-top .border-item-top, .border-item-top {
  border-top: 1px solid #ebedf1;
}

// .border-parent-top .border-item-top:last-child {
//   border-top: 1px solid transparent;
// }
.overflow-y-auto {
  overflow-y: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}

.table-row-parent {
  .table-row-child {
    width: 12rem;
  }
}

.map-integrations-table-row-parent {
  .map-integrations-table-row-child {
    width: 17rem;
  }
}

.hide-caret {
  caret-color: transparent;
}

.hg-bg-white {
  background: white;
}

.input-autofill-issue {
  background: transparent !important;

  &:-internal-autofill-selected {
    background-image: none !important;
    box-shadow: none !important;
    background-color: -internal-light-dark(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0)
    ) !important;
    color: #101010 !important;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active {
    box-shadow: 0 0 0 30px #f6f8fb inset !important;
    background-color: transparent !important;
    border: none;
  }

  &:-webkit-autofill:focus {
    box-shadow: 0 0 0 30px #ebf6fc inset !important;
  }
}

#root {
  .input-error {
    border: 1px solid #f36060 !important;
  }
}

.modal-body {
  .input-error {
    border: 1px solid #f36060 !important;
  }
}

.modal-fixed {
  position: fixed !important;
  top: 0 !important;
}

.small-workstation-modal {
  .input-error {
    border: 1px solid #f36060 !important;
  }

  .dropdown-toggle.input-error {
    border: 1px solid #f36060 !important;
  }
}

.country-list-dropdown {
  button {
    height: 42px;
  }

  .dropdown-menu.show {
    height: 140px;
    overflow-y: scroll;
  }
}

.dropdown-w200 {
  .dropdown-menu.show {
    width: 200px !important;
  }

  .dropdown_item.selected {
    background: transparent !important;
  }
}

.dropdown-w215 {
  .dropdown-menu.show {
    width: 215px !important;
  }

  .dropdown_item.selected {
    background: transparent !important;
  }
}

.line-divider {
  border-top: 1px solid #ebecf1;
}

.line-divider-light {
  border-top: 1px solid rgba(255, 255, 255, 0.24);
}

.border-parent {
  background: #f6f8fb;
}

.border-parent .border-item {
  border-bottom: 1px solid #ebedf1;
}

.border-parent .border-item:last-child {
  border-bottom: 1px solid transparent;
}

.border-right {
  border-right: 1px solid #ebedf1;
}

.border-left {
  border-left: 1px solid #ebedf1;
}

.border-none {
  border: none;
}

div[contenteditable="true"]:focus {
  outline: none;
  border: none;
}

.border-bottom {
  border-bottom: 1px solid #ebedf1;
}
.border-bottom-transparent {
  border-bottom: 1px solid transparent;
}

.border-bottom-transparent-2 {
  border-bottom: 2px solid transparent;
}

.border-top {
  border-top: 1px solid #ebedf1;
}

.image-group {
  margin-left: 15px;

  img {
    margin-left: -10px;
  }
}

.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.w-85 {
  width: 85%;
}

.bread-parent .bread-child {
  color: #616778;
  font-size: 16px;
  font-weight: 500;
}

.bread-parent .bread-child:last-child {
  color: #0c0d11;
  font-size: 16px;
  font-weight: 600;
}

#root .black-dropdown {
  .dropdown-menu.show {
    background: #0c0d11 !important;
    color: rgba(255, 255, 255, 0.64) !important;
  }

  .child_drop:hover {
    background: #f6f8fb30 !important;
  }

  .dropdown-item:hover {
    background: #f6f8fb30 !important;
  }

  .delete-btn {
    color: #f36060 !important;
  }

  hr {
    border-top: 1px solid #ffffff24 !important;
  }
}

.score-modal-dropdown {
  .dropdown-menu.show {
    background: #0c0d11;
  }

  .dropdown_item {
    color: rgba(255, 255, 255, 0.64) !important;
    font-weight: 400 !important;
  }

  .dropdown_item.selected {
    color: rgba(255, 255, 255, 0.64) !important;
    font-weight: 400 !important;
  }
}

.header-bottom-shadow {
  // box-shadow: 0px 9px 7px -16px #111;
}

.bg-transparent-imp {
  background: transparent !important;
}

.cursor-default {
  label {
    cursor: default !important;
  }
}

.cursor-default-label {
  label {
    cursor: default !important;
  }
}

.curs-default {
  cursor: default !important;
}

.white-space-no-wrap {
  white-space: nowrap;
}
.custom-dropdown-toggle {
  color: #616778 !important;

  &:hover {
    color: #616778 !important;
  }

  &:active {
    color: #616778 !important;
  }

  background: transparent !important;
  border: none !important;

  &:active {
    background: transparent !important;
    border: none !important;
  }

  &.hide-after {
    &::after {
      display: none !important;
    }
  }

  &::after {
    background-image: url("../public/images/theme/icons/dropdownarrow.svg");
    background-repeat: no-repeat;
    width: 8px;
    height: 6px;
    border: none !important;
    margin-bottom: -3px;
    transform: scale(1.45);
    margin-left: 0.8rem;
  }
}

.sideNav {
  width: 300px;
  .settings-menu-fixed {
    position: fixed;
    width: 300px;
    margin-top: 2.9rem;
    left: 0;
  }
}


.other-role-editor {
  border: 1px solid #99d2ee;
  padding: 3px;
  border-radius: 4px;
  height: 32px;

  input {
    border: none;
    width: 100%;
    background: transparent;
  }

  button {
    padding: 1px 5px;
  }
}

.public-DraftStyleDefault-ltr {
  margin-top: 7px;
  cursor: text;
}

.small-request-demo {
  display: none !important;
}

.smallSideMenu .img_wrapper {
  .small-request-demo {
    display: flex !important;
    margin-top: 1.6rem;
    background: rgb(235, 246, 252);
    padding: 0.86rem;
    cursor: pointer;
  }

  justify-content: center;
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 6;
  box-shadow: 0px 31px 7px -16px #fff;
  padding-top: 42px !important;
}

.smallSideMenu .sideNav {
  padding: 0em 1em 1em 1em;

  .linkMenu {
    .nav {
      height: 80%;
      overflow-y: auto;
      padding-top: 0px;
    }
  }

  .absoulteBottom {
    position: fixed !important;
    width: 5.6%;
  }
}

.absoulteBottom {
  position: absolute !important;
}

.smallSideMenu {
  .test-blur {
    display: block !important;
  }
}

.parent-visible {
  .child-visible {
    visibility: hidden;
  }

  &:hover {
    .child-visible {
      visibility: visible;
    }
  }
}

.mb-2px {
  margin-bottom: 2px;
}

.grey-border {
  border: 1px solid #ebedf1;
}
.modal-content {
  .grey-border {
    border: 1px solid #ebedf1;
  }
}

.opacity-40 {
  opacity: 0.4;
}

.products-list-summary-table {
  display: grid;
  grid-template-columns: 46% 20% 17% 18%;
}

// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// HIGHGROUND CUSTOM CLASSES FOR GLOBAL stop COMPONENTS:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
.msp-sticky-header {
  position: sticky;
  top: 56px;
  height: 45px;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  z-index: 3;
}

@mixin rings($duration, $delay) {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -8px;
  left: -8px;
  right: 0;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
  border: 8px solid rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  animation-name: ripple;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
  z-index: -1;
}

.request-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  height: 57px;
  width: 57px;
  border-radius: 100%;
  background: #ffffff;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);

  &::after {
    // @include rings(2s, 0s);
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    left: -10px;
    right: 0;
    bottom: 0;
    content: "";
    height: 77px;
    width: 77px;
    border: 6px solid rgba(0, 0, 0, 0.07);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 1s;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
  }

  &::before {
    // @include rings(2s, 0.5s);
    opacity: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    left: -10px;
    right: 0;
    bottom: 0;
    // content: "";
    height: 77px;
    width: 77px;
    border: 8px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
    animation-name: ripple;
    animation-duration: 1.7s;
    animation-delay: 0.5s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.65, 0, 0.34, 1);
    z-index: -1;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale3d(0.75, 0.75, 1);
  }

  to {
    opacity: 0;
    transform: scale3d(1.5, 1.5, 1);
  }
}

//-------------------
@mixin afterBg {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
}

.test-shock {
  &:after {
    content: "";
    position: absolute;
    top: 35%;
    left: 35%;
    // bottom: 0;
    // right: 0;
    border-radius: 50%;
    animation: shockwave 1s 0.65s ease-out infinite;
    height: 30%;
    width: 30%;
    animation: shockwave 1s 0.65s ease-out infinite;
    transform: translate(-50%, -50%);
  }

  &:before {
    content: "";
    z-index: 100;
    position: absolute;
    top: 35%;
    left: 35%;

    border-radius: 50%;
    animation: shockwave 1s 0.5s ease-out infinite;
    height: 30%;
    width: 30%;
    animation: shockwave 1s 0.5s ease-out infinite;
    transform: translate(-50%, -50%);
  }
}

.test-shock-threat {
  &:after {
    content: "";
    position: absolute;
    top: -14px;
    left: -7px;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    animation: shockwave 1s 0.65s ease-out infinite;
    height: 50px;
    width: 50px;
    animation: shockwave 1s 0.65s ease-out infinite;
  }

  &:before {
    content: "";
    z-index: 100;
    position: absolute;
    top: -14px;
    left: -7px;

    border-radius: 50%;
    animation: shockwave 1s 0.5s ease-out infinite;
    height: 50px;
    width: 50px;
    animation: shockwave 1s 0.5s ease-out infinite;
  }
}

@keyframes shockwaveJump {
  0% {
    transform: scale(1);
  }

  40% {
    transform: scale(1.08);
  }

  50% {
    transform: scale(0.98);
  }

  55% {
    transform: scale(1.02);
  }

  60% {
    transform: scale(0.98);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes shockwave {
  0% {
    transform: scale(1);
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.15), inset 0 0 1px rgba(0, 0, 0, 0.15);
  }

  95% {
    box-shadow: 0 0 50px rgba(0, 0, 0, 0), inset 0 0 30px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(2.25);
  }
}

// Continous Rotation

.rotate-full {
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

// Sliding Modal

.hg-sliding-modal {
  border: 1px solid rgb(226, 226, 226);
  height: 100%;
  background: white;
  position: fixed;
  top: 0;
  z-index: 1041; // 200;
  overflow-y: auto;
  width: 100%;

  transition: transform 0.4s ease-in, right 0.4s ease-in;

  &.hg-sliding-modal-draggable-parent {
    right: -100%;
  }
  &:not(.hg-sliding-modal-draggable-parent) {
    transform: translateX(100%);
    right: 0;
  }
}

.hg-sliding-modal.open {
  &.hg-sliding-modal-draggable-parent {
    right: 0%;
  }
  &:not(.hg-sliding-modal-draggable-parent) {
    transform: translateX(0);
  }
  transition: transform 0.4s ease-in, right 0.5s ease-out;
  box-shadow: -39px 0px 88px -71px rgba(0, 0, 0, 0.5);
}

#sliding-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // pointer-events: none;
  z-index: 10;
  // background: red;
}

.z-Index-1041 {
  z-index: 1041 !important;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}
.right-0 {
  right: 0;
}
.left-0 {
  left: 0;
}

.z-Index-100 {
  z-index: 100;
}

.z-Index-100-imp {
  z-index: 100 !important;
}

.z-Index-9-imp {
  z-index: 9 !important;
}

.z-Index-1000 {
  z-index: 1000;
}
.z-Index-0 {
  z-index: 0;
}
.z-Index-1 {
  z-index: 1;
}

.flex-1 {
  flex: 1;
}

// for Drop down

.menu-dropdown {
  .device-dropdown button {
    background: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
  }

  .show {
    .dropdown-toggle {
      border: none;
    }
  }

  .dropdown-menu.show {
    background: #0c0d11;
    box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
    border-radius: 4px;
    // inset: 0px auto auto 30px !important;
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
    color: rgba(255, 255, 255, 0.64);
    width: 165px;
    font-weight: 500;

    .drop-item {
      padding: 5px 12px;

      &:hover {
        background: #ebedf130;
      }
    }
  }
}

.menu-dropdown-light {
  .device-dropdown button {
    background: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
  }

  .show {
    .dropdown-toggle {
      border: none;
    }
  }

  .dropdown-menu.show {
    background: #ffffff;
    text-align: center;
    // box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
    border-radius: 4px;
    // inset: 0px auto auto 30px !important;
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
    color: #616778;
    width: 120px;
    // font-weight: 500;

    .drop-item {
      padding: 4px 0px;

      &:hover {
        background: #ebedf130;
      }
    }
  }
}

.hover-on-show-parent {
  &:hover {
    .hover-on-show-child {
      visibility: visible;
    }
  }

  .hover-on-show-child {
    visibility: hidden;
  }
}

#root .restricted-modal {
  .dropdown-menu.show {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
  }
}

.supply-chain-filter {
  .dropdown-menu.show.dropfilterchild {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
  }

  .dropdown_input {
    height: 27px !important;
  }

  .drop-placeholder {
    color: #b6b6bf !important;
  }
}

.modal-dialog-centered.modal-dialog-scrollable.small-workstation-modal
  .modal-content {
  max-height: 80% !important;
}

//wider modal
//  @media (min-width: 576px){
//     .modal-dialog {
//     min-width: 550px;
//     margin: 1.75rem auto;
//   }
//  }

// .modal-backdrop.show {}

.transparent-backdrop {
  opacity: 0 !important;
}

.questionnaire-filter {
  .dropdown-menu.show {
    inset: auto 0px auto auto !important;
  }

  .dropdown-toggle {
    color: black;
  }

  .dropdownfilter .dropdown-menu.show {
    transform: translate(0px, 4px) !important;
  }

  .filter-date-picker {
    .dropdown-menu.show {
      width: auto !important;
    }
  }
}

input,
textarea {
  outline: none;
}

.highlight-input {
  border: 2px solid transparent !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }

  &.error {
    border: 1px solid #f36060 !important;
  }
}

.error-border {
  border: 1px solid #f36060;
}

.highlight-input-without-borders {
  border: none !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: none !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }
}

.always-highlight-input {
  border: 2px solid #99d2ee !important;
  border-radius: 4px;
  background: #f6f8fb !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }
}
.highlight-input-with-grey-border {
  border: 2px solid #ebedf1 !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }

  &.error {
    border: 2px solid #f36060 !important;
  }
}

.highlight-tags-input-with-grey-border {
  border: 2px solid #ebedf1 !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
  }

  &.error {
    border: 2px solid #f36060 !important;
  }
}

.highlight-textarea-without-border {
  border: none;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    // border: 2px solid #99d2ee !important;
    // border-radius: 4px;
    background: #f6f8fb !important;
  }
}

.border-on-hover {
  border: 1px solid transparent !important;
  &:hover {
    border: 1px solid #d4d6dd !important;
  }
}

.highlight-input-with-grey-border-on-hover {
  border: 2px solid transparent !important;
  &:hover {
    border: 2px solid #ebedf1 !important;
  }

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }
}

.no-highlight-input-with-grey-border {
  border: 1px solid #ebedf1 !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    // border: 2px solid #99D2EE !important;
    border-radius: 4px;
    // background: #f6f8fb !important;
  }
}

.no-highlight-input {
  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border-radius: 4px;
    background: transparent !important;
  }
}

.highlight-input-with-darker-grey-border {
  border: 1px solid #d4d6dd !important;

  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }
}

.highlight-border {
  input {
    border: none;
    background: transparent;
    outline: none;
  }

  &:focus-within {
    border: 2px solid #99d2ee !important;
    border-radius: 4px;
    background: #f6f8fb !important;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.min-w-0 {
  min-width: 0;
}

.w-90 {
  width: 90% !important;
}

.visibility-hidden {
  visibility: hidden;
}
.visibility-visible {
  visibility: visible !important;
}

.faded-dropdown-bottom {
  background: linear-gradient(to top, #939191 0%, transparent 15%);
}
.white-faded-dropdown-bottom {
  background: linear-gradient(to top, #b1aeae 0%, transparent 10%);
}

.interationv2_tagDropdown {
  .dropdown-toggle {
    &::after {
      display: none;
    }
    background: transparent !important;
    border: none !important;
    padding: 0px !important;
  }
}

.dark-backdrop {
  .modal-content {
    box-shadow: 0px 0px 107px rgba(133, 133, 133, 0.9) !important;
  }
}

.disable-sent-questionnaire {
  div {
    color: #9499aa !important;
  }
  input[type="checkbox"]:checked + label {
    background-color: #9499aa !important;
    border-color: #9499aa !important;
  }
  .round label:after {
    background: #9499aa !important;
  }

  .round-checkbox.blue-checkbox input[type="radio"]:checked + label {
    background-color: #9499aa !important;
    border-color: #9499aa !important;
  }
  .hg-blue-btn {
    background: #9499aa !important;
    border: none !important;
    &:active {
      background: #9499aa !important;
      border: none !important;
    }
  }
  .hg-blue-btn-small {
    background: #9499aa !important;
    border: none !important;
    &:active {
      background: #9499aa !important;
      border: none !important;
    }
  }

  input {
    color: #9499aa !important;
    outline: none;
  }
  textarea {
    color: #9499aa !important;
    outline: none;
  }
}

.progress-cursor {
  cursor: progress !important;
}

.stop-all-events {
  div > * {
    cursor: wait !important;
    pointer-events: none;
  }
  div {
    cursor: wait !important;
    pointer-events: none;
  }
  img {
    cursor: wait !important;
    pointer-events: none;
  }
  button {
    cursor: wait !important;
    pointer-events: none;
  }
  .dropdown-toggle {
    cursor: wait !important;
    pointer-events: none;
  }
  .dropdown-menu.show {
    cursor: wait !important;
    pointer-events: none;
  }
  pointer-events: none;
}

.transparent-btn {
  background: transparent !important;
  border: none !important;

  &:focus {
    background: transparent !important;
    border: none !important;
  }

  &:hover {
    background: transparent !important;
    border: none !important;
  }
}
#root #right-side-custom-toastz.forReport {
  .toast-message {
    max-width: 86%;
  }
}

.no-border-shadow-dropdown {
  border: 1px solid transparent !important;
  -webkit-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.msp-client-list-menu-dropdown {
  .device-dropdown button {
    background: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
  }

  .show {
    .dropdown-toggle {
      border: none;
    }
  }

  .dropdown-menu.show {
    background: #0c0d11;
    box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
    border-radius: 4px;
    // inset: 0px auto auto 30px !important;
    // inset: auto 0px auto auto !important;
    // transform: translate(0px, 0px) !important;
    color: rgba(255, 255, 255, 0.64);
    width: 170px;
    font-weight: 500;

    .drop-item {
      padding: 5px 12px;

      &:hover {
        background: #ebedf130;
      }
    }
  }
}

.rotate270 {
  transform: rotate(270deg);
  transform-origin: center;
}

.rotate180 {
  transform: rotate(180deg);
  transform-origin: center;
}

.rotate90 {
  transform: rotate(90deg);
  transform-origin: center;
}

.cp-25 {
  padding: 0.8rem;
}

.msp-color-list-dropdown {
  .dropdown-toggle {
    background: #f6f8fb;
    background: transparent !important;
    border: none;
    border-radius: 4px;

    &:active {
      background: transparent !important;
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    // inset: auto 0px auto auto !important;
    max-height: 200px;
    overflow-y: auto;
    padding-top: 0px;
    padding-bottom: 0px;

    transform: translate(0px, 0px) !important;
    inset: 100% 5px auto auto !important;

    .selected {
      background: #e6e6e780 !important;
    }

    .drop-item {
      &:hover {
        background: #e6e6e750 !important;
      }
    }
  }
}

.white-filter {
  filter: invert(98%) sepia(98%) saturate(9%) hue-rotate(153deg)
    brightness(200%) contrast(103%);
}

.transparent-scroller {
  &:hover {
    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden;
  }

  &:hover {
    &::-webkit-scrollbar {
      width: 8px !important;
    }
  }

  /* width and height for the scrollbar track */
  &::-webkit-scrollbar {
    width: 8px !important;
  }

  /* background color of the scrollbar track */
  &::-webkit-scrollbar-track {
    background-color: #ffffff !important;
  }

  /* color of the scrollbar handle */
  &::-webkit-scrollbar-thumb {
    background-color: #acacac80;
  }

  /* color on hover for the scrollbar handle */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #8d8c8c80;
  }
}

.tooltip-parent {
  &:hover {
    .tooltip-item {
      display: block;
    }
  }
  .tooltip-item {
    display: none;
  }
}
.answer-checker {
  .tiny-check {
    width: 20px;
    height: 4px;
    border-radius: 5px;
  }

  .back-grey {
    background: #ebedf1;
  }

  .back-red {
    background: #f36060;
  }

  .back-yellow {
    background: #ffac2c;
  }

  .back-green {
    background: #40d88e;
  }

  .color-red {
    color: #f36060;
  }

  .color-yellow {
    color: #ffac2c;
  }

  .color-green {
    color: #40d88e;
  }
}
.dropdown-grey-theme {
  .custom_drop-down {
    color: #616778;
  }
  .custom_drop-down .dropdown_item.selected {
    background-color: rgba(0, 143, 212, 0);
  }
  .custom_drop-down .dropdown_item:hover {
    background-color: rgba(0, 143, 212, 0);
  }
  .custom_drop-down .selected-option {
    background-color: rgba(0, 143, 212, 0);
    color: #616778;
    font-weight: 500;
  }
}

.msp_assets_inputs_wrapper > div {
  // flex: 0 0 calc(33.33% - 20px);
  width: 32%;
  margin-right: 5px;
  margin-bottom: 5px;
  input {
    overflow: hidden;
  }
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.msp-package-list-checkbox {
  .circular-checkbox {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #bbbec9;
    outline: none;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
  }

  .circular-checkbox:checked {
    background-color: #008fd4;
    border-color: #008fd4;
  }

  .checkbox-label {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0 !important;
  }

  .checkbox-label::after {
    content: "";
    width: 7px;
    height: 12px;
    border: 3px solid #fff;
    border-top: none;
    border-right: none;
    position: absolute;
    top: 43%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(50deg) scaleX(-1);
    opacity: 0;
    transition: opacity 0.3s;
  }

  .circular-checkbox:checked + .checkbox-label::after {
    opacity: 1;
  }
}

// msp price calculator tables CSS

.per_asset_price_breakdown_table {
  display: grid;
  grid-template-columns: 18% 10% 18% 18% 18% 18%;
  min-height: 4rem;
  align-items: center;
}

.contribution_table {
  display: grid;
  grid-template-columns: 48% 13% 13% 13% 13%;
  min-height: 3rem;
  align-items: center;
}

.seat_pricing_table {
  display: grid;
  grid-template-columns: 75% 25%;
  height: 3rem;
  align-items: center;
}

.seat_pricing_table_mobile {
  display: grid;
  grid-template-columns: 60% 40%;
  height: 3rem;
  align-items: center;
}

.features_table {
  display: grid;
  grid-template-columns: 60% 40%;
  // min-height: 3rem;
  // align-items: center;
}

.features_table_templates {
  display: grid;
  grid-template-columns: 50% 50%;
  // min-height: 3rem;
  // align-items: center;
}

.standard_per_asset_price_breakdown_table {
  display: grid;
  grid-template-columns: 25% 15% 20% 20% 20%;
  min-height: 3rem;
  align-items: center;
}

.standard_per_asset_price_breakdown_table_for_templates {
  display: grid;
  grid-template-columns: 30% 13% 19% 19% 19%;
  height: 3rem;
  align-items: center;
}

.standard_per_asset_price_breakdown_table_for_templates_switch {
  display: grid;
  grid-template-columns: 50% 30% 20%;
  height: 3rem;
  align-items: center;
}

.per_asset_price_breakdown_table_switch {
  display: grid;
  grid-template-columns: 25% 25% 25% 25%;
  height: 3rem;
  align-items: center;
}

.per_asset_total_pricing_table {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  height: 3rem;
  align-items: center;
}

.per_asset_total_pricing_table-updated {
  display: grid;
  grid-template-columns: 55% 20% 25%;
  height: 3rem;
  align-items: center;
}

.per_seat_includes_table {
  display: grid;
  grid-template-columns: 80% 20%;
  height: 3rem;
  align-items: center;
}

.table-wrapper {
  overflow-x: auto;
  display: grid;
}

.per_asset_period-breakdown_table {
  display: grid;
  grid-template-columns: 180px 120px 120px 120px 120px;
  @include respond-to(md) {
    grid-template-columns: 40% 15% 15% 15% 15%;
  }
  height: 3rem;
  align-items: center;
}

.per_asset_period-breakdown_table-for-sell {
  display: grid;
  grid-template-columns: 120px 60px 120px 120px 120px 120px;
  @include respond-to(md) {
    grid-template-columns: 30% 10% 15% 15% 15% 15%;
  }
  height: 3rem;
  align-items: center;
}

.security-capabilities-table {
  display: grid;
  grid-template-columns: 50% 50%;
  // height: 3rem;
  // align-items: center;
}
// .security-capabilities-table {
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
//   gap: 1px;
//   border: 1px solid #ccc;

//   .border-right {
//     border-right: 1px solid #ccc;
//   }

//   .border-bottom {
//     border-bottom: 1px solid #ccc;
//   }

//   .f-500 {
//     font-weight: 500;
//   }

//   .f-400 {
//     font-weight: 400;
//   }

//   .f-12 {
//     font-size: 12px;
//   }

//   .f-14 {
//     font-size: 14px;
//   }

//   .f-black {
//     color: black;
//   }

//   .f-darkgrey {
//     color: darkgrey;
//   }
// }

.nist-component-table {
  display: grid;
  grid-template-columns: 60% 20% 20%;
  // height: 3rem;
  align-items: center;
}

.gauge-graph-text {
  top: 50%;
  left: 50%;
  font-weight: 700;
  font-size: 16px;
  transform: translate(-50%, -50%);

  width: 65px;
  height: 65px;
  border-radius: 50%;
}

.gauge-graph-text-smaller {
  top: 50%;
  left: 50%;
  font-weight: 700;
  font-size: 16px;
  transform: translate(-50%, -50%);

  width: 57px;
  height: 57px;
  border-radius: 50%;
}

.msp-service-update-loader {
  width: 12px;
  height: 12px;
  border: 2px solid #ccc;
  border-top: 2px solid #888;
  border-radius: 50%;
  animation: spin-loader-update 1s linear infinite;
}

@keyframes spin-loader-update {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.parent-hovering {
  &:hover {
    .visible-child {
      display: block;
    }
    .invisible-child {
      display: none;
    }
  }
  .visible-child {
    display: none;
  }

  .invisible-child {
    display: block;
  }
}

.place_absolute_at_center {
  position: absolute;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.hg-progress-wrapper {
  progress {
    vertical-align: middle;
    border-radius: 7px;
    width: 100%;
    height: 5px;

    box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
  }

  progress::-webkit-progress-bar {
    background-color: #d4d6dd;
    border-radius: 7px;
  }

  progress::-webkit-progress-value {
    background-color: #008fd4;
    border-radius: 7px;
    box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
  }
}

.score-question-dropdown {
  .custom_drop-down {
    background: transparent;
  }
  .custom_drop-down .dropdown_item:hover {
    background-color: rgba(0, 143, 212, 0);
  }
}

.border-blue-imp{
  border: 1px solid #008fd4 !important;
}

.transparennt-wrapper-dropdown {
  .dropdown-toggle {
    padding: 0px;
    background: transparent;
    border: none;

    &::active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }
  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: transparent;
    border-color: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset: 150% -98px auto auto !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }

  .right-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: 25px 0 auto -50px !important;
  }

  .pricing-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: 28px 0 auto -7px !important;
  }
}

.security-capabilities-dropdown {
  .dropdown-toggle {
    padding: 0px;
    background: #F6F8FB;
    border-radius: 2px;
    border: none;

    &::active {
      background: #F6F8FB;
      border-radius: 2px;
      border: none;
    }

    &::after {
      display: none;
    }
  }
  &.show {
    .dropdown-toggle {
      border: none;
      background: #F6F8FB;
      border-radius: 2px;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: #F6F8FB;
    border-radius: 2px;
    border-color: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset: 150% -98px auto auto !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.unit-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 240px !important;
    height: 250px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 240px !important;
    height: 250px !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.time-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 180px !important;
    height: 200px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 180px !important;
    height: 200px !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}


.select-product-or-labour-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 424px !important;
    height: 448px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .drop-header {
    position: sticky;
    top: -7px;
    z-index: 10;
    background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 424px !important;
    height: 448px !important;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.transparennt-custom_drop-down {
  .dropdown-toggle {
      padding: 0;
      background: transparent;
      border: none;

      &:active {
          background: transparent;
          border: none;
      }

      &::after {
          display: none;
      }
  }

  &.show {
      .dropdown-toggle {
          border: none;
          background: transparent;
      }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show>.btn-primary.dropdown-toggle {
      background-color: transparent;
      border-color: transparent;
  }

  .dropdown-menu.show {
      width: 218px !important;
      height: 300px !important;
      overflow-y: scroll;
      scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
      display: none;
  }

  .drop-header {
      position: sticky;
      top: -7px;
      z-index: 10;
      background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
      transform: translate(0px, 0px) !important;
      inset: auto auto !important;
      width: 218px !important;
      height: 300px !important;
      overflow-y: scroll;
      scrollbar-width: none;
      position: relative;
  }

  .left-dropdown-dir {
      transform: translate(0px, 0px) !important;
      inset: auto 0 auto auto !important;
  }
}

.select-product-or-security-stack-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 424px !important;
    height: 310px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .drop-header {
    position: sticky;
    top: -7px;
    z-index: 10;
    background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 424px !important;
    height: 310px !important;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.select-tool-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 200px !important;
    height: 300px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .drop-header {
    position: sticky;
    top: -7px;
    z-index: 10;
    background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 200px !important;
    height: 300px !important;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.transparennt-wrapper-dropdown-technology-mapping {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 424px !important;
    height: 300px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .drop-header {
    position: sticky;
    top: -7px;
    z-index: 10;
    background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 424px !important;
    height: 300px !important;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.select-integration-dropdown {
  .dropdown-toggle {
    padding: 0;
    background: transparent;
    border: none;

    &:active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }

  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }

  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
  }

  .dropdown-menu.show {
    width: 225px !important;
    height: 365px !important;
    overflow-y: scroll;
    scrollbar-width: none;
    position: relative;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .drop-header {
    position: sticky;
    top: -7px;
    z-index: 10;
    background-color: white;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    width: 225px !important;
    height: 365px !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.transparennt-wrapper-dropdown-menu {
  .dropdown-toggle {
    padding: 0px;
    background: transparent;
    border: none;

    &::active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }
  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
    .dropdown-menu {
      width: 174px;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: transparent;
    border-color: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset: 150% -98px auto auto !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.no_border_on_active {
  &:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }
}

.secops_training_certificate_overlap_parent {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;

  .overlap_child {
    border: 2px solid #ffffff60;
    margin-left: -7px;
    .overlapping_icon {
      position: absolute;
      bottom: 0;
      right: -3px;
    }
  }

  .overlap_child-2 {
    border: 2px solid #ffffff60;
    margin-left: -14px;
    .overlapping_icon {
      position: absolute;
      bottom: 0;
      right: -3px;
    }
  }
}

.overlap_parent {
  .overlap_child {
    border: 2px solid #ffffff60;
    margin-left: -7px;
    .overlapping_icon {
      position: absolute;
      bottom: 0;
      right: -3px;
    }
  }
}

.risk-matrix-sidebar {
  transition: all 0.3s ease-in-out;

  &.show {
    width: 25%;
  }

  &.hide {
    width: 0%;
    opacity: 0;
  }
}

.transparennt-wrapper-dropdown {
  .risk-edit-drop {
    width: 14vw !important;
    // width: 19vw !important;
    position: absolute;
    inset: 100% 0px auto auto !important;
    margin: 0px;
    transform: translate(0px, 0px) !important;
  }
  .upload-template-drop {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
  }
}

.risk-matrix-delete-modal {
  .modal-content {
    width: 70%;
    height: 187px !important;
  }
}

.highlightable-dropdown {
  .highlight {
    background: #ffdeab;
  }
}

.height-animation {
  transition: height 0.5s ease-in-out;
}

#root .custom_drop-down .dropdown-toggle.opened::after {
  transform: rotate(180deg);
  transform-origin: center;
  right: 8px !important;
}

.member-drop .custom-dropdown-toggle.opened::after {
  transform-origin: center;
  background-image: url("../public/images/theme/icons/dropdownarrow.svg") !important;
  transform: rotate(180deg) scale(1.5) !important;
  margin-bottom: -1px;
}

.msp-add-member-modal .custom-dropdown-toggle.opened::after {
  transform-origin: center;
  background-image: url("../public/images/theme/icons/dropdownarrow.svg") !important;
  transform: rotate(180deg) scale(1.5) !important;
  margin-bottom: -1px;
}

.msp-add-member-modal .user-detail-dropdown.opened::after {
  transform: rotate(180deg) scale(1.5) !important;
  transform-origin: center;
  background-image: url("../public/images/theme/icons/dropdownarrow.svg") !important;
  margin-bottom: -1px;
}

.msp-add-member-modal
  .score-question-dropdown.xx
  .dropdown-toggle.opened::after {
  transform: rotate(180deg) !important;
  transform-origin: center;
  right: 8px;
}

.hidden-dropdown {
  height: 0px !important;
  overflow: hidden !important;
}

.color-wrapper-dropdown {
  .dropdown-toggle {
    padding: 0px;
    background: transparent;
    border: none;

    &::active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }
  &.show {
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: transparent;
    border-color: none;
  }

  .selected {
    border: 1px solid #008fd4;
    border-radius: 2px;
  }
}

.text-ellipse {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Access level CSS
#root .disabled-main-dashboard-menuItem {
  cursor: default !important;
  background: transparent;
  border-radius: 4px;
  a {
    cursor: default !important;
  }

  .managedSecurityMenu {
    span {
      color: #b6b6bf !important;
    }
  }
  .menu-icon {
    img {
      filter: brightness(97%) !important;
    }
  }
}

.pl-3-5rem {
  padding-left: 3.5rem !important;
}

.plus_count_circle {
  border-radius: 999px;
  border: 1px solid var(--white-white-100, #fff);
  background: var(--grayscale-gray-05, #ebedf1);
  display: flex;
  width: 29px;
  height: 29px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.add-edit-tax-rate-modal-wrapper {
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1);
  .add-edit-tax-rate-modal {
    position: absolute;
    background: white;
    transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1);
    border: 1px solid #ebecf1;
    border-radius: 4px;
    transform: scale(0) translate(-20px, 0px) rotateX(360deg);
    top: 0%;
    left: 0%;
  }
  &.show {
    .add-edit-tax-rate-modal {
      width: 75%;
      transform: scale(1) translate(-20px, 0px) rotateX(360deg)
        translate(-0%, -0%);

      -webkit-box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.5);
      -moz-box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 21px -3px rgba(0, 0, 0, 0.5);
      top: 60%;
      left: 17%;
    }
  }
}

.mt-187px {
  .add-edit-tax-rate-modal {
    width: 500px !important;
    margin-top: 187px !important;
  }
}

#root .border-none-imp {
  border: none !important;
}

.list-highlight-class {
  background: #ffdeab !important;
}
.dropdown-highlight-class {
  background: #ffdeab !important;
}

.hg-radio {
  .round-checkbox label:after {
    border-right: none;
    content: "";
    height: 16px;
    left: 1px;
    opacity: 0;
    position: absolute;
    top: 1px;
    width: 16px;
    background: #008fd4;
    border-radius: 50px;
    border: 1px solid white;
  }
}

.apply-all-filter-notification::after {
  content: attr(data-count);
  position: absolute;
  top: 0;
  left: 77%;
  font-size: 8px;
  background: #008fd4;
  color: white;
  height: 16px;
  text-align: center;
  border-radius: 40px;
  padding: 0px 5px;
}

.nested-product-modal-in-service {
  top: 50px !important;
  .sliding-modal-footer {
    padding-bottom: 60px !important;
  }
}

.service-tree-wrapper {
  // Tree structure CSS
  /* ————————————————————–
  Tree core styles
*/
  .tree {
    margin: 0em 1em;
  }

  .tree li {
    line-height: 1.2;
    position: relative;
    // padding: 0 0 1em 1em;
  }

  .tree ul li {
    // padding: 1em 0 0 1em;
  }

  .tree > li:last-child {
    padding-bottom: 0;
  }

  .tree_label {
    position: relative;
    display: inline-block;
  }

  .tree li:before {
    position: absolute;
    top: 0;
    bottom: 0;
    // left: -.5em;
    display: block;
    width: 0;
    border-left: 1px solid #d4d6dd;
    content: "";
  }
  .tree li:first-child {
    margin-left: 0.5rem;
  }
  .tree li:first-child:before {
    top: 0px;
    bottom: 38px;
    left: -1.5em;
  }

  .tree ul li:first-child .tree_label::after {
    height: 1.4em;
    width: 1em;
    left: -2em;
  }

  .tree li:nth-child(2) {
    margin-left: 1.7rem;
  }
  .tree li:nth-child(2):before {
    top: -25px;
    bottom: 38px;
    left: -2.7em;
  }
  .tree ul li:nth-child(2) .tree_label::after {
    height: 1.4em;
    width: 2em;
    left: -3.2em;
  }

  .tree li:nth-child(3) {
    margin-left: 3.7rem;
  }
  .tree li:nth-child(3):before {
    top: -25px;
    bottom: 27px;
    left: -4.7em;
  }
  .tree ul li:nth-child(3) .tree_label::after {
    height: 1.4em;
    width: 4em;
    left: -5.2em;
  }

  .tree_label:after {
    position: absolute;
    top: 0;
    // left: -1.5em;
    display: block;
    // height: 0.5em;
    // width: 1em;
    border-bottom: 1px solid #d4d6dd;
    border-left: 1px solid #d4d6dd;
    border-radius: 0 0 0 0.3em;
    content: "";
  }

  .tree li:last-child:before {
    // height: 1em;
    // bottom: auto;
  }

  .tree > li:last-child:before {
    // display: none;
  }
}

.h-17px {
  height: 17px;
}

.h-90 {
  height: 90%;
}

.h-82 {
  height: 82%;
}

.lockerDarkGreyImage {
  height: 16px;
  width: 14px;
}

.accordionDisabled {
  cursor: pointer;
  padding: 8px 6px;
  width: 100%;
  border: none;
  margin-bottom: 3px;
  font-family: Archivo;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #101010;
  text-align: left;
  outline: none;
  font-size: 14px;
  background: #fff;
  transition: 0.4s;
  position: relative;
  display: flex;
  align-items: center;
}

.accordionDisabled:after {
  content: "";
  width: 30px;
  height: 30px;
  background: url("../public/images/settings-v2/locker-grey.svg") no-repeat;
  top: 10px;
  right: -15px;
  position: absolute;
  display: inline-block;
}

/* Define the animation */
@keyframes blink {
  0%,
  100% {
    background: #d6d6d6; /* Set initial and final color */
  }
  25%,
  75% {
    background: #ebecf1; /* Change color during animation */
  }
}

/* Apply the animation to an element */
.blinking-grey-text {
  animation: blink 1s ease-in-out 4;
}

.currency-dropdown-in-add-item-spend-modal {
  inset: calc(100% + 8px) -8px auto auto !important;
  transform: translate(0px, 0px) !important;
}
.integration-parent-hover {
  .controller-child {
    transition: width 0.35s ease;
    width: 0px;
    overflow: hidden;
  }

  &:hover {
    .controller-child {
      width: 100%;
    }
  }
}
.mobile-menu-bg-selected {
  background-color: var(--mobile-menu-item-selected-background);
}
.no-resize {
  resize: none;
}
.add-amount-currency-dropdown {
  inset: auto 0px auto auto !important;
  transform: translate(0px, 0px) !important;
}

.expand-on-hover {
  transition: width 0.5s ease-in-out;

  div {
    width: 0px;
    overflow: hidden;
    transition: width 0.5s ease-in-out;
  }

  &:hover {
    div {
      width: 100%;
      transition: width 0.5s ease-in-out;
    }
  }
}

.opacity-1-imp {
  opacity: 1 !important;
}

.set-dropdown-right-bottom {
  inset: auto 0px auto auto !important;
  transform: translate(0px, 0px) !important;
}

html {
  scroll-behavior: smooth;
}

.cyber_pre_data_popover_parent {
  .cyber_pre_data_popover {
    top: 0;
    left: 0;
    right: 100%;
    bottom: 0;
    z-index: 0;
    transform: scale(0);
    overflow: hidden;
  }
  &:hover {
    .cyber_pre_data_popover {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 2;
      overflow: visible;
      transform: scale(1);
      background: #f6f8fb;
    }
  }
}
.white-space-pre-wrap {
  white-space: pre-wrap;
}

.search-input {
  height: 22px;
  border: none !important;
}

.underline {
  text-decoration: underline;
}

.learn-more-underline {
  text-decoration: underline;
  color: #008fd4;
}

.upload-only-template-drop {
  inset: auto !important;
  transform: translate(0px, 0px) !important;
}
.text-align-start {
  text-align: start;
}
.text-align-end {
  text-align: end;
}
.text-align-center {
  text-align: center;
}
.toggle-switch-multi-active.custom-control-label {
  background-color: #007bff;
}

.toggle-switch-multi.custom-control-label {
  background-color: #ffffff;
}

.hg-grey-chip {
  font-size: 12px;
  font-weight: 500;
  color: #616778;
  border-radius: 2px;
  background: #ebedf1;
  width: fit-content;
  padding: 3px 10px;
}
// a:hover{
//   text-decoration: none !important;
// }
// a:active{
//   text-decoration: none !important;
// }
// a:focus{
//   text-decoration: none !important;

// }
// .date-time-picker-error{
//   .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active, .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle{
//     border: 1px solid #f36060 !important;
//   }
// }
// input:focus-visible{
//   outline: none;
// }

// .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle{
//   color: #0c0d11;
// }

.panel-opened {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}

.map-integration-scrolling-btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ebedf1;
  box-shadow: 0px 8px 16px 0px #10101014;

  &:hover {
    border: 1px solid #bbbec9;
  }
}

#root .highlight-contain {
  border: 2px solid #99d2ee !important;
}

#root .highlight-contain.required {
  border: 1px solid #F36060 !important;
}

.row-padding {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 18px;
  padding-right: 18px;
}

.member-loader {
  width: 30px;
  height: 30px;
  border: 2px solid #ccc;
  border-top: 2px solid #888;
  border-radius: 50%;
  animation: spin-loader-update 1s linear infinite;
}

.mb-9rem {
  padding-bottom: 9rem !important;
}

.share-package-modal {
  max-width: 600px;

  .note-block {
  }

  .border-bottom {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #ebecf1;
  }

  .chart-size {
    margin-top: -45px;
    max-width: 164px !important;
    max-height: 164px !important;
  }

  .left-block {
    margin-bottom: 16px;
    width: 385px !important;
  }

  .border-top {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #ebecf1;
  }

  .h-22px {
    height: 22px !important;
  }

  .share_table {
    display: grid;
    grid-template-columns: 50% 18% 32%;
    height: 3rem;
    align-items: center;
  }

  .counts-block {
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .modal-header {
    border-bottom: 1px solid #ebecf1 !important;
  }
  .modal-body {
    padding: 20px;
  }
  .modal-content {
    border-radius: 8px;
    max-height: 740px !important;
  }
  .modal-footer {
    border-top: none;
    padding: 9px;
  }

  .bottom-block {
    padding-top: 24px;
    border-top: 1px solid #ebecf1 !important;
  }

  .selected-method {
    margin-top: 16px;
    margin-bottom: 12px;
    color: #ffffff;
    background-color: #008fd4;
    width: 61px;
    height: 28px;
  }
}

.print-package-modal {
  max-width: 480px;

  .note-block {
  }

  .border-bottom {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #ebecf1;
  }

  .chart-size {
    margin-top: -45px;
    max-width: 164px !important;
    max-height: 164px !important;
  }

  .left-block {
    margin-bottom: 16px;
    width: 385px !important;
  }

  .content-block {
    border: 1px solid #ebecf1 !important;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 4px 8px;
  }

  .scroll {
    height: 300px;
    overflow: auto;
  }
  .border-top {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #ebecf1;
  }

  .h-22px {
    height: 22px !important;
  }

  .share_table {
    display: grid;
    grid-template-columns: 50% 18% 32%;
    height: 3rem;
    align-items: center;
  }

  .counts-block {
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .modal-header {
    height: 4px;
    padding: 1px;
    // border-bottom: 1px solid #ebecf1 !important;
  }
  .modal-body {
    max-height: 350px !important;
    padding: 20px;
  }
  .modal-content {
    border-radius: 8px;
    max-height: 420px !important;
  }
  .modal-footer {
    height: 64px !important;
    border-top: 1px solid #ebecf1 !important;
    padding: 9px;
  }

  .bottom-block {
    padding-top: 24px;
    border-top: 1px solid #ebecf1 !important;
  }

  .selected-method {
    margin-top: 16px;
    margin-bottom: 12px;
    color: #ffffff;
    background-color: #008fd4;
    width: 61px;
    height: 28px;
  }
}

.sendd-package-modal {
  max-width: 480px;

  .note-block {
  }

  .border-bottom {
    padding-bottom: 12px;
    margin-bottom: 12px;
    border-bottom: 1px solid #ebecf1;
  }

  .chart-size {
    margin-top: -45px;
    max-width: 164px !important;
    max-height: 164px !important;
  }

  .left-block {
    margin-bottom: 16px;
    width: 385px !important;
  }

  .content-block {
    border: 1px solid #ebecf1 !important;
    border-radius: 8px;
    margin-bottom: 8px;
    padding: 4px 8px;
  }

  .scroll {
    height: 300px;
    overflow: auto;
  }
  .border-top {
    padding-top: 12px;
    margin-top: 12px;
    border-top: 1px solid #ebecf1;
  }

  .h-22px {
    height: 22px !important;
  }

  .share_table {
    display: grid;
    grid-template-columns: 50% 18% 32%;
    height: 3rem;
    align-items: center;
  }

  .counts-block {
    margin-top: 4px;
    margin-bottom: 12px;
  }

  .modal-header {
    height: 4px;
    padding: 1px;
    // border-bottom: 1px solid #ebecf1 !important;
  }
  .modal-body {
    max-height: 350px !important;
    padding: 20px;
  }
  .modal-content {
    border-radius: 8px;
    max-height: 420px !important;
  }
  .modal-footer {
    height: 70px !important;
    border-top: 1px solid #ebecf1 !important;
    padding: 4px 8px;
  }

  .bottom-block {
    padding-top: 24px;
    border-top: 1px solid #ebecf1 !important;
  }

  .selected-method {
    margin-top: 16px;
    margin-bottom: 12px;
    color: #ffffff;
    background-color: #008fd4;
    width: 61px;
    height: 28px;
  }
}

.coming-soon-tag-msp-menu {
  font-weight: 600;
  font-size: 9px;
  color: #616778;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 6px;
  background: #ebedf1;
  border-radius: 9999px;
  height: 16px;
  -webkit-box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.1);
  -moz-box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.1);
  box-shadow: -2px 2px 7px 18px rgba(255, 255, 255, 0.1);
  white-space: nowrap;
}

.important-width {
  max-width: max-content !important;
  min-width: max-content !important;
}

.disabled-button {
  background: #ebecf1 !important;
  border-radius: 2px !important;
  font-family: "Archivo" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #b6b6bf !important;
  border: none !important;
  cursor: not-allowed !important;
}

.disabled-checkbox.round-checkbox.blue-checkbox
  input[type="checkbox"]:checked
  + label {
  background-color: #d4d4d4 !important;
  border-color: #d4d4d4 !important;
  cursor: not-allowed !important;
}
.modal-radius-8 {
  .modal-content {
    border-radius: 8px;
  }
}
.modal-height-auto {
  .modal-content {
    height: auto;
  }
}

.max-w-100 {
  max-width: 100%;
}
.menu-inset-right-corner {
  inset: auto 0px auto auto !important;
  transform: translate(0px, 0px) !important;
}

.roleMenuDropdown {
  .device-dropdown button {
    background: transparent;
    border: none;

    &::after {
      display: none;
    }
  }

  .roleMenuDropdownToggle {
    display: flex;
    background: transparent !important;
    border: none !important;
    align-items: center;

    &:focus {
      border: none !important;
    }
  }

  .dotsMenuDropdownToggle.show {
    border: none !important;
  }

  .dropdown-menu.show {
    padding-top: 10px;
    padding-bottom: 10px;
    // margin-top: 4px !important;
    margin-left: -80px !important;
    background: #0c0d11;
    color: white;
    box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
    border-radius: 4px;
    width: 120px !important;
  }

  .dotsDropdownItem {
    display: flex;
    align-items: center;
    line-height: 22px;
    size: 14px;
    font-weight: 400;
    height: 30px;
    padding-left: 12px;

    .lockerImage {
      height: 13px;
      margin-right: 4px;
    }
  }
}

.integrationv2-map-clients-oauth2-btn {
  button {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 0px;
    color: #008fd4;
    font-weight: 500;
    // margin-top: 5px;
    // border-radius: 4px;

    &:focus {
      border: none !important;
      outline: 0px auto -webkit-focus-ring-color;
    }
  }
}

.GandRModal {
  display: grid;
  grid-template-columns: 35% 65%;
  height: 3rem;
  align-items: center;
}

.GandRModalDate {
  .dropdown-menu.show {
    inset: auto 20px auto auto !important;
    transform: translate(0px, 2px) !important;
  }

  .dropdown-toggle {
    width: 100%;
  }
}

.GandRUpdateFileModal {
  .modal-content {
    max-height: 660px !important;
    border-radius: 8px;
    overflow-x: hidden;
    margin: auto;
  }

  .modal-header {
    border-bottom: 1px solid #ebedf1 !important;
  }

  .nav-link.active {
    color: #008fd4 !important;

    border-bottom: 1px solid #008fd4;
  }

  .input-with-error-border {
    border: 2px solid #f36060 !important;

    input {
      border: none;
      background: transparent;
      outline: none !important;
    }
  }

  .input-date-error {
    border: 2px solid #f36060 !important;
  }
}

.sideBarDropdownUpdated .dropdown-toggle {
  background-color: transparent !important;
  border: none !important;
  padding-right: 0px !important;
}

.hover-scale-integration-card {
  &:hover {
    transform: scale(1.02);
    box-shadow: -5px 0 10px rgba(0.1, 0.1, 0.1, 0.1);

    .hover-over-slider {
      width: 50%;
      transition: width 0.2s ease;
      background: #e6e8ec;
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    }
  }
  transition: transform 0.2s ease;

  .hover-over-slider {
    width: 0px;
    overflow: hidden;
  }
}

.import-template-modal {
  @include respond-to(md) {
    max-width: 320px !important;
  }

  .modal-content {
    max-height: 380px !important;
  }

  .modal-footer {
    padding: 8px !important;
    height: 57px !important;
  }
}

.import-template-modal-details {
  max-width: 800px !important;

  .modal-content {
    max-height: 600px !important;
 
  .blue-checkbox .form-check-input {
    width: 16px;
    height: 16px;
    background-color: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 2px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    transition: background-color 0.2s ease, border-color 0.2s ease;
}

.blue-checkbox .form-check-input:checked {
    background-color: #008FD4;
    border: 1px solid #008FD4;
}

.blue-checkbox .form-check-input:checked::before {
    content: "";
    position: absolute;
    top: 55%;
    left: 46%;
    width: 8px;
    height: 6px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(-45deg) scaleX(-1)
}

    
  }

  .modal-footer {
    padding: 8px !important;
    height: 57px !important;
  }
}

.p1-5 {
  padding: 0.4rem;
}

.contextmenu-grey-item {
  &:hover {
    background: var(--context-menu-item-bg);
  }
  padding: 0.4rem;
  border-radius: 4px;
  &.disabled {
    background: #ebecf1;
    pointer-events: none;
    &:hover {
      background: #ebecf1;
    }
  }
}
.contextmenu-red-item {
  &:hover {
    background: var(--context-menu-item-red-bg);
  }
  padding: 0.4rem;
  border-radius: 4px;
}

.search-box-with-border {
  border: 1px solid #ececec;
  &:focus-within {
    background: #008fd420;
    border: 1px solid #99d2ee;
    background: #f6f8fb;
  }
}

.h-auto {
  height: auto !important;
}

.marketplace-wrapper {
  max-width: calc(100% - 42px);
}

.marketplace-table {
  display: grid !important;
  grid-template-columns: 250px 150px 150px 150px 150px 80px;
  @include respond-to(lg) {
    grid-template-columns: 42% 13% 13% 13% 13% 6%;
  }
  height: 3rem;
  align-items: center;
}

.pricing-cost-table {
  display: grid !important;
  grid-template-columns: 30% 10% 30% 15% 15%;
  height: 3rem;
  align-items: center;
}

.pricing-table-1 {
  display: grid !important;
  grid-template-columns: 66% 34%;
  height: 1rem;
  align-items: center;
}

.pricing-table-2 {
  display: grid !important;
  grid-template-columns: 33% 33% 34%;
  height: 1rem;
  align-items: center;
}

.table-style {
  background-color: #ebedf1 !important;
  // &:hover,
  // &:active,
  // &:focus {
  //   background-color: #EBEDF1 !important;
  // }
}

.table-hover {
  &:hover {
    background: #ebedf130 !important;
  }
}

.service-type-dropdown {
  .dropdown-toggle {
    padding: 0px;
    background: transparent !important;
    border: none !important;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    inset: auto 0px auto auto !important;
    transform: translate(0px, 0px) !important;
    // .blue-icon {
    //     filter: invert(47%) sepia(63%) saturate(3767%) hue-rotate(172deg) brightness(90%) contrast(101%)
    // }
  }

  .dropdown-menu.show {
    height: 200px !important;
    overflow-y: scroll;
    scrollbar-width: none;
  }

  .dropdown-menu.show::-webkit-scrollbar {
    display: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset:  auto auto !important;
    height: 200px !important;
  }

}

.border-gray {
  border: 1px solid #ebedf1 !important;
  border-radius: 8px !important;
}

.line-height-20 {
  line-height: 20px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-28{
  line-height: 28px;
}

.line-height-32{
  line-height: 32px;
}

.line-height-36{
  line-height: 36px;
}

.line-height-18  {
  line-height: 18px;
}

.line-height-12  {
  line-height: 12px;
}

.letter-space-2 {
  letter-spacing: 0.02em; /* or 0.02px */
}

.transparennt-wrapper-dropdown-periods {
  .dropdown-toggle {
    padding: 0px;
    background: transparent;
    border: none;

    &::active {
      background: transparent;
      border: none;
    }

    &::after {
      display: none;
    }
  }
  &.show {
    .dropdown-menu {
      width: 240px !important;
    }
    .dropdown-toggle {
      border: none;
      background: transparent;
    }
  }
  .btn-primary:not(:disabled):not(.disabled).active,
  .btn-primary:not(:disabled):not(.disabled):active,
  .show > .btn-primary.dropdown-toggle {
    // color: #fff;
    background-color: transparent;
    border-color: none;
  }

  .dropdown-menu.wrapper-dropdown.show {
    transform: translate(0px, 0px) !important;
    inset: 150% -98px auto auto !important;
  }

  .left-dropdown-dir {
    transform: translate(0px, 0px) !important;
    inset: auto 0 auto auto !important;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.client-report-security-capability-wrapper {
  border: 2px solid #40d88e;
  border-radius: 12px;
  overflow: hidden;
  .wrapper-header {
    background: #40d88e;
    color: #fff;
    display: grid;
    grid-template-columns: 45% 25% 30%;

    div {
      &:first-child {
        border-right: 1px solid #fff;
      }
      &:nth-child(2) {
        border-right: 1px solid #fff;
      }
    }
  }

  .wrapper-row {
    .line-row {
      display: grid;
      grid-template-columns: 45% 25% 30%;
      &:first-child {
        border-top: 1px solid #EBEDF1;
      }
      
      border-bottom: 1px solid #EBEDF1;
      > div {
        &:nth-child(1),
        &:nth-child(2) {
          border-right: 1px solid #EBEDF1;
        }
      }
    }
  }
}
.client-report-security-improve-wrapper {
  border: 2px solid #FFAC2C;
  border-radius: 12px;
  overflow: hidden;
  .wrapper-header {
    background: #FFAC2C;
    color: #fff;
    display: grid;
    grid-template-columns: 45% 25% 30%;

    div {
      &:first-child {
        border-right: 1px solid #fff;
      }
      &:nth-child(2) {
        border-right: 1px solid #fff;
      }
    }
  }

  .wrapper-row {
    .line-row {
      display: grid;
      grid-template-columns: 45% 25% 30%;
      &:first-child {
        border-top: 1px solid #EBEDF1;
      }
      
      border-bottom: 1px solid #EBEDF1;
      > div {
        &:nth-child(1),
        &:nth-child(2) {
          border-right: 1px solid #EBEDF1;
        }
      }
    }
  }
}
.client-report-security-incomplete-wrapper {
  border: 2px solid #F6F8FB;
  border-radius: 12px;
  overflow: hidden;
  .wrapper-header {
    background: #F6F8FB;
    color: #616778;
    display: grid;
    grid-template-columns: 60% 40%;

    div {
      &:first-child {
        border-right: 1px solid #fff;
      }
      
    }
  }

  .wrapper-row {
    .line-row {
      display: grid;
      grid-template-columns: 60% 40%;
      &:first-child {
        border-top: 1px solid #EBEDF1;
      }
      
      border-bottom: 1px solid #EBEDF1;
      > div {
        &:nth-child(1)  {
          border-right: 1px solid #EBEDF1;
        }
      }
    }
  }
}



.client-report-attack-surface-overview{
background: #F6F8FB;
.overview-asset-item{
display: grid;
grid-template-columns:35% 55% 10%;
}
}

.client-report-attack-surface-microsoft{
  .mfa-table{
    border:1px solid #EBEDF1;
    overflow: hidden;
    border-radius: 8px;
    .cell {
      padding: 8px 16px;
    }
    .wrapper-header {
      background: #F6F8FB;
      color: #616778;
      display: grid;
      grid-template-columns: 56% 22% 22%;
  
      div {
        &:not(:last-child) {
          border-right: 1px solid #EBEDF1;
        }
        
      }
    }
  
    .wrapper-row {
      .line-row {
        display: grid;
        grid-template-columns: 56% 22% 22%;
        &:first-child {
          border-top: 1px solid #EBEDF1;
        }
        
        border-bottom: 1px solid #EBEDF1;
        > div {
          &:not(:last-child) {
            border-right: 1px solid #EBEDF1;
          }
        }
      }
    }
  }
  .no-data {
    border: 2px solid #F6F8FB;
    border-radius: 12px;
  }
}
.client-report-security-device-wrapper {
  border: 2px solid #F6F8FB;
  border-radius: 12px;
  overflow: hidden;
  .cell {
    padding: 8px 16px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .wrapper-header {
    background: #F6F8FB;
    color: #616778;
    display: grid;
    grid-template-columns: 30% 25% 15% 15% 15%;

    div {
      &:not(:last-child) {
        border-right: 1px solid #EBEDF1;
      }
      
    }
  }

  .wrapper-row {
    .line-row {
      display: grid;
      grid-template-columns: 30% 25% 15% 15% 15%;
      &:first-child {
        border-top: 1px solid #EBEDF1;
      }
      
      border-bottom: 1px solid #EBEDF1;
      > div {
        &:not(:last-child) {
          border-right: 1px solid #EBEDF1;
        }
      }
      .period-label {
        background-color: #EBEDF1;
        border-radius: 9999px;
        padding: 0 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        .icon {
          margin: 4px 2px;
        }
        .text {
          font-size: 12px;
          line-height: 18px;
          font-weight: 500;
          color: #616778;
          padding: 1px 4px;
        }
      }
    }
  }
}


.client-report-security-action-centre-wrapper{
  .wrapper-item{
    display: grid;
    grid-template-columns: 20% 20% 20% 15% 10% 15%;
  }
}

.client-report-price-breakdown-table{
  border: 1px solid #ebecf1;
  border-radius: 8px;
overflow: hidden;
  .table-item{
    display: grid;
    grid-template-columns: 20% 20% 20% 15% 10% 15%;
  }
}

.client-report-cyber-score{ 
.status{
  display: none;
}
.score{
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}
.score__container{
  background: transparent;
}


}



.nist-parent {
  .nist-child {
    width: calc(50% - 1px); /* Adjust for border spacing */
    
    // box-sizing: border-box;
  }
 
}

.nist-parent > .nist-child:nth-child(1) {
  
}
.nist-parent > .nist-child:nth-child(2) {
  border-left: 1px solid #ebecf1;
  border-bottom: 1px solid #ebecf1;
}
.nist-parent > .nist-child:nth-child(3) {
  border-top: 1px solid #ebecf1;
  border-right: 1px solid #ebecf1;
}
.nist-parent > .nist-child:nth-child(4) {
  // border-top: 0;
}
.nist-parent > .nist-child:nth-child(5) {
  border-top: 1px solid #ebecf1;
  border-right: 1px solid #ebecf1;
}
.nist-parent > .nist-child:nth-child(6) {
  border-top: 1px solid #ebecf1;
}

.cliet-report-cover-note{
  &.covernote .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
  }
  /* Snow Theme */
  &.covernote .ql-snow.ql-toolbar {
    display: block;
    // background: #eaecec;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  /* Bubble Theme */
  &.covernote .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
  }
  &.covernote .ql-editor {
    min-height: 18em;
  }
  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }
}

.covernote-text{
  p{
    margin: 0;
  }
  img {
    max-width: 100%;
  }
}

.export-modal-close-icon{
  &:hover{
    background: #e4e2e2;
  }
}


.export-modal-tech-subscriptions-grid{
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  gap: 10px;
  // padding: 20px;
}

.line-through-text {
  text-decoration: line-through;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.my-dropdown {
  .toggle {
    box-shadow: none !important;
    &[aria-expanded="true"] {
      .arrow {
        transform: rotate(180deg);
      }
    }
  }
  .menu {
    background-color: #fff;
    border: 1px solid #EBEDF1 !important;
    border-radius: 4px;
    box-shadow: 0px 12px 24px 0px #10101014;
    &.fixed {
      position: fixed;
      z-index: 1000;
    }
    &.disable-transform {
      transform: none !important;
      inset: auto !important;
      &[x-placement="top-end"],
      &[x-placement="top-start"] {
        bottom: 100% !important;
      }
    }
    &.overlap-toggle {
      top: 0% !important;
    }
    &.drop-left {
      left: -100% !important;
      top: 0% !important;
    }
    &.align-right {
      right: 0 !important;
    }
    &.align-left {
      left: 0 !important;
    }
    .item {
      &:hover {
        background-color: #ececec90;
      }
    }
  }
  &.show > .dropdown-toggle > .arrow {
    transform: rotate(180deg);
  }
}


// .round-radio2 .custom-radio input[type="radio"] {
//   appearance: none;
//   -webkit-appearance: none;
//   background-color: #fff;
//   border: 1px solid #007bff; /* Border color */
//   border-radius: 50%;
//   width: 16px;
//   height: 16px;
//   position: relative;
//   margin-right: 8px;
//   outline: none;
//   cursor: pointer;
// }

// .round-radio2 .custom-radio input[type="radio"]:checked::before {
//   content: '';
//   display: block;
//   width: auto;
//   height: auto;
//   margin: 1px;
//   border-radius: 50%;
//   background-color: #007bff; /* Inner circle color */
//   position: absolute;
//   top: 1px;
//   left: 1px;
//   bottom: 1px;
//   right: 1px;
// }

.round-radio2 .custom-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  background-color: #fff;
  border: 2px solid #007bff; /* Border color */
  border-radius: 50%;
  width: 18px; /* Slightly larger width */
  height: 18px; /* Slightly larger height */
  position: relative;
  margin-right: 8px;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

.round-radio2 .custom-radio input[type="radio"]:checked::before {
  content: '';
  display: block;
  width: 12px; /* Set inner circle size */
  height: 12px; /* Set inner circle size */
  border-radius: 50%;
  background-color: #007bff; /* Inner circle color */
  position: absolute;
  top: 1.5px; /* Center inner circle vertically */
  left: 1.5px; /* Center inner circle horizontally */
}

.products_grid {
  display: grid;
  grid-template-columns: 67% 10% 10% 13%;
  // height: 3rem;
  align-items: center;
}

.custom-checkbox-button {
  width: 20px;
  height: 20px;
  background-color: #008FD4;
  border-radius: 2px; 
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
}

.checkmark-icon {
  width: 11px;
  height: 8px;
  fill: white; 
}

.right-block {
  min-width: 500px;
  max-width: 500px;
}


.choose-product-modal { 
  .modal-backdrop.show {
    opacity: 0.5 !important;
  }
  
  .modal-backdrop.fade {
    transition: opacity 0s linear;
  }
  .modal-content {
      width: 600px !important;
      min-height: 230px !important;
      // max-height: 700px !important;
      height: auto !important;
      border-radius: 8px !important;
      border: 1px solid #ebedf1;
      box-shadow: 0px 0px 40px rgba(16, 16, 16, 0.08);
  
      .dragger-icon {
          cursor: move;
      }
  
      .choose-product-modal-header {
          padding: 14px !important;
          border-bottom: 1px solid #EBEDF1 !important;
          position: sticky;
          top: 0;
          background: white;
          z-index: 1;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
          border-top: 1px solid #ebedf1;
          border-left: 1px solid #ebedf1;
          border-right: 1px solid #ebedf1;
      }
  
      .choose-product-modal-body {
          padding: 0px 12px;
          height: 600px;
          background: white;
          overflow-y: auto;
          scrollbar-width: none;
      }
  
      .choose-product-modal-body::-webkit-scrollbar {
          width: 0px;
      }
  
  
      .choose-product-modal-footer {
          padding: 4px 0px !important;
          border-top: 1px solid #EBEDF1 !important;
          position: sticky;
          bottom: 0;
          background: white;
          z-index: 1;
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
          border-bottom: 1px solid #ebedf1;
          border-left: 1px solid #ebedf1;
          border-right: 1px solid #ebedf1;
      }
  
      .blue-checkbox-minus .form-check-input {
          width: 16px;
          height: 16px;
          background-color: #FFFFFF;
          border: 1px solid #9E9E9E;
          border-radius: 2px;
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          outline: none;
          cursor: pointer;
          display: inline-block;
          vertical-align: middle;
          position: relative;
      }
  
      .blue-checkbox-minus .form-check-input:checked {
          background-color: #008FD4;
          border: none;
      }
  
      .blue-checkbox-minus input[type="checkbox"]:checked+label {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 16px;
          height: 16px;
          border: 1px solid #000;
          border-radius: 2px;
          outline: none;
          cursor: pointer;
      }
  
      .blue-checkbox-minus input[type="checkbox"]:checked::before {
          content: '−';
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
          line-height: 16px;
          color: white;
          background-color: #008FD4;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          border: 1px solid #008FD4 !important;
          font-weight: bold;
      }
  
      .current-spend-table {
          display: grid;
          grid-template-columns: 50% 14% 8% 14% 14%;
          // grid-template-columns: 52% 12% 12% 12% 12%;
          // height: 3rem;
          // align-items: center;
      }
  
      .current-spend-table-footer-block {
          display: grid;
          grid-template-columns: 40% 20% 20% 20%;
          // height: 3rem;
          // align-items: center;
      }
  
      .category-filter-dropdown {
          .dropdown-toggle {
              &::after {
                  display: none;
              }
  
              background: transparent !important;
              border: none !important;
          }
  
          .dropdown-menu.show {
              min-width: 190px;
              margin-left: 10px !important;
              max-width: 264px !important;
              max-height: 400px !important;
              overflow-y: auto;
              scrollbar-width: none;
          }
  
          .dropdown-menu.show::-webkit-scrollbar {
              width: 0px;
          }
  
  
  
  
      }
  
  }
}

.coming-tag {
  font-weight: 600;
  border-radius: 9999px;
  line-height: 12px;
  font-size: 9px;
  background: #EBEDF1;
  color: #616778;
  -webkit-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
  box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
  
  &:hover {
    background: #D6EDF8;
    color: #008FD4; 
  }
}

.coming-tag-blue {
  font-weight: 600;
  border-radius: 9999px;
  line-height: 12px;
  font-size: 9px;
  background: #D6EDF8;
  color: #008FD4;
  -webkit-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
  -moz-box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
  box-shadow: -2px 1px 7px 0px rgba(255, 255, 255, 0.6);
}

.date-status-small {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #616778;
}

.calendly-popup {
  max-height: 730px !important;
}

.images-container {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px !important;
}

.connected-tool {
  background-color: #EBEDF1;
  border-radius: 9999px;
  padding: 0px 4px 0px 0px;
  color: #616778;
  .connected-tool-text {
      display: none;
  }
  @include respond-to(md) {
      .connected-tool-text {
          display: flex;
      }
  }
}

.client-portal-body {
  height: calc(100dvh - 64px) !important;
  overflow-y: auto;
  padding: 20px;
  @include respond-to(lg) {
    height: calc(100dvh - 68px) !important;
  }
}

.poster-hover-element {
  opacity: 1;
  cursor: pointer;
  transition: opacity 0.3s ease-in-out;
  &:hover {
      opacity: 0.5;
  }
}

.horizontal-scroll {
  overflow-x: scroll;
  overflow-y: hidden; 
  white-space: nowrap; 
  scrollbar-width: thin; 
  scrollbar-color: transparent transparent;
}

.service-components-block {
  display: grid;
  grid-template-columns: 30% 35% 35%;
  // height: 3rem;
  // align-items: center;
}