.clrblack {
  color: #101010;
}
.myIntegrationBox {
  height: 100%;
  background: #ffffff;
  border: 1px solid #ebecf1;
  box-sizing: border-box;
  border-radius: 16px;
  padding: 5px;
  min-height: 185px;
  overflow: hidden;
}
.myIntegrationBox .mainImg {
  border-radius: 8px;
  border: 0.5px solid #EBECF1;
  margin-bottom: 5px;
}
.statusBox {
  padding: 5px 10px;
  border-radius: 4px;
  min-width: 108px;
}
.notEditMode input, .notEditMode .input-group-text {
  background-color: transparent !important;
  border: none !important;
}
.emitter {
  visibility: hidden;
  background-color: #222;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  top: 40%;
  left: 0%;
}
.dot-container {
  position:absolute;
  left:0;
  top:0;
  overflow:visible;
  z-index:5000;
  pointer-events:none;
}
.dot {
  position: absolute;
  pointer-events: none; /*performance optimization*/
}