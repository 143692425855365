@media print {
  .main-page-wrapper {
    margin-top: 0 !important;
  }
  .page-footer, .page-header {
    position: relative !important;
    z-index: 1000 !important;
    padding-left: 1.5cm !important; 
    padding-right: 1.5cm !important;
    background-color: #fff !important; 
    
    width: 100% !important;
    display: flex !important;
    align-items: center !important;
    flex-direction: column !important;
    .hr {
      border-bottom: 1px solid #EBEDF1 !important;
    }
    & > div {
      width: 100% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
    }
  }
  .page-footer {
    color: #9499AA !important;
    font-family: Archivo !important;
    font-style: normal !important;
    font-size: 14px !important;
    line-height: 22px !important;
    & > div {
      margin-top: 24px !important;
    }
    opacity: 0 !important;
    &.fixed {
      position: fixed !important;
      bottom: 0 !important;
      opacity: 1 !important;
    }
  }
  .page-header {
    & > div {
      margin-top: 22px !important;
      margin-bottom: 14px !important;
    }
  }
  .page-title {
    line-height: 26px !important;
    padding-top: 0 !important;
  }
  .export-report-print-only {
    display: block !important;
  }
  .padding-00 {
    padding: 0 !important;
  }
  .hide-on-print {
    display: none !important;
  }
  .show-on-print {
    display: block !important;
  }
  @page {
    margin: 24px 0 32px 0 !important;
  }
  .export-cust-modal-content {
    margin-bottom: 500px !important;
  }
  .custom-margin {
    margin-bottom: 100px !important;
  }
  .main-page-wrapper {
    padding-top: 0 !important;
    .first-page-header {
      padding-top: 0 !important;
    }
  }
}
