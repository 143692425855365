.downgradeModal {
  .modal-content{
    max-height: 550px !important;
    min-height: 340px !important;
  }
  .modal-footer{
    height:87px;
  }
  .redClr{
    background: #F36060;
    border: 1px solid #F36060;
  }
  .margin_center{
    text-align: center;
    
    h1{
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  .premium_center{
    border: 1px solid #EBECF1;
    padding: 24px;
    border-radius: 8px;
    width:360px;
    margin:auto;
    text-align: left;

  }
  .premium_txt{
    font-size: 18px;
    line-height: 19.58px;
    font-weight: 700;
    color: #008FD4;
  }
  .price_txt{
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;

  }
  .premium_wrap{
    width:360px;
    margin:30px auto 35px auto;
    text-align: left;
  }

  .err{
    margin-top: 20px;
    color: #F36060;
  }
  .loader {
    height: 128px;
    width: 144px;
    left: 249px;
    border-radius: 16px;
    padding: 24px;
    background: #f6f8fb;
    border: 1px solid #ebecf1;
  }
  .loader_wrapper {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    background: #ffffffd9;
  }
  .loader_circle {
    border: 6px solid #c8dff7;
    border-radius: 50%;
    border-top: 6px solid #008fd4;
    width: 38px;
    height: 38px;
    margin: auto;
    margin-top: 8px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
}