.custom_drop-down {
  background: #f6f8fb;
  border-radius: 4px;

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #616778;

    .tick {
      height: 10px;
      margin-top: 0px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0);
    }
  }

  .selected-option {
    margin-right: 18px;
  }

  .dropdown-toggle {
    text-align: left;
    background: transparent !important;
    border: none !important;
    border-radius: 4px;
    // color: #b6b6bf;
    color: #616778 !important;
    font-weight: 400;
    font-family: "Archivo";
    font-style: normal;
    // font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }
    &:not(.locked)::after {
      content: " ";
      background-image: url("../../../../../public/images/big-chevron-down.svg") !important;
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 6px;
  }

  &.locked::after{
    visibility: hidden;
  }
  }

  .dropdown-menu {
    &.show.left-align {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
  .dropdown-menu {
    &.show.right-align {
      position: absolute;
      inset: auto 0px auto 0px !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
}


.custom_drop-down-snap {
  background: #f6f8fb;
  border-radius: 4px;

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #616778;

    .tick {
      height: 10px;
      margin-top: 0px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0);
    }
  }

  .selected-option {
    margin-right: 18px;
  }

  .dropdown-toggle {
    text-align: left;
    background: transparent !important;
    border: none !important;
    border-radius: 4px;
    // color: #b6b6bf;
    color: #616778 !important;
    font-weight: 400;
    font-family: "Archivo";
    font-style: normal;
    // font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }
    &:not(.locked)::after {
      content: " ";
      background-image: url("../../../../../public/images/big-chevron-down.svg") !important;
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 6px;
  }

  &.locked::after{
    visibility: hidden;
  }
  }

  .dropdown-menu {
    &.show.left-align {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
  .dropdown-menu {
    &.show.right-align {
      position: absolute;
      inset: auto 0px auto 0px !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
}

.custom_drop-down-with-label {
  background: #f6f8fb;
  border-radius: 4px;

  .search_input {
    padding: 10px 19px;
    border-bottom: 1px solid #f6f8fb;
  }

  .select_label {
    color: #b6b6bf;
    margin-right: 5px;
  }

  .circle_div {
    background: #3d42de;
    height: 20px;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
    padding: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    .tick {
      height: 10px;
      margin-top: 12px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0.08);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0.08);
    }
  }

  .dropdown-toggle {
    text-align: left;
    background: #f6f8fb;
    border: 1px solid #f6f8fb;
    border-radius: 4px;
    color: #b6b6bf;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    height: 46px;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }

    &:hover .select_label {
      color: #008fd4 !important;
    }

    &::after {
      content: " ";
      background-image: url("../../../../../public/images/theme/icons/dropdownarrow.svg");
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 0px;
    }
  }

  .dropdown-menu {
    &.show {
      width: 100%;
    }
  }
}

// Multi Select DropDown
.custom_drop-down-multi {
  background: #f6f8fb;
  border-radius: 4px;

  .selected_label {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: start;
  }

  .selected_label_inner {
    align-items: center;
    display: inline-flex;
    background: #f6f8fb;
    padding: 5px 15px;
    border-radius: 25px 25px;
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 5px;
  }

  .selected_label_inner2 {
    align-items: center;
    display: inline-flex;
    border-radius: 25px 25px;
    font-size: 12px;
    margin-right: 10px;
  }

  .labelcount {
    color: #008fd4;
    font-size: 14px;
  }

  .search_input {
    padding: 10px 19px;
    border-bottom: 1px solid #f6f8fb;
  }

  .select_label {
    color: #b6b6bf;
    margin-right: 5px;
  }

  .circle_div {
    background: #3d42de;
    height: 20px;
    color: #fff;
    width: 20px;
    border-radius: 50%;
    font-size: 12px;
    padding: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .circle_div2 {
    background: #3d42de;
    height: 15px;
    color: #fff;
    width: 15px;
    border-radius: 50%;
    font-size: 12px;
    padding: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;

    .tick {
      height: 10px;
      margin-top: 12px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0.08);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0.08);
    }
  }

  .dropdown-toggle {
    text-align: left;
    background: #f6f8fb;
    border: 1px solid #f6f8fb;
    border-radius: 4px;
    color: #b6b6bf;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    height: 46px;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }

    &:hover .select_label {
      color: #008fd4 !important;
    }

    &::after {
      content: " ";
      background-image: url("../../../../../public/images/theme/icons/dropdownarrow.svg");
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 0px;
    }
  }

  .dropdown-menu {
    &.show {
      width: 100%;
    }
  }
}

.light-text {
  font-family: "Archivo";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9499aa;
}

.search-timezone {
  border: none;
  outline: none;

  &::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9499aa;
  }
}

.time-zone-drop-down {
  .dropdown-menu.show {
    width: 440px;
  }
}



.custom_drop-down-adjustable {

  background: #f6f8fb;
  border-radius: 4px;

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #616778;

    .tick {
      height: 10px;
      margin-top: 0px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0);
    }
  }

  .selected-option {
    margin-right: 18px;
  }

  .dropdown-toggle {
    text-align: left;
    background: transparent !important;
    border: none !important;
    border-radius: 4px;
    // color: #b6b6bf;
    color: #616778 !important;
    font-weight: 400;
    font-family: "Archivo";
    font-style: normal;
    // font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }

    &::after {
      content: " ";
      background-image: url("../../../../../public/images/big-chevron-down.svg") !important;
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 6px;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      // inset: auto 0px auto auto !important;
      margin: 0px;
      // transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }

}


.custom_drop-down-gray {
  background: #f6f8fb;
  border-radius: 4px;

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: #616778;

    .tick {
      height: 10px;
      margin-top: 0px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0);
    }
  }

  .selected-option {
    margin-right: 18px;
  }

  .dropdown-toggle {
    text-align: left;
    background: #EBEDF1 !important;
    border: none !important;
    border-radius: 4px;
    // color: #b6b6bf;
    color: #616778 !important;
    font-weight: 400;
    font-family: "Archivo";
    font-style: normal;
    // font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }
    &:not(.locked)::after {
      content: " ";
      background-image: url("../../../../../public/images/big-chevron-down.svg") !important;
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: 6px;
  }

  &.locked::after{
    visibility: hidden;
  }
  }

  .dropdown-menu {
    &.show.left-align {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
  .dropdown-menu {
    &.show.right-align {
      position: absolute;
      inset: auto 0px auto 0px !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
}

.company-dropdown {
  &:focus {
    border-color: #99d2ee !important;
    box-shadow: 0 0 5px #99d2ee !important;
    outline: none;
  }
}