.kpi-settings-dropdown-menu {
  box-shadow: 0px 12px 24px 0px #10101014;
  background: #fff;
  border: 1px solid #EBEDF1;
  border-radius: 4px;
  padding: 8px 4px !important;
  .title-container {
    padding: 4px 12px;
    .title {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: #9499AA;
    }
  }
  .list {
    display: flex;
    flex-direction: column;
    .item {
      display: flex;
      justify-content: space-between;
      gap: 12px;
      align-items: center;
      padding: 4px 12px;
      font-size: 14px;
      line-height: 22px;
      color: #616778;
      h4 {
        white-space: nowrap;
        margin-bottom: 0 !important;
        font-weight: 400;
      }
    }
  }
}
