.active-sessions-wrapper {
  margin-top: 2.5rem !important;
  max-width: 60%;
  margin: auto;

  .devices-wrapper {
    background-color: #f6f8fb;
    border-radius: 8px;
  }
  .device-wrap {
    padding: 1.2rem 1.6rem;
  }
  .device-name {
    color: #0c0d11;
    font-weight: 500;
    font-size: 14px;
  }
  .device-date {
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
    margin-right: 9px;
  }
  .device-dropdown button {
    background: transparent;
    border: none;
    &::after {
      display: none;
    }
  }
  .dropdown-toggle {
    background: transparent !important;
    border: none !important;
  }
  .show {
    .dropdown-toggle {
      border: none;
      // border: 1px solid #f36060;
      // border-radius: 2px;
    }
  }
  .dropdown-menu.show {
    background: #0c0d11;
    box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
    border-radius: 4px;
    transform: translate(0px, 0px) !important;
    inset: auto 0px auto auto !important;
    width: 148px;
  }
  .notification-btn {
    cursor: pointer;
    color: rgba(255, 255, 255, 0.64);
    padding: 4px 14px;
    margin: 7px 0px;
  }
  .remove-btn {
    cursor: pointer;
    color: #f36060;
    padding: 4px 15px;
    margin: 5px 0px;
  }
  .notification-divider {
    // border: 1px solid rgba(255, 255, 255, 0.24);
    margin-bottom: 10px !important;
  }
  .logout-btn {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: #fef2f2;
    border: none;
    color: #f36060;
  }
  .logout {
    position: absolute;
    bottom: -43px;
    right: 0;
    button:active {
      background: #fef2f2 !important;
      border: none !important;
      color: #f36060 !important;
    }
  }
}

.test-notification-modal {
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    img {
      width: 12px;
      object-fit: contain;
    }
  }
  .modal-content {
    max-height: 70% !important;
    border-radius: 7px;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
  }
  .option-wrapper {
    &:hover {
      background: #f6f8fb;
      border-radius: 4px;
    }
    height: 80px;
    cursor: pointer;
  }

  .cyberscore-noti {
    margin: 0rem 0rem 4rem 0rem;
    .score__number {
      font-size: 1.3em;
    }
    .score__wrapper .status {
      display: none;
    }
  }

  .threat-noti {
    margin-right: 15px;
    .bar {
      height: 42px !important;
      width: 32px;
      margin-left: 0.5rem;
    }
    .bar__scale span {
      display: none !important;
    }
  }
}
