@use '../../../breakpoints.scss' as *;

.action-centre-wrapper {
  margin-top: 2.5rem !important;
  width: 100%;
  padding: 0 20px;
  @include respond-to(lg) {
    max-width: 60%;
    width: 100%;
    padding: 0;
    margin: auto;
  }

  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .option-wrapper {
    background: #f6f8fb;
    border-radius: 8px;
    margin: 0;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616778;
  }
  .connect-btn {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  }
}
