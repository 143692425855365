.background_container
{
/* White */

background: #FFFFFF;
/* Light garay */

border: 1px solid #EBECF1;
box-sizing: border-box;
border-radius: 16px;


}

.roi_background span
{
    justify-content: center;
    align-content: center;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
.roi_background
{
    background: #F6F8FB;
border-radius: 8px;
height: 100%;
text-align: center;
display: table;
}

.cyberoi_links
{
    font-family: Archivo;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 15px;


/* Gray */

color: #B6B6BF !important;
}

.roi_greytext
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 10px;
line-height: 140%;
/* identical to box height, or 14px */

/* Gray */

color: #B6B6BF;
}

.roi_headings
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;

    
    /* Gray */
    
    color: #B6B6BF;
}

.roi_subtext
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 17px;
    text-transform: capitalize;
    margin-top: 4px;
    /* Black */
    
    color: #101010;
}

.roi_splitter
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 17px;
text-transform: capitalize;

/* Black */

color: #101010;
}
.roi-table {
    padding: 33px 11px 33px 32px;
}
.roi-divider {
    border: 1px solid black;
}