.my-tooltip {
  position: relative;
  .anchor {
    width: fit-content;
  }
  .tooltip-body {
    z-index: 9999;
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
}