@use '../../../breakpoints.scss' as *;

.cdsa-wrapper {
  max-width: calc(100% - 32px);
  margin: 0 16px;
  @include respond-to(lg) {
    max-width: 70%;
    margin: auto;
  }

  .tool-listing-wrapper {
    .checkbox-margin {
      margin-bottom: 20px;
    }
  }

  .panel {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;
  }

  .tool-creator-field {
    border: 1px solid #99d2ee;
    padding: 3px;
    border-radius: 4px;
    height: 32px;

    input {
      border: none;
      width: 100%;
      background: transparent;
    }

    button {
      padding: 1px 5px;
    }
  }

  .border-parent .border-item {
    border-bottom: 1px solid #ebedf1;
  }

  .border-parent .border-item:last-child {
    border-bottom: 1px solid transparent;
  }

  .attack-date-picker {
    .highground-datpicker {
      button.dropdown-toggle.btn-primary{
        color: #0c0d11;
      }
      width: 101px;

      .dropdown-toggle {
        input.bg-transparent-imp {
          padding-left: 0px !important;
        }
      }

    }
  }

  .arrow-up {
    display: none;
  }

  .input-name-field {
    input {
      border: none;
      background: transparent;
    }
  }
}