.upgradePremium{
  .modal-content{
    width:640px !important;
  }
  .annual_wrapper{
    border-right: 1px solid #EBECF1;
    padding-right:16px !important;
  }
  .mt40{
    margin-top:40px;
  }
  .mt16{
    margin-top:16px;
  }
  .listtxt{
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #B6B6BF
  }
  .h32{
    height:32px;
  }
  .d24{
     height:24px;
     width:100%;
    //  background: red;
  }
  hr{
    margin:0px;
  }
  .payment_method_wrapper{
    display: flex;
    flex-direction: row;

    .w30{
      width:30%;
      height:100px;
    }
   
  }
  .mt-10px{
    margin-top:10px;
  }
  .order_summary{
    background: #f6f8fb;
    padding: 12px;
    border-radius: 8px;
  }
    .order_r{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .blubtn{
      background: #008FD4;
      border-radius: 4px;
      font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    height:31px;
    }
    .gtxt{
    font-size: 12px;
    line-height: 13px;
    color: #B6B6BF;
    padding-top:4px;
    }
    .greytxt{
      color: #B6B6BF;
      margin-bottom: 8px;
    }
    .gryClr{
      color: #B6B6BF;
    }
    .grey-input{
     margin-bottom: 8px;
      .form-control{
        height:32px;
      }
     
    }
    .round-checkbox{
      height:13px;
      width:13px;
      label{
        height:13px;
        width:13px;
        
       &::after{
          border: 2px solid #fff;
          border-top: none;
          border-right: none;
          content: "";
          height: 5px;
          left: 1px;
          opacity: 0;
          position: absolute;
          top: 2px;
          transform: rotate(-45deg);
          width: 8px;
      }

      }
      .form-check-input{
        margin-top: 0px;
      }
    }

    .txtcard{
      white-space: pre;
      font-size: 14px;
    }

    .brdBlu{
    border: 1px solid #008fd4;
    }
    .card_number_wrapper{
      display: flex;
      flex-direction: row;
      flex: .6 .2  .2;
    }

    .card_number_wrapper div.cardnumber{
      width: 58%;
    }
    .card_number_wrapper div.datemonth{
      width: 25%;
    }
    .card_number_wrapper div.csvnumber{
      width: 17%;
    }
    .responseList{
      width:400px;
      margin:auto;
      p{
        margin-bottom: 5px;
        img{
          height:12px;
          margin-right: 5px;
          width:12px;
        }
      }
    }
    .txtsucess{
      font-family: Archivo;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #40D88E;
    }
    .txtfail{
      font-family: Archivo;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      color: #F36060;
    }
    .loader{
      height: 128px;
      width: 144px;
      left: 249px;
      border-radius: 16px;
      padding: 24px;
      background: #F6F8FB;
      border: 1px solid #EBECF1

    }
    .loader_wrapper{
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    }
    .loader_circle {
      border: 6px solid #c8dff7;
      border-radius: 50%;
      border-top: 6px solid #008FD4;
      width: 38px;
      height: 38px;
      margin:auto;
      margin-top: 8px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    /* Safari */
      @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
      }

      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }

      .carderr{
        background-color: rgba(254, 95, 91, 0.08);  
        background-image: url('../../../../../public/images/card_error.svg');
        background-repeat: no-repeat;
        background-position: calc(100% - 10px) 10px ;
      }
      .carderr1{
        background-color: rgba(254, 95, 91, 0.08);  
        background-image: url('../../../../../public/images/card_error.svg');
        background-repeat: no-repeat;
        background-position: 5px 8px ;
        text-indent: 15px;
      }
      .billingInfo{
        flex: 0 0 70%;
        margin: auto;
      }
}

.resultText{
  width: 65%;
  margin: auto;
}