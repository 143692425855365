.cc_containerlinktext
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    
    /* Gray */
    
    color: #B6B6BF; 
}

.cfo-font
{
    font-family: Archivo;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 26px;

    
    /* Black */
    
    color: #101010;

}

.cto-font
{
    font-family: Archivo;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 35px;
    /* identical to box height */
    margin-bottom: 0;

    align-items: center;
    text-align: center;
    
    /* Black */
    
    color: #101010;

}

.c_border
{
    background: #F6F8FB;
    border-radius: 8px;
}

.c_border .cfo-font
{
    margin-bottom: 0
}