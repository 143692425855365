.dropdownfilter {
  .dropfilterchild.dropdown-menu {
    width: 227px !important;
    transform: translate(0px, 45px) !important;
    padding: 10px 0px !important;
    height: fit-content !important;
  }
  .show > .btn-primary.dropdown-toggle {
    background-color: transparent;
  }
  .dropdown_input {
    height: 42px;
    font-size: 14px;
    font-family: "Archivo";
    font-style: normal;
    border: 2px solid #fff;
    border-radius: 4px;
    width: 100%;
    line-height: 22px;
    padding: 0px 8px;
    border: 0px;
    display: flex;
    align-items: center;
    color: #0c0d11;

    .crossimg {
      height: 9px;
      margin-left: 5px;
      cursor: pointer;
    }

    &:focus {
      border: 2px solid #99d2ee;
    }

    &:focus-within {
      border: 2px solid #99d2ee;
    }
  }
  .custombadge {
    border: 1px solid #d4d6dd;
    border-radius: 9999px;
    padding: 0px 7px 0px 2px;
    font-size: 12px;
    line-height: 23px;
    color: #616778;
    font-weight: 500;
  }
  .custombadge2 {
    color: #0c0d11;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 7px 0px 2px;
    line-height: 23px;
  }

  .filter_toggle {
    width: 100%;
    font-family: Archivo, sans-serif;
    position: relative !important;
    left: -8px;
    border: 0px;
    background-color: #f6f8fb !important;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px 12px !important;
    margin-right: 4px;
    &:after {
      display: none;
    }
  }

  .dropdown-menu {
    width: 350px;
    padding: 10px 15px;
    min-height: auto;
    // max-height: 400px;
    height: auto !important;
    transform: translate(-65px, 57px) !important;

    .child_drop {
      padding: 5px 16px;
      cursor: pointer;

      &:hover {
        background: #f6f8fb;
      }
    }
  }
  .active-dropdown-toggle {
    background: #008fd4 !important;
    span {
      color: #fff !important;
    }
  }
  .dropdown-toggle {
    width: 100%;
    border: 0px;
    background: transparent;
    &:after {
      display: none;
    }
    &:active {
      background-color: transparent !important;
    }
  }

  .custombadge {
    border: 1px solid #d4d6dd;
    border-radius: 9999px;
    padding: 0px 7px 0px 2px;
    font-size: 12px;
    line-height: 23px;
    color: #616778;
    font-weight: 500;
  }
  .custombadge2 {
    color: #0c0d11;
    width: 100%;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    padding: 0px 7px 0px 2px;
    line-height: 23px;
  }

  .tableStatus {
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    line-height: 15px;

    .tablecircle {
      height: 8px;
      width: 8px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .datepicker_cutom {
    .custom_date_picker {
      background-color: transparent;
      border: 1px solid #fff;
    }
  }

  .cancel-btnn {
    font-weight: 500;
    margin-right: 8px;
    border: none;
    background: #f6f8fb;
    border-radius: 2px;
    color: #616778;
    &:active {
      background: #f6f8fb !important;

      color: #616778 !important;
      border: none !important;
    }
  }
  .apply-btn {
    font-weight: 500;
    background: #008fd4;
    border-radius: 2px;
    border: none;
    &:hover {
      border: none;
    }
    &:active {
      border: none !important;
    }
  }
}
