.personal-profile-settings-v2 {
  max-width: 60%;
  margin: auto;
  .change-photo-btn {
    background-color: #f6f8fb;
    color: #616778;
    font-weight: 500;
    border: none;
  }
  .remove-photo-btn {
    background-color: transparent;
    color: #616778;
    border: none;
  }
  .user-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }
  .name-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.2rem;
  }
  .name-label {
    color: #0c0d11;
    font-weight: 500;
  }
  .name {
    color: #616778;
    font-weight: 400;
  }
  .details-divider {
    border: 1px solid #ebedf1;
  }
  .details-arrow {
    margin: 0rem 1rem 0rem 1.5rem;
    cursor: pointer;
  }
  .role-arrow {
    margin: 0rem 1rem;
    cursor: pointer;
  }
  .security-details {
    background-color: #f6f8fb;
    border-radius: 8px;
  }
  .reset-password-wrapper {
    padding: 1rem;
  }
  .security-wrapper {
    margin-top: 1rem;
  }
}
