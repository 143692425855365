.collapHeading {
    padding: 18px 24px;
    margin: 16px;
    background: #008FD4;
    color: white;
    border-radius: 4px 4px 0px 0px;
}
.collapHeading p {
    font-weight: 500;
}
.collapHeading p, .collapContent p {
    font-family: Archivo;
    font-style: normal;
    margin: 0;
    font-size: 12px;
}
.collapContent p
{
    word-break: break-word;
    font-weight: normal;
}

.collapContent {
    margin: 0 16px;
    border-radius: 4px;
}

.collapContent:nth-child(odd) {
    background: white;
}

.collapContainer {
    background: #F6F8FB;
    border-radius: 8px;
    padding: .3em;
}
.collapContainer50{
    height: 50vh
}

.collapContainer80{
    height: 83vh
}

.collapBottomDiv {
    background: white;
    padding: 26px 0;
    margin: 1em;
    text-align: right;
}

.collapBottomDiv p {
    color: black;
    font-weight: 500;
    margin-right: 34.1em;
    margin-bottom: 0;
}

.collapBottomDiv p span {
    color: #B6B6BF;
}
.table-banner
{
    background: rgba(0, 143, 212, 0.08);
/* Accent */

border: 1px solid #008FD4;
box-sizing: border-box;
border-radius: 4px;
height: 66px;
}

.preReqText
{
    font-family: Archivo;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 140%;
/* identical to box height, or 17px */


/* Black */

color: #101010;
}
.table-rows {
 overflow-y: scroll;
 overflow-x: hidden;
 height: 42em;
}
.panel-heading  a:before {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    transition: all 0.5s;
 }
 .panel-heading.active a:before {
     -webkit-transform: rotate(180deg);
     -moz-transform: rotate(180deg);
     transform: rotate(180deg);
 }
 .accordin-row:focus, .accordin-btn:active {
     box-shadow: none;
 }
 .accordin-row {
    padding: 18px 24px;
    margin: 0;
 }
 .accordion-collapse {
    padding: 0px 24px 18px 24px;
 }
 .accordion-collapse hr {
     margin-top: 0;
 }
 .drillbadge{
    display: flex;
   align-items: center;
    font-size: 14px!important;
    height: 25px;
    padding: 10px;
    border-radius: 12px 12px 12px 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
   justify-content: center;
    line-height: 20px;
 }