.threat_level {
  padding-top: 25px;
}

.threat_card {
  background-color: #ffffff;
  border: 1px solid #ebecf1;
  border-radius: 8px;
}

.threat_link {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  /* Gray */
  color: #b6b6bf !important;
  margin-left: 50px;
}

.threat_crit {
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  /* Red */
  color: #f36060;
}

.threat_attention {
  /* Light yellow BG */
  font-family: Archivo;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  /* Yellow */
  color: #ffac2c;
}

.bar__title {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  /* Black */
  color: #101010;
}

.threat_good {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  /* Green */
  color: #40d88e;
}

.threat_text {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  padding: 0.5em;
}

.crit {
  background: rgba(254, 95, 91, 0.08);
  border-radius: 4px;
  display: inline;
}

.looksgood {
  background: rgba(64, 216, 142, 0.08);
  border-radius: 4px;
}

.needsatt {
  /* Light yellow BG */
  background: rgba(255, 172, 44, 0.08);
  border-radius: 4px;
}

.threat_level .bar__title {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #101010;
}

.threat_level .bar__scale span {
  font-family: Archivo;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  /* identical to box height */
  display: flex;
  align-items: center;
  /* Gray */
  color: #b6b6bf;
}

.threat_card .card-body .card-title {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* Black */
  color: #101010;
}

.threat_card .card-body ul li {
  margin-top: 1.2em;
}

.btmSideBar {
  bottom: 0.5%;
  position: absolute;
}

.notificationIcon a img {
  padding-top: 2px;
  /* padding-right: 20px; */
}
.notificationIcon img {
  left: 15px;
  top: 11px;
}
.upgradePro {
  border: 1px solid #ebecf1 !important;
  border-radius: 8px !important;
  margin: 30px 30px 10px 14px !important;
  padding-top: 12px;
  padding-bottom: 12px;
  background: #fff;
}
.upgradePro span {
  font-size: 12px;
  color: #101010;
}
.upgradePro .upgradeText {
  padding-left: 10px;
}
.upgradePro .closeButton {
  margin-top: -17px;
}
.proServiceCredit p {
  font-weight: 500;
  font-size: 16px;
}
.proServiceCredit span {
  font-weight: 500;
  font-size: 14px;
  color: #008fd4;
}
.proServiceCredit img {
  padding-left: 10px;
}
.proServiceBtn {
  color: #ffffff;
}
.proServiceBtn .btn-primary:hover {
  background-color: rgba(0, 143, 212, 0.9) !important;
  color: #ffffff !important;
  border-color: #008fd4 !important;
}

.proServicePrice {
  color: #008fd4;
  font-weight: 500;
  font-size: 18px;
}
.sideBarDropdown .dropdown-toggle,
.sideBarDropdown .dropdown-toggle:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

.sideBarDropdown .dropdown-toggle:focus {
  box-shadow: none !important;
}

.sideBarDropdown .dropdown-menu {
  transform: translate(-55px, 40px) !important;
  padding: 7px 5px !important;
  background: #f6f8fb !important;
  border-radius: 8px!important;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%) !important;
}
.sideBarDropdown .dropdown-menu a {
  font-weight: 500;
}
.supportDropdown .dropdown-menu :active{
  background: none!important;
}

.supportDropdown .dropdown-toggle::after {
  content: none !important;
}

/* .sideBarDropdown .dropdown-menu :focus {
  background-color: transparent !important;
} */
.user-details-info :hover {
  color:#b6b6bf;
}
.supportDropdown-user:hover {
  color:#b6b6bf!important
}
.knowlegeBasePanel {
  border: 1px solid #ebecf1;
  border-radius: 8px;
  margin: 1px 21px;
  padding: 15px;
  cursor: pointer;
}
.knowlegeBasePanel h5 {
  color: rgb(0, 143, 212);
}

.knowlegeBasePanel img {
  width: 8px;
  padding-bottom: 14px;
}
.upgrade_pro_title{
  font-size: 14px; 
  font-weight: 500;
  margin-top:14px !important;
}