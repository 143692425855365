.device-user-issues-filter {
  .menu {
    padding: 4px 0;
    white-space: nowrap;
    min-width: 240px;
  }
  .section {
    .title {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      padding: 4px 0;
    }
    .item {
      cursor: pointer;
      padding: 4px;
      font-size: 14px;
      line-height: 22px;
      font-weight: 500;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
