@use '../../../breakpoints.scss' as *;

.msp_attackSurface {
    .head {
        background: #f6f8fb;
        border-radius: 8px;
        height: 250px;
        width: 100%;

        .status {
            display: none;
        }

        .graph {
            position: absolute;
            right: 0;
            left: 0rem;
            top: 1.5rem;

            &.threatLevelBar {
                left: 42% !important;
            }

            .bar__scale {
                display: none;
            }

            &.complianceGraph {
                left: 0rem !important;
                top: 2rem !important;
            }
        }
    }

    .score-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-template-areas: none;
        gap: 16px;
        @include respond-to(md) {
            grid-template-columns: 1fr 1fr;
            grid-template-rows: auto auto;
            grid-template-areas: 
            "left right"
            "footer footer";
            .section {
                &.footer {
                    grid-area: footer;
                }
            }
        }
        @include respond-to(xl) {
            grid-template-columns: 1fr 1fr 1fr;
            grid-template-rows: auto;
            grid-template-areas: none;
            .section {
                &.footer {
                    grid-area: auto;
                }
            }
        }
    }

    .category-left-section-wrapper {
        border-right: none;
        @include respond-to(md) {
            border-right: 1px solid #ebedf1;
        }
    }
    .category-item-on-dashboard {
        border-right: none;
        @include respond-to(md) {
            border-right: 1px solid #ebedf1;
            &:nth-child(2n) {
                border-right: none;
            }
        }
        @include respond-to(lg) {
            &:nth-child(2n) {
                border-right: 1px solid #ebedf1;
            }
            &:nth-child(3n) {
                border-right: none;
            }
        }
    }

    .kpis-item {
        border-right: none;
        &:not(:last-child) {
            border-bottom: 1px solid #ebedf1;
        }
        @include respond-to(md) {
            border-right: 1px solid #ebedf1;
            &:nth-child(2n) {
                border-right: none;
            }
            &:nth-last-child(-n+2) {
                border-bottom: none;
            }
        }
        @include respond-to(lg) {
            border-bottom: none;
            &:nth-child(2n) {
                border-right: 1px solid #ebedf1;
            }
            &:nth-child(4n) {
                border-right: none;
            }
        }
    }

    .progress_bar_x {

        .graph_text {
            top: 45%;
            left: 50%;
            bottom: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            text-align: center;

        }

        .progress-bar-x {
            width: 250px;
            height: 250px;
        }

        .background-x {
            fill: none;
            // stroke: gray;
            // stroke-width: 5;
            // stroke-dasharray: 285;
            // stroke-dashoffset: 0;
            // transform: rotate(-90deg);
            // transform-origin: center;
        }

        .progress-x {
            fill: none;
            // stroke: blue;
            // stroke-width: 5;
            // stroke-linecap: round;
            // stroke-dasharray: 0;
            // stroke-dashoffset: 0;
            // transform: rotate(-90deg);
            // transform-origin: center;
            // transition: all 1s linear;
        }

        .progress-text-x {
            text-anchor: middle;
            font-size: 20px;
            // fill: blue;


        }

    }
}


.create-category-spend-modal {
    .modal-content {
        border-radius: 8px !important;
        height: auto !important;
        // max-height: 650px !important;
        width: 100%;
        min-height: 462px !important;
          overflow: visible !important;
        @include respond-to(md) {
            width: 500px;
        }
    }
    .modal-body{
         overflow: visible !important;
    }

    .modal-header {
        border-bottom: 1px solid #edecf1 !important;
    }

}
.create-sub-category-modal {
    .modal-content {
        border-radius: 8px !important;
        height: auto !important;
        // max-height: 650px !important;
        width: 100%;
        // min-height: 400px !important;
        overflow: visible !important;
        @include respond-to(md) {
            width: 500px;
        }
    }

    .modal-body{
        overflow: visible !important;
    }

    .modal-header {
        border-bottom: 1px solid #edecf1 !important;
    }

}

// .setDropdownLeft{inset: 100% 0px auto auto !important;
//     transform: translate(0px, 0px) !important;}
.tech-spend-subscription-view{
    display: grid;
    grid-template-columns: 1fr;
    @include respond-to(md) {
        grid-template-columns: 1fr 1fr;
    }
    @include respond-to(lg) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @include respond-to(xl) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @include respond-to(xxl) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    gap: 10px;
}
.sub-tile{
    border: 1px solid #EBEDF1;
    border-radius: 4px;
    background:#F6F8FB;
    &:hover{
        background:#EBEDF1;

    }

    &.variant-2 {
        border: none;
        background: transparent;
        &:hover{
            background:#F6F8FB;
        }
    }

    .status{
        padding: 2px 5px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        border-radius: 2px;
        &.completed{
            background: #9499AA;
        }
        &.active{
            
            background: #40D88E;
        }
        &.suspended{
            
            background: #FFAC2C;
            
        }
        &.cancelled{
            
            background: #9499AA;
        }
    }
}

.tech-spend-list-view{
    background: #F6F8FB;
    border-radius: 8px;
    overflow-x: auto;
    .table-col{
        min-width: 150px;
        @include respond-to(md) {
            min-width: 200px;
        }
        @include respond-to(lg) {
            min-width: 14.199%;
        }
    }

    .border-item:not(:first-child) {
        cursor: pointer;
       &:hover{
        background: #EBEDF1;
       }
       .color-dot{
        width: 9px;
        height: 9px;
        border-radius: 50%;
        min-width: 9px;
      
       }
       .cat-subcat-ship{
        padding: 3px 11px;
        background: #EBEDF1;
        color: #616778;
        font-size: 12px;
        font-weight: 500;
        border-radius: 30px;
        margin-right: 0.5rem;
        margin-bottom: 0.25rem;
        width: fit-content;
       }
      }
}