@use '../../../../breakpoints.scss' as *;

.title-input {
    border: 2px solid #ebedf1;

    input {
        border: none;
        background: transparent;
    }

    &:focus-within {
        border: 2px solid #99D2EE;
        border-radius: 4px;
        background: #f6f8fb;
    }
}

.title-input-error {
    border: 2px solid #f36060;

    input {
        border: none;
        background: transparent;
    }

    &:focus-within {
        border: 2px solid #99D2EE;
        border-radius: 4px;
        background: #f6f8fb;
    }
}

.textarea-input-error {
    border: 2px solid #f36060;
}

.security-baseline-wrapper {
    width: calc(100% - 32px);
    margin: 0 16px;
    @include respond-to(lg) {
        width: 50%;
        margin: 0 auto;
    }
}

.edit-sec-baseline-modal {
    @include respond-to(md) {
        width: 500px;
    }
}