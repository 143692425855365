.review-suggestions {
  padding: 8px 16px;
  gap: 8px;
  .review-btn {
    padding: 4px;
    border-radius: 2px;
    border: none;
    background-color: #008FD4;
    span {
      padding: 1px 4px;
    }
  }
}