@use '../../../breakpoints.scss' as *;

.msp-clients-wrapper {
    .banner {
        background: #f6f8fb;
        border-radius: 8px;
        margin: 1.3rem 2.7rem;

        .banner-element-border {
            border-right: 1px solid #ebedf1;
        }

        .banner-element {
            flex-basis: 0;
            flex-grow: 1;
            padding: 1.15rem;

            .banner-element-name {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                color: #616778;
            }

            .banner-element-value {
                font-family: "Archivo";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #0c0d11;
            }
        }
    }

    .features-wrapper {
        margin: .9rem 2.7rem;
        
        .blocks-container {
            display: grid;
            grid-template-columns: 1fr;

        }

        .feature-box {
            img {
                max-height: 100%;
                
            }
        }

        // .feature-list {
        //     height: 250px;
        // }
    }

    .clients-header {
        margin: .9rem 2.7rem;
    }




.clients-table-parent{
    display: grid;
    grid-template-columns: 23% 18% 18% 18% 18% 5% ;
    .table-row-child{
        // width: 15%;
    }
}





}

@include respond-to(sm) {
    .msp-clients-wrapper {
        .features-wrapper  {
            .blocks-container {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

@include respond-to(lg) {
    .msp-clients-wrapper {
        .features-wrapper  {
            .blocks-container {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
    }
}
  

// Create Client Wrapper
// Create Client Wrapper
// Create Client Wrapper
// Create Client Wrapper
// Create Client Wrapper

.create-client-wrapper {
    width: calc(100% - 40px);
    margin: 0 20px;
    @include respond-to(md) {
        width: 50%;
        margin: 0 auto;
    }

    .bread {
        .active {
            .number {
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background: #0c0d11;
                padding-top: 2px;
                margin-right: 7px;
                margin-bottom: 1px;
            }

            .page-name {
                color: #0c0d11;
            }
        }

        .not-active {
            .number {
                color: #ffffff;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 18px;
                width: 18px;
                border-radius: 50%;
                background: #616778;
                padding-top: 2px;
                margin-right: 7px;
                margin-bottom: 1px;
            }

            .page-name {
                color: #616778;
            }
        }
    }

    .no-image-wrapper {
        background: #F6F8FB;
        height: 88px;
        width: 88px;
        border-radius: 50%;
    }

    .option-dropdown {
        .custom_drop-down {
            background: transparent;
        }
    }




    .tool-creator-field {
        border: 1px solid #99d2ee;
        padding: 3px;
        border-radius: 4px;
        height: 32px;

        input {
            border: none;
            width: 100%;
            background: transparent;
        }

        button {
            padding: 1px 5px;
        }
    }

}

.clients-filter-dropdown {
    .drop-drag-issue {
        z-index: 2;
        width: auto;
        min-width: 215px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
        color: #212529 !important;
        padding: 5px 0px 5px 0px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.15);
        background: #fff;
    }
}

.tax-type-menu {
    left: -100% !important;
    transform: translateY(0) translateX(-8px) !important;
}