div.msp-integration-spinner {
    position: relative;
    background:transparent;
    // display: inline-block;
    margin-left: 5px;
    // margin-right: 50%;
     width:2px;
    height:24px;
    
    // padding: 10px;
    border-radius: 10px;
  }
  
  div.msp-integration-spinner div {
    width: 6%;
    height: 16%;
    background: #1a1919;
    position: absolute;
    left: 49%;
    top: 43%;
    opacity: 0;
    border-radius: 50px;
    border-radius: 50px;
  /*   box-shadow: 0 0 3px rgba(0,0,0,1); */
  animation: fade 1s linear infinite;
    animation: fade 1s linear infinite;
  }
  
  @keyframes fade {
    from {opacity: 1;}
    to {opacity: 0.3;}
  }
  
  div.msp-integration-spinner div.bar1 {
     transform:rotate(0deg) translate(0, -130%);
     animation-delay: 0s;
  }    
  
  div.msp-integration-spinner div.bar2 {
    transform:rotate(30deg) translate(0, -130%); 
    animation-delay: -0.9167s;
  }
  
  div.msp-integration-spinner div.bar3 {
    transform:rotate(60deg) translate(0, -130%); 
    animation-delay: -0.833s;
  }
  div.msp-integration-spinner div.bar4 {
    transform:rotate(90deg) translate(0, -130%); 
    animation-delay: -0.7497s;
  }
  div.msp-integration-spinner div.bar5 {
    transform:rotate(120deg) translate(0, -130%); 
    animation-delay: -0.667s;
  }
  div.msp-integration-spinner div.bar6 {
    transform:rotate(150deg) translate(0, -130%); 
    animation-delay: -0.5837s;
  }
  div.msp-integration-spinner div.bar7 {
    transform:rotate(180deg) translate(0, -130%); 
    animation-delay: -0.5s;
  }
  div.msp-integration-spinner div.bar8 {
    transform:rotate(210deg) translate(0, -130%); 
    animation-delay: -0.4167s;
  }
  div.msp-integration-spinner div.bar9 {
    transform:rotate(240deg) translate(0, -130%); 
    animation-delay: -0.333s;
  }
  div.msp-integration-spinner div.bar10 {
    transform:rotate(270deg) translate(0, -130%); 
    animation-delay: -0.2497s;
  }
  div.msp-integration-spinner div.bar11 {
    transform:rotate(300deg) translate(0, -130%); 
    animation-delay: -0.167s;
  }
  div.msp-integration-spinner div.bar12 {
    transform:rotate(330deg) translate(0, -130%); 
    animation-delay: -0.0833s;
  }
  

  .connect-integration-modal{

    progress {
        border-radius: 8px;
        width: 100%;
        height: 10px;
  
        box-shadow: 1px 1px 1px rgba(182, 228, 255, 0.205);
      }
      progress::-webkit-progress-bar {
        background-color: #ebedf1;
        border-radius: 7px;
      }
      progress::-webkit-progress-value {
        background-color: #008fd4;
        border-radius: 7px;
        box-shadow: 1px 1px 5px 3px rgba(182, 228, 255, 0.205);
      }
    



.company-id-dropdown {

  .dropdown-toggle {

    padding: 3px 0px !important;
    background: transparent !important;

    &::after {
      display: none;
    }
  }
  .dropdown-menu.show{
    max-height: 200px !important;
    overflow-y: auto;
  }

}
  }