.form-password {
  width: 100%;
  max-width: 438px;
  padding: 5px 9px 5px 20px;
  background: #f6f8fb;
  border-radius: 4px;
  display: inline-flex;
  min-width: 200px;
  height: 46px;
  border: 1px solid transparent;
  &:hover {
    box-sizing: border-box;
    background: #f6f8fb;
    border: 1px solid #008fd4;
    .password-label {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      display: flex;
      align-items: flex-end;
      color: #008fd4;
    }
  }
  .password-label {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    color: #b6b6bf;
  }
  .input-password-field {
    background: transparent !important;
    &:-internal-autofill-selected {
      background-image: none !important;
      box-shadow: none !important;
      background-color: -internal-light-dark(
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0)
      ) !important;
      color: #101010 !important;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active {
      box-shadow: 0 0 0 30px #f6f8fb inset !important;
      background-color: transparent !important;
      border: none;
    }

    &:-webkit-autofill:focus {
      box-shadow: 0 0 0 30px #ebf6fc inset !important;
    }
    padding-left: 5px;
    outline: none;
    background-color: transparent;
    border-color: transparent;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    display: flex;
    align-items: flex-end;
    color: #101010;
    width: 100%;
    flex: 1;
  }

  &:focus-within {
    background-color: rgba(0, 143, 212, 0.08);

    border: 1px solid #008fd4;
    .password-label {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      display: flex;
      align-items: flex-end;
      color: #008fd4;
    }
  }
}
