.score_container {
  background: #ffffff;
  border: 1px solid #ebecf1;
  border-radius: 8px;
  width: 100%;
  position: absolute;
  bottom: 40px
}

.score_detailstext {
  float: right;
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: flex-end;
  padding: 0.5em;
  /* Gray */
  color: #b6b6bf !important;
}

.score_text {
  font-family: Archivo;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  /* Black */
  color: #101010;
}

.scoreCont ul li {
  display: inline-block;
  padding-left: 2.7em;
  margin-top: 0.5em;
  padding-bottom: 0.5em;
}

.scoreCont ul {
  display: inline;
}
.maxCount {
  padding-left: 8px;
}
.scoreCont .dashboardBars .bar-filled {
  width: 25px !important;
  background: #40d88e !important;
}
.scoreCont .dashboardBars .bar-active {
  width: 25px !important;
  border: 1px solid#40D88E;
}
.scoreCont .dashboardBars .bar-not-active {
  width: 25px !important;
  background: #ebecf1;
}
.connectIntegrationBtn {
  width: 182px !important;
  margin-left: 16px;
  padding: 5px 23px !important;
  border-radius: 4px;
  color: #ffffff !important;
  /* height: 31px; */
}
.connectIntegrationBtn-disabled {
  width: 182px !important;
  margin-left: 16px;
  padding: 5px 17px !important;
  border-radius: 4px;
  color: #b6b6bf !important;
  border-color: #b6b6bf!important;
  background-color: #f6f8fb!important;
  /* height: 31px; */
}
.freeIntegration .btn-primary:hover {
  background-color: rgba(0,143,212,0.9)!important;
  color:#ffffff !important;
  border-color: #008fd4!important;
}

.freeIntegration span {
  font-size: 14px;
  align-items: flex-end;
  color: #008fd4;
  cursor: pointer;
}

.scoreCont {
  padding: 6px;
}
