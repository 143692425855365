.company-settings-address-modal {
  input:focus {
    border-color: #ced4da;
  }
  .modal-content {
    height: auto !important;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    font-weight: 500;
    .close-icon {
      width: 12px;
    }
  }
  .modal-content {
    border-radius: 8px;
  }
  .modal-footer {
    border-top: none;
    padding: 9px;
    .cancel-btn {
      height: 32px;
      padding: 4px;
      width: 62px;
      height: 32px;
      background: #f6f8fb;
      border-radius: 2px;
      color: #616778;
      margin-right: 9px;
      &:active {
        color: #616778 !important;
        background: #f6f8fb !important;
        border: none !important;
      }
      &:focus {
        color: #616778 !important;
        background: #f6f8fb !important;
        border: none !important;
      }
    }
    .save-btn {
      width: 49px;
      height: 32px;
      background: #008fd4;
      border-radius: 2px;
      color: #ffffff;
      padding: 4px !important;
    }
  }
  .modal-body {
    padding: 20px;
  }
  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #9499aa;
  }
  .label-heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #0c0d11;
  }
}
// Company settings update name modal

.company-settings-name-modal {
  input:focus {
    border-color: #ced4da;
  }
  .modal-header {
    border-bottom: 1px solid lightgray !important;
    font-weight: 500;
    .close-icon {
      width: 12px;
    }
  }
  .modal-content {
    border-radius: 8px;
    max-height: 236px !important ;
    min-height: 220px !important;
  }
  .modal-footer {
    border-top: none;
    padding: 9px;
    .cancel-btn {
      height: 32px;
      padding: 4px;
      width: 62px;
      height: 32px;
      background: #f6f8fb;
      border-radius: 2px;
      color: #616778;
      margin-right: 9px;
      &:active {
        color: #616778 !important;
        background: #f6f8fb !important;
        border: none !important;
      }
      &:focus {
        color: #616778 !important;
        background: #f6f8fb !important;
        border: none !important;
      }
    }
    .save-btn {
      width: auto;
      height: 32px;
      background: #008fd4;
      border-radius: 2px;
      color: #ffffff;
      padding: 4px !important;
    }
  }
  .modal-body {
    padding: 10px 20px;
  }
  input::placeholder {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #9499aa;
  }
  .label-heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #0c0d11;
  }
  .input-error {
    border: 1px solid #f36060 !important;
  }
  .error-text {
    color: #f36060 !important;
    font-size: 12px;
  }
}

.company-settings-notifications-modal{
  max-width: 560px;
  .modal-header {
    border-bottom: 1px solid #dee2e6!important;
  }
  table{
    margin-bottom: unset;
    border-bottom: unset;
  }
  tr {
    display: flex;
    align-items: stretch;
  }
  th{
    color: #9499AA;
    font-weight: 500;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 8px 4px 24px;
    align-items: center;
    border-left: none;
    // height: 40px;
    flex: 1;
    border-top: unset;
  }
  td{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // height: 40px;
    border-left: none;
    flex: 1;
  }
  .w-th-larger{
    max-width: 224px;
  }
  .w-th-smaller{
    max-width: 168px;
  }
  .modal-footer {
    border-top: none;
    padding: 9px;
    justify-content: space-between;
    
    .save-btn {
      width: 49px;
      height: 32px;
      background: #008fd4;
      border-radius: 2px;
      color: #ffffff;
      padding: 4px !important;
    }
  }

  .search{
    display: flex;
    padding: 4px 16px;
    align-items: flex-start;
    align-self: stretch;
    input{
      display: flex;
      padding: 8px;
      align-items: center;
      flex: 1 0 0;
    }
  }
}
.dark-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(12, 13, 17, 0.24); /* Цвет и прозрачность темного фона */
  z-index: 1000; /* Выше всех остальных элементов */
}

.company-settings-selectuser-modal{

  max-width: 480px;
  max-height: 426px;
  min-height: 426px;
  margin-top: 13rem;

  .modal-header {
    border-bottom: 1px solid #dee2e6!important;
  }

  .block{
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: flex-start;
    // gap: 8px;
    align-self: stretch;
  }
  .item{
    padding: 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-right: 1px solid var(--grayscale-gray-05, #EBEDF1);
  }
  .label{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .blue-checkbox-minus input[type="checkbox"]:checked+label {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 13px;
    height: 13px;
    border: 1px solid #000;
    border-radius: 2px;
    outline: none;
    cursor: pointer;
  }
  
  .blue-checkbox-minus input[type="checkbox"]:checked::before {
    content: '−';
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    line-height: 13px;
    color: white;
    background-color: #007bff;
    border-radius: 2px;
    width: 13px;
    height: 13px;
    border: 1px solid #007bff !important;
    font-weight: bold; 
  }

  // .checkboxminus label:after{
	// 	content: '\2212';
	// 	left: 2px;
	// 	opacity: 1;
	// 	transform: scale(1);
  // }
  // .hidden-checkbox {
  //   opacity: 0;
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  // }
  .modal-footer {
    border-top: none;
    padding: 9px;
    justify-content: space-between;
    
    .save-btn {
      width: 49px;
      height: 32px;
      background: #008fd4;
      border-radius: 2px;
      color: #ffffff;
      padding: 4px !important;
    }
  }
}
.company-settings-sendcopy-modal{

  max-width: 480px;
  // height: 340px;
  // min-height: 340px;
  width: 100%;
  // margin-top: 13rem;
  .modal-content {
    border-radius: 8px;
    max-height: 340px !important;
  }

  .modal-header {
    border-bottom: 1px solid #dee2e6!important;
  }

  .edit-task {
    // width: 100%;
    border: 1px solid transparent;
    border-radius: 3px;
    padding: 3px;
    white-space: pre-wrap;
    // overflow: hidden;
    resize: none;
    height: max-content;
    background-color: #fff;
    width: 100%;
  }
  .chip-item{
    display: flex;
    padding: 2px 6px;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    background:  #EBEDF1;
    width: fit-content;
    white-space: nowrap;
  }


  .modal-footer {
    border-top: none;
    padding: 9px;
    justify-content: space-between;
    
    .save-btn {
      width: 49px;
      height: 32px;
      background: #008fd4;
      border-radius: 2px;
      color: #ffffff;
      padding: 4px !important;
    }
  }
}

.taxes-modal {
  max-height: fit-content !important;
  height: fit-content !important;
  .modal-content {
    max-height: 500px !important;
    min-height: 440px !important;
    height: fit-content !important;
    overflow-y: auto;
  }
  .tax-status-section {
    .title {
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: 0.02em;
    }
    .item {
      padding: 4px 12px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.02em;
      .transparennt-wrapper-dropdown .dropdown-toggle{
        opacity: 0;
      }
      &:hover {
        background-color: #F6F8FB;
        .transparennt-wrapper-dropdown .dropdown-toggle{
          opacity: 1;
        }
      }
    }
  }
}