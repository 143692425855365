.c-suite-wrapper {
  margin-top: 2.5rem !important;
  max-width: 60%;
  margin: auto;
  .heading {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #0c0d11;
  }
  .details {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
  .c-suite-settings {
    background: #f6f8fb;
    border-radius: 8px;
  }
  .title {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0c0d11;
  }
  .subtitle {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #616778;
  }
  .send-report-btn {
    background: #008fd4;
    border-radius: 2px;

    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }
  .content-div {
    padding: 1rem;
  }
  .upgrade {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background: #008fd4;
  }
  .premium-text {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 8px;
    color: #ffffff;
  }
  .premium-tag {
    background: #9499aa;
    border-radius: 2px;
    padding: 0px 3px;
  }
}

.test-drop-down {
  .label {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
  .dropdown-toggle {
    background: transparent;
    color: #616778;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    &.locked::after{
      visibility: hidden;
    }
  }

  .show {
    .dropdown-toggle {
      background: transparent;
      color: #616778;
      border: none;
      font-family: "Archivo";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }

  .btn-success:not(:disabled):not(.disabled):active,
  .show > .btn-success.dropdown-toggle {
    background: transparent;
    color: #616778;
    border: none;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }
  .drop-item {
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #616778;
  }
  .selected-item {
    .drop-item {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #0c0d11;
    }
  }

  .week-data {
    // padding: 0rem 0.7rem;
    .week-day {
      font-family: "Archivo";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #0c0d11;
    }
  }
  .check-btn {
    margin-bottom: 1.4rem;
  }
  .status-wrapper {
    background: #f6f8fb;
    padding: 10px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #9499aa;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .c-suite-dropdown {
    #parent-dropdown + .dropdown-menu {
      width: 320px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      padding: 12px 16px;
      min-height: 125px;
    }

    #disabled-dropdown + .dropdown-menu {
      min-height: 130px !important;
    }
    #parent-dropdown::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
    }

    .show {
      #parent-dropdown::after {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #616778;
        border-width: 0px 3px 3px 0px;
        transform: rotate(225deg);
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }
    #parent-dropdown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
  }

  // Frequency Drop down

  .frequency-dropdown {
    #child-frequency-dropdown + .dropdown-menu {
      width: 200px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      padding: 12px 16px;
    }
    #child-frequency-dropdown::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
    }

    .show {
      #child-frequency-dropdown::after {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #616778;
        border-width: 0px 3px 3px 0px;
        transform: rotate(225deg);
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }
    #child-frequency-dropdown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
  }

  // Days of week Drop dwon
  .month-week-dropdown {
    #child-weekday-dropdown + .dropdown-menu {
      width: 200px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      padding: 12px 16px;
    }
    #child-weekday-dropdown::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
    }

    .show {
      #child-weekday-dropdown::after {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #616778;
        border-width: 0px 3px 3px 0px;
        transform: rotate(225deg);
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }
    #child-weekday-dropdown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }

    #child-month-dropdown + .dropdown-menu {
      width: 200px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      padding: 12px 16px;
    }

    #child-month-dropdown::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
    }

    .show {
      #child-month-dropdown::after {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #616778;
        border-width: 0px 3px 3px 0px;
        transform: rotate(225deg);
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }
    #child-month-dropdown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
  }

  // Time drop down

  .time-dropdown {
    #child-time-dropdown + .dropdown-menu {
      width: 200px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      padding: 12px 16px;
    }
    #child-time-dropdown::after {
      box-sizing: border-box;
      height: 8px;
      width: 8px;
      border-style: solid;
      border-color: #616778;
      border-width: 0px 3px 3px 0px;
      transform: rotate(45deg);
      margin-left: 9px;
      margin-bottom: -3px;
    }

    .show {
      #child-time-dropdown::after {
        box-sizing: border-box;
        height: 8px;
        width: 8px;
        border-style: solid;
        border-color: #616778;
        border-width: 0px 3px 3px 0px;
        transform: rotate(225deg);
        margin-left: 9px;
        margin-bottom: -3px;
      }
    }
    #child-time-dropdown.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    }
  }
  .last-div {
    margin-bottom: 6.5rem !important;
  }
}
