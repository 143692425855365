.supplychain-requestsmodal {

  .modal-body::-webkit-scrollbar {
    display: none;
  }

  .modal-header {
    border-bottom: 1px solid #ebedf1 !important;
  }

  .modal-content {
    border-radius: 6px;
    height: auto;
    min-height: 550px;
  }

  .image-group {
    margin-left: 15px;

    img {
      margin-left: -10px;
    }
  }

  .request-item {
    padding: 0.8rem 0.6rem;

    &:hover {
      background: #f6f8fb;
      border-radius: 4px;
    }
  }

  .search-supplier-input {
    margin-top: 9px;
    padding-left: 10px;
    border: none;
    height: 34px;
    background: #f6f8fb;
    border-radius: 4px;

    &::placeholder {
      color: #9499aa;
    }
  }

  .sent-request-dropdown {
    .custom_drop-down {
      background: transparent !important;

      .tick {
        display: none;
      }
    }
  }

  .requestlist-dropdown {
    .device-dropdown button {
      background: transparent;
      border: none;

      &::after {
        display: none;
      }
      
    }

    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .show {
      .dropdown-toggle {
        border: none;
      }
    }

    .dropdown-menu.show {
      background: #0C0D11;
      box-shadow: 0px 24px 40px rgba(16, 16, 16, 0.16);
      border-radius: 4px;
      inset: auto 0px auto auto !important;
      transform: translate(0px,0px)  !important;
      color: rgba(255, 255, 255, 0.64);
      width: 210px; //fit-content; //165px;
      font-weight: 500;

      .drop-item {
        padding: 5px 9px;

        &:hover {
          background: #ffffff20;
        }
      }
    }
  }

  // Client Request data css
  .verify-client-alert {
    background: #ffffff;

    border: 1px solid #ebedf1;
    border-radius: 8px;
  }

  .client-reponse {
    background: #f6f8fb;
    border-radius: 4px;
    padding: 0.8rem;
  }

  .primary-kpi-dropdown {
    .custom_drop-down .dropdown-menu.show {
      width: 200px;
    }
  }

  .compliance-filter-dropdown {
    .tick {
      display: none;
    }

    .custom_drop-down {
      background: transparent;

      button {
        .selected-option {
          color: #616778;
          background-color: #ffffff25;
        }
      }

      .dropdown-menu.show {
        background: #0C0D11;
        color: rgba(255, 255, 255, 0.64);
        width: max-content;
      }

      .selected {
        color: rgba(255, 255, 255, 0.64) !important;
      }

      .dropdown_item {
        color: rgba(255, 255, 255, 0.64);

        &.selected {
          background-color: #ffffff25 !important;
        }

        &:hover {
          background-color: #ffffff25;
        }
      }
    }

  }

  .user-detail-dropdown {
    .device-dropdown button {
      background: transparent;
      border: none;

      &::after {
        display: none;
      }
    }

    .dropdown-toggle {
      background: transparent !important;
      border: none !important;
    }

    .show {
      .dropdown-toggle {
        border: none;
        // border: 1px solid #f36060;
        // border-radius: 2px;
      }
    }

    .dropdown-menu.show {
      background: #0c0d11;
      box-shadow: 0px 5px 12px rgba(16, 16, 16, 0.09);
      border-radius: 4px;
      transform: translate(0px, 0px) !important;
      inset: auto 0px auto auto !important;
      width: 200px;
    }

    .notification-btn {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.64);
      padding: 4px 13px;
    }

    .remove-btn {
      cursor: pointer;
      color: #f36060;
      padding: 5px 10px 5px 13px;
    }
  }


  .disabled-dropdown {
    pointer-events: none;

    .selected-option {
      margin-right: 0px;
    }

    .drop-placeholder {
      display: none;
    }

    .dropdown-toggle::after {
      display: none;
    }

    .dropdown-toggle {
      padding-right: 2px;
    }
  }

  .dark-dropdown .custom_drop-down {
    img {
      display: none;
    }

    background: transparent;

    .selected {

      background: #ffffff20 !important;
    }

    .dropdown-menu.show {
      background: #0c0d11 !important;

    }

    .child_drop:hover {
      background: #ffffff20 !important;
      color: rgba(255, 255, 255, 0.64) !important;
    }

    .dropdown_item:hover {
      background-color: #ffffff20 !important;
      color: rgba(255, 255, 255, 0.64) !important;
    }

    .dropdown_item.selected {
      background-color: #ffffff20 !important;
      color: rgba(255, 255, 255, 0.64) !important;
    }

    .delete-btn {
      color: #f36060 !important;
    }

    hr {
      border-top: 1px solid #ffffff24 !important;
    }

    .dropdown-menu.show {
      width: max-content !important;
    }
  }
}