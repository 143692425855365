.supplier-profiler{
    .main-block {
        display: flex;
        padding: 24px 24px 155px 24px;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        // flex: 1 0 0;
        align-self: stretch;
    }

    .flex-row{
        flex-direction: row;
    }
    .btn-block{
        gap: 8px;
    }

    .py-20px{
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .py-6px{
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .thread{
      line-height: 22px;

      &:hover{
        background: #f6f8fb;
        border-radius: 4px;
        .hover-visible{
          display: block;
        }
      }
    }
    .thread-selected{
        background: #f6f8fb;
        border-radius: 4px;
        .hover-visible{
          display: block;
        }
      
    }
    .timeline-circle{
      margin-top: 12px;
      z-index: 0;
    }
    .dragndrop-item{
        // align-items: flex-start;
        line-height: 22px;
        justify-items: start;
        // display: grid;
        // grid-template-columns: 5% 95%;
        // padding: 6px;
      //   border-left: 1px solid grey;
      // margin-left: -19px;
    }

    .info-timeline ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .info-timeline ul li span.timeline-circle {
      position: relative;
      border: 1px solid #BBBEC9;
      border-radius: 100%;
      width: 14px;
      height: 13px;
      line-height: 9px;
      text-align: center;
      margin-top: 10px;
      background-color: #fff;
      z-index: 0;
      display: inline-block;
      font-size: 14px;
      color: #BBBEC9;
    }

    .info-timeline ul li:not(:first-of-type) span.timeline-circle::before {
      // .info-timeline ul .lll li:first-of-type span.timeline-circle::before {
      position: absolute;
      border-left: 1px solid #BBBEC9;
      width: 0;
      height: 22px;
      display: block;
      content: '';
      left: 50%;
      z-index: 1;
      top: -23px;
      margin-left: -1px;
    }
    // .info-timeline ul li span.timeline-circle::before {
    //   position: absolute;
    //   border-left: 1px solid #BBBEC9;
    //   width: 0;
    //   height: 22px;
    //   display: block;
    //   content: '';
    //   left: 50%;
    //   z-index: 1;
    //   top: -23px;
    //   margin-left: -1px;
    // }

    .info-timeline ul li a {
      color: #000;
    }
    .plus{
      margin-top: -1px;
      margin-left: -1px;
      font-size: 10px;
    }
    .risk-drop{
      display: flex;
      padding: 4px 4px 4px 8px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 4px;

      .risk-item{

        .tooltip-show{
          display: none;
          &:hover{
            display: block;
          }
          
        }
        .tooltip-img{
          display: block;
          left: 147px;
          top: -1px;
        }
       
      }
     
      .menu{
      display: flex;
      width: 280px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      // inset: 36px -71px auto auto !important;
      inset: 36px auto auto auto !important;
        .submenu{
          inset: -19px -20px auto auto !important;
        }
         .impact{
          inset: -64px -20px auto auto !important;
        }
      }

      .white-arr{
         .dropdown-toggle {
    
        &::after {
          background-image: url("../../../public/images/theme/icons/dropdownarrow-white.svg");
          background-repeat: no-repeat;
          width: 20px;
          height: 20px;
          border: none !important;
          margin-bottom: -3px;
          transform: scale(1.45);
          margin-left: 0.8rem;
        }
      }
      }
     
    }
   
}



.supplier-menu .custom-dropdown-toggle::after{
  display: none;
}
.dropdown-history{
  position: relative;
  .history{
    z-index: 1;
    position: absolute;
    display: none;

    padding: 4px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    align-self: stretch;

    border-radius: 4px;
    border: 1px solid var(--grayscale-gray-05, #EBEDF1);
    background: var(--white-white-100, #FFF);
    /* Shadow / Bottom / Medium / M */
    box-shadow: 0px 12px 24px 0px rgba(16, 16, 16, 0.08);
  }

  &:hover{
    .history{
      display: flex;
    }
  }
}
.dropdown-revoke-text{
  display: flex;
  flex-direction: row;
  // white-space: pre-wrap;
  flex-wrap: wrap;
  padding: 4px 8px;
  line-height: 22px;
  letter-spacing: 0.28px;
  width: 280px;
}

.dropdown-revoke{
  display: flex;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  white-space: nowrap;

  line-height: 22px;
  letter-spacing: 0.28px;
  width: 280px;

  .item{
    display: flex;
    padding: 4px 8px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    justify-content: space-between;
    .revoke{
      display: none;
    }

    &:hover{
      border-radius: 4px;
      background-color: #F6F8FB;
      .revoke{
        display: flex;
      }
    }
  }
  span{
    display: inline-block;
    white-space: nowrap;
  }
}
.revoke-btn{
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 2px;
}
.revoke-drop-position{
  .dropdown-menu.show {
    inset: auto -17px auto auto !important;
}
}
.hover-visible{
  display: none;
  &:hover{
    display: block;
  }
}
.custom_tooltip .arrow {
  display: inline-flex !important;
}
.custom_tooltip_wrapper{
  padding:0;
}
.tooltip_subtitle{
  margin-top: 0px;
}
.custom_tooltip_risk{
  margin-right: 80px;
  // margin-right: -200px;
}
@media (min-width: 1230px) and (max-width: 1366px) {
  .custom_tooltip_risk{
    margin-right: 220px;
    // margin-right: -200px;
  }
}
@media (min-width: 1366px) and (max-width: 1500px) {
  .custom_tooltip_risk{
    margin-right: 80px;
    // margin-right: -200px;
  }
}
@media (min-width: 1500px) and (max-width: 1600px) {
  .custom_tooltip_risk{
    margin-right: 100px;
    // margin-right: -200px;
  }
}
@media (min-width: 1600px) and (max-width: 1850px) {
  .custom_tooltip_risk{
    margin-right: -240px;
  }
}
@media (min-width: 1850px) and (max-width: 2100px) {
  .custom_tooltip_risk{
    margin-right: -410px;
  }
}
.edit-task {
  // width: 100%;
  border: 1px solid transparent;
  border-radius: 3px;
  padding: 4px;
  white-space: pre-wrap;
  // overflow: hidden;
  resize: none;
  height: max-content;
  // background-color: #fff;
  background-color: transparent;
}
.edit-title{
  border-radius: 4px;
  border:
    2px solid var(--primary-primary-10, #99D2EE);
  background: #F6F8FB;
  // padding: 4px;
}
.text-input{
  &:active,
  &:focus,
  &:focus-within {
    border-radius: 4px;
  border:
    2px solid var(--primary-primary-10, #99D2EE);
  // background: #F6F8FB;
  margin: -2px;
  }
}

.supplier-assessment{
    padding-bottom: 24px;

    .flex-row{
      flex-direction: row;
  }
    .title{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .block{
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .open-block{
        padding: 2px;
        display: none;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
        
      }
      &:hover{
        .open-block{
        display: flex;
        }
      }
    }
    .item-question{
      line-height: 22px;
      justify-items: start;
      display: grid;
      grid-template-columns: 30% 60% 10%;
      padding: 6px;
      align-items: center;
      // &:hover{
      //   .hover-visible{
      //     display: block;
      //   }
      // }
    }
    .p-6px{
      padding: 6px;
    }
    .summary{
      width: 610px;
    }
    .selector-list-item{
       &:hover{
        .hover-visible{
          display: block;
        }
      }
    }

    .edit-task {
      // width: 100%;
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 3px;
      white-space: pre-wrap;
      // overflow: hidden;
      resize: none;
      height: max-content;
      background-color: #fff;
    }

    .edit-title{
      border-radius: 4px;
      border:
        2px solid var(--primary-primary-10, #99D2EE);
      background: #F6F8FB;
    }

    .chip-item{
      display: flex;
      padding: 2px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      background:  #EBEDF1;
      width: fit-content;
      white-space: nowrap;
    }

    .text-icon {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      background: #EBEDF1;
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4px 8px;
      // padding: 20px;
    }

    .file-blocks{
      // display: flex;
      height: 100%;
      padding: 12px;
      align-items: center;
      // gap: 8px;
      display: grid;
      grid-template-columns: 10% 75% 15%;
      // gap: 16px;
      // flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid #EBEDF1;
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }
    .file-block-info{
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
    }

    .grey-dot{
      background: #BBBEC9;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      display: inline-flex;
    }
    .link-file{
      display: flex;
      padding: 2px 6px 2px 2px;
      justify-content: center;
      align-items: center;
      line-height: 18px;
    }
    .add-btn{
      border-radius: 2px;
      border: 1px solid #EBEDF1;
      display: flex;
      padding: 2px 6px 2px 2px;
      justify-content: center;
      align-items: center;
      line-height: 18px;
    }

    .framework-block{
      // display: flex;
      padding: 12px;
      align-items: center;
      gap: 8px;
      // flex: 1 0 0;
      display: grid;
      grid-template-columns: 59% 33% 6%;
      border-radius: 8px;
      border: 1px solid var(--grayscale-gray-05, #EBEDF1);
      background: var(--white-white-100, #FFF);
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }

    .provider-block{
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        // flex: 1 0 0;
        display: grid;
        grid-template-columns: 95% 5%;
        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-05, #EBEDF1);
        .dots-hover{
          display: none;
          
        }
        &:hover{
          .dots-hover{
          display: flex;
          }
        }
    }

    .certificate-block{
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--grayscale-gray-05, #EBEDF1);
      min-height: 200px;

    }

    .add-cert-block{
      height: 100%;
        display: flex;
        padding: 12px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-05, #EBEDF1);
        min-height: 200px;
    }

    .certificate-title{
      display: flex;
      align-items: center;
      gap: 8px;
      display: grid;
      grid-template-columns: 95% 5%;
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }

    .cert-item{
        display: grid;
        grid-template-columns: 7% 70% 18% 5%;
        align-items: center;
        padding-bottom: 4px;
        .dots-hover{
          display: none;
          
        }
        &:hover{
          .dots-hover{
          display: flex;
          }
        }
    }
    .cert-item-add{
      display: grid;
      grid-template-columns: 7% 70% 18% 5%;
      align-items: center;
      padding-bottom: 4px;
    }

    .info-line ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .info-line ul li span.timeline-circle {
      position: relative;
      border: 1px solid #BBBEC9;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      line-height: 12px;
      text-align: center;
      margin-top: 10px;
      background-color: #fff;
      z-index: 2;
      display: inline-block;
    }

    .info-line ul li:not(:first-of-type) span.timeline-circle::before {
      position: absolute;
      border-left: 1px solid #BBBEC9;
      width: 0;
      height: 22px;
      display: block;
      content: '';
      left: 50%;
      z-index: 1;
      top: -23px;
      margin-left: -1px;
    }

    .info-line ul li a {
      color: #000;
    }

    .img-block{
      width: 24px;
      height: 24px;
      // margin-left: -7px;
      margin-top: -2px;
    }
    .inprogress{
      color: var(--primary-primary-50, #008FD4);
    }
    .expiring{
      color: var(--support-warning-50, #FFAC2C);
    }
    .expired{
      color: var(--support-alert-50, #F36060);
    }

    .line-div{
      border-left: 1px solid #BBBEC9;
      margin-bottom: -22px;
      padding-bottom: 22px;
      margin-left: -24px;
      padding-left: 24px;
      margin-top: -32px;
      padding-top: 32px;
    }
    .header{
      padding-left: 8px;
    }
    .supplier-menu .custom-dropdown-toggle::after{
      display: none;
    }
    .dropdown-revoke-text{
      display: flex;
      flex-direction: row;
      // white-space: pre-wrap;
      flex-wrap: wrap;
      padding: 4px 8px;
      line-height: 22px;
      letter-spacing: 0.28px;
      width: 280px;
    }
    .dropdown-revoke{
      display: flex;
      padding: 4px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      // white-space: nowrap;
      white-space: pre-wrap;

      line-height: 22px;
      letter-spacing: 0.28px;
      width: 280px;

      .item{
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        justify-content: space-between;
        .revoke{
          display: none;
        }

        &:hover{
          border-radius: 4px;
          background-color: #F6F8FB;
          .revoke{
            display: flex;
          }
        }
      }
      span{
        display: inline-block;
        white-space: nowrap;
      }
    }
    .dropdown-contributors{
      display: flex;
      padding: 4px 8px;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      // white-space: nowrap;
      white-space: pre-wrap;

      line-height: 22px;
      letter-spacing: 0.28px;
      width: 280px;

      .item{
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        justify-content: space-between;
        .revoke{
          display: none;
        }

        &:hover{
          border-radius: 4px;
          background-color: #F6F8FB;
          .revoke{
            display: flex;
          }
        }
      }
      span{
        display: inline-block;
        white-space: nowrap;
      }
    }

    .revoke-btn{
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 2px;
    }
    .revoke-drop-position{
        .dropdown-menu.show {
          inset: auto -17px auto auto !important;
      }
    }

    .message-block{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .secops_training_certificate_overlap_parent{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    // @media (min-width: 576px)
    .divider{
      border-bottom: 1px solid #EBEDF1!important;
    }
}

.question-chat-dropdown {
  .dropdown-toggle {
      // &:active, &:hover {
      //     border: 0px;
      //     background: transparent;

      //     &:after {
      //         display: none;
      //     }
      // }

      border: 0px;
      background: transparent !important;

      &:after {
          display: none;
      }
  }
  .drop-item {
    padding: 5px 12px;

    &:hover {
      background: #ebedf130;
    }
  }
  .dropdown-menu.show {
      width: 312px;
      // inset: auto 0px auto auto !important;
  }
    .dropdown.show .dropdown-menu {
      animation-name: pop-up;
      opacity: 1;
      animation-duration: 400ms;
    }
   
    @keyframes pop-up {
      0% {
         scale:0  ;
      }
      70% {
          scale:1.1  ;
      }
      100% {
          scale:1  ;
      }
    }
}
.supplier-dropdown-custom{
  // .selected-option
  .dropdown-toggle::after {
//  right: -18px !important;
}
}
.contributor-info{
  .dropdown-menu-contributors {
  inset: -70px auto auto -310px !important;
  }
}
.dropdown-menu-contributors{
  .item{
    &:hover{
      background-color: #fff !important;
    }
  }
}

  
.sidetoggle-dropdown-custom::after {
    background-image: url("../../../public/images/theme/icons/dropdownarrow-right.svg") !important;
    background-repeat: no-repeat;
    width: 8px;
    height: 6px;
    border: none !important;
    margin-bottom: 4px;
    transform: scale(1.45);
    margin-left: 0.8rem;
    padding: 7px;
}


.options_drop-down {
  // background: #f6f8fb;
  border-radius: 4px;

  .py-4px{
    padding: 4px 0;
  }

  .selected {
    color: #101010 !important;
  }

  &.show {
    .btn-primary.dropdown-toggle {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }
  }

  .dropdown-menu {
    background: #ffffff;
    border: 1px solid #f6f8fb;
    box-shadow: 0px 16px 40px rgba(16, 16, 16, 0.08);
    border-radius: 4px;
  }

  .dropdown_item {
    cursor: pointer;
    padding: 8px 16px;
    font-family: "Archivo";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #616778;

    .tick {
      height: 10px;
      margin-top: 0px;
    }

    &.selected {
      background-color: rgba(0, 143, 212, 0);
    }

    &:hover {
      background-color: rgba(0, 143, 212, 0);
    }
  }

  .selected-option {
    margin-right: 18px;
  }

  .dropdown-toggle {
    text-align: left;
    background: transparent !important;
    border: none !important;
    border-radius: 4px;
    // color: #b6b6bf;
    color: #616778;
    font-weight: 400;
    font-family: "Archivo";
    font-style: normal;
    // font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    // padding: 8px 16px;
    width: 100%;
    display: flex;
    align-items: center;

    &.btn-primary:not(:disabled):not(.disabled):active {
      color: #b6b6bf;
      border: 1px solid #008fd4;
      background-color: rgba(0, 143, 212, 0.08);
      box-shadow: none;
    }

    &:hover {
      border: 1px solid #008fd4;
    }

    &::after {
      content: " ";
      background-image: url("../../../public/images/big-chevron-down.svg") !important;
      background-position: 0 6px;
      height: 20px;
      width: 20px;
      position: absolute;
      background-repeat: no-repeat;
      border: 0;
      right: -6px;
    }
  }

  .dropdown-menu {
    &.show.left-align {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
  .dropdown-menu {
    &.show.right-align {
      position: absolute;
      inset: auto 0px auto 0px !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }

  .dropdown-menu {
    &.show {
      position: absolute;
      inset: auto 0px auto auto !important;
      margin: 0px;
      transform: translate(0px, 0px) !important;
      width: max-content;
    }
  }
}

.inset-addsection{
  .dropdown-menu.show {

    inset: auto auto auto auto !important;
  }
}

.important-supplier{

  .item{
    border-bottom: 1px solid #EBEDF1;
    padding: 8px 12px;
    // align-items: center;
    &:last-child, &:first-child{
      border: none;
    }
  }
}

// .focused-input:focus-within{
//   border: 1px solid #ededed;
//   padding: 4px;
//   border-radius: 4px;
//   background-color: #fff;
// }
.input-style {
  border: 1px solid #ccc;
  padding: 5px;
}

/* Style when the input is focused */
.input-style:focus, .input-style:focus-within {
  border: 2px solid blue;
}

.custom-chat{
  inset: 0px 0px auto auto !important;
    margin: 0px;
    transform: translate(0px, 22px) !important;
}
// .chat-body::before,
// .chat-body::after {
//   content: '';
//   position: absolute;
//   left: 0;
//   right: 0;
//   height: 30px; /* Adjust the height of the fade effect as needed */
//   pointer-events: none;
//   background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%); /* Fading background */
// }
.chat-body::before,
.chat-body::after,
.message-test::before
{
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 40px; /* Adjust the height of the fade effect as needed */
  pointer-events: none;
}

.chat-body::before {
  top:37px;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.00) 100%);

  // background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #ededed 100%);
}

.message-test::before{
  top:-42px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%);
}

// .chat-body::after {
//   bottom: 48px;
//   background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #fff 100%);
//   // background: linear-gradient(180deg, #ededed 0%, rgba(255, 255, 255, 0.00) 100%);
// }

.supplier-assessment ul li:last-child .line-div {
  border-left: unset;
}

.profilingModal{
  .supplier-assessment .line-div {
    border-left: 1px solid #BBBEC9;
    margin-bottom: -22px;
    padding-bottom: 22px;
    margin-left: -19px;
    padding-left: 19px;
    margin-top: -52px;
    padding-top: 52px;
}
.supplier-assessment .info-line ul li span.timeline-circle {
  position: relative;
  // border: 1px solid #BBBEC9;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  line-height: 12px;
  text-align: center;
  margin-top: 10px;
  background-color: #fff;
  z-index: 2;
  display: inline-block;
}

.supplier-assessment .info-line ul li:not(:first-of-type) span.timeline-circle::before {
  position: absolute;
  border-left: 1px solid #BBBEC9;
  width: 0;
  height: 22px;
  // display: block;
  display: none;
  content: '';
  left: 50%;
  z-index: 1;
  top: -23spx;
  margin-left: -1px;
}

//  padding-bottom: 24px;

    .flex-row{
      flex-direction: row;
  }
    .title{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .block{
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .open-block{
        padding: 2px;
        display: none;
        font-weight: 500;
        font-size: 12px;
        cursor: pointer;
        
      }
      &:hover{
        .open-block{
        display: flex;
        }
      }
    }
    .item-question{
      line-height: 22px;
      justify-items: start;
      display: grid;
      grid-template-columns: 48% 50% 10%;
      padding: 6px;
      align-items: center;
      // &:hover{
      //   .hover-visible{
      //     display: block;
      //   }
      // }
    }
    .p-6px{
      padding: 6px;
    }
    .summary{
      width: 270px;
    }
    

    .edit-task {
      // width: 100%;
      border: 1px solid transparent;
      border-radius: 3px;
      padding: 3px;
      white-space: pre-wrap;
      // overflow: hidden;
      resize: none;
      height: max-content;
      background-color: #fff;
    }

    .edit-title{
      border-radius: 4px;
      border:
        2px solid var(--primary-primary-10, #99D2EE);
      background: #F6F8FB;
    }

    .chip-item{
      display: flex;
      padding: 2px 6px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      background:  #EBEDF1;
      width: fit-content;
      white-space: nowrap;
    }

    .text-icon {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 999px;
      background: #EBEDF1;
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 4px 8px;
      // padding: 20px;
    }

    .file-blocks{
      // display: flex;
      height: 100%;
      padding: 12px;
      align-items: center;
      // gap: 8px;
      display: grid;
      grid-template-columns: 23% 61% 15%;
      // grid-template-columns: 15% 70% 15%;
      // gap: 16px;
      // flex: 1 0 0;
      border-radius: 8px;
      border: 1px solid #EBEDF1;
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }
    .file-block-info{
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
    }

    .grey-dot{
      background: #BBBEC9;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      display: inline-flex;
    }
    .link-file{
      display: flex;
      padding: 2px 6px 2px 2px;
      justify-content: center;
      align-items: center;
      line-height: 18px;
    }
    .add-btn{
      border-radius: 2px;
      border: 1px solid #EBEDF1;
      display: flex;
      padding: 2px 6px 2px 2px;
      justify-content: center;
      align-items: center;
      line-height: 18px;
    }

    .framework-block{
      // display: flex;
      padding: 12px;
      align-items: center;
      gap: 8px;
      // flex: 1 0 0;
      display: grid;
      grid-template-columns: 59% 33% 6%;
      border-radius: 8px;
      border: 1px solid var(--grayscale-gray-05, #EBEDF1);
      background: var(--white-white-100, #FFF);
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }

    .provider-block{
        display: flex;
        padding: 12px;
        align-items: center;
        gap: 8px;
        // flex: 1 0 0;
        display: grid;
        grid-template-columns: 95% 5%;
        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-05, #EBEDF1);
        .dots-hover{
          display: none;
          
        }
        &:hover{
          .dots-hover{
          display: flex;
          }
        }
    }

    .certificate-block{
      display: flex;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      flex: 1 0 0;
      align-self: stretch;
      border-radius: 8px;
      border: 1px solid var(--grayscale-gray-05, #EBEDF1);
      min-height: 200px;

    }

    .add-cert-block{
        display: flex;
        padding: 12px;
        align-items: center;
        justify-content: center;
        gap: 8px;
        align-self: stretch;
        border-radius: 8px;
        border: 1px solid var(--grayscale-gray-05, #EBEDF1);
        min-height: 200px;
    }

    .certificate-title{
      display: flex;
      align-items: center;
      gap: 8px;
      display: grid;
      grid-template-columns: 95% 5%;
      .dots-hover{
        display: none;
        
      }
      &:hover{
        .dots-hover{
        display: flex;
        }
      }
    }

    .cert-item{
        display: grid;
        grid-template-columns: 7% 70% 18% 5%;
        align-items: center;
        padding-bottom: 4px;
        .dots-hover{
          display: none;
          
        }
        &:hover{
          .dots-hover{
          display: flex;
          }
        }
    }
    .cert-item-add{
      display: grid;
      grid-template-columns: 10% 80% 5% 5%;
      align-items: center;
      padding-bottom: 4px;
    }

    .info-line ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .info-line ul li span.timeline-circle {
      position: relative;
      border: 1px solid #BBBEC9;
      border-radius: 100%;
      width: 12px;
      height: 12px;
      line-height: 12px;
      text-align: center;
      margin-top: 10px;
      background-color: #fff;
      z-index: 2;
      display: inline-block;
    }

    .info-line ul li:not(:first-of-type) span.timeline-circle::before {
      position: absolute;
      border-left: 1px solid #BBBEC9;
      width: 0;
      height: 22px;
      display: block;
      content: '';
      left: 50%;
      z-index: 1;
      top: -27px;
      margin-left: -1px;
    }

    .info-line ul li a {
      color: #000;
    }

    .img-block{
      width: 24px;
      height: 24px;
      margin-left: -7px;
      margin-top: -7px;
    }
    .inprogress{
      color: var(--primary-primary-50, #008FD4);
    }
    .expiring{
      color: var(--support-warning-50, #FFAC2C);
    }
    .expired{
      color: var(--support-alert-50, #F36060);
    }

    .line-div{
      border-left: 1px solid #BBBEC9;
      margin-bottom: -22px;
      padding-bottom: 22px;
      margin-left: -24px;
      padding-left: 24px;
      margin-top: -32px;
      padding-top: 32px;
    }
    .header{
      padding-left: 8px;
    }
    .supplier-menu .custom-dropdown-toggle::after{
      display: none;
    }
    .dropdown-revoke-text{
      display: flex;
      flex-direction: row;
      // white-space: pre-wrap;
      flex-wrap: wrap;
      padding: 4px 8px;
      line-height: 22px;
      letter-spacing: 0.28px;
      width: 280px;
    }
    .dropdown-revoke{
      display: flex;
      padding: 4px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      // white-space: nowrap;
      white-space: pre-wrap;

      line-height: 22px;
      letter-spacing: 0.28px;
      width: 280px;

      .item{
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 8px;
        align-self: stretch;
        justify-content: space-between;
        .revoke{
          display: none;
        }

        &:hover{
          border-radius: 4px;
          background-color: #F6F8FB;
          .revoke{
            display: flex;
          }
        }
      }
      span{
        display: inline-block;
        white-space: nowrap;
      }
    }

    .revoke-btn{
      display: flex;
      padding: 4px;
      justify-content: center;
      align-items: center;
      align-self: stretch;
      border-radius: 2px;
    }

    .revoke-drop-position{
      .dropdown-menu.show {
        inset: auto -17px auto auto !important;
    }
    }

    .message-block{
      display: flex;
      justify-content: flex-end;
      width: 100%;
    }

    .secops_training_certificate_overlap_parent{
      display: flex;
      align-items: center;
      gap: 8px;
    }
    // @media (min-width: 576px)
    .modal-dialog {
        max-width: 1200px;
        margin: 1.75rem auto;
    }
    .divider{
      border-bottom: 1px solid #EBEDF1!important;
    }
}
