.category-selector {
  .menu-item {
    padding: 4px;
    border-radius: 4px;
    &:hover {
      background-color: #F6F8FB;
    }
  }
  .category-item {
    background-color: #EBEDF1;
    padding: 2px;
    border-radius: 9999px;
    width: fit-content;
  }
}